<h1 mat-dialog-title>Create a discussion</h1>

<div mat-dialog-content class="container flex column gap-16">
  <div class="secondary-text">Discussions will be automatically closed after 14 days of inactivity.</div>
  <!-- <mat-slide-toggle color="primary" > This discussion will be inside this channel. </mat-slide-toggle> -->
  <div class="flex column gap-8">
    <div>Initial message</div>
    <div class="message-preview">
      <csh-chat-message
        [configMessageOption]="configMessageOption"
        [previousMessage]="null"
        [message]="data.message"
      ></csh-chat-message>
    </div>
  </div>
  <mat-form-field>
    <mat-label>Discussion name</mat-label>
    <input
      matInput
      [maxlength]="MAX_LENGTH"
      [formControl]="nameControl"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="nameControl.hasError('required')"> Discussion name is required </mat-error>
    <mat-error *ngIf="nameControl.hasError('channel-existed')"> Channel already exists </mat-error>
    <mat-error *ngIf="nameControl.hasError('maxlength')"> Maximum 30 characters </mat-error>
    <mat-error *ngIf="nameControl.hasError('invalid-name')">
      Discussion name can't contain spaces, periods, or most punctuation.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Discussion description</mat-label>
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10"
      [formControl]="description"
      [errorStateMatcher]="matcher"
    ></textarea>
    <mat-error *ngIf="description.hasError('maxlength')"> Maximum 1000 characters </mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions class="flex justify-content-end align-items-center">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    (click)="create()"
    color="primary"
    [loading]="creating"
    [disabled]="description.invalid || nameControl.invalid"
  >
    Create
  </button>
</div>
