import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChatMessage, IMessBodyData, IMessComponent } from '@b3networks/api/chat';
import { InfoShowMention } from '../../../core/state/app-state.model';

@Component({
  selector: 'csh-interactive-message',
  templateUrl: './interactive-message.component.html',
  styleUrls: ['./interactive-message.component.scss']
})
export class InteractiveMessageComponent implements OnChanges {
  @Input() message: ChatMessage;

  @Output() showProfile: EventEmitter<InfoShowMention> = new EventEmitter<InfoShowMention>();

  firstComponents: IMessComponent[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['message']) {
      if (!this.message.state.isErrorFormat) {
        this.firstComponents = (this.message.body?.data as IMessBodyData)?.components || [];
      }
    }
  }
}
