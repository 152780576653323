export class SupplierInfo {
  supplierOrgUuid: string;
  inboxName: string;
  inboxUuid: string;

  constructor(obj?: Partial<SupplierInfo>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
