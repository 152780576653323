import { Injectable } from '@angular/core';
import {
  ActivityType,
  CaseInfoSystemMessage,
  ChatMessage,
  ConversationType,
  ConvoType,
  GroupType,
  Status,
  SystemMsgType,
  TxnMessageData,
  TxnMoveInboxData
} from '@b3networks/api/chat';
import { Contact, ContactQuery, ContactService } from '@b3networks/api/contact';
import {
  ActivitiesService,
  CaseActivity,
  CaseInfo,
  InboxesQuery,
  InboxesService,
  PENDING_TXNS,
  TXNS_SUPPORT,
  Txn,
  TxnQuery,
  TxnService
} from '@b3networks/api/inbox';
import {
  ConversationGroup,
  ConversationGroupQuery,
  ConversationGroupService,
  CustomerInfo
} from '@b3networks/api/workspace';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { ViewTxn } from '../state/app-state.model';
import { AppQuery } from '../state/app.query';

@Injectable({ providedIn: 'root' })
export class MessageSystemTxnService {
  private _moveInbox$: Subject<TxnMoveInboxData> = new Subject();

  pushEventMoveInbox(moveIndex: TxnMoveInboxData) {
    this._moveInbox$.next(moveIndex);
  }

  onMoveInbox() {
    return this._moveInbox$.asObservable().pipe(share());
  }

  constructor(
    private contactQuery: ContactQuery,
    private contactService: ContactService,
    private inboxesQuery: InboxesQuery,
    private inboxesService: InboxesService,
    private txnQuery: TxnQuery,
    private txnService: TxnService,
    private convoGroupQuery: ConversationGroupQuery,
    private convoGroupService: ConversationGroupService,
    private appQuery: AppQuery,
    private activitiesService: ActivitiesService
  ) {}

  addConversation2Store(message: ChatMessage) {
    const rootConvo = this.addTeamConvo(message);
    this.addPublicConvo(message);
    return this.convoGroupQuery.getEntity(rootConvo.conversationGroupId);
  }

  checkAndStoreTxnContact(data: TxnMessageData, message: ChatMessage, notCountPending = false) {
    if (data?.txnUuid) {
      const entity = this.txnQuery.getEntity(data.txnUuid);
      const txn = { ...entity };
      if (!txn?.createdAt) txn.createdAt = Date.now();
      if (txn.endedAt && data.type === SystemMsgType.unarchived) {
        txn.endedAt = null;
      }

      //  count/discount pending txn
      if (
        !notCountPending &&
        !!entity &&
        TXNS_SUPPORT.includes(data?.channel) &&
        entity?.status &&
        entity.inboxUuid &&
        data?.status &&
        data.inboxUuid &&
        data.inboxUuid === entity.inboxUuid
      ) {
        if (PENDING_TXNS.includes(entity.status) && !PENDING_TXNS.includes(data?.status)) {
          this.inboxesService.descreasePendingTxn(data?.inboxUuid);
        } else if (!PENDING_TXNS.includes(entity.status) && PENDING_TXNS.includes(data?.status)) {
          // count pending txn
          this.inboxesService.inscreasePendingTxn(data?.inboxUuid);
        }
      }

      // mapping txn model
      const txnUpdated = this.converterTxn(data, message);
      Object.assign(txn, txnUpdated);

      // mapping caseinfo of txn
      const temp = this.converterCaseInfo(txn?.caseInfo || <CaseInfo>{}, data.caseInfo || <CaseInfoSystemMessage>{});
      const txnData = new Txn({
        ...txn,
        caseInfo: new CaseInfo(temp)
      });

      // add to store
      const added = this.txnService.addTxns2Store(txnData);
      if (added) {
        if (!notCountPending && !entity && data?.inboxUuid && data?.status && PENDING_TXNS.includes(data?.status)) {
          this.inboxesService.inscreasePendingTxn(data.inboxUuid);
        }
        this.addTxnToFilterView(txnData);
      }

      // add contact to store
      if (data?.customer?.uuid) {
        const contact = this.contactQuery.getEntity(data?.customer?.uuid);
        let cloneContact: Contact;
        if (!contact) {
          cloneContact = new Contact({
            uuid: data.customer?.uuid,
            displayName: data.customer?.displayName,
            chatCustomerId: data?.customer?.chatUserId,
            isTemporary: false
          });
        } else {
          cloneContact = new Contact({
            ...contact
          });
        }
        this.contactService.updateContacts2Store([cloneContact]);
      }
    }
  }

  private converterTxn(data: TxnMessageData, message: ChatMessage) {
    const txnUpdated = <Partial<Txn>>{};
    if (data.txnUuid) txnUpdated.txnUuid = data.txnUuid;
    if (data.inboxUuid) txnUpdated.inboxUuid = data.inboxUuid;
    if (data.publicConvoId) {
      txnUpdated.publicConvoId = data.publicConvoId;
      if (data.publicConvoId !== message.convo) {
        txnUpdated.teamConvoId = message.convo;
      }
    }

    if (data.createdAt) txnUpdated.createdAt = data.createdAt;
    if (data.channel) txnUpdated.channel = data.channel;
    if (data.title) txnUpdated.title = data.title;
    if (data.status) txnUpdated.status = data.status;
    // if (data.whatsapp) txnUpdated.whatsapp = data.whatsapp;
    if (data.endedAt) txnUpdated.endedAt = data.endedAt;

    if (data.txnTypeId) txnUpdated.typeId = data.txnTypeId;
    if (data.txnSeverityId) txnUpdated.severityId = data.txnSeverityId;
    if (data.productIds) txnUpdated.productIds = data.productIds;
    if (data.transcriptKey) txnUpdated.transcriptKey = data.transcriptKey;
    if (data.assigningTo) txnUpdated.assigningTo = data.assigningTo;

    if (
      [
        ActivityType.editTitle,
        ActivityType.editDesc,
        ActivityType.close,
        ActivityType.open,
        ActivityType.reopen,
        ActivityType.assign,
        ActivityType.unassign
      ].includes(data.eventType)
    ) {
      const activity = new CaseActivity({
        id: message.client_ts, //use client_ts as id util migrate to chat module - use msgId
        eventType: data.eventType,
        type: 'event',
        triggeredByName: data.triggeredByName,
        triggeredByPhotoUrl: data.triggeredByPhotoUrl,
        triggeredByUuid: data.triggeredByUuid,
        updatedAt: message.client_ts,
        createdAt: message.client_ts,
        affectedName: data.affectedName,
        txnUuid: data.txnUuid
      });
      this.activitiesService.addActivityToStore(activity);
    }

    // customer
    if (data?.['customer']) {
      if (data['customer']?.uuid) txnUpdated.customerUuid = data['customer']?.uuid;
      if (data['customer']?.displayName) txnUpdated.customerName = data['customer']?.displayName;
      if (data['customer']?.chatUserId) txnUpdated.customerChatUserId = data['customer']?.chatUserId;
    }

    return txnUpdated;
  }

  private converterCaseInfo(current: CaseInfo, item: CaseInfoSystemMessage) {
    const caseInfo = <Partial<CaseInfo>>{
      ...current
    };
    if (item.id) caseInfo.id = item.id;
    if (item.sid) caseInfo.sid = item.sid;
    if (item.txnUuid) caseInfo.txnUuid = item.txnUuid;
    if (item.description) caseInfo.description = item.description;

    if (item.ownerOrgUuid) caseInfo.ownerOrgUuid = item.ownerOrgUuid;
    if (item.ownerOrgName) caseInfo.ownerOrgName = item.ownerOrgName;

    if (item.srcOrgUuid) caseInfo.srcOrgUuid = item.srcOrgUuid;
    if (item.srcDomain) caseInfo.srcDomain = item.srcDomain;

    if (item.createdByIdentity) caseInfo.createdByIdentity = item.createdByIdentity;
    if (item.createdByIdentityName) caseInfo.createdByIdentityName = item.createdByIdentityName;

    if (item.createdByOrg) caseInfo.createdByOrg = item.createdByOrg;
    if (item.createdByOrgName) caseInfo.createdByOrgName = item.createdByOrgName;

    if (item.accessControlId) caseInfo.accessControlId = item.accessControlId;
    if (item.dueAt) caseInfo.dueAt = item.dueAt;
    return caseInfo;
  }

  private addTxnToFilterView(txn: Txn) {
    const view = this.appQuery.getValue()?.viewTxn;
    if (view === ViewTxn.assignToMe) {
      if (!txn.customerUuid) {
        return;
      }
      const uiState = this.contactQuery.getUiState(txn.customerUuid);
      const txnFilterV4 = uiState?.txnFilterV4;
      if (txnFilterV4?.loaded) {
        const type = txnFilterV4?.channels?.length > 0 ? txnFilterV4.channels?.includes(txn.channel) : true;
        const agent =
          txnFilterV4?.assignees?.length > 0 ? txn?.lastAssignedAgents?.includes(txnFilterV4.assignees?.[0]) : true;
        const text = txnFilterV4?.textSearch?.trim();
        const checkSearch =
          text?.length > 0 ? txn.txnUuid === text || txn?.title?.toUpperCase().includes(text?.toUpperCase()) : true;
        if (type && agent && checkSearch) {
          this.contactService.updateUIViewState(txn?.customerUuid, {
            txnUuidsFilter: [txn.txnUuid, ...uiState.txnUuidsFilter]
          });
        }
      }
    } else if (view === ViewTxn.inbox) {
      if (!txn.inboxUuid) {
        return;
      }
      const uiState = this.inboxesQuery.getUiState(txn.inboxUuid);
      const txnFilterV4 = uiState?.txnFilterV4;
      if (txnFilterV4?.loaded) {
        const type = txnFilterV4?.channels?.length > 0 ? txnFilterV4.channels?.includes(txn.channel) : true;
        const agent =
          txnFilterV4?.assignees?.length > 0 ? txn?.lastAssignedAgents?.includes(txnFilterV4.assignees?.[0]) : true;
        const text = txnFilterV4?.textSearch?.trim();
        const checkSearch =
          text?.length > 0 ? txn.txnUuid === text || txn?.title?.toUpperCase().includes(text?.toUpperCase()) : true;
        if (type && agent && checkSearch) {
          this.inboxesService.updateUIViewState(txn?.inboxUuid, {
            txnUuidsFilter: [txn.txnUuid, ...uiState.txnUuidsFilter]
          });
        }
      }
    }
  }

  private addPublicConvo(message: ChatMessage) {
    const data = message.body?.data as TxnMessageData;

    if (data && data?.publicConvoId) {
      let customerConvo = this.convoGroupQuery.getEntity(data.publicConvoId);
      if (!customerConvo) {
        customerConvo = new ConversationGroup(<Partial<ConversationGroup>>{
          conversationGroupId: data.publicConvoId,
          conversations: [
            {
              conversationId: data.publicConvoId,
              conversationType: ConversationType.public,
              members: []
            }
          ],
          customerInfo: undefined,
          createdAt: new Date(),
          status: data?.type === SystemMsgType.archived ? Status.archived : Status.opened,
          type: message.groupType
        });

        if (data?.customer) {
          customerConvo.customerInfo = <CustomerInfo>{
            uuid: data?.customer?.uuid || undefined,
            name: data?.customer?.displayName || undefined
          };
        }
        this.convoGroupService.addConversation2Store(customerConvo);
      } else {
        if (data?.customer && !customerConvo.customerInfo?.uuid) {
          const customerInfo = <CustomerInfo>{
            uuid: data?.customer?.uuid || undefined,
            name: data?.customer?.displayName || undefined
          };
          this.convoGroupService.addConversation2Store(
            new ConversationGroup({
              ...customerConvo,
              customerInfo: customerInfo
            })
          );
        }
      }
    } else if (data && data?.txnUuid) {
      let customerConvo = this.convoGroupQuery.getEntity(data.txnUuid);
      if (!customerConvo) {
        customerConvo = new ConversationGroup(<Partial<ConversationGroup>>{
          conversationGroupId: data.txnUuid,
          conversations: [
            {
              conversationId: data.txnUuid,
              conversationType: ConversationType.public,
              members: []
            }
          ],
          customerInfo: undefined,
          createdAt: new Date(),
          status: data?.type === SystemMsgType.archived ? Status.archived : Status.opened,
          type:
            message.ct === ConvoType.LIVECHAT
              ? GroupType.LIVECHAT
              : message.ct === ConvoType.whatsapp
              ? GroupType.WhatsApp
              : message.ct === ConvoType.support_center
              ? GroupType.SupportCenter
              : message.ct === ConvoType.call
              ? GroupType.call
              : null
        });

        if (data?.customer) {
          customerConvo.customerInfo = <CustomerInfo>{
            uuid: data?.customer?.uuid || undefined,
            name: data?.customer?.displayName || undefined
          };
        }

        this.convoGroupService.addConversation2Store(customerConvo);
      } else {
        if (data?.customer && !customerConvo.customerInfo?.uuid) {
          const customerInfo = <CustomerInfo>{
            uuid: data?.customer?.uuid || undefined,
            name: data?.customer?.displayName || undefined
          };
          this.convoGroupService.addConversation2Store(
            new ConversationGroup({
              ...customerConvo,
              customerInfo: customerInfo
            })
          );
        }
      }
    }
  }

  private addTeamConvo(message: ChatMessage) {
    const data = message.body?.data as TxnMessageData;

    let rootConvo = this.convoGroupQuery.getConvo(message.convo);
    if (!rootConvo) {
      rootConvo = new ConversationGroup(<Partial<ConversationGroup>>{
        conversationGroupId: message.convo,
        conversations: [
          {
            conversationId: message.convo,
            conversationType: ConversationType.public,
            members: []
          }
        ],
        customerInfo: undefined,
        createdAt: new Date(),
        status: data?.type === SystemMsgType.archived ? Status.archived : Status.opened,
        type: message.groupType
      });
      if (data?.customer) {
        rootConvo.customerInfo = <CustomerInfo>{
          uuid: data?.customer?.uuid || undefined,
          name: data?.customer?.displayName || undefined
        };
      }

      this.convoGroupService.addConversation2Store(rootConvo);
    } else {
      if (data?.customer && !rootConvo.customerInfo?.uuid) {
        const customerInfo = <CustomerInfo>{
          uuid: data?.customer?.uuid || undefined,
          name: data?.customer?.displayName || undefined
        };
        this.convoGroupService.addConversation2Store(
          new ConversationGroup({
            ...rootConvo,
            customerInfo: customerInfo
          })
        );
      }
    }

    return rootConvo;
  }
}
