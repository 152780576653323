import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ChatMessage, ChatService } from '@b3networks/api/chat';
import { CacheMedia, CacheMediaQuery, CacheMediaService } from '@b3networks/api/common';
import { DownloadFileV3Req, FileService, RequestUploadData } from '@b3networks/api/file';
import { LiveChatService } from '@b3networks/api/inbox';
import { MediaService } from '@b3networks/api/workspace';
import { UUID_V4_REGEX, X, isLocalhost } from '@b3networks/shared/common';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'csh-audio-file-message',
  templateUrl: './audio-file-message.component.html',
  styleUrls: ['./audio-file-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioFileMessageComponent {
  @Input() message: ChatMessage;
  @Input() parentElr: HTMLElement;
  @Input() isPublic: boolean;

  src: string;

  constructor(
    private mediaService: MediaService,
    private cacheMediaQuery: CacheMediaQuery,
    private fileService: FileService,
    private cacheMediaService: CacheMediaService,
    private chatService: ChatService,
    private liveChatService: LiveChatService,
    private cdr: ChangeDetectorRef
  ) {}

  onRender() {
    if (isLocalhost()) {
      return;
    }

    const { keyWithOrgUuid } = this.message?.attachmentUI || {};
    if (!keyWithOrgUuid) {
      return;
    }

    const { attachmentData, isStorage } = this.message?.attachmentUI || {};
    if (attachmentData) {
      const url = isStorage
        ? this.cacheMediaQuery.getMediaByKey(keyWithOrgUuid, true)?.url
        : this.cacheMediaQuery.getMediaByKey(keyWithOrgUuid, false)?.url;
      if (url) {
        this.initAudio(url);
        return;
      }
    }

    const { hs, ns, ct, channelId } = this.message;

    let api$: Observable<string>;
    if (isStorage) {
      if (hs) {
        const session = this.chatService.session;
        api$ = this.fileService
          .downloadFileV3Public(keyWithOrgUuid, <RequestUploadData>{
            chatUserId: session.chatUser,
            orgUuid: hs ? ns : X.orgUuid,
            wssToken: session.token,
            chatServer: session.addr,
            convoType: ct,
            hyperspaceId: hs,
            mediaOrgUuid: ns
          })
          .pipe(
            map(resp => {
              const file = new Blob([new Uint8Array(resp.body)], {
                type: `${resp.headers.get('content-type')}`
              });
              return URL.createObjectURL(file);
            }),
            tap(src => {
              this.cacheMediaService.add(
                new CacheMedia({
                  key: this.withoutOrgUuid(keyWithOrgUuid),
                  url: src
                })
              );
            })
          );
      } else {
        const req: DownloadFileV3Req = {};
        if (this.isPublic) {
          req.visitorToken = this.liveChatService.sessionToken;
          req.convoId = channelId;
          req.orgUuid = hs ? ns : X.orgUuid;
        }
        api$ = this.fileService.downloadFileV3(keyWithOrgUuid, req).pipe(
          map(resp => {
            const file = new Blob([new Uint8Array(resp.body)], {
              type: `${resp.headers.get('content-type')}`
            });
            return URL.createObjectURL(file);
          }),
          tap(src => {
            this.cacheMediaService.add(
              new CacheMedia({
                key: this.withoutOrgUuid(keyWithOrgUuid),
                url: src
              })
            );
          })
        );
      }
    } else {
      if (this.isPublic) {
        api$ = this.mediaService.getMediaImgOriginalPublic(keyWithOrgUuid, channelId).pipe(
          map(res => res['url']),
          tap(src => {
            this.cacheMediaService.add(
              new CacheMedia({
                key: keyWithOrgUuid,
                url: src,
                time: Date.now()
              })
            );
          })
        );
      } else {
        api$ = this.mediaService.getMediaImgOriginal(keyWithOrgUuid, channelId).pipe(
          map(res => res['url']),
          tap(src => {
            this.cacheMediaService.add(
              new CacheMedia({
                key: keyWithOrgUuid,
                url: src,
                time: Date.now()
              })
            );
          })
        );
      }
    }

    api$?.pipe(filter(src => src != null))?.subscribe(src => {
      this.initAudio(src);
    });
  }

  private initAudio(src: string) {
    this.src = src;
    this.cdr.markForCheck();
  }

  private withoutOrgUuid(fileKey: string) {
    let s3KeyWithouOrgUuid = fileKey;
    const rs: RegExpMatchArray = s3KeyWithouOrgUuid.match(UUID_V4_REGEX);
    if (rs && rs.index === 0) {
      s3KeyWithouOrgUuid = s3KeyWithouOrgUuid.slice(37);
    }
    return s3KeyWithouOrgUuid;
  }
}
