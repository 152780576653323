<ng-container *ngIf="errorText; else render">
  <span class="normal-text red-fg">{{ errorText }}</span>
</ng-container>
<ng-template #render>
  <ng-container [ngSwitch]="component.element.type">
    <ng-container *ngSwitchCase="IMessElementType.plain_text">
      <ng-container *ngIf="!component.element.multiline; else textarea">
        <mat-form-field>
          <mat-label>{{ this.builtTextMessage.text }}</mat-label>
          <input
            type="text"
            [attr.data-elementId]="component.element.id"
            matInput
            [placeholder]="component.element.placeholder"
            [formControl]="control"
          />
        </mat-form-field>
      </ng-container>
      <ng-template #textarea>
        <mat-form-field>
          <mat-label>{{ this.builtTextMessage.text }}</mat-label>
          <textarea
            matInput
            [placeholder]="component.element.placeholder"
            [formControl]="control"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
          ></textarea>
        </mat-form-field>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="IMessElementType.number">
      <mat-form-field>
        <mat-label>{{ this.builtTextMessage.text }}</mat-label>
        <input
          type="number"
          [attr.data-elementId]="component.element.id"
          matInput
          [placeholder]="component.element.placeholder"
          [formControl]="control"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="IMessElementType.select">
      <div class="flex column">
        <mat-form-field>
          <mat-label *ngIf="component?.label">{{ this.builtTextMessage.text }}</mat-label>
          <mat-select [formControl]="control" [attr.data-elementId]="component.element.id">
            <!-- <mat-option value="">None</mat-option> -->
            <mat-option *ngFor="let option of component.element?.options" [value]="option?.value">
              {{ option?.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="IMessElementType.file">
      <csh-interactive-upload
        [backgroundUploading]="backgroundUploading"
        [component]="component"
        [control]="control"
        [uploadPercentage]="uploadPercentage"
        [uploadStatusMap]="uploadStatusMap"
        [isSubmiting]="isSubmiting"
      ></csh-interactive-upload>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="normal-text red-fg"> Input type not supported yet.! </span>
    </ng-container>
  </ng-container>
</ng-template>
