import { Injectable } from '@angular/core';
import { MemberStatus } from '@b3networks/api/auth';
import {
  Channel,
  ChannelHyperspace,
  ChannelHyperspaceQuery,
  ChannelHyperspaceService,
  ChannelQuery,
  ChannelService,
  ChannelType,
  ChatChannelHyperspaceStoreName,
  ChatChannelStoreName,
  HistoryMessageService,
  Privacy,
  Privacy as PrivacyChannel,
  ViewUIStateCommon
} from '@b3networks/api/chat';
import { ContactQuery } from '@b3networks/api/contact';
import { InboxesQuery, TxnCustom } from '@b3networks/api/inbox';
import { RequestLeaveQuery } from '@b3networks/api/leave';
import {
  ConversationGroup,
  ConversationGroupQuery,
  ConversationGroupService,
  ConvoGroupStoreName,
  HyperspaceQuery,
  User,
  UserHyperspace,
  UserQuery
} from '@b3networks/api/workspace';
import { EntityStoreAction, Order, runEntityStoreAction } from '@datorama/akita';
import Fuse from 'fuse.js';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

export type SupportedConvo = ConversationGroup | Channel | ChannelHyperspace;

const DEFAULT_THRESHOLD = 0.3;

export interface ResultSearch1<K> {
  result: Fuse.FuseResult<K>[];
  hasMore?: boolean;
}

export enum StatusFilter {
  active = 'active',
  archived = 'archived'
}

export interface FormGroupFilter {
  search: string;
  type: Privacy | ChannelType.dm | 'ALL';
  status: StatusFilter[];
  sort: Order;
}

@Injectable({
  providedIn: 'root'
})
export class ConvoHelperService {
  txnAssignedToMe: TxnCustom[] = []; // cache data to search

  constructor(
    private channelQuery: ChannelQuery,
    private channelService: ChannelService,
    private channelHyperspaceQuery: ChannelHyperspaceQuery,
    private channelHyperspaceService: ChannelHyperspaceService,
    private convoGroupQuery: ConversationGroupQuery,
    private convoGroupService: ConversationGroupService,
    private historyMessageService: HistoryMessageService,
    private userQuery: UserQuery,
    private contactQuer: ContactQuery,
    private hyperspaceQuery: HyperspaceQuery,
    private requestLeaveQuery: RequestLeaveQuery,
    private inboxesQuery: InboxesQuery
  ) {}

  getAllUsersContainsAndMore(key: string, limit: number, optionsConfig = {}): ResultSearch1<User> {
    const list = this.userQuery.getAll({
      filterBy: user => user.memberStatus === MemberStatus.active,
      sortBy: 'displayName',
      sortByOrder: Order.ASC
    });

    let result = [];
    if (key) {
      let options = {
        keys: ['displayName', 'email', 'mobileNumber'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse(list, options);
      result = fuse.search(key);
    } else {
      result = list?.map(
        x =>
          <Fuse.FuseResult<User>>{
            score: 0,
            item: x,
            refIndex: 0
          }
      );
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }

    return { result };
  }

  getSearchableThreadsContains(
    key: string,
    parentId: string,
    limit: number,
    optionsConfig = {}
  ): ResultSearch1<Channel> {
    const threads = this.channelQuery.getAll({
      filterBy: entity => entity.type === ChannelType.THREAD && entity.parentId === parentId && !entity.closedL2
    });

    let result = [];
    if (key) {
      let options = {
        keys: ['displayName'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse(threads, options);
      result = fuse.search(key);
    } else {
      result = threads?.map(
        c =>
          <Fuse.FuseResult<Channel>>{
            item: c,
            refIndex: 0,
            score: 0
          }
      );
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }

    return { result };
  }

  getSearchablePrivateChannelsContains(key: string, limit: number, optionsConfig = {}): ResultSearch1<Channel> {
    const publicChannel = this.channelQuery.getAll({
      filterBy: entity => entity.type === ChannelType.gc && entity.privacy === PrivacyChannel.public
    });

    let result = [];
    if (key) {
      let options = {
        keys: ['displayName'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse(publicChannel, options);
      result = fuse.search(key);
    } else {
      result = publicChannel?.map(
        c =>
          <Fuse.FuseResult<Channel>>{
            item: c,
            refIndex: 0,
            score: 0
          }
      );
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }

    return { result };
  }

  selectFilterChannels(filter: FormGroupFilter, limit: number, optionsConfig = {}): Observable<ResultSearch1<Channel>> {
    return this.channelQuery
      .selectAll({
        filterBy: entity => {
          const hasStatus = filter.status?.some(status => {
            if (status === StatusFilter.archived) {
              return entity.isArchived;
            }
            return !entity.isArchived;
          });
          const hasType =
            filter.type !== 'ALL'
              ? filter.type === ChannelType.dm
                ? entity.type === ChannelType.dm && !entity.isDisableUser
                : entity.type === ChannelType.gc && filter.type === entity.privacy
              : (entity.type === ChannelType.dm && !entity.isDisableUser) || entity.type === ChannelType.gc;

          return hasStatus && hasType && (entity.isMember || entity.unreadCount > 0);
        },
        // sortBy: (a, b, state) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim())
        sortBy: (a, b, state) => b.timeLastMessage - a.timeLastMessage
      })
      .pipe(
        map(publicChannel => {
          let result = [];
          if (filter.search) {
            let options = {
              keys: ['displayName'],
              threshold: DEFAULT_THRESHOLD,
              includeScore: true
            };

            if (optionsConfig) {
              options = {
                ...options,
                ...optionsConfig
              };
            }

            const fuse = new Fuse(publicChannel, options);
            result = fuse.search(filter.search);
          } else {
            result = publicChannel?.map(
              c =>
                <Fuse.FuseResult<Channel>>{
                  item: c,
                  refIndex: 0,
                  score: 0
                }
            );
          }

          if (limit > 0) {
            const hasMore = result.length > limit;
            result = result.splice(0, limit);
            return { result, hasMore };
          }

          return { result };
        })
      );
  }

  selectFilterThreadsActive(
    filter: FormGroupFilter,
    limit: number,
    optionsConfig = {}
  ): Observable<ResultSearch1<Channel>> {
    return this.channelQuery
      .selectAll({
        filterBy: entity => {
          return entity.isThread && (entity.unreadCount > 0 || !entity.closedL2);
        },
        sortBy: 'timeLastMessage',
        sortByOrder: filter.sort
      })
      .pipe(
        map(thread => {
          let result = [];
          if (filter.search) {
            let options = {
              keys: ['displayName'],
              threshold: DEFAULT_THRESHOLD,
              includeScore: true
            };

            if (optionsConfig) {
              options = {
                ...options,
                ...optionsConfig
              };
            }

            const fuse = new Fuse(thread, options);
            result = fuse.search(filter.search);
          } else {
            result = thread?.map(
              c =>
                <Fuse.FuseResult<Channel>>{
                  item: c,
                  refIndex: 0,
                  score: 0
                }
            );
          }

          if (limit > 0) {
            const hasMore = result.length > limit;
            result = result.splice(0, limit);
            return { result, hasMore };
          }

          return { result };
        })
      );
  }

  getSearchablePrivateChannelsHyperspaceContains(
    hyperspaceId: string,
    key: string,
    limit: number,
    optionsConfig = {}
  ): ResultSearch1<ChannelHyperspace> {
    const publicChannel = this.channelHyperspaceQuery.getAll({
      filterBy: entity =>
        entity.hyperspaceId === hyperspaceId &&
        entity.type === ChannelType.gc &&
        entity.privacy === PrivacyChannel.public
    });

    let result = [];
    if (key) {
      let options = {
        keys: ['displayName'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse(publicChannel, options);
      result = fuse.search(key);
    } else {
      result = publicChannel?.map(
        c =>
          <Fuse.FuseResult<ChannelHyperspace>>{
            item: c,
            refIndex: 0,
            score: 0
          }
      );
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }
    return { result };
  }

  getDirectChannels(limit: number): Channel[] {
    return this.channelQuery.getAll({
      filterBy: entity => !entity.isGroupChat && !!this.userQuery.getUserByChatUuid(entity.directChatUsers.otherUuid),
      limitTo: limit
    });
  }

  getTxnByCustomerName(key: string, limit: number, optionsConfig = {}) {
    let result = [];
    if (key) {
      let options = {
        keys: ['customerName'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse(this.txnAssignedToMe, options);
      result = fuse.search(key);
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }

    return { result };
  }

  getInboxByName(key: string, limit: number, optionsConfig = {}) {
    const inboxes = this.inboxesQuery.getAll({
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });

    let result = [];
    if (key) {
      let options = {
        keys: ['name'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse(inboxes, options);
      result = fuse.search(key);
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }

    return { result };
  }

  getChannelsContainsByQuickSearch(
    key: string,
    limit: number,
    optionsConfig = {}
  ): ResultSearch1<UnifiedSearchChannel> {
    const channel = this.channelQuery
      .getAll({
        filterBy: entity =>
          (entity.isGroupChat || (entity.isThreadChat && !entity.isClosed && entity.isMember)) && !!entity.displayName
      })
      ?.map(x => this.transferUnifiedSearchChannel(x));

    const channelHyperspace = this.channelHyperspaceQuery
      .getAll({
        filterBy: entity => entity.isGroupChat && !!entity.displayName
      })
      ?.map(x => this.transferUnifiedSearchChannel(x));

    let result = [];
    if (key) {
      let options = {
        keys: ['displayName'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse([...channel, ...channelHyperspace], options);
      result = fuse.search(key);
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }

    return { result };
  }

  getChannelsHyperspaceContainsByQuickSearch(
    hyperspaceId: string,
    key: string,
    limit: number,
    optionsConfig = {}
  ): ResultSearch1<ChannelHyperspace> {
    const channel = this.channelHyperspaceQuery.getAll({
      filterBy: entity => entity.hyperspaceId === hyperspaceId && entity.isGroupChat && !!entity.displayName
    });

    let result = [];
    if (key) {
      let options = {
        keys: ['displayName'],
        threshold: DEFAULT_THRESHOLD,
        includeScore: true
      };

      if (optionsConfig) {
        options = {
          ...options,
          ...optionsConfig
        };
      }

      const fuse = new Fuse(channel, options);
      result = fuse.search(key);
    } else {
      result = channel?.map(
        c =>
          <Fuse.FuseResult<ChannelHyperspace>>{
            item: c,
            refIndex: 0,
            score: 0
          }
      );
    }

    if (limit > 0) {
      const hasMore = result.length > limit;
      result = result.splice(0, limit);
      return { result, hasMore };
    }

    return { result };
  }

  getUIState(convo: SupportedConvo) {
    if (convo instanceof Channel) {
      return this.channelQuery.getChannelUiState(convo?.id);
    } else if (convo instanceof ChannelHyperspace) {
      return this.channelHyperspaceQuery.getChannelUiState(convo?.id);
    } else {
      return this.convoGroupQuery.getConvoUiState(convo?.conversationGroupId);
    }
  }

  updateUIState(convo: SupportedConvo, newState: Partial<ViewUIStateCommon>) {
    if (convo instanceof Channel) {
      this.channelService.updateChannelViewState(convo.id, newState);
    } else if (convo instanceof ChannelHyperspace) {
      this.channelHyperspaceService.updateChannelViewState(convo.id, newState);
    } else {
      this.convoGroupService.updateConvoViewState(convo.conversationGroupId, newState);
    }
  }

  updateConvo(convo: SupportedConvo, res: SupportedConvo) {
    if (convo) {
      if (convo instanceof Channel) {
        runEntityStoreAction(ChatChannelStoreName, EntityStoreAction.UpdateEntities, update => update(convo.id, res));
      } else if (convo instanceof ConversationGroup) {
        runEntityStoreAction(ConvoGroupStoreName, EntityStoreAction.UpdateEntities, update => update(convo.id, res));
      } else if (convo instanceof ChannelHyperspace) {
        runEntityStoreAction(ChatChannelHyperspaceStoreName, EntityStoreAction.UpdateEntities, update =>
          update(convo.id, res)
        );
      }
    }
  }

  updateIsDisconnectedUIState(isDisconnect: boolean) {
    this.channelService.updateStateStore({
      isDisconnected: isDisconnect
    });

    this.channelHyperspaceService.updateStateStore({
      isDisconnected: isDisconnect
    });

    this.convoGroupService.updateStateStore({
      isDisconnected: isDisconnect
    });
  }

  removeMsgWhenReconnected() {
    // clear history
    this.historyMessageService.cleanupAllMessage();

    // reset state
    this.convoGroupService.resetChannelViewStateHistory(null);
    this.channelService.resetChannelViewStateHistory(null);
    this.channelHyperspaceQuery.resetChannelViewStateHistory(null);
  }

  getRecentChannel() {
    const channel: UnifiedSearchChannel[] = [];
    const recentChannels = this.channelQuery.getValue()?.recentChannels || [];
    const recent = [...recentChannels] || [];
    for (let i = 0; i < recent?.length; i++) {
      const item = this.channelQuery.getEntity(recent[i].id);
      if (item) {
        const activeId = this.channelQuery.getActiveId();
        if (item.id !== activeId) {
          if (item.type === ChannelType.dm) {
            const hasEntity = this.userQuery.hasEntity(entity => entity?.userUuid === item.directChatUsers.otherUuid);
            if (hasEntity) {
              channel.push(this.transferUnifiedSearchChannel(item, recent[i].date));
              continue;
            }
          } else {
            channel.push(this.transferUnifiedSearchChannel(item, recent[i].date));
          }
        }
      } else {
        const itemHypers = this.channelHyperspaceQuery.getEntity(recent[i].id);
        const activeIdHyper = this.channelHyperspaceQuery.getActiveId();
        if (itemHypers && itemHypers.id !== activeIdHyper) {
          channel.push(this.transferUnifiedSearchChannel(itemHypers, recent[i].date));
        }
      }
    }
    return channel.sort((a, b) => b.lastRecentChannel - a.lastRecentChannel);
  }

  selectChannePersonal() {
    return this.channelQuery
      .selectAll({
        filterBy: entity => {
          return entity.type === ChannelType.PERSONAL;
        },
        sortBy: (a, b, state) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim())
      })
      .pipe(map(channels => channels.map(c => this.transferUnifiedChannel(c))));
  }

  selectThreadChannel() {
    return this.channelQuery.selectActiveId().pipe(
      switchMap(activeId =>
        this.channelQuery
          .selectAll({
            filterBy: entity => {
              if (entity.type !== ChannelType.THREAD) {
                // stop condition
                return false;
              }
              const isNotDraft = !this.channelQuery.getChannelUiState(entity.id)?.draftMsg;
              return (
                entity.type === ChannelType.THREAD && isNotDraft && (entity.unreadCount > 0 || entity.id === activeId)
              );
            },
            sortBy: (a, b, state) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim())
          })
          .pipe(map(channels => channels.map(c => this.transferUnifiedChannel(c))))
      )
    );
  }

  selectMentionChannel() {
    return this.channelQuery.selectActiveId().pipe(
      switchMap(activeId =>
        this.channelQuery
          .selectAll({
            filterBy: entity => {
              if (entity.type === ChannelType.THREAD) {
                // stop condition
                return false;
              }

              if (entity.isStarred) {
                // stop condition
                return false;
              }

              if (entity.type === ChannelType.dm && !entity.isDisableUser) {
                const isDraft = !!this.channelQuery.getChannelUiState(entity.id)?.draftMsg;
                return entity.unreadCount > 0 || entity.id === activeId || isDraft;
              }

              if (entity.type === ChannelType.gc) {
                return entity.mentionCount > 0;
              }
              return false;
            },
            sortBy: (a, b, state) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim())
          })
          .pipe(map(channels => channels.map(c => this.transferUnifiedChannel(c))))
      )
    );
  }

  selectChannelSection() {
    return this.channelQuery.selectActiveId().pipe(
      switchMap(activeId =>
        this.channelQuery
          .selectAll({
            filterBy: entity => {
              if (entity.type === ChannelType.THREAD || entity.type === ChannelType.dm) {
                // stop condition
                return false;
              }

              if (entity.isStarred) {
                // stop condition
                return false;
              }

              if (entity.type === ChannelType.gc) {
                const isDraft = !!this.channelQuery.getChannelUiState(entity.id)?.draftMsg;
                return (
                  (entity.mentionCount === 0 || !entity.mentionCount) &&
                  ((entity.id === activeId && entity.isArchived) ||
                    entity.unreadCount > 0 ||
                    isDraft ||
                    entity.id === activeId)
                );
              }
              return false;
            },
            sortBy: (a, b, state) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim())
          })
          .pipe(map(channels => channels.map(c => this.transferUnifiedChannel(c))))
      )
    );
  }

  selectStarredChannel() {
    return this.channelQuery.selectActiveId().pipe(
      switchMap(activeId => {
        return this.channelQuery.selectAll({
          filterBy: entity => {
            return (
              entity.type !== ChannelType.THREAD &&
              entity.isStarred &&
              (!entity.isArchived || entity.id === activeId) &&
              (entity.type === ChannelType.gc ||
                (entity.type === ChannelType.dm && !entity.isDisableUser) ||
                entity.unreadCount > 0 ||
                entity.id === activeId)
            );
          },
          sortBy: (a, b, state) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim())
        });
      }),
      map(channels => channels.map(c => this.transferUnifiedChannel(c)))
    );
  }

  transferUnifiedSearchChannel(channel: Channel | ChannelHyperspace, lastRecentChannel?: number) {
    return <UnifiedSearchChannel>{
      detail: this.transferUnifiedChannel(channel),
      id: channel.id,
      displayName: channel.name,
      hyperspaceId: channel instanceof ChannelHyperspace ? channel.hyperspaceId : null,
      privacy: channel.privacy,
      type: channel.type,
      isGroupChat: channel.isGroupChat,
      userUuidDirectChat: channel?.directChatUsers?.otherUuid,
      lastRecentChannel: lastRecentChannel,
      icon: channel.icon,
      userDirectChat$:
        channel instanceof Channel && !!channel?.directChatUsers?.otherUuid
          ? this.userQuery.selectUserByChatUuid(channel.directChatUsers.otherUuid).pipe(
              map(
                u =>
                  new User({
                    ...u,
                    requestLeaveNow: !u.isBot
                      ? this.requestLeaveQuery.getEntity(u?.identityUuid)?.requestLeaveNow
                      : null
                  }) ||
                  <User>{
                    displayName: 'Unknown user'
                  }
              )
            )
          : of(null),
      userHyperspaceDirectChat$:
        channel instanceof ChannelHyperspace && !!channel?.directChatUsers?.otherUuid
          ? this.hyperspaceQuery
              .selectHyperByHyperspaceId(channel.hyperspaceId)
              .pipe(map(x => x.allMembers.find(u => u.userUuid === channel.directChatUsers.otherUuid)))
          : of(null),
      isSvg: channel?.isSvg
    };
  }

  getActiveByChannel(convo: SupportedConvo) {
    return convo instanceof Channel
      ? this.channelQuery.getActiveId()
      : convo instanceof ChannelHyperspace
      ? this.channelHyperspaceQuery.getActiveId()
      : this.convoGroupQuery.getActiveId();
  }

  private transferUnifiedChannel(channel: Channel | ChannelHyperspace) {
    return <UnifiedChannel>{
      id: channel.id,
      name: channel.displayName,
      hyperspaceId: channel instanceof ChannelHyperspace ? channel.hyperspaceId : null,
      privacy: channel.privacy,
      type: channel.type,
      unreadCount: channel.unreadCount || 0,
      mentionCount: channel.mentionCount || 0,
      isStarred: channel.isStarred,
      isMember: channel.isMember,
      userUuidDirectChat: channel?.directChatUsers?.otherUuid,
      icon: channel.icon,
      isSvg: channel.isSvg,
      classIcon: channel.classIcon,
      isMyChannel: channel.isMyChannel,
      isArchived: channel.isArchived,
      displayName: channel.displayName,
      isBot: channel.isBot,
      userDirectChat$:
        channel instanceof Channel && !!channel?.directChatUsers?.otherUuid
          ? this.userQuery.selectUserByChatUuid(channel?.directChatUsers?.otherUuid).pipe(
              map(
                u =>
                  new User({
                    ...u,
                    requestLeaveNow: this.requestLeaveQuery.getEntity(u?.identityUuid)?.requestLeaveNow
                  }) ||
                  <User>{
                    displayName: 'Unknown user'
                  }
              )
            )
          : of(null),
      userHyperspaceDirectChat$:
        channel instanceof ChannelHyperspace && !!channel?.directChatUsers?.otherUuid
          ? this.hyperspaceQuery
              .selectHyperByHyperspaceId(channel.hyperspaceId)
              .pipe(map(x => x.allMembers.find(u => u.userUuid === channel.directChatUsers.otherUuid)))
          : of(null),
      isDraft: !!this.channelQuery.getChannelUiState(channel.id)?.draftMsg,
      isDraft$:
        channel instanceof Channel
          ? channel.type === ChannelType.THREAD && channel.isClosed
            ? of(false)
            : this.channelQuery.selectUIState(channel.id, 'draftMsg').pipe(
                map(d => !!d),
                distinctUntilChanged()
              )
          : this.channelHyperspaceQuery.selectUIState(channel.id, 'draftMsg').pipe(
              map(d => !!d),
              distinctUntilChanged()
            ),
      parentId: channel.parentId
    };
  }
}

export interface UnifiedChannel {
  id: string;
  name: string;
  hyperspaceId: string;
  privacy: Privacy;
  type: ChannelType;
  unreadCount: number;
  mentionCount: number;
  isStarred: boolean;
  isMember: boolean;
  userUuidDirectChat: string;
  icon: string;
  isSvg: string;
  classIcon: string;
  isMyChannel: boolean;
  isArchived: boolean;
  displayName: string;
  isBot: boolean;
  isDraft: boolean;

  // observable
  isDraft$: Observable<boolean>;
  userHyperspaceDirectChat$: Observable<UserHyperspace>;
  userDirectChat$: Observable<User>;

  // thread
  parentId: string;
}

export interface UnifiedSearchChannel {
  detail: UnifiedChannel;
  id: string;
  displayName: string;
  hyperspaceId: string;
  privacy: Privacy;
  type: ChannelType;
  lastRecentChannel: number;
  isGroupChat: boolean;
  userUuidDirectChat: string;
  userHyperspaceDirectChat$: Observable<UserHyperspace>;
  userDirectChat$: Observable<User>;
  icon: string;
  isSvg: string;
}
