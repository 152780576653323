<div class="container flex column gap-16">
  <div class="body spacer flex column gap-16">
    <ng-container *ngIf="[ChannelType.gc, ChannelType.THREAD].includes(channel.type)">
      <div class="body__item flex column">
        <div class="body__item__title flex">
          <div class="title spacer">Channel Name</div>
          <a href="javascript:void" *ngIf="!channel.isGeneral" (click)="editChannelName()" class="edit-action">Edit</a>
        </div>
        <div>{{ channel.name }}</div>
      </div>

      <div class="body__item flex column">
        <div class="body__item__title flex">
          <div class="title spacer">Channel Description</div>
          <a href="javascript:void" (click)="editDescription()" class="edit-action">Edit</a>
        </div>
        <div *ngIf="!!channel.description">{{ channel.description }}</div>
        <div *ngIf="!channel.description" class="secondary-text">No description</div>
      </div>

      <ng-container *ngIf="!channel.isGeneral">
        <div class="body__item p-0 flex column" *ngIf="channel.createdBy | selectUser | async as user">
          <div class="body__item__sub flex column">
            <div class="body__item__title flex">
              <div class="title spacer">Owner</div>
              <!-- <a *ngIf="!channel.isThread" href="javascript:void" (click)="transferRole()">Transfer</a> -->
            </div>
            <div>
              {{ user.displayName }}
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="body__item__sub flex column">
            <div class="body__item__title flex">
              <div class="title spacer">Created by</div>
            </div>
            <div class="flex gap-4">
              <span>{{ user.displayName }}</span>
              {{ channel.createdAt | date: 'fullDate' }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="[ChannelType.dm].includes(channel.type)">
      <div class="body__item flex column gap-8">
        <ng-container *ngIf="directChatUser$ | async as directChatUser">
          <div class="avarta-container flex justify-content-start align-items-center gap-10">
            <div fxFlex="50px" fxLayoutAlign="center center">
              <b3n-avatar-user [member]="directChatUser" [showStatus]="true" [disabledClick]="true"></b3n-avatar-user>
            </div>
            <div class="flex column justify-content-center align-items-none">
              <span class="display-name">{{ directChatUser.displayName }}</span>
              <span class="mat-caption" *ngIf="!directChatUser.isBot">{{ channel.displayConvoType }}</span>
              <span class="mat-caption" *ngIf="directChatUser.isBot">Bot</span>
            </div>
          </div>

          <ng-container *ngIf="!directChatUser?.isBot && $any(directChatUser)?.teams?.length > 0">
            <div class="phone-container flex justify-content-start align-items-center gap-10">
              <div fxFlex="50px" fxLayoutAlign="center center">
                <div fxLayoutAlign="center center">
                  <mat-icon>group</mat-icon>
                </div>
              </div>
              <div class="flex column justify-content-center align-items-none">
                <span>Teams</span>
                <a class="mat-caption cursor-pointer" (click)="onOpenOrgChartDialog($any(directChatUser)?.uuid)">
                  {{ $any(directChatUser)?.displayTeams }}
                </a>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="$any(directChatUser)?.email">
            <div class="phone-container flex justify-content-start align-items-center gap-10">
              <div fxFlex="50px" fxLayoutAlign="center center">
                <mat-icon>mail</mat-icon>
              </div>
              <div class="flex column justify-content-center align-items-none">
                <span>{{ $any(directChatUser).email }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectExtension$ | async as extension">
            <div class="phone-container flex justify-content-start align-items-center gap-10">
              <div fxFlex="50px" fxLayoutAlign="center center">
                <mat-icon (click)="makeCallTo(extension?.extKey, directChatUser)" svgIcon="b3n_call"></mat-icon>
              </div>
              <div class="flex column justify-content-center align-items-none">
                <span># {{ extension?.extKey }} </span>
                <span class="mat-caption">Extension</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="$any(directChatUser)?.mobileNumber">
            <div class="phone-container flex justify-content-start align-items-center gap-10">
              <div fxFlex="50px" fxLayoutAlign="center center">
                <mat-icon
                  (click)="makeCallTo($any(directChatUser).mobileNumber, directChatUser)"
                  svgIcon="b3n_call"
                ></mat-icon>
              </div>
              <div class="flex column justify-content-center align-items-none">
                <span> {{ $any(directChatUser).mobileNumber }} </span>
                <span class="mat-caption">Work</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!directChatUser?.isBot && !!$any(directChatUser)?.about">
            <div class="phone-container flex justify-content-start align-items-center gap-10">
              <div fxFlex="50px" fxLayoutAlign="center center">
                <div fxLayoutAlign="center center">
                  <mat-icon>info</mat-icon>
                </div>
              </div>
              <div class="flex column justify-content-center align-items-none">
                <span>About</span>
                <span class="about-text"> ({{ $any(directChatUser)?.about }}) </span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="flex gap-8">
    <ng-container [ngSwitch]="channel.type">
      <ng-container *ngSwitchCase="ChannelType.gc">
        <ng-container *ngIf="!channel.isGeneral">
          <ng-container *ngIf="!channel.isOwner; else ownerActions">
            <button
              *ngIf="channel.isMember; else joinBtn"
              mat-stroked-button
              color="warn"
              class="flex-auto b3n-warn"
              (click)="leaveChannel()"
            >
              Leave Channel
            </button>
            <ng-template #joinBtn>
              <button mat-stroked-button color="primary" class="flex-auto" (click)="joinChannel()">Join Channel</button>
            </ng-template>
          </ng-container>
          <ng-template #ownerActions>
            <ng-container></ng-container>
            <button
              *ngIf="!channel.isArchived"
              mat-stroked-button
              class="flex-auto b3n-warn"
              color="warn"
              (click)="closeChannel()"
            >
              Archive Channel
            </button>
            <button
              *ngIf="channel.isArchived"
              mat-stroked-button
              class="flex-auto b3n-warn"
              color="warn"
              (click)="openChannel()"
            >
              Unarchive Channel
            </button>
            <!-- <button mat-raised-button [loading]="archiving" (click)="archiveChannel()" class="flex-auto b3n-warn">
            Close Channel
          </button> -->
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
