<div class="container flex column gap-16 justi">
  <div class="header flex justify-content-start align-items-center gap-4">
    <b3n-channel-name class="spacer" [channel]="data.channel" [noHoverChannelName]="true"></b3n-channel-name>
    <mat-icon class="s-24 cursor-pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="body">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>About</div>
        </ng-template>
        <ng-template matTabContent>
          <b3n-channel-details-about
            [channel]="data.channel"
            (hiddenDialog)="hiddenDialog($event)"
            (closeDialog)="close()"
          ></b3n-channel-details-about>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="data.channel.type === ChannelType.gc">
        <ng-template mat-tab-label>
          <div class="flex gap-3">
            <span>Members</span>
            <div>({{ (members$ | async)?.length || 0 }})</div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <b3n-channel-details-members
            [channel]="data.channel"
            [members]="members$ | async"
            (hiddenDialog)="hiddenDialog($event)"
          ></b3n-channel-details-members>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
