import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Channel, ChannelQuery, ChannelService, ChannelType } from '@b3networks/api/chat';
import { MediaService, StarService, User, UserQuery } from '@b3networks/api/workspace';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { Observable, map, skip, takeUntil } from 'rxjs';

export interface ChannelDetailsInput {
  channel: Channel;
}

@Component({
  selector: 'b3n-channel-details',
  templateUrl: './channel-details.component.html',
  styleUrls: ['./channel-details.component.scss']
})
export class ChannelDetailsComponent extends DestroySubscriberComponent implements OnInit {
  archiving: boolean;
  members$: Observable<User[]>;

  readonly ChannelType = ChannelType;

  constructor(
    public dialogRef: MatDialogRef<ChannelDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChannelDetailsInput,
    private starService: StarService,
    private channelQuery: ChannelQuery,
    private channelService: ChannelService,
    private userQuery: UserQuery,
    private mediaService: MediaService,
    private ngZone: NgZone
  ) {
    super();
  }

  ngOnInit() {
    this.members$ = this.channelQuery.selectPropertyChannel(this.data.channel.id, 'participants').pipe(
      map(participants => {
        return this.userQuery.getAllUserByChatUuid(participants?.map(m => m.userID) || []);
      }),
      map(m => this.sort(m))
    );

    this.channelQuery
      .selectEntity(this.data.channel.id)
      .pipe(skip(1), takeUntil(this.destroySubscriber$))
      .subscribe(channel => {
        this.data.channel = channel;
      });
  }

  hiddenDialog(isHidden: boolean) {
    const dialogElr = document.querySelector('#' + this.dialogRef.id);
    if (isHidden) {
      dialogElr?.classList.add('hidden');
    } else {
      dialogElr?.classList.remove('hidden');
    }
  }

  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  unstarConvo($event) {
    $event.stopPropagation();
    this.starService.unstarChannelTeamChat(this.data.channel.id).subscribe(() => {
      this.channelService.updateOneChannel(<Channel>{
        id: this.data.channel.id,
        isStarred: false
      });
    });
  }

  starConvo($event) {
    $event.stopPropagation();
    this.starService.starChannelTeamChat(this.data.channel.id).subscribe(() => {
      this.channelService.updateOneChannel(<Channel>{
        id: this.data.channel.id,
        isStarred: true
      });
    });
  }

  private sort(members: User[]) {
    return members.sort((a, b) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim()));
  }
}
