import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[resizableHeight]'
})
export class ResizableHeightDirective implements AfterViewInit, OnChanges {
  @Input() root: HTMLElement; // other element resize

  @Input() initHeight: number;
  @Input() grabWidth = 8; // px
  @Input() moreSpace = 0; // can add 10px because 1px border to hard to hover resize
  @Input() minHeight = 50; // px
  @Input() maxHeight = 1000;

  @Output() heightChanged = new EventEmitter<number>();

  private _elementResize: HTMLElement;
  private _dragging = false;

  constructor(private elr: ElementRef, private renderer: Renderer2, private ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initHeight'] && this._elementResize && this.initHeight > 0) {
      this.newHeight(this.initHeight);
    }
  }

  ngAfterViewInit(): void {
    this._elementResize = this.root || this.elr?.nativeElement;
    this.renderer.setStyle(this._elementResize, 'border-bottom', this.grabWidth + 'px solid rgba(0, 0, 0, 0.3)');

    if (this.initHeight) {
      this.newHeight(this.initHeight);
    }

    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('mousemove', this.mouseMoveG.bind(this), true);
      document.addEventListener('mouseup', this.mouseUpG.bind(this), true);
      this._elementResize.addEventListener('mousedown', this.mouseDown.bind(this), true);
      this._elementResize.addEventListener('mousemove', this.mouseMove.bind(this), true);
    });
  }

  private inDragRegion(evt) {
    return (
      this._elementResize.clientHeight - evt.clientY + this._elementResize.offsetTop < this.grabWidth + this.moreSpace
    );
  }

  private newHeight(height: number) {
    let newHeight = Math.max(this.minHeight, height); // check minHeight
    newHeight = Math.min(this.maxHeight, newHeight); // check maxHeight
    this.renderer.setStyle(this._elementResize, 'height', newHeight + 'px');
    this.renderer.setStyle(this._elementResize, 'min-height', newHeight + 'px');
    this.renderer.setStyle(this._elementResize, 'max-height', newHeight + 'px');
    this.heightChanged.emit(newHeight);
  }

  private preventGlobalMouseEvents() {
    this.renderer.setStyle(this._elementResize, 'pointer-events', 'none');
  }

  private restoreGlobalMouseEvents() {
    this.renderer.setStyle(this._elementResize, 'pointer-events', 'auto');
  }

  private mouseMoveG(evt) {
    if (!this._dragging) {
      return;
    }
    this.newHeight(evt.clientY - this._elementResize.offsetTop);
    evt.stopPropagation();
  }

  private mouseUpG(evt) {
    if (!this._dragging) {
      return;
    }
    this.restoreGlobalMouseEvents();
    this._dragging = false;
    evt.stopPropagation();
  }

  private mouseDown(evt) {
    if (this.inDragRegion(evt)) {
      this._dragging = true;
      this.preventGlobalMouseEvents();
      evt.stopPropagation();
    }
  }

  private mouseMove(evt) {
    if (this.inDragRegion(evt) || this._dragging) {
      this.renderer.setStyle(this._elementResize, 'cursor', 'ns-resize');
    } else {
      this.renderer.setStyle(this._elementResize, 'cursor', 'default');
    }
  }
}
