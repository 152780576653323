import { Pipe, PipeTransform } from '@angular/core';

enum RingMode {
  ringAll = 'ringAll', // cdConfig , ringConfig
  sequential = 'sequential', // cdConfig, ringConfig
  roundRobin = 'roundRobin', // cdConfig
  longestIdle = 'longestIdle', // cdConfig
  proficiency = 'proficiency',
  stickyAgent = 'stickyAgent'
}

@Pipe({
  name: 'ringConfig'
})
export class RingConfigPipe implements PipeTransform {
  transform(ringConfig: RingMode): string {
    switch (ringConfig) {
      case RingMode.ringAll:
        return 'Ring All';
      case RingMode.sequential:
        return 'sequential';

      default:
        return ringConfig;
    }
  }
}
