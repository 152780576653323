<div class="video flex align-items-start gap-16">
  <video
    [intersectionObserverDom]="parentElr"
    (render)="onRenderVideo()"
    class="video-js vjs-big-play-centered"
    controls
    preload="none"
  >
    <ng-container *ngTemplateOutlet="noSupport"></ng-container>
  </video>

  <ng-container *ngTemplateOutlet="cannotLoaded"></ng-container>
</div>

<ng-template #cannotLoaded>
  <button *ngIf="isError" mat-icon-button class="icon-download" matTooltip="Download video" (click)="download.emit()">
    <mat-icon class="material-icons-outlined">download</mat-icon>
  </button>
</ng-template>

<ng-template #noSupport>
  <p class="vjs-no-js">
    To view this video please enable JavaScript, and consider upgrading to a web browser that
    <a href="https://videojs.com/html5-video-support/" target="_blank"> supports HTML5 video </a>
  </p>
</ng-template>
