import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Extension, ExtensionBase } from '@b3networks/api/bizphone';
import { WebrtcService } from '@b3networks/api/call';
import { DelegatedCallerId } from '@b3networks/api/callcenter';
import { DirectoryMemberService, GetDirectoryExtensionContactListReq } from '@b3networks/api/directory';
import { ToastService } from '@b3networks/shared/ui/toast';
import { debounceTime, finalize, startWith } from 'rxjs/operators';

type ExtensionsForm = FormGroup<{
  searchExtension: FormControl<string>;
}>;

@Component({
  selector: 'b3n-extensions',
  templateUrl: './extensions.component.html',
  styleUrls: ['./extensions.component.scss']
})
export class ExtensionsComponent implements OnInit {
  @Input() delegatedCallerIds: DelegatedCallerId[] = [];
  @Input() extension: Extension;
  @Input() closeDialogAfterCall = true;

  @Output() makeCallTo = new EventEmitter<string>();
  @Output() openManagePhoneDialog = new EventEmitter<any>();

  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  form: ExtensionsForm;
  listExts: ExtensionBase[] = [];
  page = 0;
  isDisableLoadMore = false;
  isLoading = false;

  readonly pageSizeDefault: number = 50;

  constructor(
    private fb: FormBuilder,
    private webrtcService: WebrtcService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private directoryMemberService: DirectoryMemberService,
    private toastService: ToastService
  ) {
    this.form = this.fb.group({
      searchExtension: ['']
    });
  }

  ngOnInit(): void {
    this.searchExtensionFC.valueChanges.pipe(startWith(''), debounceTime(300)).subscribe(value => {
      this.page = 0;
      this.isDisableLoadMore = false;
      this.listExts = [];
      this.onLoadExt(value);
    });
  }

  onLoadExt(keyword: string) {
    this.isLoading = true;
    this.directoryMemberService
      .getExtensionContactList(<GetDirectoryExtensionContactListReq>{
        page: this.page,
        size: this.pageSizeDefault,
        keyword
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        exts => {
          if (exts.length === 0 || exts.length < this.pageSizeDefault) this.isDisableLoadMore = true;
          this.listExts = [...this.listExts, ...exts];
          this.page += 1;
        },
        err => {
          this.isDisableLoadMore = true;
          this.toastService.error(err?.message);
        }
      );
  }

  callExtension(extKey: string, callerId: string) {
    this.webrtcService.updateUAConfiguration('display_name', callerId);
    this.makeCallTo.emit(extKey);

    if (this.closeDialogAfterCall) {
      this.ngZone.run(() => {
        this.dialog.closeAll();
      });
    }

    this.openManagePhoneDialog.emit();
  }

  openMenu() {
    this.matMenuTrigger?.openMenu();
  }

  get searchExtensionFC() {
    return this.form.controls.searchExtension;
  }
}
