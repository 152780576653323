import { TxnChannel } from '@b3networks/api/contact';
import { ChannelType } from '../inboxes/inboxes.model';

export class RelatesTo {
  fromTxn: string;
  orgUuid: string;
  listRelatedTo: CaseRelatedTo[];

  constructor(obj?: Partial<RelatesTo>) {
    if (obj) {
      Object.assign(this, obj);
      this.listRelatedTo = obj?.listRelatedTo?.map(x => new CaseRelatedTo(x)) || [];
    }
  }

  get key() {
    return `${this.orgUuid}_${this.fromTxn}`;
  }
}

export class CaseRelatedTo {
  uuid: string;
  orgUuid: string;
  title: string;
  channel: ChannelType;
  mode: ModeRelatedTxn;
  constructor(obj?: Partial<CaseRelatedTo>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class ToastTxnCreated {
  txnUuid: string;
  displayText: string;
  srcOrgUuid?: string;
}

export enum ModeRelatedTxn {
  normal = 'normal',
  converted = 'converted'
}

export interface ReqCasesRelatedTo {
  channels: TxnChannel[];
  textSearch: string;
}

export interface QueryCaseRelatedTo {
  txnUuid: string;
  title: string;
}
