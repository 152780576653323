<h1 mat-dialog-title>
  <span>Archive</span>
  <span *ngIf="convo.type !== GroupType.WhatsApp && convo.type !== GroupType.SMS"> Channel </span>
  <span *ngIf="convo.type === GroupType.WhatsApp || convo.type === GroupType.SMS"> Conversation </span>
</h1>
<div mat-dialog-content>
  <ng-container *ngIf="convo.type !== GroupType.WhatsApp && convo.type !== GroupType.SMS; else CHAT">
    <div class="flex align-items-center mb-8">
      <span class="mr-4"> Are you sure you want to archive </span>

      <mat-icon *ngIf="convo.privacy === Privacy.private" class="s-16">lock</mat-icon>
      <mat-icon *ngIf="convo.privacy !== Privacy.private" class="s-16">tag</mat-icon>
      <h3>{{ convo?.name }}</h3>
      ?
    </div>
    Archiving is useful if you don’t think you’ll need this channel any longer. Here’s what will happen when you archive
    this channel:
    <ul>
      <li>No one will be able to send messages to it</li>
      <li>Apps installed in this channel will be disabled</li>
      <li>A message will be posted that you archived the channel</li>
    </ul>
    <p>
      Messages from archived channels will still show up in search and archives, the same way they do today. You can
      always un-archive an archived channel later, if you need it again.
    </p>
  </ng-container>
  <ng-template #CHAT> Do you want to close this conversation with {{ convo?.name }}? </ng-template>
</div>
<div mat-dialog-actions class="flex justify-content-end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button [loading]="processing" color="warn" (click)="submit()">Archive</button>
</div>
