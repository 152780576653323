<div class="reply-container flex justify-content-start align-items-center">
  <ng-container *ngIf="!!user; else asyncUser">
    <ng-container *ngTemplateOutlet="renderUser; context: { user: user }"></ng-container>
  </ng-container>
  <ng-template #asyncUser>
    <ng-container *ngIf="user$ | async as user">
      <ng-container *ngTemplateOutlet="renderUser; context: { user: user }"></ng-container>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="!msgReply.buildMsg?.builtTextMessage?.isTriggerDirective; else trigger">
    <span
      class="normal-text spacer"
      [innerHTML]="msgReply.buildMsg?.builtTextMessage?.text | safe: 'html'"
      (click)="navigateMessage($event, msgReply)"
    ></span>
  </ng-container>
  <ng-template #trigger>
    <span
      class="normal-text spacer"
      lazyloadUnknown
      [text]="msgReply.buildMsg?.builtTextMessage.text"
      [participants]="participants"
      [innerHTML]="msgReply.buildMsg?.builtTextMessage?.text | safe: 'html'"
      (click)="navigateMessage($event, msgReply)"
      (showProfile)="showProfile.emit($event)"
    ></span>
  </ng-template>
</div>

<ng-template #renderUser let-user="user">
  <div class="flex align-items-center">
    <b3n-avatar-user
      [member]="user"
      [disabledClick]="true"
      [parentElr]="parentElr"
      [panelClass]="'avatar-customize'"
    ></b3n-avatar-user>
    <span class="reply-avatar">{{ user.displayName + ': ' }}</span>
  </div>
</ng-template>
