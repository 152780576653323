import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChatMessage } from '@b3networks/api/chat';
import { FileService } from '@b3networks/api/file';
import { Media } from '@b3networks/api/inbox';
import { MediaService, WaTemplateMessageData } from '@b3networks/api/workspace';
import { downloadData, getFileType } from '@b3networks/shared/common';

export class MediaUI {
  fileName: string;
  logoFile: string;
  s3Key: string;
  ext: string;
  size: number;
  width?: number;
  height?: number;

  constructor(media: Media) {
    if (media) {
      this.fileName = media.fileName;
      this.logoFile = getFileType(media.mimeType);
      this.s3Key = media.fileKey;
      this.ext = media.fileName.split('.').pop();
      this.size = Number(media.fileSize);
      this.width = media.width ?? null;
      this.height = media.height ?? null;
    }
  }
}

@Component({
  selector: 'b3n-wa-template-message',
  templateUrl: './wa-template-message.component.html',
  styleUrls: ['./wa-template-message.component.scss']
})
export class WaTemplateMessageComponent implements OnChanges {
  @Input() message: ChatMessage;
  @Input() parentElr: HTMLElement;

  templateData: WaTemplateMessageData;
  mediaUI: MediaUI;

  constructor(public mediaService: MediaService, private fileService: FileService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['message']) {
      this.templateData = this.message.body?.data;

      switch (this.templateData.header?.format) {
        case 'image':
          this.mediaUI = new MediaUI(this.templateData.header.image);
          break;
        case 'video':
          this.mediaUI = new MediaUI(this.templateData.header.video);
          break;
        case 'document':
          this.mediaUI = new MediaUI(this.templateData.header.document);
          break;
      }
    }
  }

  download() {
    this.fileService.downloadFileV3(this.mediaUI.s3Key).subscribe(res => {
      const file = new Blob([new Uint8Array(res.body)], {
        type: res.headers.get('content-type')
      });
      downloadData(file, this.mediaUI.fileName);
    });
  }
}
