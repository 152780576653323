import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import {
  CacheMessageQuery,
  CacheMessageService,
  Channel,
  ChannelQuery,
  ChannelService,
  ChannelType,
  ConfigStore,
  FilterConvoMessageRangeRequest,
  HistoryMessageService,
  PinMessage,
  PinMessageQuery,
  ViewChannelSection
} from '@b3networks/api/chat';
import {
  PersonalAppSettings,
  PersonalSettingsQuery,
  PersonalSettingsService,
  UnifiedWorkspaceSetting
} from '@b3networks/api/portal';
import { User, UserQuery } from '@b3networks/api/workspace';
import { APP_IDS, X } from '@b3networks/shared/common';
import { HashMap } from '@datorama/akita';
import { Observable, Subject, filter } from 'rxjs';
import { finalize, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ModeSidebar } from '../../../core/state/app-state.model';
import { AppQuery } from '../../../core/state/app.query';
import { AppService } from '../../../core/state/app.service';
import { ChannelDetailsComponent, ChannelDetailsInput } from '../../dialog/channel-details/channel-details.component';
import { DirectDetailsComponent, DirectDetailsInput } from '../../dialog/direct-details/direct-details.component';
import { InputSearchDialog, SearchDialogComponent } from '../../dialog/search-dialog/search-dialog.component';
import { ThreadMenuComponent } from './thread-menu/thread-menu.component';

@Component({
  selector: 'b3n-conversation-header',
  templateUrl: './conversation-header.component.html',
  styleUrls: ['./conversation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('triggerMenuPinned') triggerMenuPinned: MatMenuTrigger;
  @ViewChild('triggerMenuThread') triggerMenuThread: MatMenuTrigger;
  @ViewChild(ThreadMenuComponent) threadMenuComponent: ThreadMenuComponent;

  @Input() channel: Channel;
  @Input() isNotFullScreen: boolean;
  @Input() hasBackAction: boolean;
  @Input() showSelectView: boolean;

  @Output() hideWidget = new EventEmitter<boolean>();
  @Output() jumpToMessage = new EventEmitter<Channel>();
  @Output() backEvent = new EventEmitter<boolean>();

  directUser$: Observable<User>; // direct chat user
  toggleInfoBtn$: Observable<boolean>;
  countPinned$: Observable<number>;
  countThread$: Observable<number>;
  viewChannelSection$: Observable<ViewChannelSection>;

  isLoadingPinned = true;
  selectTab = new FormControl();

  private _id: string;
  private _destroyConvo = new Subject();
  private _mappingFetchingMessage: HashMap<boolean> = {};

  readonly ChannelType = ChannelType;
  readonly ViewChannelSection = ViewChannelSection;

  constructor(
    private router: Router,
    private userQuery: UserQuery,
    private messageService: HistoryMessageService,
    private channelQuery: ChannelQuery,
    private channelService: ChannelService,
    private personalSettingsQuery: PersonalSettingsQuery,
    private personalSettingService: PersonalSettingsService,
    private dialog: MatDialog,
    private appQuery: AppQuery,
    private appService: AppService,
    private cacheMessageService: CacheMessageService,
    private cacheMessageQuery: CacheMessageQuery,
    private pinMessageQuery: PinMessageQuery,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this._destroyConvo.next(true);
    this._destroyConvo.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel && this.channel.id !== this._id) {
      this._id = this.channel.id;
      if (this.channel.isDirectChat) {
        this.directUser$ = this.userQuery.selectUserByChatUuid(this.channel.directChatUsers.otherUuid);
      }
      this.viewChannelSection$ = this.channelQuery.selectUIState(this.channel.id, 'viewChannelSection').pipe(
        tap(view => {
          this.selectTab.setValue(view === ViewChannelSection.chat ? 0 : 1);
        })
      );
      this.countPinned$ = this.pinMessageQuery.countPinByChannel(this.channel.id);
      this.countThread$ = this.channelQuery.selectCountThreadsActive(this.channel.id);
      this.trackingSeeAllPined();
    }
  }

  ngOnInit() {
    this.toggleInfoBtn$ = this.appQuery.modeRightSidebar$.pipe(
      switchMap(mode =>
        mode === ModeSidebar.side
          ? this.personalSettingsQuery
              .selectAppSettings(X.orgUuid, APP_IDS.UNIFIED_WORKSPACE)
              .pipe(map((result: PersonalAppSettings) => (result as UnifiedWorkspaceSetting)?.showRightSidebar))
          : this.appQuery.showRightSidebar$
      )
    );
  }

  onTabChanged($event) {
    this.changedViewChannel($event === 0 ? ViewChannelSection.chat : ViewChannelSection.files);
  }

  changedViewChannel(view: ViewChannelSection) {
    this.channelService.updateChannelViewState(this.channel.id, {
      viewChannelSection: view
    });
  }

  onMenuOpenThread() {
    this.threadMenuComponent.onMenuOpen();
  }

  onSelectThread(thread: Channel) {
    if (!this.isNotFullScreen) {
      this.channelService.updateChannelViewState(this.channel.id, {
        activeThreadId: thread.id
      });
    } else {
      this.router.navigate(['discussion', thread.id]);
    }
    this.triggerMenuThread.closeMenu();
  }

  onMenuOpenPinned() {
    // fetch detail message of pin
    this.isLoadingPinned = true;
    this.pinMessageQuery
      .selectPinByChannel(this.channel.id)
      .pipe(takeUntil(this._destroyConvo))
      .subscribe(pinned => {
        const messageIdsNotFound = pinned.filter(x => !this.cacheMessageQuery.hasEntity(x.messageId));
        if (messageIdsNotFound.length > 0) {
          this.isLoadingPinned = true;
          this.cdr.detectChanges();
          const arr$ = messageIdsNotFound.map(pinned => this.getHistoryMessageById(pinned));
          arr$.forEach((api, index) =>
            api
              .pipe(
                finalize(() => {
                  if (index === arr$.length - 1) {
                    setTimeout(() => {
                      this.isLoadingPinned = false;
                      this.cdr.detectChanges();
                    }, 1000);
                  }
                })
              )
              .subscribe()
          );
        } else {
          this.isLoadingPinned = false;
          this.cdr.detectChanges();
        }
      });
  }

  navigateChannelParent() {
    // open current thread
    this.channelService.updateChannelViewState(this.channel.parentId, {
      activeThreadId: this.channel.id
    });
    this.router.navigate(['conversations', this.channel.parentId]);
  }

  openDetailConvo(channel: Channel) {
    if (this.channel.isPersonalBookmark) {
      return;
    }

    if (channel.type === ChannelType.gc) {
      this.dialog.open(ChannelDetailsComponent, {
        width: '580px',
        height: '600px',
        data: <ChannelDetailsInput>{
          channel: channel
        },
        autoFocus: false
      });
    } else if (channel.type === ChannelType.dm) {
      const user = this.userQuery.getUserByChatUuid(channel.directChatUsers.otherUuid);
      this.dialog.open(DirectDetailsComponent, {
        data: <DirectDetailsInput>{
          convo: channel,
          member: user
        },
        width: '350px',
        autoFocus: false
      });
    }
  }

  openFullscreen(channel: Channel) {
    if (channel.isThread) {
      this.router.navigate(['discussion', channel.id]);
    } else {
      this.router.navigate(['conversations', channel.id]);
    }
  }

  openSearchMessages(convo: Channel) {
    this.dialog.open(SearchDialogComponent, {
      data: <InputSearchDialog>{
        key: '',
        channel: convo,
        isPersonalBookmark: convo.isPersonalBookmark
      },
      width: '50%',
      height: '100%',
      maxWidth: 'unset',
      position: <DialogPosition>{ right: '0px' },
      panelClass: 'search-message',
      autoFocus: false
    });
  }

  viewDetail(toggleInfoBtn: boolean) {
    const mode = this.appQuery.getValue()?.modeRightSidebar;
    if (mode === ModeSidebar.side) {
      const settings = <UnifiedWorkspaceSetting>(
        this.personalSettingsQuery.getAppSettings(X.orgUuid, APP_IDS.UNIFIED_WORKSPACE)
      );
      settings.showRightSidebar = !toggleInfoBtn;
      this.personalSettingService.updateStorePersonal(settings);
      this.personalSettingService.updateAppSettings(settings, true).subscribe();
    } else if (mode === ModeSidebar.over) {
      this.appService.update({
        showRightSidebar: !toggleInfoBtn
      });
    }
  }

  viewMenu(toggleInfoBtn: boolean) {
    const mode = this.appQuery.getValue()?.modeRightSidebar;
    if (mode === ModeSidebar.over) {
      this.appService.update({
        showLeftSidebar: !toggleInfoBtn
      });
    }
  }

  private trackingSeeAllPined() {
    this.appQuery.triggerSeeAllPinned$.pipe(skip(1), takeUntil(this._destroyConvo)).subscribe(() => {
      if (this.triggerMenuPinned) {
        this.triggerMenuPinned.openMenu();
      }
    });
  }

  private getHistoryMessageById(pinned: PinMessage) {
    if (!this._mappingFetchingMessage?.[pinned.messageId]) {
      this._mappingFetchingMessage[pinned.messageId] = true;
      const req = <FilterConvoMessageRangeRequest>{
        convoId: pinned.convoId,
        limit: 1,
        from: pinned.messageId,
        to: pinned.messageId,
        fromInclusive: true,
        toInclusive: true,
        beforeFromSize: 0,
        afterToSize: 0,
        isAsc: true
      };
      return this.messageService
        .getChannelRangeHistory(req, <ConfigStore>{
          isNoStore: true,
          noLoading: true
        })
        .pipe(
          map(x => x?.messages?.find(m => m.id === pinned.messageId)),
          finalize(() => delete this._mappingFetchingMessage[pinned.messageId]),
          tap(message => {
            if (message) {
              this.cacheMessageService.upsertManyMessages([message]);
            }
          })
        );
    } else {
      return this.cacheMessageQuery.selectEntity(pinned.messageId).pipe(
        filter(x => x != null),
        take(1)
      );
    }
  }
}
