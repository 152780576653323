<div class="flex column gap-8" *ngIf="pinned$ | async as pinned; else loadingPinned">
  <h2>Pinned Messages</h2>
  <div class="spacer body flex column">
    <ng-container *ngIf="!isLoadingPinned; else loadingPinned">
      <ng-container *ngIf="pinned.length > 0; else noData">
        <div class="flex column" *ngFor="let message of pinned; let i = index; trackBy: trackByPinned">
          <div (click)="jumpToMessage(message)" class="item-message">
            <div class="actions">
              <mat-icon class="icon action s-24" (click)="unpin($event, message)" matTooltip="Unpin this message">
                keep_off
              </mat-icon>
            </div>
            <csh-chat-message
              [message]="message"
              [previousMessage]="null"
              [configMessageOption]="configMessageOption"
            ></csh-chat-message>
          </div>
          <mat-divider *ngIf="i !== pinned.length - 1"></mat-divider>
        </div>
      </ng-container>
      <ng-template #noData>
        <div class="pinned-loadding flex column justify-content-center align-items-center gap-8 noData">
          <mat-icon class="s-64">keep</mat-icon>
          <span class="secondary-text">This channel doesn’t have any pinned messages yet.</span>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
<ng-template #loadingPinned>
  <div class="pinned-loadding flex justify-content-center align-items-center">
    <mat-spinner [diameter]="35"></mat-spinner>
  </div>
</ng-template>
