import { Observable } from 'rxjs';
import { ChatMessage } from '../../chat-message/chat-message.model';
import { BuildMessageUI } from '../../chat-message/chat-messsage-ui.model';
import { ConvoType, Privacy, Status, UserType } from '../../enums.model';
import { ChannelType, NameChannelPersonal } from '../model/enum-channel.model';

export const ChatChannelStoreName = 'chat_channel';

// only support : direct and group
export class Channel {
  id: string;
  updatedAt: number; // date
  createdAt: number; // date
  archivedAt: number; // date
  archivedBy: string;
  createdBy: string;
  name: string;
  description: string;
  privacy: Privacy;
  type: ChannelType;
  status: Status;
  participants: IParticipant[];
  closedL2?: ClosedChannel; // when channel close
  parent: ParentThreadInfo;

  // withMe
  // TODO: move to UI state
  unreadCount: number;
  mentionCount: number;
  lastSeenMillis: number | null;
  emc: number; // estimated message count
  isStarred: boolean;
  nameUserDirect: string;
  isBot: boolean;
  isDisableUser: boolean;

  private _lastMessage: ChatMessage;
  public get lastMessage(): ChatMessage {
    return this._lastMessage;
  }
  public set lastMessage(value: ChatMessage) {
    if (value?.body?.text) {
      this._lastMessage = value;
      this.timeLastMessage = value?.ts;
    }
  }

  // client UI
  meUuid: string; // chatUser
  directChatUsers: DirectChatUser = <DirectChatUser>{};
  isDraft$: Observable<boolean>;
  icon: string;
  isSvg: boolean;
  classIcon: string;
  timeLastMessage: number; // to sort
  isTemporary: boolean; // need to refetch detail

  constructor(obj?: Partial<Channel>) {
    if (obj) {
      Object.assign(this, obj);

      const typeLowerCase = obj?.type?.toLowerCase() as string;
      if (['group', 'gc'].includes(typeLowerCase)) {
        this.type = ChannelType.gc;
      } else if (['direct', 'dm'].includes(typeLowerCase)) {
        this.type = ChannelType.dm;
      } else if (['personal'].includes(typeLowerCase)) {
        this.type = ChannelType.PERSONAL;
      } else if (['thread'].includes(typeLowerCase)) {
        this.type = ChannelType.THREAD;
      }

      if (obj?.privacy?.toLowerCase() === 'private') {
        this.privacy = Privacy.private;
      } else if (obj?.privacy?.toLowerCase() === 'public') {
        this.privacy = Privacy.public;
      }

      if (obj?.createdAt) this.createdAt = +obj?.createdAt;
      if (obj?.updatedAt) this.updatedAt = +obj?.updatedAt;
      if (obj?.archivedAt) this.archivedAt = +obj?.archivedAt;
      if (obj?.emc) this.emc = +obj.emc || 0;
      if (obj?.unreadCount) this.unreadCount = +this.unreadCount || 0;
      if (obj?.mentionCount) this.mentionCount = +this.mentionCount || 0;
      if (obj?.lastSeenMillis) this.lastSeenMillis = +this.lastSeenMillis || 0;
      if (obj?.lastMessage) {
        this.lastMessage = new ChatMessage({
          ...obj.lastMessage,
          buildMsg: <BuildMessageUI>{
            newByLastMessageChannel: true
          }
        });
      }

      if (!obj.isTemporary) {
        this.isTemporary = false;
      }

      // set icon
      this.setIconChannel();
    }
  }

  withMeUuid(meUuid: string) {
    this.meUuid = meUuid;
    if (!!meUuid && this.type === ChannelType.dm) {
      // because participant only 2 user (me and you) in direct chat
      this.directChatUsers = <DirectChatUser>{
        meUuid: meUuid,
        otherUuid:
          this.participants?.length === 1
            ? meUuid
            : this.participants[0].userID === meUuid
            ? this.participants[1].userID
            : this.participants[0].userID
      };
    }
    return this;
  }

  // filter orther public channel
  get isMyChannel() {
    return this.type === ChannelType.dm || this.type === ChannelType.PERSONAL || this.isMember;
  }

  get parentId() {
    return this.parent?.message?.convo;
  }

  get parentMessageId() {
    return this.parent?.message?.id;
  }

  get closedAt() {
    return this.closedL2?.at;
  }

  get displayName(): string {
    switch (this.type) {
      case ChannelType.dm:
        return this.nameUserDirect || this.directChatUsers?.otherUuid;
      case ChannelType.PERSONAL:
        return this.name[0].toUpperCase() + this.name.slice(1);
      default:
        // because group only id&name property,no type property
        return this.name;
    }
  }

  get isOwner() {
    return this.createdBy === this.meUuid;
  }

  get isOpen(): boolean {
    return !this.archivedAt;
  }

  get isClosed(): boolean {
    return !!this.closedL2?.id;
  }

  get isArchived(): boolean {
    return !!this.archivedAt || !!this.archivedBy;
  }

  get isGroupChat() {
    return this.type === ChannelType.gc;
  }

  get isThread() {
    return this.type === ChannelType.THREAD;
  }

  get isDirectChat() {
    return this.type === ChannelType.dm;
  }

  get isPersonalChat() {
    return this.type === ChannelType.PERSONAL;
  }

  get isThreadChat() {
    return this.type === ChannelType.THREAD;
  }

  get isPersonalBookmark() {
    return this.type === ChannelType.PERSONAL && this.name === NameChannelPersonal.BOOKMARKS;
  }

  get isMember() {
    return !!this.meUuid && this.participants?.some(m => m.userID === this.meUuid);
  }

  get memLength() {
    return this.participants?.length || 0;
  }

  get isUnread(): boolean {
    return this.unreadCount > 0 || this.mentionCount > 0;
  }

  get isGeneral(): boolean {
    return this.type === ChannelType.gc && this.name === 'general';
  }

  get displayConvoType(): string {
    return this.type === ChannelType.dm ? 'Member' : 'Team';
  }

  get displayDetailConvoType(): string {
    return this.type === ChannelType.dm ? 'Member' : 'Channel';
  }

  get isPublic() {
    return this.privacy === Privacy.public;
  }

  get convoType() {
    switch (this.type) {
      case ChannelType.dm:
        return ConvoType.direct;
      case ChannelType.gc:
        return ConvoType.groupchat;
      case ChannelType.THREAD:
        return ConvoType.THREAD;
      case ChannelType.PERSONAL:
        return ConvoType.personal;
    }
  }

  get userType() {
    return UserType.TeamMember;
  }

  private setIconChannel() {
    if (this.type === ChannelType.PERSONAL) {
      this.setIconForPersonalChannel();
    } else if (this.type === ChannelType.gc) {
      if (this.privacy === Privacy.private) {
        this.icon = 'lock';
        this.isSvg = false;
        this.classIcon = 'material-symbols-rounded';
      } else {
        // this.icon = '#';
        this.icon = 'tag';
        this.isSvg = false;
        this.classIcon = 'material-symbols-rounded';
      }
    } else if (this.type === ChannelType.dm) {
      this.icon = 'fiber_manual_record';
      this.isSvg = false;
      this.classIcon = 'material-symbols-rounded';
    } else if (this.type === ChannelType.THREAD) {
      this.icon = 'thread_outline';
      this.isSvg = true;
    }
  }

  private setIconForPersonalChannel() {
    switch (this.name) {
      case NameChannelPersonal.BOOKMARKS:
        this.icon = 'bookmark';
        this.isSvg = false;
        this.classIcon = 'material-symbols-rounded';
        break;
      default:
        this.icon = 'person';
        break;
    }
  }
}

export interface ParentThreadInfo {
  message: {
    id: string; // created thread by messageId
    convo: string; // channelId
  };
}

export interface ClosedChannel {
  id: string; // close Id
  at: number;
  by: string; // chatUserId
}

export interface ChannelPersonalResponse {
  channels: {
    [key: string]: {
      details: Partial<Channel>;
    };
  };
}

export interface IParticipant {
  userID: string;
  role: string;
}

export interface IParticipantA {
  id: string;
  role: string;
}

export interface DirectChatUser {
  meUuid: string; // chatUser
  otherUuid: string; // chatUser
}

export interface CreateConvoGroupReq {
  name: string;
  description: string;
  privacy: Privacy;
  type: ChannelType | string;
  participants: string[]; // chatUser
}

// only 1 property
export interface UpdateChannelReq {
  name: string;
  description: string;
  add: string[];
  del: string[];
}

export interface RecentChannel {
  id: string;
  date: number;
}

// if the original message changed, calling the copy API again with the same payload to update the cloned message
export interface CopyMessageRequest {
  srcMessage: {
    convo: string;
    id: string;
    ct: string;
  };
  dstConvoId: string;
  options?: {
    shouldDeduplicate?: boolean; // must be true for bookmark channel
  };
}

export interface GetChannelWithMeRequest {
  personalChannels?: boolean;
  threads?: boolean;
}

export interface CreateThreadRequest {
  thread: {
    details: {
      name: string;
      description: string;
    };
    parent: ParentThreadInfo;
  };
}

export class ActiveThreadByParentRepsonse {
  details: {
    id: string;
    name: string;
    description: string;
    privacy: Privacy;
    type: ChannelType;
    createdBy: string;
    createdAt: number;
    namespace: string;
  };
  lastMessage: ChatMessage;
  parent: ParentThreadInfo;
  users: IParticipantA[];

  constructor(obj: Partial<ActiveThreadByParentRepsonse>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.['lastMessage']) {
        this.lastMessage = new ChatMessage({
          ...obj.lastMessage,
          buildMsg: <BuildMessageUI>{
            newByLastMessageChannel: true
          }
        });
      }

      if (obj?.details?.createdAt) this.details.createdAt = +obj.details.createdAt;
    }
  }
}

export interface GetChannelWithMeV2Request {
  filter: {
    include: {
      channelIds: string[];
    };
  };
}

export interface ConfigChannelWithMeV2 {
  loadedMineChannel?: boolean;
}

export class GetChannelWithMeV2Response {
  details: {
    id: string;
    name: string;
    privacy: Privacy;
    type: ChannelType;
    createdBy: string;
    createdAt: number;
    namespace: string;
  };
  lastMessage: ChatMessage;
  seenStatus: {
    unreadCount: number;
    mentionCount: number;
    lastSeenMillis: number;
  };
  parent: ParentThreadInfo;
  closedL2: ClosedChannel; // when channel close
  users: IParticipantA[];
  estimatedMessagesCount: number;
  archived: {
    at: number;
    by: string;
  };

  constructor(obj: Partial<GetChannelWithMeV2Response>) {
    if (obj) {
      Object.assign(this, obj);
      if (obj?.lastMessage)
        this.lastMessage = new ChatMessage({
          ...obj.lastMessage,
          buildMsg: <BuildMessageUI>{
            newByLastMessageChannel: true
          }
        });

      // convert string to number timestamp
      if (obj?.details?.createdAt) this.details.createdAt = +obj.details.createdAt;
      if (obj?.seenStatus?.unreadCount) this.seenStatus.unreadCount = +obj.seenStatus.unreadCount;
      if (obj?.seenStatus?.['mentionsCount']) this.seenStatus.mentionCount = +obj.seenStatus['mentionsCount'];
      if (obj?.seenStatus?.lastSeenMillis) this.seenStatus.lastSeenMillis = +obj.seenStatus.lastSeenMillis;
      if (obj?.estimatedMessagesCount) this.estimatedMessagesCount = +obj.estimatedMessagesCount || 0;
      if (obj?.closedL2?.at) this.closedL2.at = +obj.closedL2.at;
      if (obj?.archived?.at) this.archived.at = +obj.archived.at;
    }
  }
}

export interface RequestRangeThreadsByParent {
  parentId?: string;
  limit: number;
  fromInclusive?: boolean;
  toInclusive?: boolean;
  beforeFromSize?: number; // null = 0
  afterToSize?: number; // null = 0
  isAsc?: boolean;
  options?: {
    withBookmarks?: boolean;
  };

  // closedL2 id
  from?: string;
  to?: string;
}

export class ResponseRangeThreads {
  threads: GetChannelWithMeV2Response[];
  count: number;

  // threadId
  from: string;
  to: string;

  // ui
  channels: Channel[];

  constructor(obj?: Partial<ResponseRangeThreads>) {
    if (obj) {
      Object.assign(this, obj);

      this.threads = obj?.threads?.map(x => new GetChannelWithMeV2Response(x)) || [];
    }
  }

  withConvertChannel(channels: Channel[]) {
    this.channels = channels || [];
    return this;
  }
}

export interface RequestRangeThreadsByUser {
  limit: number;
  fromInclusive?: boolean;
  toInclusive?: boolean;
  beforeFromSize?: number; // null = 0
  afterToSize?: number; // null = 0
  isAsc?: boolean;
  options?: {
    withBookmarks?: boolean;
  };

  // threadId
  from?: string;
  to?: string;
}
