import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChatMessage, ChatService } from '@b3networks/api/chat';
import { CacheMediaQuery } from '@b3networks/api/common';
import { FileService, RequestUploadData } from '@b3networks/api/file';
import { MediaService } from '@b3networks/api/workspace';
import { X } from '@b3networks/shared/common';
import { filter, finalize, take } from 'rxjs';
import { MediaUI } from '../wa-template-message.component';

@Component({
  selector: 'b3n-wa-template-image',
  templateUrl: './wa-template-image.component.html',
  styleUrls: ['./wa-template-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaTemplateImageComponent implements OnInit {
  @Input() message: ChatMessage;
  @Input() mediaUI: MediaUI;
  @Input() parentElr: HTMLElement;

  loaded: boolean;
  isLoading = true;
  noIntersectionObserver: boolean;
  bgImage: string;
  style: any;

  constructor(
    private cdr: ChangeDetectorRef,
    public mediaService: MediaService,
    private cacheMediaQuery: CacheMediaQuery,
    private fileService: FileService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.style = this.customStyleMessage(this.mediaUI);

    const url = this.cacheMediaQuery.getMediaByKey(this.mediaUI.s3Key, true)?.url;
    this.noIntersectionObserver = !!url;
    this.isLoading = !this.noIntersectionObserver;
    this.bgImage = url ? `url('${url}')` : null;
  }

  onRenderImg(index = 0) {
    if (!this.mediaUI.s3Key) {
      return;
    }

    this.chatService.session$
      .pipe(
        filter(x => x != null),
        take(1)
      )
      .subscribe(session => {
        const req: RequestUploadData = <RequestUploadData>{
          chatUserId: session.chatUser,
          orgUuid: X.orgUuid,
          wssToken: session.token,
          chatServer: session.addr,
          convoType: this.message.ct
        };

        this.fileService
          .getThumbnailMediaStorageUuid(this.mediaUI.s3Key, req)
          .pipe(
            finalize(() => {
              this.loaded = true;
              this.isLoading = false;
              this.cdr.markForCheck();
            })
          )
          .subscribe({
            next: url => {
              if (url) {
                this.bgImage = `url('${url}')`;
              }
            },
            error: (err: HttpErrorResponse) => {
              if (err.status === 423) {
                setTimeout(() => {
                  this.onRenderImg(index + 1);
                }, 2000);
              }
            }
          });
      });
  }

  private customStyleMessage(mediaUI: MediaUI) {
    const defaultSize = 316;
    const width = mediaUI.width && mediaUI.width > 0 ? mediaUI.width : defaultSize;
    const height = mediaUI.height && mediaUI.height > 0 ? mediaUI.height : defaultSize;

    let resizeWith = width;
    let resizeHeight = height;

    if (width !== defaultSize) {
      resizeWith = defaultSize;
      resizeHeight = (height * resizeWith) / width;

      if (resizeHeight > defaultSize) {
        resizeHeight = defaultSize;
        resizeWith = (width * resizeHeight) / height;
      }
    }

    return { width: resizeWith + 'px', height: resizeHeight + 'px' };
  }
}
