const b3nColorPalettes = {
  black: {
    DEFAULT: '#000000',
    50: '#1D1F22',
    100: '#313131',
    150: '#080809',
    200: '#2a2a2a',
    300: '#1f1f1f',
    400: '#363636',
    500: '#3b3b3b',
    600: '#1D1C1D',
    700: '#04131f',
    800: '#313338',
    900: '#2b2d31',
    901: '#23252A',
    902: '#232329',
    903: '#d8d7e7'
  },
  white: {
    DEFAULT: '#FFFFFF'
  },
  gray: {
    DEFAULT: '#c1c1c1',
    50: '#fafafa',
    100: '#f5f5f5',
    150: '#cecece',
    200: '#eeeeee',
    250: '#e6e6e6',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    902: '#ECECEC',
    903: '#4B4B4B',
    904: '#444444',
    905: '#888888',
    906: '#d3d3d3',
    907: '#808080',
    908: '#5a6976',
    909: '#7a7a7a',
    910: '#525252',
    911: '#cccccc',
    912: '#9d9d9d',
    913: '#4b4e54',
    914: '#D8DFE7',
    915: '#4e5058',
    916: '#dbdee1',
    917: '#B5BAC1',
    918: '#75798a',
    919: '#D9D9DD',
    920: '#cbcbcf',
    921: '#ECEFF3',
    922: '#454950'
  },
  blue: {
    DEFAULT: '#172B4D',
    50: '#E3E5E8',
    70: '#027cb5',
    100: '#1979b1',
    150: '#F2F3F5',
    200: '#BBDEFB',
    250: '#fafafb',
    300: '#E3F2FD',
    350: '#EBEDEF',
    400: '#E7EAFE',
    450: '#949BA4',
    500: '#3E7CE4',
    550: '#232429',
    600: '#dde2e6',
    650: '#5c5e66',
    700: '#007be0',
    750: '#2196F3',
    800: '#00b8d9',
    850: '#14b0ff',
    900: '#80cbc4',
    950: '#184440',
    951: '#E5ECF5',
    952: '#D3E6FF',
    953: '#ebf2fb',
    954: '#F8FCFF',
    955: '#0A5D9F',
    956: '#138FCE',
    957: '#F6F8FC'
  },
  green: {
    DEFAULT: '#155724',
    100: '#d4edda',
    200: '#c3e6cb',
    300: '#35C75A',
    400: '#4caf50',
    500: '#0a3622',
    600: '#d1e7dd',
    700: '#75b798',
    800: '#051b11',
    900: '#0f5132',
    950: '#36b37e',
    951: '#1B5E20',
    952: '#E0F3E0'
  },
  brown: {
    DEFAULT: '#795548'
  },
  yellow: {
    DEFAULT: '#FFE082',
    50: '#997404',
    100: '#FFECB3',
    200: '#ffc300',
    300: '#faf8e2',
    400: '#ffeb3b',
    500: '#4b4300',
    600: '#fff3cd',
    700: '#664d03',
    800: '#ffda6a',
    900: '#332701',
    950: '#FAF2D7',
    951: '#816000'
  },
  red: {
    DEFAULT: '#d72b3f',
    50: '#ea868f',
    100: '#ff5630',
    150: '#2c0b0e',
    200: '#ff0000',
    250: '#842029',
    300: '#e95849',
    350: '#FF6D6B',
    400: '#d64f46',
    450: '#db1000',
    500: '#f44336',
    600: '#fff8f8',
    700: '#614b4b',
    800: '#c62828',
    900: '#f8d7da',
    950: '#CA3434',
    951: '#FDF1F0'
  },
  orange: {
    100: '#F59908',
    200: '#ff991f'
  }
};

const getHexTransparencies = (hexColor: string, transparencies: number) => {
  const hexTransparencies = {
    100: 'FF',
    99: 'FC',
    98: 'FA',
    97: 'F7',
    96: 'F5',
    95: 'F2',
    94: 'F0',
    93: 'ED',
    92: 'EB',
    91: 'E8',
    90: 'E6',
    89: 'E3',
    88: 'E0',
    87: 'DE',
    86: 'DB',
    85: 'D9',
    84: 'D6',
    83: 'D4',
    82: 'D1',
    81: 'CF',
    80: 'CC',
    79: 'C9',
    78: 'C7',
    77: 'C4',
    76: 'C2',
    75: 'BF',
    74: 'BD',
    73: 'BA',
    72: 'B8',
    71: 'B5',
    70: 'B3',
    69: 'B0',
    68: 'AD',
    67: 'AB',
    66: 'A8',
    65: 'A6',
    64: 'A3',
    63: 'A1',
    62: '9E',
    61: '9C',
    60: '99',
    59: '96',
    58: '94',
    57: '91',
    56: '8F',
    55: '8C',
    54: '8A',
    53: '87',
    52: '85',
    51: '82',
    50: '80',
    49: '7D',
    48: '7A',
    47: '78',
    46: '75',
    45: '73',
    44: '70',
    43: '6E',
    42: '6B',
    41: '69',
    40: '66',
    39: '63',
    38: '61',
    37: '5E',
    36: '5C',
    35: '59',
    34: '57',
    33: '54',
    32: '52',
    31: '4F',
    30: '4D',
    29: '4A',
    28: '47',
    27: '45',
    26: '42',
    25: '40',
    24: '3D',
    23: '3B',
    22: '38',
    21: '36',
    20: '33',
    19: '30',
    18: '2E',
    17: '2B',
    16: '29',
    15: '26',
    14: '24',
    13: '21',
    12: '1F',
    11: '1C',
    10: '1A',
    9: '17',
    8: '14',
    7: '12',
    6: '0F',
    5: '0D',
    4: '0A',
    3: '08',
    2: '05',
    1: '03',
    0: '00'
  };
  return hexColor + hexTransparencies[transparencies];
};

export { b3nColorPalettes, getHexTransparencies };
