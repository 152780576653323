<div class="flex justify-content-start align-items-center gap-16">
  <div class="flex column justify-content-start align-items-start">
    <div class="file_message">
      <ng-container *ngIf="!message.attachmentUI?.isImgFile; else imageWidget">
        <ng-container *ngIf="message.attachmentUI?.isVideo">
          <csh-video-file-message
            [message]="message"
            [isPublic]="isPublic"
            [parentElr]="parentElr"
          ></csh-video-file-message>
        </ng-container>
        <ng-container *ngIf="message.attachmentUI?.isAudio">
          <csh-audio-file-message
            [message]="message"
            [isPublic]="isPublic"
            [parentElr]="parentElr"
          ></csh-audio-file-message>
        </ng-container>
        <ng-container *ngIf="!message.attachmentUI?.isAudio && !message.attachmentUI?.isVideo">
          <mat-card class="file-container">
            <div class="p-16 flex justify-content-start align-items-center gap-8">
              <img class="attachment__avatar" [src]="message.attachmentUI?.logoFileType" />
              <div class="flex column justify-content-start align-items-start">
                <span class="semi-bold">{{ message.attachmentUI?.attachmentData?.name }}</span>
                <span class="description mat-caption">
                  <span *ngIf="message.attachmentUI?.sizeFile"> {{ message.attachmentUI?.sizeFile }} </span>
                  <ng-container *ngIf="fileType">
                    <span class="divider">|</span>
                    <span>{{ fileType }}</span>
                  </ng-container>
                </span>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </ng-container>
      <ng-template #imageWidget>
        <div
          *ngIf="!noIntersectionObserver"
          [intersectionObserverDom]="parentElr"
          class="image-container flex justify-content-center align-items-center"
          [class.blur]="isHiding"
          [ngStyle]="style"
          [style.background-image]="!isHiding && backgroundImage ? backgroundImage : ''"
          (render)="onRenderImg()"
          (click)="zoom()"
        >
          <mat-spinner *ngIf="!loaded || mediaService.loadingMap?.[this.message.id]" diameter="40"></mat-spinner>
          <span
            *ngIf="loaded && !backgroundImage && !mediaService.loadingMap?.[this.message.id]"
            class="material-icons not-loaded-image"
          >
            broken_image
          </span>
        </div>

        <div
          *ngIf="noIntersectionObserver"
          class="image-container flex justify-content-center align-items-center"
          [class.blur]="isHiding"
          [style.background-image]="!isHiding && backgroundImage ? backgroundImage : ''"
          [ngStyle]="style"
          (click)="zoom()"
        >
          <mat-spinner *ngIf="mediaService.loadingMap?.[this.message.id]" diameter="40"></mat-spinner>
        </div>
      </ng-template>

      <ng-container
        *ngIf="!mediaService.loadingMap?.[this.message.id]&& !isHideAction && (!isLoading || !message.attachmentUI?.isImgFile)"
      >
        <div class="actions-container flex justify-content-end align-items-center">
          <div
            *ngIf="!isHiding && !message.attachmentUI?.isVideo && !message.attachmentUI?.isAudio"
            class="actions-container__item tooltip-custom"
            data-tooltip="Download"
            (click)="download()"
          >
            <mat-icon>save_alt</mat-icon>
          </div>
          <div
            *ngIf="message.attachmentUI?.isImgFile && !isPublic"
            class="actions-container__item tooltip-custom"
            [attr.data-tooltip]="isHiding ? 'Show this' : 'Hide this'"
            (click)="hide()"
          >
            <mat-icon>{{ isHiding ? 'visibility' : 'visibility_off' }}</mat-icon>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="message.attachmentUI?.isFailed">
      <span class="mat-caption red-fg">Failed to send message.</span>
    </ng-container>

    <ng-container *ngIf="message.hasThread && !configMessageOption?.noShowThread">
      <b3n-info-thread-message
        [message]="message"
        [parentElr]="parentElr"
        (showProfile)="showProfile.emit($event)"
      ></b3n-info-thread-message>
    </ng-container>
  </div>

  <mat-spinner
    *ngIf="!message.attachmentUI?.isAudio && !message.attachmentUI?.isVideo && !message.attachmentUI?.isImgFile && mediaService.loadingMap?.[this.message.id]"
    diameter="30"
  ></mat-spinner>
</div>
