import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: '[shc-zoom-pan-container], shc-zoom-pan-container',
  templateUrl: './zoom-pan-container.component.html',
  styleUrls: ['./zoom-pan-container.component.scss'],
  animations: [
    trigger('transformAnimation', [
      state('*', style({ transform: '{{transform}}' }), { params: { transform: 'scale(1)', duration: '0s' } }),
      transition('* => *', animate('{{duration}} ease'))
    ])
  ]
})
export class ZoomPanContainerComponent {
  @Input() minZoom = 1;
  @Input() maxZoom = 10;
  private scale = 1;
  private translate: [number, number] = [0, 0];
  private translateOnPanStart: [number, number] = [0, 0];
  private start: { x: number; y: number } = { x: 0, y: 0 };
  private isMouseMove = false;

  transformAnimationState = {
    value: null,
    params: {
      transform: 'scale(1)',
      duration: '0s'
    }
  };

  constructor(private elementRef: ElementRef) {}

  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event) {
    const currentScale = this.scale;
    const newScale = this._clamp(this.scale + Math.sign(event.wheelDelta) / 10, this.minZoom, this.maxZoom);
    if (currentScale !== newScale) {
      this.translate = this._calculateTranslationToZoomPoint(currentScale, newScale, this.translate, event);
      this.scale = newScale;

      this._updateTransformAnimationState();
    }
    event.preventDefault();
  }

  reset() {
    this.scale = 1;
    this.translate = [0, 0];
    this._updateTransformAnimationState();
  }

  downloadSvg(svg: Node, name: string = null) {
    const tempSvg: SVGGraphicsElement = svg as SVGGraphicsElement;
    const data = new XMLSerializer().serializeToString(tempSvg);
    const svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);
    const img = new Image();
    img.addEventListener('load', () => {
      const bBox = tempSvg.getBBox();
      const canvas = document.createElement('canvas');
      canvas.width = bBox.width;
      canvas.height = bBox.height;
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, bBox.width, bBox.height);
      URL.revokeObjectURL(url);
      const a = document.createElement('a');
      a.download = name || `file_${new Date().getTime()}.png`;
      document.body.appendChild(a);
      a.href = canvas.toDataURL();
      a.click();
      a.remove();
    });
    img.src = url;
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMoveStart(event: MouseEvent) {
    this.isMouseMove = true;
    this.translateOnPanStart = [...this.translate] as [number, number];
    this.start = { x: event.clientX, y: event.clientY };
    event.preventDefault();
  }

  @HostListener('mousemove', ['$event'])
  onMove(event: MouseEvent) {
    if (this.isMouseMove) {
      this.translate = [
        this.translateOnPanStart[0] + (event.clientX - this.start.x),
        this.translateOnPanStart[1] + (event.clientY - this.start.y)
      ];
      this._updateTransformAnimationState('0s');
      event.preventDefault();
    }
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onMoveEnd() {
    this.isMouseMove = false;
  }

  private _calculateTranslationToZoomPoint(
    currentScale: number,
    newScale: number,
    currentTranslation: [number, number],
    e: { clientX: number; clientY: number }
  ): [number, number] {
    const [eventLayerX, eventLayerY] = this._projectToLayer(e);

    const xAtCurrentScale = (eventLayerX - currentTranslation[0]) / currentScale;
    const yAtCurrentScale = (eventLayerY - currentTranslation[1]) / currentScale;

    const xAtNewScale = xAtCurrentScale * newScale;
    const yAtNewScale = yAtCurrentScale * newScale;

    return [eventLayerX - xAtNewScale, eventLayerY - yAtNewScale];
  }

  private _projectToLayer(eventClientXY: { clientX: number; clientY: number }): [number, number] {
    const layerX = Math.round(eventClientXY.clientX - this._clientX);
    const layerY = Math.round(eventClientXY.clientY - this._clientY);
    return [layerX, layerY];
  }

  private _updateTransformAnimationState(duration = '.5s') {
    this.transformAnimationState = {
      value: this.scale + this.translate[0] + this.translate[1],
      params: {
        transform: `translate3d(${this.translate[0]}px, ${this.translate[1]}px, 0px) scale(${this.scale})`,
        duration
      }
    };
  }

  private _clamp(n: number, min: number, max: number) {
    return Math.min(max, Math.max(min, n));
  }

  private get _clientX() {
    return (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().left;
  }

  private get _clientY() {
    return (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().top;
  }
}
