import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, UserDefinedField } from '@b3networks/api/data';
import { AppQuery } from '../../../core/state/app.query';
import { AppService } from '../../../core/state/app.service';

@Component({
  selector: 'b3n-template-field',
  templateUrl: './template-field.component.html',
  styleUrls: ['./template-field.component.scss']
})
export class TemplateFieldComponent implements OnInit {
  @Input() field: UserDefinedField;
  @Input() ctrl: FormControl;

  readonly FieldType = FieldType;

  constructor(private appService: AppService, private appQuery: AppQuery) {}

  ngOnInit(): void {
    // update 2 way
    this.ctrl.valueChanges.subscribe(value => {
      const popupState = this.appQuery.getValue()?.popupState;
      const tag = { ...popupState.tag };
      tag[this.field.key] = value;
      this.appService.update({
        popupState: {
          ...popupState,
          tag: tag
        }
      });
    });
  }

  onKeydown(event: any) {
    if (this.field.type === FieldType.double || this.field.type === FieldType.long) {
      const pattern = /^[0-9\+\-]*$/;
      const inputChar = String.fromCharCode(event.key);
      const number = +event.target?.value;
      const min = -1000000000000000;
      const max = 1000000000000000;

      if (!pattern.test(inputChar) && !['Backspace'].includes(event.key) && (number < min || number > max)) {
        event.preventDefault();
        return;
      }

      if (this.field.type === FieldType.long && event.key === '.') {
        event.preventDefault();
        return;
      }
    } else {
      const pattern = /^[a-zA-Z0-9]*$/;
      const inputChar = String.fromCharCode(event.key);
      const limit = this.field.type === FieldType.textarea ? 2000 : 256;
      if (!pattern.test(inputChar) && !['Backspace'].includes(event.key) && event.target.value.length >= limit) {
        event.preventDefault();
        return;
      }
    }
  }
}
