import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { QueuesQuery } from '@b3networks/api/callcenter';
import {
  BlockDetail,
  BlockType,
  CallQueueInboxData,
  CallType,
  CallbackQueueInboxData,
  Leg,
  StatusCall,
  TxnEndInbox
} from '@b3networks/api/data';
import { InboxesQuery, Txn, TxnService } from '@b3networks/api/inbox';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { guid } from '@datorama/akita';
import { debounceTime, filter, fromEvent, map, share, takeUntil } from 'rxjs';
import { BlockJourney } from '../../../../model/journey-block.model';

@Component({
  selector: 'b3n-txn-incoming-generating',
  templateUrl: './txn-incoming-generating.component.html',
  styleUrls: ['./txn-incoming-generating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TxnIncomingGeneratingComponent extends DestroySubscriberComponent implements OnInit {
  @Input() viewport: HTMLElement | null;
  @Input() txn: Txn;
  @Input() txnHistory: TxnEndInbox;
  @Input() legs: Leg[] = [];
  @Input() enterQueue: CallQueueInboxData;
  @Input() callbackData: CallbackQueueInboxData;
  @Input() hasCallback: boolean;
  @Input() hasVoicemail: boolean;
  @Input() isChild: boolean;

  blocks: BlockJourney[] = [];
  isLoading = true;

  constructor(
    private txnService: TxnService,
    private queuesQuery: QueuesQuery,
    private inboxesQuery: InboxesQuery,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    this.initJourneyIncoming();
    console.log('this.blocks: ', this.blocks);
    this.trackingScrollEvent();
    this.cdr.markForCheck();

    setTimeout(() => {
      this.updateTxnViewState(0);
    }, 300);
  }

  private initJourneyIncoming() {
    this.blocks = [];

    if (!this.isChild && this.txnHistory?.callcenter?.fromTxn) {
      this.blocks.push(
        new BlockJourney({
          id: guid(),
          time: this.txn.createdAt,
          title: [`Txn triggered from <strong>${this.txnHistory.callcenter.fromTxn}</strong>`],
          icon: 'account_tree',
          isOutline: true,
          fontSizeIcon: 18,
          navigateTxnUuid: {
            txnUuid: this.txnHistory.callcenter.fromTxn
          }
        })
      );
    }

    this.blocks.push(
      new BlockJourney({
        id: guid(),
        time: this.txn.createdAt,
        title: [`Incoming call from <strong>${this.txnHistory.from?.number}</strong>`],
        icon: 'phone_callback',
        fontSizeIcon: 18
      })
    );

    if (this.txnHistory.blocks?.length > 0) {
      this.addBlockAutoAttendant();
    }

    if (this.enterQueue) {
      if (this.enterQueue?.entered) {
        if (this.txnHistory?.callcenter?.queueName) {
          this.blocks.push(
            new BlockJourney({
              id: guid(),
              time: this.txnHistory?.callcenter?.queueTime || this.getLastTimeBlock(),
              title: [`Call routed to inbox <strong>${this.txnHistory?.callcenter?.queueName}</strong>`],
              icon: 'meeting_room',
              isOutline: true
            })
          );
        }
        this.addBlockLegs();
      } else {
        if (this.enterQueue.timeout) {
          // max wait time
          if (this.txnHistory?.callcenter?.queueName) {
            this.blocks.push(
              new BlockJourney({
                id: guid(),
                time: this.txnHistory?.callcenter?.queueTime || this.getLastTimeBlock(),
                title: [`Call routed to inbox <strong>${this.txnHistory?.callcenter?.queueName}</strong>`],
                icon: 'meeting_room',
                isOutline: true
              })
            );
          }
          this.addBlockLegs();

          this.blocks.push(
            new BlockJourney({
              id: guid(),
              time: this.enterQueue?.queueTime + this.enterQueue?.queueDuration,
              title: [`Call reached max wait time`],
              icon: 'no_meeting_room',
              backgroundIcon: '#FFDF87',
              isOutline: true
            })
          );
        } else {
          // max queue size
          this.blocks.push(
            new BlockJourney({
              id: guid(),
              time: this.getLastTimeBlock(),
              title: [`Call reached max inbox size`],
              icon: 'no_meeting_room',
              backgroundIcon: '#FFDF87',
              isOutline: true
            })
          );
        }
      }
    } else {
      this.addBlockLegs();
    }

    if (this.hasVoicemail) {
      this.blocks.push(
        new BlockJourney({
          id: guid(),
          time: this.getLastTimeBlock(),
          title: [`Left voicemail`],
          icon: 'graphic_eq'
        })
      );
    }

    if (this.hasCallback) {
      if (this.callbackData) {
        this.blocks.push(
          new BlockJourney({
            id: guid(),
            time: this.callbackData.registeredTime,
            title: [`Requested a callback to number <strong>${this.callbackData?.callbackNumber}</strong>`],
            navigateTxnUuid: {
              txnUuid: this.callbackData?.txnUuid
            },
            icon: 'add_call'
          })
        );
      } else {
        this.blocks.push(
          new BlockJourney({
            id: guid(),
            time: this.getLastTimeBlock(),
            title: [`Requested a callback to number`],
            icon: 'add_call'
          })
        );
      }
    }

    this.blocks.push(
      new BlockJourney({
        id: guid(),
        time: this.txnHistory?.endTime,
        title: [`Call ended`],
        icon: 'call_end',
        backgroundIcon: '#E0E0E0'
      })
    );

    this.isLoading = false;
  }

  private addBlockAutoAttendant() {
    const firstBlock = this.txnHistory?.blocks?.[0];
    const parent = new BlockJourney({
      id: guid(),
      time: firstBlock?.time || this.txn.createdAt,
      title: [
        `Auto Attendant: <strong>${firstBlock.workFlowName}</strong>`,
        `Number: <strong>${this.txnHistory.accessNumber}</strong>`
      ],
      isFlowBlockAA: true,
      icon: 'swap_horiz',
      children: []
    });
    const listBlockTransfer: BlockDetail[] =
      this.txnHistory?.blocks?.filter(x => x.blockType === BlockType.transfer) || [];

    for (let i = 0; i < this.txnHistory?.blocks?.length; i++) {
      const item = this.txnHistory.blocks[i];
      let child: BlockJourney;
      switch (item.blockType) {
        case BlockType.gather:
          {
            child = new BlockJourney({
              id: guid(),
              parent: parent.id,
              time: item.time,
              title: [`Reached <strong>${item.blockName}</strong>: Gather input`],
              sgvIcon: 'flow-tree',
              fontSizeIcon: 16
            });

            const next = this.txnHistory.blocks?.[i + 1];
            if (next?.blockType === BlockType.gathered) {
              const hasMarked = next.digit === '#### (masked)';
              if (hasMarked) {
                child.title.push(`Caller input masked`);
              } else {
                child.title.push(`Caller pressed <strong>${next.digit}</strong>`);
              }
            }
            parent.addChild(child);
          }
          break;

        case BlockType.confirm:
          {
            child = new BlockJourney({
              id: guid(),
              parent: parent.id,
              time: item.time,
              title: [`Reached <strong>${item.blockName}</strong>: Confirm`],
              icon: 'question_exchange'
            });

            const next = this.txnHistory.blocks?.[i + 1];
            if (next?.blockType === BlockType.confirmed) {
              const hasMarked = next.digit === '#### (masked)';
              if (hasMarked) {
                child.title.push(`Caller input masked`);
              } else {
                child.title.push(`Caller pressed <strong>${next.digit}</strong>`);
              }
            }
            parent.addChild(child);
          }
          break;

        case BlockType.play:
          {
            child = new BlockJourney({
              id: guid(),
              parent: parent.id,
              time: item.time,
              title: [`Reached <strong>${item.blockName}</strong>: Play messsage only`],
              icon: 'play_arrow'
            });
            parent.addChild(child);
          }
          break;

        case BlockType.record:
          {
            child = new BlockJourney({
              id: guid(),
              parent: parent.id,
              time: item.time,
              title: [`Reached <strong>${item.blockName}</strong>: Record call and notify`],
              icon: 'graphic_eq'
            });

            parent.addChild(child);
          }
          break;

        case BlockType.go:
          {
            child = new BlockJourney({
              id: guid(),
              parent: parent.id,
              time: item.time,
              title: [
                `Reached <strong>${item.blockName}</strong>: Forward to <strong>${
                  this.txnHistory.blocks?.[i + 1]?.blockName
                }</strong>`
              ],
              sgvIcon: 'forward'
            });

            parent.addChild(child);
          }
          break;

        case BlockType.transfer:
          {
            const findIndex = listBlockTransfer?.findIndex(x => x.time === item.time);
            const isTransferToQueue = findIndex === listBlockTransfer?.length - 1; // latest
            child = new BlockJourney({
              id: guid(),
              parent: parent.id,
              time: item.time,
              title: [`Reached <strong>${item.blockName}</strong>: Transfer to`],
              sgvIcon: 'transfer'
            });

            if (isTransferToQueue) {
              if (this.txnHistory?.callcenter?.queueName) {
                child.title[0] += ` inbox <strong>${this.txnHistory?.callcenter?.queueName}</strong>`;
              }
            } else {
              child.title[0] += ` <strong>${this.txnHistory.blocks?.[i + 1]?.blockName}</strong>`;
            }

            parent.addChild(child);
          }
          break;
        case BlockType.webhook:
          {
            child = new BlockJourney({
              id: guid(),
              parent: parent.id,
              time: item.time,
              title: [`Reached <strong>${item.blockName}</strong>: Webhook`],
              icon: 'webhook'
            });

            parent.addChild(child);
          }
          break;
      }
    }

    this.blocks.push(parent);
  }

  private addBlockLegs() {
    let unanswered: BlockJourney[] = [];
    let answered: BlockJourney;
    let answeredData: Leg;
    const listAnswered: Leg[] = [];
    const transfer: BlockJourney[] = [];
    let hasChild: boolean;
    for (let i = 0; i < this.legs?.length; i++) {
      const item = this.legs[i];
      if (!answered && item.status === StatusCall.unanswered) {
        unanswered.push(
          new BlockJourney({
            id: guid(),
            time: item.startTime,
            title: [`<strong>${item.extensionKey} - ${item.extensionLabel}</strong> unanswered`],
            hasRecording: item?.['hasRecording'],
            icon: 'phone_missed',
            backgroundIcon: '#FFCBC7'
          })
        );
        continue;
      }

      if (!answered && item.status === StatusCall.answered) {
        answered = new BlockJourney({
          id: guid(),
          time: item.startTime,
          title: [`Call answered by <strong>${item.extensionKey} - ${item.extensionLabel}</strong>`],
          hasRecording: item?.['hasRecording'],
          icon: 'phone_in_talk',
          children: []
        });
        listAnswered.push(item);
        answeredData = item;
        continue;
      }

      if (answered) {
        const to = this.displayName(item);

        const block = new BlockJourney({
          id: guid(),
          time: item.startTime,
          title: [
            `<strong>${listAnswered[listAnswered.length - 1]?.extensionKey} - 
            ${listAnswered[listAnswered.length - 1]?.extensionLabel}
            </strong> transferred to ${to?.text}`,
            `Result: <span class='${item.status}'>${this.titleCaseWord(item.status)}</span>`
          ],
          hasRecording: item?.['hasRecording'],
          sgvIcon: 'transfer'
        });

        if (this.txnHistory?.callcenter?.toTxn && to?.isQueue && !hasChild) {
          hasChild = true;
          block.txnChild = this.txnHistory.callcenter.toTxn;
          block.navigateTxnUuid = {
            txnUuid: this.txnHistory.callcenter.toTxn
          };
          block.children = [new BlockJourney()]; // fake child to show expaned
        }

        transfer.push(block);

        if (item.status === StatusCall.answered) {
          if (listAnswered.length === 1) {
            listAnswered.push(item);
          }

          if (listAnswered.length === 2) {
            let displayHungup = '';
            let connectedCall = '';
            if (listAnswered[0].endTime < listAnswered[1].endTime) {
              displayHungup = this.displayName(listAnswered[0])?.text;
              connectedCall = this.displayName(listAnswered[1])?.text;
              listAnswered.splice(0, 1);
            } else {
              connectedCall = this.displayName(listAnswered[0])?.text;
              displayHungup = this.displayName(listAnswered[1])?.text;
              listAnswered.splice(1, 1);
            }
            transfer.push(
              new BlockJourney({
                id: guid(),
                time: item.startTime,
                title: [`${this.titleCaseWord(displayHungup)} hung up`, `${connectedCall} connected to the caller`],
                sgvIcon: 'transfer'
              })
            );
          }
        }
      }
    }

    if (answered) {
      // update parent for answered block
      if (unanswered.length > 0) {
        unanswered.push(
          new BlockJourney({
            id: guid(),
            time: answeredData.startTime,
            title: [`<strong>${answeredData.extensionKey} - ${answeredData.extensionLabel}</strong> answered`],
            icon: 'phone_in_talk'
          })
        );
      }
      unanswered = unanswered.map(x => new BlockJourney({ ...x, parent: answered.id }));
      answered.children = unanswered;
      this.blocks.push(answered);
    } else {
      if (unanswered.length > 0) {
        const parent = new BlockJourney({
          id: guid(),
          time: unanswered[unanswered.length - 1]?.time,
          title: [`Call unanswered`],
          icon: 'phone_missed',
          backgroundIcon: '#FFCBC7',
          children: []
        });
        // update id parent block
        unanswered = unanswered.map(x => new BlockJourney({ ...x, parent: parent.id }));
        parent.children = unanswered;
        this.blocks.push(parent);
      }
    }

    if (transfer?.length > 0) {
      this.blocks = [...this.blocks, ...transfer];
    }
  }

  private getLastTimeBlock() {
    const lastItem = this.blocks[this.blocks.length - 1];
    return lastItem?.children?.length > 0 ? lastItem.children[lastItem.children.length - 1].time : lastItem.time;
  }

  // ======================= TRACKING =====================
  private trackingScrollEvent() {
    this.ngZone.runOutsideAngular(() => {
      if (this.viewport) {
        setTimeout(() => {
          // update lastSeenMessage, viewDate
          fromEvent<Event>(this.viewport, 'scroll')
            .pipe(
              map((event: Event) => event.target['scrollTop'].valueOf()),
              share()
            )
            .pipe(
              takeUntil(this.destroySubscriber$),
              filter(_ => !!this.viewport),
              debounceTime(100)
            )
            .subscribe(value => {
              this.updateTxnViewState(value);
            });
        }, 500);
      } else {
        setTimeout(() => {
          this.trackingScrollEvent();
        }, 10);
      }
    });
  }

  private updateTxnViewState(topOffset: number) {
    const txnUuid = this.txn.txnUuid;
    const array: HTMLDivElement[] = Array.from(this.viewport?.querySelectorAll('.parent .item__1'));
    let viewDate = Date.parse(new Date().toString());
    if (array.length > 0) {
      const itemFirstViewed = array.find((block: HTMLDivElement) => {
        return block.offsetTop + block.clientHeight - 16 >= topOffset;
      });
      if (itemFirstViewed) {
        viewDate = +itemFirstViewed.getAttribute('data-ts');
      } else {
        viewDate = +array[0].getAttribute('data-ts');
      }
    }
    this.txnService.updateTxnViewState(txnUuid, {
      viewDate: viewDate
    });
    this.cdr.markForCheck();
  }

  private titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1);
  }

  private displayName(item: Leg): DisplayNameBlock {
    if (item.type === CallType.outgoing) {
      const queue = this.queuesQuery.getQueueByQueueCode(item.to);
      if (queue) {
        const inbox = this.inboxesQuery.getInboxByQueue(queue.uuid);
        const text = inbox ? `inbox <strong>${inbox.name}</strong>` : `<strong>${item.to}</strong>`;
        return {
          text,
          isQueue: !!inbox
        };
      }
      return {
        text: `<strong>${item.to}</strong>`
      };
    }
    return {
      text: `<strong>${item.extensionKey} - ${item.extensionLabel}</strong>`
    };
  }
}

interface DisplayNameBlock {
  text: string;
  isQueue?: boolean;
}
