import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import {
  CallQueueInboxData,
  CallbackQueueInboxData,
  GetReportV4Payload,
  Leg,
  Period,
  ReportV4Code,
  ResultCallBack,
  TxnEndInbox,
  V4Service
} from '@b3networks/api/data';
import { Txn, TxnQuery, TxnService } from '@b3networks/api/inbox';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { addHours, subHours } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Observable, catchError, of } from 'rxjs';
import { PER_PAGE_LEG } from '../../../model/journey-block.model';

@Component({
  selector: 'b3n-txn-outgoing',
  templateUrl: './txn-outgoing.component.html',
  styleUrls: ['./txn-outgoing.component.scss']
})
export class TxnOutgoingComponent extends DestroySubscriberComponent implements OnInit {
  @Input() txn: Txn;
  @Input() txnHistory: TxnEndInbox;
  @Input() timezone: string;
  @Input() isChild: boolean;

  viewDate$: Observable<number>;

  legs: Leg[] = [];
  callbackData: CallbackQueueInboxData;
  isCallback: boolean;
  hasVoicemail: boolean;
  enterQueue: CallQueueInboxData;

  isFetchingLegs = true;

  readonly notSupportDraw = [ResultCallBack.expired, ResultCallBack.rejoined];
  readonly ResultCallBack = ResultCallBack;

  constructor(
    private txnQuery: TxnQuery,
    private txnService: TxnService,
    private v4Service: V4Service,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.viewDate$ = this.txnQuery.selectUIState(this.txn.txnUuid, 'viewDate');

    this.isFetchingLegs = true;
    this.checkResultCallback(this.txn);
  }

  private checkResultCallback(txn: Txn) {
    const reqCallBack = <GetReportV4Payload>{
      startTime: format(
        subHours(utcToZonedTime(new Date(this.txn.createdAt), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      endTime: format(
        addHours(utcToZonedTime(new Date(this.txn?.endedAt || Date.now()), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      filter: {
        txnUuid: txn.txnUuid,
        result3: []
      }
    };

    this.v4Service
      .getReportData<CallbackQueueInboxData>(
        Period.dump,
        ReportV4Code.txnJourney.callback,
        reqCallBack,
        new Pageable(1, 5),
        false
      )
      .pipe(catchError(() => of(null)))
      .subscribe(
        callbackData => {
          this.callbackData = callbackData?.rows?.find(x => x.txnUuid === txn.txnUuid);
          console.log('this.callbackData: ', this.callbackData);

          if (this.callbackData) {
            this.isCallback = true;
            if (this.callbackData?.state === 'ended') {
              this.txnService.updateTxnViewState(txn.txnUuid, {
                callbackData: this.callbackData
              });

              this.isFetchingLegs = true;
              this.fetchLegs(txn);
            }
          } else {
            this.isCallback = false;
            this.callbackData = <CallbackQueueInboxData>{
              result: ResultCallBack.none,
              callbackNumber: this.txn?.metadata?.customerNumber,
              extensionLabel: this.txnHistory?.from?.extensionLabel,
              extensionKey: this.txnHistory?.from?.extensionKey,
              startTime: this.txnHistory.startTime,
              txnUuid: this.txnHistory.txnUuid,
              endTime: this.txnHistory?.endTime,
              state: 'ended',
              callerId: null,

              isCallback: this.isCallback
            };
            this.txnService.updateTxnViewState(txn.txnUuid, {
              callbackData: this.callbackData
            });
            this.isFetchingLegs = true;
            this.fetchLegs(txn);
          }

          this.cdr.markForCheck();
        },
        err => {
          this.isFetchingLegs = false;
        }
      );
  }

  private fetchLegs(txn: Txn, page = 1) {
    if (txn.txnUuid !== this.txn?.txnUuid) {
      return;
    }

    if (page === 1) {
      this.isFetchingLegs = true;
    }

    if (page === 1) {
      this.legs = [];
    }

    const req = <GetReportV4Payload>{
      startTime: format(
        subHours(utcToZonedTime(new Date(this.txn.createdAt), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      endTime: format(
        addHours(utcToZonedTime(new Date(this.txn?.endedAt || Date.now()), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      filter: {
        txnUuid: txn.txnUuid
      },
      scope: 'org'
    };

    this.v4Service
      .getReportData<Leg>(Period.dump, ReportV4Code.txnJourney.leg, req, new Pageable(page, PER_PAGE_LEG), false)
      .subscribe(
        data => {
          const dataLegs = data.rows?.map(x => new Leg({ ...x }));
          this.legs = [...this.legs, ...dataLegs];
          console.log('this.legs: ', this.legs);

          if (data.rows.length === PER_PAGE_LEG) {
            // get more
            page++;
            this.fetchLegs(txn, page);
          } else {
            // if ([ResultCallBack.customerUnanswered, ResultCallBack.answered].includes(this.callbackData.result)) {
            //   // remove the latest legs
            //   this.legs.pop();
            // }
            if (!this.isCallback) {
              this.legs.pop();
            }

            this.isFetchingLegs = false;
          }
          this.cdr.markForCheck();
        },
        err => {
          this.isFetchingLegs = false;
        }
      );
  }
}
