<div class="container flex">
  <div
    *ngIf="callManagement$ | async as state"
    class="left flex column justify-content-space-around align-items-center gap-16"
    [style.width.px]="defaultWidthLeft"
  >
    <div class="information flex column justify-content-center align-items-center gap-16">
      <button mat-fab class="mat-elevation-z1 btn-fab btn-fab__group">
        <mat-icon>{{ state.isRoom ? 'group' : 'person' }}</mat-icon>
      </button>
      <div class="room flex column justify-content-space-between align-items-center">
        <div *ngIf="displayMember | async as displayMember" class="room-name">
          <span *ngIf="state.isRoom"> Meeting Room: </span> {{ displayMember }}
        </div>

        <div *ngIf="state.timerCall.second" class="time-call">{{ state.timerCall.second | duration }}</div>
      </div>
    </div>
    <div class="actions flex column justify-content-space-around align-items-center gap-40">
      <div class="action flex justify-content-space-around align-items-center">
        <mat-icon *ngIf="state.canHold && state.isHold" class="material-icons-outlined" (click)="toggleHold()">
          play_arrow
        </mat-icon>
        <mat-icon *ngIf="state.canHold && !state.isHold" class="material-icons-outlined" (click)="toggleHold()">
          pause
        </mat-icon>
        <mat-icon *ngIf="state.canDTMF" class="material-icons-outlined" (click)="dialpad()">dialpad</mat-icon>
        <mat-icon *ngIf="state.canHold" class="material-icons-outlined" (click)="toggleMute()">
          {{ state.isMute ? 'mic_off' : 'mic' }}
        </mat-icon>
      </div>
      <div
        *ngIf="state.isRemote && !state.canHold"
        class="accept flex justify-content-space-between align-items-center"
      >
        <button mat-fab class="mat-elevation-z1 btn-fab btn-fab__call_end" (click)="ended()">
          <mat-icon>call_end</mat-icon>
        </button>
        <button
          *ngIf="state.isRemote && !state.canHold"
          mat-fab
          class="mat-elevation-z1 btn-fab btn-fab__call"
          (click)="accept()"
        >
          <mat-icon>call</mat-icon>
        </button>
      </div>
      <div *ngIf="!(state.isRemote && !state.canHold)" class="action flex justify-content-center align-items-center">
        <button mat-mini-fab class="mat-elevation-z1 btn-fab btn-fab__call_end" (click)="ended()">
          <mat-icon>call_end</mat-icon>
        </button>
      </div>
    </div>

    <div
      *ngIf="!(isShowPopup$ | async)"
      (click)="zoomOut()"
      class="minimize-container flex justify-content-center align-items-center"
    >
      <div class="divider"></div>
    </div>
  </div>

  <ng-container *ngIf="isShowPopup$ | async">
    <div
      class="right flex justified-content-center align-items-center"
      [style.width.px]="defaultWidthRight"
      *ngIf="isLoadingTemplate$ | async; else loadTemplateNote"
    >
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <ng-template #loadTemplateNote>
      <div class="right flex column" [style.width.px]="defaultWidthRight" *ngIf="me && me?.assignedTxn">
        <ng-container [ngSwitch]="me.assignedTxn.type">
          <ng-container *ngSwitchCase="TxnType.incoming">
            <b3n-inbound-txn [me]="me" (finishCall)="onFinishCall()"></b3n-inbound-txn>
          </ng-container>
          <ng-container *ngSwitchCase="TxnType.callback">
            <b3n-callback-txn [me]="me" (finishCall)="onFinishCall()"></b3n-callback-txn>
          </ng-container>
          <ng-container *ngSwitchCase="TxnType.autodialer">
            <b3n-outbound-txn [me]="me" (finishCall)="onFinishCall()"></b3n-outbound-txn>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
    <div
      *ngIf="!isEndedCall || isNotACW"
      (click)="zoomOut()"
      class="minimize-container flex justify-content-center align-items-center"
    >
      <div class="divider"></div>
    </div>
  </ng-container>
</div>
