<div class="message" *ngIf="templateData.header || templateData.body">
  <div *ngIf="templateData.header?.text" [innerHTML]="templateData.header.text"></div>

  <div class="media-container flex column">
    <div class="media-title text-center">WhatsApp Message</div>
    <div class="media-body flex column">
      <ng-container [ngSwitch]="templateData.header?.format">
        <b3n-wa-template-image
          *ngSwitchCase="'image'"
          [message]="message"
          [parentElr]="parentElr"
          [mediaUI]="mediaUI"
        ></b3n-wa-template-image>

        <b3n-wa-template-video
          *ngSwitchCase="'video'"
          [parentElr]="parentElr"
          [mediaUI]="mediaUI"
          (download)="download()"
        ></b3n-wa-template-video>

        <b3n-wa-template-document
          *ngSwitchCase="'document'"
          [mediaUI]="mediaUI"
          (download)="download()"
        ></b3n-wa-template-document>
      </ng-container>

      <div class="content" *ngIf="templateData.body?.text">
        <div class="body" [innerHTML]="templateData.body?.text"></div>
      </div>

      <div class="buttons" *ngIf="templateData.buttons?.buttons?.length > 0">
        <ng-container *ngFor="let b of templateData.buttons.buttons; let i = index">
          <div class="button">
            <mat-icon svgIcon="{{ b.icon }}"></mat-icon>
            <span>{{ b.text }}</span>
          </div>
          <mat-divider *ngIf="i < templateData.buttons.buttons.length - 1"></mat-divider>
        </ng-container>
      </div>

      <div class="content" *ngIf="templateData.footer?.text">
        <div class="footer" [innerHTML]="templateData.footer?.text"></div>
      </div>
    </div>
  </div>
</div>
