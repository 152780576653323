<div class="container flex column gap-16">
  <div class="flex justify-content-space-between align-items-center">
    <h1>Search Messsages</h1>
    <mat-icon (click)="dialogRef.close(false)" class="icon action s-24 material-symbols-rounded">close</mat-icon>
  </div>

  <div class="flex column gap-8">
    <mat-form-field class="search no-validator">
      <mat-label>{{ placeholder }}</mat-label>
      <input
        #inputSearch
        matInput
        autocomplete="off"
        [formControl]="textSearch"
        (keyup.enter)="key.setValue(inputSearch.value)"
      />
      <mat-icon
        class="s-20 cursor-pointer"
        *ngIf="!!inputSearch.value"
        matSuffix
        aria-label="Clear"
        (click)="inputSearch.value = ''"
        >close</mat-icon
      >
    </mat-form-field>

    <div class="body scroller-firefox spacer flex justify-content-start align-items-none gap-5">
      <div class="spacer flex column">
        <div *ngIf="groupResult?.length > 0; else noResult">
          <div class="flex column gap-10" style="margin-top: 0.1rem">
            <mat-selection-list [multiple]="false" [formControl]="selectedCtr" [compareWith]="compareObjects">
              <ng-container *ngFor="let group of groupResult; trackBy: trackByGroup">
                <mat-list-option
                  *ngFor="let message of group; trackBy: trackByMessage"
                  [value]="message"
                  (click)="valueChange(message)"
                >
                  <csh-chat-message
                    [message]="message"
                    [previousMessage]="null"
                    [configMessageOption]="configMessageOption"
                    (showProfile)="onShowProfile($event)"
                  ></csh-chat-message>
                </mat-list-option>
              </ng-container>
            </mat-selection-list>

            <div fxLayoutAlign="center center" *ngIf="form">
              The search keyword from {{ form.fromMillis | date: 'dd-MM-YYYY' }}
            </div>
          </div>
        </div>
        <ng-template #noResult>
          <div *ngIf="form" fxLayoutAlign="center center" style="padding-bottom: 1.6rem">
            <span class="text-secondary">
              No message with the search keyword from {{ form.fromMillis | date: 'dd-MM-YYYY' }}
            </span>
          </div>
        </ng-template>

        <div *ngIf="loading; else stop" fxLayoutAlign="center center">
          <mat-spinner diameter="35"></mat-spinner>
        </div>
        <ng-template #stop>
          <div *ngIf="hasMore" class="btn-load-more" fxLayoutAlign="center" fxLayoutAlign="center">
            <a color="primary" (click)="onLoadContinue()">Search older messages</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
