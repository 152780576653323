<div class="flex column justify-content-center align-items-center gap-16">
  <div class="spinner-loading">
    <div class="spinner"></div>
  </div>
  <div class="collecting flex gap-3">
    <span>Generating call journey </span>
    <div class="loading-dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>
