import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Channel, ChannelQuery, ChannelService, ChatMessage } from '@b3networks/api/chat';
import { MeQuery } from '@b3networks/api/workspace';
import { Observable, filter, map } from 'rxjs';
import { InfoShowMention } from '../../../core/state/app-state.model';

@Component({
  selector: 'b3n-info-thread-message',
  templateUrl: './info-thread-message.component.html',
  styleUrls: ['./info-thread-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoThreadMessageComponent implements OnInit {
  @Input() message: ChatMessage;
  @Input() parentElr: HTMLElement;

  noIntersectionObserver: boolean;

  thread$: Observable<Channel>;
  lastMessage$: Observable<ChatMessage>;

  @Output() showProfile = new EventEmitter<InfoShowMention>();

  constructor(private meQuery: MeQuery, private channelService: ChannelService, private channelQuery: ChannelQuery) {}

  ngOnInit(): void {
    const getEntity = this.channelQuery.getEntity(this.message.myThreadId);
    this.noIntersectionObserver = getEntity && !getEntity.isTemporary;
    this.thread$ = this.channelQuery.selectEntity(this.message.myThreadId);
    this.lastMessage$ = this.thread$.pipe(
      map(x => x?.lastMessage),
      filter(x => !!x?.body?.text)
    );
  }

  onRender() {
    this.channelService.getDetailsSequential(this.message.myThreadId, this.meQuery.getMe().userUuid).subscribe();
  }

  openThread() {
    this.channelService.updateChannelViewState(this.message.channelId, {
      activeThreadId: this.message.myThreadId
    });
  }
}
