<div
  *ngIf="!noIntersectionObserver"
  [intersectionObserverDom]="parentElr"
  (render)="onRender()"
  class="container flex column"
>
  <ng-container *ngTemplateOutlet="viewThread"></ng-container>
</div>

<div *ngIf="noIntersectionObserver" class="container flex column">
  <ng-container *ngTemplateOutlet="viewThread"></ng-container>
</div>

<ng-template #viewThread>
  <ng-container *ngIf="thread$ | async as thread; else loading">
    <div class="widget flex column gap-8" [class.closed]="thread.closedL2" [id]="thread.id">
      <div class="flex align-items-center gap-8">
        <div class="flex-spacer flex align-items-center gap-8">
          <mat-icon class="icon s-14" [svgIcon]="'thread_outline'"></mat-icon>
          <span>{{ thread.displayName }}</span>
        </div>
        <a class="total-replies flex align-items-center" (click)="openThread()">
          <span>{{ thread.emc || 0 }} replies</span>
          <mat-icon class="icon arrow-right s-18">chevron_right</mat-icon>
        </a>
      </div>

      <ng-container *ngIf="lastMessage$ | async as lastMessage; else defaultMsg">
        <div
          *ngIf="lastMessage.user | selectUser | async as user"
          class="last-message-container flex justify-content-start align-items-center gap-16"
        >
          <div class="overflow-hidden flex-spacer flex">
            <div class="flex align-items-center">
              <b3n-avatar-user
                [member]="user"
                [disabledClick]="true"
                [isSmall]="true"
                [parentElr]="parentElr"
                [panelClass]="'avatar-customize'"
              ></b3n-avatar-user>
              <span class="display-name">{{ user.displayName + ': ' }}</span>
            </div>

            <ng-container *ngIf="!lastMessage.buildMsg?.builtTextForLastMessageConvo?.isTriggerDirective; else trigger">
              <span
                class="flex-spacer normal-text"
                [innerHTML]="lastMessage.buildMsg?.builtTextForLastMessageConvo?.text | safe: 'html'"
              ></span>
            </ng-container>
            <ng-template #trigger>
              <span
                class="flex-spacer normal-text"
                lazyloadUnknown
                [text]="lastMessage.buildMsg?.builtTextForLastMessageConvo.text"
                [innerHTML]="lastMessage.buildMsg?.builtTextForLastMessageConvo?.text | safe: 'html'"
                (showProfile)="showProfile.emit($event)"
              ></span>
            </ng-template>
          </div>

          <span class="time-ago">{{ lastMessage?.ts | timeAgo }}</span>
        </div>
      </ng-container>
      <ng-template #defaultMsg>
        <div class="secondary-text">There are no messages in this thread.</div>
      </ng-template>
    </div>
  </ng-container>
</ng-template>
<ng-template #loading>
  <div class="thread-loading flex-spacer flex justify-content-center align-items-center">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>
</ng-template>
