import { DeviceType } from '@b3networks/api/bizphone';
import { BlockDetail } from './history.model';

export class TxnEndInbox {
  callcenter: {
    queueName: string;
    fromTxn: string;
    toTxn: string;
    queueUuid: string;
    customer: CustomerCallcenter;
    queueTime: number;
  };
  accessNumber: string;
  blocks: BlockDetail[];
  from: {
    number?: string;

    // outgoing
    sipUsername?: string;
    identityUuid?: string;
    extensionLabel?: string;
    extensionKey?: string;
    device: DeviceType;
  };
  startTime: number;
  txnUuid: string;
  endTime: number;
  type: string;

  constructor(obj?: Partial<TxnEndInbox>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export interface CustomerCallcenter {
  emails: string[];
  chatId: string;
  name: string;
  numbers: string[];
  uuid: string;
}
