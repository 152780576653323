import { TxnFilterState } from '@b3networks/chat/shared/core';

export interface ContactUI {
  uuid: string; // id entity,
  selectTab: TabTxn;

  txnFilterV4: ChannelStateV3; //view assigned to me
  txnUuidsFilter: string[]; // txnUuids data from txnFilterV4

  // state view
  viewingOlderMessage: boolean;
  lastSeenMsgID: string;
  viewDate: number;

  watching: TxnFilterState; //view watching
  relatedToMe: TxnFilterState; //view related to me
  createdByMe: TxnFilterState; //view created by me
}

export enum TabTxn {
  whatsapp = 'whatsapp',
  livechat = 'livechat',
  call = 'call'
}

export interface ChannelStateV2 extends RequestFilterTxnsV2 {
  loaded?: boolean;
  loadFirst?: boolean; // flag check load first for page 1
  page?: number;
  perPage?: number;
  hasMore?: boolean;
}

export interface ChannelStateV3 extends RequestFilterCustomerTxnsV2 {
  loaded?: boolean;
  hasMore?: boolean;
  page: number;
  perPage: number;
}

// Duplicate enums and interfaces below from inbox/txn/txn.model to prevent circular dependency between "api-contact" and "api-inbox"

export enum AssignedMode {
  me = 'me', // assigned to me
  others = 'others', // assigned to others
  all = 'all'
}

export enum TxnGroupBy {
  txn = 'txn',
  customer = 'customer'
}

export interface RequestFilterTxnsV2 {
  assignedMode: AssignedMode;
  statuses: TxnStatus[];
  inboxUuids?: string[]; // optional
  channels?: TxnChannel[]; // optional
  customerUuid?: string;
  groupBy: TxnGroupBy;
}

export interface RequestFilterCustomerTxnsV2 {
  customerUuid: string;
  inboxUuids: string[];
  channels: TxnChannel[];
  assignees: string[];
  createdByIdentity: string;
  statuses: TxnStatus[];
  textSearch: string;
  sortField?: string; // created_at
  sortOrder?: 'ASC' | 'DESC'; // asc,desc
}

export enum TxnChannel {
  livechat = 'livechat',
  whatsapp = 'whatsapp',
  sms = 'sms',
  email = 'email',
  supportCenter = 'supportCenter',
  call = 'call'
}

export enum TxnStatus {
  // pending = 'pending',
  waiting = 'waiting',
  active = 'active',
  assigning = 'assigning',
  assigned = 'assigned',
  transferring = 'transferring',
  failed = 'failed',
  ended = 'ended'
}
