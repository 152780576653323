import { Directive, Inject, Injectable, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangeSelectionStrategy
} from '@angular/material/datepicker';

@Injectable()
export class MaxRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  start: D;
  limitNumber: number;
  dateRangeCtrl: FormControl;

  constructor(private dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D, currentRange: DateRange<D>) {
    let { start, end } = currentRange;
    const maxDate = start ? this.dateAdapter.addCalendarDays(start, this.limitNumber) : null;
    if (maxDate && date > maxDate) {
      return new DateRange<D>(null, null);
    }
    if (!start || (start && end)) {
      start = date;
      end = null;
    } else if (end == null) {
      end = date ? (date > maxDate ? maxDate : date) : null;
    }

    if (start && end && start > end) {
      start = end;
      end = null;
    }

    return new DateRange<D>(start, end);
  }
  createPreview(activeDate: D | null, currentRange: DateRange<D>, event: MouseEvent): DateRange<D> {
    event.target['childNodes'][0]['classList']?.remove('mat-calendar-selected-custom');
    if (currentRange.start && !currentRange.end) {
      const maxDate = this.dateAdapter.addCalendarDays(currentRange.start, this.limitNumber);
      const rangeEnd = activeDate ? (activeDate > maxDate ? maxDate : activeDate) : null;

      if (activeDate > maxDate) {
        event.target['childNodes'][0]['classList']?.add('mat-calendar-selected-custom');
        this.dateRangeCtrl.setValidators(this._dateRangeValidator());
      } else {
        this.dateRangeCtrl.clearValidators();
      }
      this.dateRangeCtrl.updateValueAndValidity();

      return new DateRange(currentRange.start, rangeEnd);
    }

    return new DateRange<D>(null, null);
  }

  private _dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return { maxDateRange: true };
    };
  }
}

@Directive({
  selector: '[maxRange]',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeSelectionStrategy
    }
  ]
})
export class MaxDateRangeDirective implements OnInit {
  @Input() dateRangeCtrl: FormControl;
  @Input() set maxRange(value: number) {
    this.maxRangeStrategy.limitNumber = (+value || 90) - 1;
  }

  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private maxRangeStrategy: MaxRangeSelectionStrategy<any>
  ) {}

  ngOnInit(): void {
    this.maxRangeStrategy.dateRangeCtrl = this.dateRangeCtrl;
  }
}
