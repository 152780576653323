import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autoFocusInput]'
})
export class AutoFocusInputDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}

  ngAfterContentInit() {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 300);
  }
}
