<div class="wh-container flex !flex-col items-stretch justify-start">
  <section class="flex flex-auto !flex-col items-center justify-start">
    <div
      class="working-schedule-content flex !flex-col items-stretch justify-start gap-[16px]"
      [formGroup]="workingSchedule"
    >
      <div formArrayName="shifts" *ngFor="let shift of shiftsField.controls; let i = index" class="flex gap-[10px]">
        <ng-container [formGroupName]="i" *ngIf="shift['controls']; let shiftControls">
          <mat-slide-toggle formControlName="enabled" (change)="shiftChanged($any(shift))">{{
            shiftControls.dayOfWeek.value | titlecase
          }}</mat-slide-toggle>

          <ng-container formArrayName="timeRanges">
            <div *ngIf="shiftControls.timeRanges.controls; let shiftTimeRanges" class="flex !flex-col gap-[16px]">
              <ng-container
                *ngFor="let timeRange of shiftTimeRanges; let timeIndex = index"
                [formGroupName]="timeIndex"
              >
                <ng-container *ngIf="timeIndex === 0 || shiftControls.enabled.value">
                  <div class="item-range flex items-center justify-start gap-[25px]">
                    <shc-input-timepicker
                      class="w-[146px]"
                      placeholder="From"
                      [disabled]="timeRange.disabled"
                      [timeValue]="timeRange.value?.from"
                      (changeTime)="onChangeTime(i, timeIndex, $event, 'from')"
                    ></shc-input-timepicker>
                    <shc-input-timepicker
                      class="w-[146px]"
                      placeholder="To"
                      [disabled]="timeRange.disabled"
                      [timeValue]="timeRange.value?.to"
                      (changeTime)="onChangeTime(i, timeIndex, $event, 'to')"
                    ></shc-input-timepicker>
                    <mat-icon
                      *ngIf="shiftTimeRanges.length > 1"
                      [class.disable]="!shiftControls.enabled.value"
                      [class.mr-0]="timeIndex > 0"
                      class="icon icon-remove"
                      (click)="removeTimeInRange(shiftControls, timeIndex)"
                    >
                      clear
                    </mat-icon>

                    <mat-icon
                      *ngIf="
                        shiftTimeRanges.length === 1 ||
                        (shiftTimeRanges.length > 1 && timeIndex === shiftTimeRanges.length - 1)
                      "
                      [class.disable]="
                        !shiftControls.enabled.value ||
                        !shiftTimeRanges[shiftTimeRanges.length - 1]?.get('from').value ||
                        !shiftTimeRanges[shiftTimeRanges.length - 1]?.get('to').value ||
                        shiftTimeRanges.length === limitShiftTimes
                      "
                      [class.add-icon-position]="timeIndex > 0"
                      matTooltip="Add more ranges"
                      class="icon icon-add"
                      (click)="addMoreRange(shiftTimeRanges)"
                    >
                      add
                    </mat-icon>
                  </div>
                </ng-container>
              </ng-container>
              <div *ngIf="shiftControls.timeRanges.invalid">
                <mat-error *ngIf="shiftControls.timeRanges.hasError('errorOverlap')">
                  * Time ranges for the same day can't overlap.
                </mat-error>
                <mat-error *ngIf="shiftControls.timeRanges.hasError('errorRangeTime')">
                  * The start time of the range can't be later than the end time.
                </mat-error>
              </div>
            </div>

            <button
              [disabled]="!shiftControls.enabled.value"
              mat-icon-button
              class="icon btn-icon !mt-[13px]"
              (click)="format24Hours(shiftControls.timeRanges)"
              matTooltip="Work 24 hours"
            >
              <span class="material-icons"> update </span>
            </button>
          </ng-container>

          <button
            [disabled]="!shiftControls.enabled.value || shiftControls.timeRanges.invalid"
            mat-icon-button
            class="icon btn-icon !mt-[13px]"
            matTooltip="Apply this time to all work schedules"
            (click)="applyWorkTimeToAll($any(shift))"
          >
            <span class="material-icons"> sync </span>
          </button>
        </ng-container>
      </div>
    </div>
  </section>
</div>
