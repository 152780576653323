import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ChannelHyperspaceService,
  ChannelHyperspaceUI,
  ChannelService,
  ChannelUI,
  ChatMessage,
  ChatService,
  ConvoType,
  HistoryMessageService,
  MsgType,
  TimeService
} from '@b3networks/api/chat';
import { ConversationGroupQuery, ConversationGroupService, ConversationGroupUI } from '@b3networks/api/workspace';
import { DestroySubscriberComponent, NetworkService } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { MessageConstants } from '../../../core/constant/message.const';
import { MessageReceiveProcessor } from '../../../core/service/message-receive.processor';
import { InfoShowMention } from '../../../core/state/app-state.model';
import { ConfigMessageOption } from '../chat-message.component';

@Component({
  selector: 'csh-normal-message',
  templateUrl: './normal-message.component.html',
  styleUrls: ['./normal-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NormalMessageComponent extends DestroySubscriberComponent implements OnChanges {
  @Input() message: ChatMessage;
  @Input() participants: string[];
  @Input() isEditing: boolean;
  @Input() configMessageOption: ConfigMessageOption;
  @Input() isExpand: boolean;
  @Input() parentElr?: HTMLElement; // use intersection to lazy loading

  @Output() edited = new EventEmitter<boolean>();
  @Output() showProfile = new EventEmitter<InfoShowMention>();
  @Output() jumpToMessage = new EventEmitter<string>();
  @Output() seeAllPinned = new EventEmitter<boolean>();
  @Output() mentionsChanged = new EventEmitter<string[]>();
  @Output() expandMsg = new EventEmitter<boolean>();

  readonly ConvoType = ConvoType;

  isTimeoutResponse: boolean;
  isSubstring: boolean;

  constructor(
    private toastService: ToastService,
    private router: Router,
    private messageService: HistoryMessageService,
    private convoGroupService: ConversationGroupService,
    private convoGroupQuery: ConversationGroupQuery,
    private channelService: ChannelService,
    private channelHyperspaceService: ChannelHyperspaceService,
    private chatService: ChatService,
    private timeService: TimeService,
    private messageReceiveProcessor: MessageReceiveProcessor,
    private networkService: NetworkService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['message']) {
      if (!this.message.id && this.message.mt === MsgType.message) {
        this.checkTimeoutResponse();
      }
      if (this.message.id) {
        this.isTimeoutResponse = false;
      }

      this.isSubstring =
        this.configMessageOption.isBookmarkChannel && !!this.message.messageBookmark
          ? this.message.messageBookmark.isSubstring
          : this.message.isSubstring;

      this.cdr.markForCheck();
    }
  }

  openThread() {
    this.router.navigate(['conversations', this.message.convo, 'discussion', this.message.metadata.threadId]);
  }

  resend() {
    if (!this.networkService.isOnline) {
      this.toastService.warning(
        "Your computer seems to be offline. We'll keep trying to reconnect, or you can try refresh your browser",
        10e3
      );
      return;
    }

    setTimeout(() => {
      const clone = new ChatMessage({ ...this.message });
      clone.markIsRetry();
      this.chatService.send(clone);
    });
  }

  removeUnsend(message: ChatMessage) {
    this.messageService.removeMessage(message);
    // trigger delete this msg in view
    const eventDeleteMsg = ChatMessage.createDeleteMessage(message);
    eventDeleteMsg.client_ts = message.client_ts;
    eventDeleteMsg.markIsNoStore();
    this.messageReceiveProcessor.pushEventMessage(eventDeleteMsg);
  }

  resetEdit() {
    this.edited.emit(true);
    if (this.message.isFromChannel) {
      if (this.message?.hs) {
        this.channelHyperspaceService.updateChannelViewState(this.message.channelId, <ChannelHyperspaceUI>{
          editingMessageId: null
        });
      } else {
        this.channelService.updateChannelViewState(this.message.channelId, <ChannelUI>{
          editingMessageId: null
        });
      }
    } else {
      if (this.message.ct === ConvoType.email) {
        const convo = this.convoGroupQuery.getConvosByChildId(this.message.channelId);
        if (convo[0]) {
          this.convoGroupService.updateConvoViewState(convo[0].conversationGroupId, <ConversationGroupUI>{
            editingMessageId: null
          });
        }
      } else {
        this.convoGroupService.updateConvoViewState(this.message.channelId, <ConversationGroupUI>{
          editingMessageId: null
        });
      }
    }
    this.cdr.markForCheck();
  }

  private checkTimeoutResponse() {
    this.ngZone.runOutsideAngular(() => {
      const deta = MessageConstants.RESEND - (this.timeService.nowInMillis() - this.message.ts);
      if (deta > 0) {
        setTimeout(() => {
          if (!this.message.id) {
            this.isTimeoutResponse = true;
          }
        }, deta);
      }
    });
  }
}
