import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ChatMessage, ChatService } from '@b3networks/api/chat';
import { CacheMediaQuery } from '@b3networks/api/common';
import { DownloadFileV3Req, FileService, RequestUploadData } from '@b3networks/api/file';
import { LiveChatService } from '@b3networks/api/inbox';
import { MediaService } from '@b3networks/api/workspace';
import { X, detectMobile, download, downloadData, isLocalhost } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';
import Viewer from 'viewerjs';
import { InfoShowMention } from '../../../core/state/app-state.model';
import { AppService } from '../../../core/state/app.service';
import { ConfigMessageOption } from '../chat-message.component';

@Component({
  selector: 'csh-attachment-message',
  templateUrl: './attachment-message.component.html',
  styleUrls: ['./attachment-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentMessageComponent implements OnInit {
  @Input() message: ChatMessage;
  @Input() parentElr: HTMLElement;
  @Input() isHideAction: boolean;
  @Input() isMobile: boolean;
  @Input() isPublic: boolean;
  @Input() configMessageOption: ConfigMessageOption;

  @Output() showProfile = new EventEmitter<InfoShowMention>();

  loaded: boolean;
  isHiding: boolean;
  isLoading = true;
  isZooming: boolean;
  noIntersectionObserver: boolean;

  backgroundImage: string;
  fileType: string;
  style: any;

  private _defaultImageSize = 300;

  constructor(
    public mediaService: MediaService,
    private cacheMediaQuery: CacheMediaQuery,
    private fileService: FileService,
    private chatService: ChatService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private liveChatService: LiveChatService
  ) {}

  ngOnInit() {
    this.style = this.customStyleMessage();

    const { isImgFile, keyWithOrgUuid, isStorage } = this.message?.attachmentUI || {};
    if (isImgFile) {
      this.isHiding = this.appService.cacheHidedImage[this.message.id];
      const url = this.cacheMediaQuery.getMediaByKey(keyWithOrgUuid, isStorage)?.url;
      this.noIntersectionObserver = !!url;
      this.isLoading = !this.noIntersectionObserver;
      this.backgroundImage = url ? `url('${url}')` : null;
    }
    this.cdr.markForCheck();
  }

  onRenderImg(index = 0) {
    if (isLocalhost()) {
      return;
    }

    const { keyWithOrgUuid } = this.message?.attachmentUI || {};
    if (!keyWithOrgUuid) {
      return;
    }
    const { hs, ns, ct, channelId } = this.message;
    const { isStorage } = this.message?.attachmentUI || {};

    if (index > 8) {
      // stop
      return;
    }
    if (isStorage) {
      this.chatService.session$
        .pipe(
          filter(x => x != null),
          take(1)
        )
        .subscribe(session => {
          const req: RequestUploadData = <RequestUploadData>{
            chatUserId: session.chatUser,
            orgUuid: hs ? ns : X.orgUuid,
            wssToken: session.token,
            chatServer: session.addr,
            convoType: ct,
            hyperspaceId: hs,
            mediaOrgUuid: ns
          };

          if (this.isPublic) {
            req.visitorToken = this.liveChatService.sessionToken;
            req.convoId = channelId;
            req.orgUuid = hs ? ns : X.orgUuid;
          }

          this.fileService
            .getThumbnailMediaStorageUuid(keyWithOrgUuid, req)
            .pipe(
              finalize(() => {
                this.loaded = true;
                this.isLoading = false;
                this.cdr.markForCheck();
              })
            )
            .subscribe(
              url => {
                if (url) {
                  this.backgroundImage = `url('${url}')`;
                }
              },
              (err: HttpErrorResponse) => {
                if (err.status === 423) {
                  setTimeout(() => {
                    this.onRenderImg(index + 1);
                  }, 2000);
                }
              }
            );
        });
    } else {
      let api$;
      if (this.isPublic) {
        api$ = this.mediaService.getMediaImgThumbnailPublic(keyWithOrgUuid, channelId);
      } else {
        api$ = this.mediaService.getMediaImgThumbnail(keyWithOrgUuid, channelId);
      }
      api$
        ?.pipe(
          finalize(() => {
            this.loaded = true;
            this.isLoading = false;
            this.cdr.markForCheck();
          })
        )
        ?.subscribe(res => {
          if (res?.url) {
            this.backgroundImage = `url('${res?.url}')`;
          }
        });
    }
  }

  download() {
    const { keyWithOrgUuid, isStorage, attachmentData } = this.message?.attachmentUI || {};
    if (!keyWithOrgUuid) {
      return;
    }
    const { id, hs, ns, ct, channelId } = this.message;

    this.mediaService.loadingMap[id] = true;
    this.cdr.markForCheck();
    if (isStorage) {
      if (hs) {
        this.chatService.session$
          .pipe(
            filter(x => x != null),
            take(1),
            finalize(() => {
              this.mediaService.loadingMap[id] = false;
              this.cdr.markForCheck();
            })
          )
          .subscribe(session => {
            this.fileService
              .downloadFileV3Public(keyWithOrgUuid, <RequestUploadData>{
                chatUserId: session.chatUser,
                orgUuid: hs ? ns : X.orgUuid,
                wssToken: session.token,
                chatServer: session.addr,
                convoType: ct,
                hyperspaceId: hs,
                mediaOrgUuid: ns
              })
              .subscribe(resp => {
                const file = new Blob([new Uint8Array(resp.body)], {
                  type: `${resp.headers.get('content-type')}`
                });
                downloadData(file, attachmentData.name);
              });
          });
      } else {
        const req: DownloadFileV3Req = {};
        if (this.isPublic) {
          req.visitorToken = this.liveChatService.sessionToken;
          req.convoId = channelId;
          req.orgUuid = hs ? ns : X.orgUuid;
        }

        this.fileService
          .downloadFileV3(keyWithOrgUuid, req)
          .pipe(
            finalize(() => {
              this.mediaService.loadingMap[id] = false;
              this.cdr.markForCheck();
            })
          )
          .subscribe(resp => {
            const file = new Blob([new Uint8Array(resp.body)], {
              type: `${resp.headers.get('content-type')}`
            });
            downloadData(file, attachmentData.name);
          });
      }
    } else {
      (this.isPublic
        ? this.mediaService.getMediaImgOriginalPublic(keyWithOrgUuid, channelId)
        : this.mediaService.getMediaImgOriginal(keyWithOrgUuid, channelId)
      )
        .pipe(
          finalize(() => {
            this.mediaService.loadingMap[id] = false;
            this.cdr.markForCheck();
          })
        )
        .subscribe(res => {
          download(res['url'], attachmentData.name);
        });
    }
  }

  hide() {
    this.appService.cacheHidedImage[this.message.id] = !this.isHiding;
    this.isHiding = !this.isHiding;
    this.cdr.markForCheck();
  }

  zoom() {
    if (this.isZooming) {
      return;
    }

    const { keyWithOrgUuid } = this.message?.attachmentUI || {};
    if (!keyWithOrgUuid) {
      return;
    }
    const { id, hs, ns, ct, channelId } = this.message;
    const { isStorage } = this.message?.attachmentUI || {};

    this.isZooming = true;
    let originalImg$: Observable<string> = of(null);
    this.mediaService.loadingMap[id] = true;
    this.cdr.markForCheck();
    if (isStorage) {
      if (hs) {
        const session = this.chatService.session;
        originalImg$ = this.fileService
          .downloadFileV3Public(keyWithOrgUuid, <RequestUploadData>{
            chatUserId: session.chatUser,
            orgUuid: hs ? ns : X.orgUuid,
            wssToken: session.token,
            chatServer: session.addr,
            convoType: ct,
            hyperspaceId: hs,
            mediaOrgUuid: ns
          })
          .pipe(
            map(resp => {
              const file = new Blob([new Uint8Array(resp.body)], {
                type: `${resp.headers.get('content-type')}`
              });
              return URL.createObjectURL(file);
            })
          );
      } else {
        const req: DownloadFileV3Req = {};
        if (this.isPublic) {
          req.visitorToken = this.liveChatService.sessionToken;
          req.convoId = channelId;
          req.orgUuid = hs ? ns : X.orgUuid;
        }

        originalImg$ = this.fileService.downloadFileV3(keyWithOrgUuid, req).pipe(
          map(resp => {
            const file = new Blob([new Uint8Array(resp.body)], {
              type: `${resp.headers.get('content-type')}`
            });

            const isMobile = detectMobile();
            if (this.isPublic && !isMobile) {
              const reader = new FileReader();
              reader.onload = e => {
                const src = e.target.result as string;
                const newTab = window.open();
                newTab.document.body.innerHTML = `<div style="display: flex;width: 100%;height: 100%;justify-content: center;
                align-items: center;"><img src="${src}"></div>`;
              };
              reader.readAsDataURL(file);
              this.isZooming = false;
            }

            return URL.createObjectURL(file);
          })
        );
      }
    } else {
      if (this.isPublic) {
        originalImg$ = this.mediaService
          .getMediaImgOriginalPublic(keyWithOrgUuid, channelId)
          .pipe(map(resp => resp?.url));
      } else {
        originalImg$ = this.mediaService.getMediaImgOriginal(keyWithOrgUuid, channelId).pipe(map(resp => resp?.url));
      }
    }
    originalImg$
      ?.pipe(
        finalize(() => {
          this.mediaService.loadingMap[id] = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe(
        url => {
          if (!url) {
            return;
          }

          const isMobile = detectMobile();
          if (this.isPublic && !isMobile) {
            return;
          }

          const image = new Image();
          image.src = url;
          const viewer = new Viewer(image, {
            hidden: () => {
              this.isZooming = false;
              this.appService.triggerFocusQuillEdior();
              viewer.destroy();
              this.cdr.markForCheck();
            },
            toolbar: {
              zoomIn: 4,
              zoomOut: 4,
              oneToOne: 4,
              reset: 4,
              rotateLeft: 4,
              rotateRight: 4,
              flipHorizontal: 4,
              flipVertical: 4
            }
          });
          viewer.show();
          this.mediaService.loadingMap[id] = false;
          this.cdr.markForCheck();
        },
        err => {
          this.isZooming = false;
          this.toastService.error(err?.message);
        }
      );
  }

  private customStyleMessage() {
    if (this.isMobile) {
      this._defaultImageSize = 200;
    }

    const { attachmentData } = this.message?.attachmentUI || {};
    const width = attachmentData.width && attachmentData.width > 0 ? attachmentData.width : this._defaultImageSize;
    const height = attachmentData.height && attachmentData.height > 0 ? attachmentData.height : this._defaultImageSize;

    let resizeWith = width;
    let resizeHeight = height;

    if (width !== this._defaultImageSize) {
      resizeWith = this._defaultImageSize;
      resizeHeight = (height * resizeWith) / width;

      if (resizeHeight > this._defaultImageSize) {
        resizeHeight = this._defaultImageSize;
        resizeWith = (width * resizeHeight) / height;
      }
    }

    return { width: resizeWith + 'px', height: resizeHeight + 'px', 'margin-bottom': '0' };
  }
}
