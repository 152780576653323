<div class="container flex column gap-8">
  <div *ngIf="!loading; else loadingThread" #viewport class="body spacer flex column gap-8">
    <ng-container *ngIf="threads$ | async as threads; else loadingThread">
      <ng-container *ngIf="threads.length > 0; else noResult">
        <div
          class="item flex align-items-center gap-8"
          *ngFor="let item of threads; trackBy: trackByThread"
          (click)="openThread($event, item)"
        >
          <mat-icon class="icon s-18" svgIcon="thread_outline"></mat-icon>
          <span class="name">{{ item.name }}</span>
        </div>
      </ng-container>
      <ng-template #noResult>
        <div class="thread-loadding flex column justify-content-center align-items-center gap-8">
          <mat-icon class="thread_outline-icon" svgIcon="threads"></mat-icon>
          <span class="secondary-text">No Discussions</span>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #loadingThread>
  <div class="thread-loadding flex justify-content-center align-items-center">
    <mat-spinner [diameter]="35"></mat-spinner>
  </div>
</ng-template>
