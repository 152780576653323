import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ChatMessage, SystemMessageData, SystemMsgType } from '@b3networks/api/chat';

@Component({
  selector: 'b3n-system-message-custom',
  templateUrl: './system-message-custom.component.html',
  styleUrls: ['./system-message-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMessageCustomComponent implements OnChanges {
  @Input() message: ChatMessage;

  @Output() jumpToMessage = new EventEmitter<string>();
  @Output() seeAllPinned = new EventEmitter<boolean>();

  system: SystemMessageData;

  readonly SystemMsgType = SystemMsgType;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['message']) {
      this.system = this.message.body.data as SystemMessageData;
    }
  }

  jumpToMsg(event, messageId: string) {
    event.stopPropagation();

    if (messageId) {
      this.jumpToMessage.emit(messageId);
    }
  }

  openAllPinned(event) {
    event.stopPropagation();
    this.seeAllPinned.emit(true);
  }
}
