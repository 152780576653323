<div
  *ngFor="let item of blocks; let i = index; trackBy: trackBy"
  class="item flex gap-16"
  [class]="'item__' + count"
  [attr.data-ts]="item?.time"
  [attr.id]="item?.id"
>
  <div class="left flex column">
    <div class="icon-container" [style.backgroundColor]="item.backgroundIcon">
      <mat-icon
        *ngIf="item.icon"
        [style.height.px]="item.fontSizeIcon"
        [style.width.px]="item.fontSizeIcon"
        [style.font-size.px]="item.fontSizeIcon"
        [class.material-icons-outlined]="item.isOutline"
        [style.color]="item.colorIcon"
      >
        {{ item.icon }}
      </mat-icon>
      <mat-icon
        *ngIf="item.sgvIcon"
        [style.height.px]="item.fontSizeIcon"
        [style.width.px]="item.fontSizeIcon"
        [style.font-size.px]="item.fontSizeIcon"
        [class.material-icons-outlined]="item.isOutline"
        [style.color]="item.colorIcon"
        [svgIcon]="item.sgvIcon"
      ></mat-icon>
    </div>

    <div *ngIf="i !== blocks.length - 1 || parent" class="flex-auto flex justify-content-center align-items-center">
      <div class="divider"></div>
    </div>
  </div>
  <div class="right flex column" [class.hasChildren]="item.hasChildren">
    <span class="time">{{ item.time | date: 'HH:mm:ss' }}</span>
    <ng-container *ngFor="let title of item?.title; let i1 = index">
      <div class="title" [innerHTML]="title | safe: 'html'"></div>
      <div *ngIf="item.hasRecording && i1 === item.title?.length - 1" class="flex align-items-center gap-3">
        <mat-icon class="icon-mic">mic</mat-icon>
        <span>Call recorded</span>
      </div>
    </ng-container>

    <div *ngIf="item?.navigateTxnUuid" class="flex align-items-center gap-8">
      <ng-container
        *ngTemplateOutlet="
          navigateTxn;
          context: { label: item?.navigateTxnUuid?.label, txnUuid: item?.navigateTxnUuid?.txnUuid }
        "
      ></ng-container>
    </div>

    <ng-container *ngIf="item.children?.length > 0">
      <ng-container *ngIf="!item.isExpanded; else expanded">
        <a href="javascript:void" class="icon-pending flex align-items-center gap-8" (click)="item.toggle()">
          <div class="arrow-child"></div>
          <mat-icon class="icon s-16">pending</mat-icon>
          <div>Show {{ item?.txnChild ? 'TXN' : '' }} details</div>
        </a>
      </ng-container>
      <ng-template #expanded>
        <div class="children flex column">
          <div class="arrow-child"></div>

          <ng-container *ngIf="item.txnChild; else listBlocks">
            <b3n-txn-child [count]="count + 1" [parent]="item"></b3n-txn-child>
          </ng-container>
          <ng-template #listBlocks>
            <b3n-txn-journey-block
              [blocks]="item.expandedWithCount > 0 ? item.children?.slice(0, item.expandedWithCount) : item.children"
              [count]="count + 1"
              [parent]="item"
            ></b3n-txn-journey-block>
          </ng-template>

          <ng-container *ngIf="item.hasMore">
            <a
              href="javascript:void"
              class="icon-pending expaned flex align-items-center gap-8"
              (click)="item.loadMoreChildren()"
            >
              <mat-icon class="icon s-16">pending</mat-icon>
              <div>Show more</div>
            </a>
          </ng-container>

          <a href="javascript:void" class="icon-pending expaned flex align-items-center gap-8" (click)="item.toggle()">
            <mat-icon class="icon s-16">pending</mat-icon>
            <div>Show less</div>
          </a>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #navigateTxn let-txnUuid="txnUuid" let-label="label">
  <div>{{ label || 'Go to TXN' }}:</div>
  <button class="box" mat-button (click)="goToTxn(txnUuid)">
    {{ txnUuid | slice: 0:6 }}
  </button>
</ng-template>
