<ng-container *ngIf="isFetchingLegs; else loadJourney">
  <ng-container *ngIf="!isChild; else loadingChild">
    <div class="container flex column justify-content-center align-items-center gap-16">
      <b3n-txn-progressing-call></b3n-txn-progressing-call>
    </div>
  </ng-container>
  <ng-template #loadingChild>
    <div class="icon-pending expaned flex align-items-center gap-8">
      <mat-spinner [diameter]="16"></mat-spinner>
      <div class="collecting flex gap-3">
        <span>Generating call journey </span>
        <div class="loading-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #loadJourney>
  <div #viewport class="container journey scroller-firefox flex column">
    <ng-container *ngIf="!isChild">
      <div *ngIf="viewDate$ | async as viewDate" class="viewing-date flex justify-content-center align-items-center">
        <span>{{ viewDate | viewDate }}</span>
      </div>
    </ng-container>
    <b3n-txn-incoming-generating
      [txn]="txn"
      [txnHistory]="txnHistory"
      [viewport]="viewport"
      [legs]="legs"
      [enterQueue]="enterQueue"
      [hasCallback]="hasCallback"
      [callbackData]="callbackData"
      [hasVoicemail]="hasVoicemail"
      [isChild]="isChild"
    ></b3n-txn-incoming-generating>
  </div>
</ng-template>
