import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ListToastsComponent } from './component/list-toasts/list-toasts.component';
import { ToastComponent } from './component/toast/toast.component';
import { TOAST_CONFIG_TOKEN, defaultToastConfig } from './config/toast-configs';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatDividerModule,
    OverlayModule,
    MatProgressSpinnerModule
  ],
  declarations: [ToastComponent, ListToastsComponent]
})
export class SharedUiToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<SharedUiToastModule> {
    return {
      ngModule: SharedUiToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config }
        }
      ]
    };
  }
}
