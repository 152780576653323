import { ElementRef } from '@angular/core';
import { Channel, ChannelHyperspace, ChatMessage, ReplyMessage } from '@b3networks/api/chat';
import { ChannelStateV3, Contact } from '@b3networks/api/contact';
import { User, UserHyperspace } from '@b3networks/api/workspace';

export interface AppState {
  showMainSidebar: boolean;
  sidebarTabActive?: SidebarTabs;
  emailUWState?: EmailUWState;
  endTxnsUWState?: EndTxnsUWState;
  endTxnsUWOrgState?: EndTxnsUWState;

  showRightSidebar: boolean;
  showLeftSidebar: boolean;
  modeLeftSidebar: ModeSidebar;
  modeRightSidebar: ModeSidebar;
  isLeftChatSidebar: boolean;

  mentionCountTeamChat: number;
  quillEditor: {
    triggerfocus: boolean;
  };
  triggerScrollBottomView: boolean;
  triggerDoCheckScroll: boolean;

  memberMenu: InfoShowMention;

  popupState: PopupState;
  isCollapseAssignedToMe: boolean;
  isCollapseAllInbox: boolean;
  loadingQueryTxn: boolean;

  initHeightTxn: number;
  viewTxn: ViewTxn;
  triggerRefreshTxn: boolean;
  triggerScrollUpTxn: boolean;
  triggerRefreshQueryTxn: boolean;
  triggerRefreshActiveCall: boolean;
  customerTxnReports: Contact[];

  collapseSidebar: CollapseSidebar;

  triggerIsScrollDownTxn: boolean;

  // TODO: cache with convo;
  triggerSeeAllPinned: boolean;

  submittedTicket: TxnFilterState;
  splitMode: SplitMode;
  isFetchingTxnDetail: boolean; // show loading when switch another txn
  isSmallBreakpont: boolean;
}

export interface CollapseSidebar {
  starred: boolean;
  me: boolean;
  inboxes: boolean;
  watching: boolean;
  related: boolean;
  created: boolean;
  mentions: boolean;
  channel: boolean;
}

export interface RightCLickMessage {
  uuid: string; // identity

  menus: MenuMessageInfo[];
  message: ChatMessage;

  xPosition: number;
  yPosition: number;
  elr: ElementRef;
}

export interface MenuMessageInfo {
  key: MenuMsg;
  value: string;
  order: number;
  icon: string;
  size: number; // px
  isSvg: boolean;
  classIcon: string;
  classText: string;

  // input data
  dataReply?: ReplyMessage; // MenuMsg.reply
  targetCopy?: string; // MenuMsg.copyText
  targetLink?: string; // MenuMsg.openTab, MenuMsg.copyLinkAddress

  // MenuMsg.deleteMessage
  deleteMessage?: {
    isPinned: boolean;
  };
}

export interface PopupState {
  isShowPopup: boolean;
  isLoadingTemplate: boolean;
  tag: { [key: string]: string | string[] };
  systemStatusExpiredAt: number;
  remainTime: number;
}

export enum MenuMsg {
  // message
  editMessage = 'editMessage',
  reply = 'reply',
  pinMsg = 'pinMsg',
  unpinMsg = 'unpinMsg',
  createThread = 'createThread',
  copyText = 'copyText',
  copyLinkAddress = 'copyLinkAddress',
  removePreviewLink = 'removePreviewLink',
  deleteMessage = 'deleteMessage',
  jumpFromBookmark = 'jumpFromBookmark',
  removeBookmark = 'removeBookmark',

  // jumpReply = 'jumpReply',
  // addBookmark = 'addBookmark',
  // copyLink = 'copyLink',

  // files
  showInChat = 'showInChat',
  downloadFile = 'downloadFile'
}

export interface InfoShowMention {
  xPosition: number;
  yPosition: number;
  member: User | UserHyperspace;
  convo: Channel | ChannelHyperspace;
}

export enum ViewTxn {
  assignToMe = 'assignedToMe', //view Me
  inbox = 'inbox', //view Inbox
  submittedTicket = 'submittedTicket', //view submitted ticket
  report = 'report', //view all transaction
  watching = 'watching', //view submitted ticket
  related = 'related', //view related to me,
  createdByMe = 'createdByMe' //view created by me
}

export enum ModeSidebar {
  over = 'over',
  side = 'side',
  push = 'push'
}

export enum SidebarTabs {
  teamchat = 'teamchat',
  email = 'email'
}

export enum SplitMode {
  horizontalSplit = 'horizontalSplit', // default
  noSplit = 'noSplit'
}

export interface EmailUWState {
  isExpandPersonal: boolean;
  isExpandTeam: boolean;
  isExpandTeammate: boolean;
}

export interface EndTxnsUWState {
  page?: number;
  perPage?: number;
  hasMore?: boolean;
}

export interface TxnFilterState {
  txnFilter?: ChannelStateV3;
  txnUuidsFilter?: string[];
}

export interface FetchingSidebar {
  watching: boolean;
  related: boolean;
  created: boolean;
}

export const NameAppStore = 'workspace_app_state';

export enum SearchBy {
  uuid = 'uuid',
  title = 'title'
}
