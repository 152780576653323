import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceType } from '@b3networks/api/bizphone';
import { WebrtcQuery } from '@b3networks/api/call';
import { Agent, AgentStatus, Me, MeQuery, MeService, SystemStatusCode, TxnType } from '@b3networks/api/callcenter';
import { FormQuery, FormService, FormType } from '@b3networks/api/data';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { combineLatest, filter, finalize, of, take, takeUntil } from 'rxjs';
import { AppQuery } from '../../core/state/app.query';
import { AppService } from '../../core/state/app.service';
import { ManagePhoneDialogComponent } from '../manage-phone-dialog/manage-phone-dialog.component';
import { CallbackTxnComponent } from './callback-txn/callback-txn.component';
import { InboundTxnComponent } from './inbound-txn/inbound-txn.component';
import { ManualOutgoingTxnComponent } from './manual-outgoing-txn/manual-outgoing-txn.component';
import { OutboundTxnComponent } from './outbound-txn/outbound-txn.component';

export interface RequestFinishedCall {
  note?: string;
  code?: string;
  action?: string;
  txnUuid?: string;
  session?: string; // txnUuid
  tag?: { [key: string]: string | string[] };
  noteTemplateId?: string;
}

class AgentNotification {
  agent: Agent;
  assignedTxn: string;
  at: number;
  status: AgentStatus;
  systemStatus: SystemStatusCode;
}

@Component({
  selector: 'b3n-pop-up-processor',
  template: ``
})
export class PopUpProcessorComponent extends DestroySubscriberComponent implements OnInit {
  incommingDialogRef: MatDialogRef<InboundTxnComponent>;
  callbackDialogRef: MatDialogRef<CallbackTxnComponent>;
  outboundDialogRef: MatDialogRef<OutboundTxnComponent>;
  manualCallDialogRef: MatDialogRef<ManualOutgoingTxnComponent>;

  private _meHandling: Me;
  private me: Me;
  private _isInactiveWindow = false;

  constructor(
    private meQuery: MeQuery,
    private meService: MeService,
    private dialog: MatDialog,
    private formService: FormService,
    private formQuery: FormQuery,
    private webrtcQuery: WebrtcQuery,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
    private appQuery: AppQuery
  ) {
    super();
  }

  ngOnInit() {
    let _preStatusSystem;
    this.meQuery.meCallcenter$.pipe(takeUntil(this.destroySubscriber$)).subscribe(me => {
      if (!me || !(me instanceof Me)) {
        return;
      }

      console.log('me: ', {
        assignedTxn: JSON.stringify(me?.assignedTxn),
        primaryDeviceType: me?.primaryDeviceType,
        status: me?.status,
        systemStatus: me?.systemStatus,
        systemStatusExpiredAt: me?.systemStatusExpiredAt
      });

      // handle popup
      this.me = me;

      if (me && me?.assignedTxn) {
        this.notifyIfNeeded(me);
      } else {
        if (me.systemStatus === SystemStatusCode.free && me.status === AgentStatus.available) {
          this.closeDialog();
        }
      }

      // handle countDown wrapuptime and tracking ACW status
      if (_preStatusSystem === SystemStatusCode.acw && me.systemStatus === SystemStatusCode.free) {
        this.appService.clearIntervalTime();
      }

      if (me.systemStatus === SystemStatusCode.acw && _preStatusSystem !== SystemStatusCode.acw) {
        const popupState = this.appQuery.getValue().popupState;
        this.appService.update({
          popupState: {
            ...popupState,
            systemStatusExpiredAt: me?.systemStatusExpiredAt > 0 ? me?.systemStatusExpiredAt : null
          }
        });
        this.appService.countDownPopup();

        if (!me?.assignedTxn?.queue?.agentWorkflowConfig?.disablePopup) {
          this.openManagePhoneDialog();
        } else {
          // update status ACW
          if (me?.systemStatusExpiredAt > 0) {
            this.appQuery
              .select(entity => entity?.popupState?.systemStatusExpiredAt)
              .pipe(
                filter(systemStatusExpiredAt => systemStatusExpiredAt == null),
                take(1)
              )
              .subscribe(() => {
                this.meService.get().subscribe();
              });
          }
        }
      }

      _preStatusSystem = me.systemStatus;
    });

    // open dialog when has call or isShowPopup
    combineLatest([this.webrtcQuery.session$, this.appQuery.isShowPopup$])
      .pipe(takeUntil(this.destroySubscriber$))
      .subscribe(([session, isShowPopup]) => {
        if (session) {
          this.openManagePhoneDialog();
          return;
        } else {
          if (isShowPopup) {
            this.openManagePhoneDialog();
          }
        }
      });
  }

  private notifyIfNeeded(me: Me) {
    if (me.assignedTxn && [DeviceType.WEBRTC, DeviceType.IP_PHONE].includes(me.primaryDeviceType)) {
      const disablePopup = me?.assignedTxn?.queue?.agentWorkflowConfig?.disablePopup;
      if (disablePopup) {
        return;
      }

      switch (me.assignedTxn.type) {
        case TxnType.callback:
          if (me.assignedTxn.status !== 'dialing' || me.systemStatus === SystemStatusCode.acw) {
            this.showPopup(me);
          }
          break;
        case TxnType.incoming:
        case TxnType.autodialer:
          if (['talking', 'holding'].includes(me.assignedTxn.status) || me.systemStatus === SystemStatusCode.acw) {
            this.showPopup(me);
          }
          break;
        case TxnType.crossApp:
        case TxnType.crossAppIn:
        case TxnType.incoming2extension:
        case TxnType.outgoing:
        case TxnType.internal:
        case TxnType.outgoingFromSipGateway:
          break;
      }
    }
  }

  private showPopup(me: Me) {
    const popupState = this.appQuery.getValue()?.popupState;
    this.appService.update({
      popupState: {
        ...popupState,
        isShowPopup: true,
        isLoadingTemplate: true
      }
    });

    const template = this.formQuery.getEntity(me.assignedTxn.queue?.agentWorkflowConfig?.noteTemplateId);
    (!me.assignedTxn.queue?.agentWorkflowConfig?.noteTemplateId
      ? of(null).pipe(take(1))
      : template
      ? of(template).pipe(take(1))
      : this.formService.getNoteTemplateV3Detail(
          FormType.popup,
          me.assignedTxn.queue?.agentWorkflowConfig?.noteTemplateId
        )
    )
      .pipe(
        finalize(() => {
          const popupState = this.appQuery.getValue()?.popupState;
          this.appService.update({
            popupState: {
              ...popupState,
              isLoadingTemplate: false
            }
          });
        })
      )
      .subscribe(() => {
        this._meHandling = me;
      });

    this.cdr.markForCheck();
  }

  private closeDialog() {
    const dialog = this.dialog?.openDialogs?.find(d => d.componentInstance instanceof ManagePhoneDialogComponent);
    if (dialog) {
      dialog.close();
      this.appService.clearIntervalTime();
      const popupState = this.appQuery.getValue()?.popupState;
      this.appService.update({
        popupState: {
          ...popupState,
          isLoadingTemplate: false,
          isShowPopup: false,
          tag: {},
          systemStatusExpiredAt: null,
          remainTime: null
        }
      });
    }
  }

  private openManagePhoneDialog() {
    const isOpenDialog = this.dialog?.openDialogs?.some(d => d.componentInstance instanceof ManagePhoneDialogComponent);
    if (!isOpenDialog) {
      this.webrtcQuery.isManagePhoneOpening$.next(true);
      this.dialog
        .open(ManagePhoneDialogComponent, {
          data: {},
          minWidth: '350px',
          maxWidth: '800px',
          disableClose: true,
          height: '600px',
          panelClass: 'manage-call'
        })
        .afterClosed()
        .subscribe(_ => {
          const isOpenDialog = this.dialog?.openDialogs?.some(
            d => d.componentInstance instanceof ManagePhoneDialogComponent
          );

          if (!isOpenDialog) {
            this.webrtcQuery.isManagePhoneOpening$.next(false);
          }
        });
    }
  }
}
