import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ToastAnimationState, toastAnimations } from '../../config/animation';
import { ToastData } from '../../config/toast-configs';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'b3n-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() data: ToastData;

  @Output() timeoutToast = new EventEmitter<ToastData>();

  animationState: ToastAnimationState = 'default';

  private _intervalId: any;

  constructor(private toastService: ToastService, public cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.data.duration !== -1) {
      this._intervalId = setTimeout(() => this.close(), this.data.duration);
    }
  }

  ngOnDestroy() {
    clearTimeout(this._intervalId);
  }

  close() {
    this.toastService.removeToast(this.data.guid);
  }

  onFadeFinished(event: any) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
