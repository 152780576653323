import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MemberStatus } from '@b3networks/api/auth';
import { ChannelHyperspaceQuery, ChannelQuery, ChannelService } from '@b3networks/api/chat';
import {
  ConversationGroupQuery,
  ConversationGroupService,
  HyperspaceQuery,
  MeQuery,
  User,
  UserQuery,
  UserService
} from '@b3networks/api/workspace';
import { MatchType, isLocalhost } from '@b3networks/shared/common';
import { HashMap } from '@datorama/akita';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { InfoFileMarkdown } from '../../lib/components/chat-message/chat-message.component';
import { InfoShowMention } from '../../lib/core/state/app-state.model';

@Directive({
  selector: '[lazyloadUnknown]',
  standalone: true
})
export class LazyLoadUnknownDirective implements OnChanges, OnDestroy {
  @Input() text: string;
  @Input() participants: string[];

  @Output() showProfile = new EventEmitter<InfoShowMention>();
  @Output() jumpToMessage = new EventEmitter<string>();
  @Output() seeAllPinned = new EventEmitter<boolean>();
  @Output() downloadFileWithFileKey = new EventEmitter<InfoFileMarkdown>();

  mapDomMentionUser: HashMap<{ child: HTMLElement; user: User }> = {};

  private subcription$ = new Subject();

  constructor(
    private el: ElementRef,
    private channelService: ChannelService,
    private channelQuery: ChannelQuery,
    private userQuery: UserQuery,
    private userService: UserService,
    private hyperspaceQuery: HyperspaceQuery,
    private channelHyperspaceQuery: ChannelHyperspaceQuery,
    private conversationGroupQuery: ConversationGroupQuery,
    private conversationGroupService: ConversationGroupService,
    private meQuery: MeQuery,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['participants'] &&
      changes['participants']?.previousValue?.length !== changes['participants']?.currentValue?.length
    ) {
      Object.keys(this.mapDomMentionUser)?.forEach(key => {
        this.tweakDomUserMention(this.mapDomMentionUser[key]?.child, this.mapDomMentionUser[key].user, false);
      });
    }

    if (changes['text']) {
      this.subcription$.next(true);
      try {
        this.renderText();
      } catch (error) {
        console.log('🚀 ~ error', error);
      }
    }
  }

  private renderText() {
    this.el.nativeElement.querySelectorAll('.cannotRender')?.forEach(child => {
      const type = child?.dataset?.cannotRender; // attr = data-cannot-render
      if (type === MatchType.CHANNEL.toString()) {
        const channelString = child.getAttribute('data-channel');

        const hyperId = child.getAttribute('data-hyper-id');
        if (hyperId) {
          const find = this.channelHyperspaceQuery.getEntity(channelString);
          if (find) {
            child.href = `./#/hyperspace/${hyperId}/${channelString}`;
            child.innerHTML = `#${find.name}`;
            child.classList.remove('cannotRender');
          } else {
            this.channelHyperspaceQuery
              .selectEntity(channelString)
              .pipe(
                filter(x => x != null),
                take(1),
                takeUntil(this.subcription$)
              )
              .subscribe(() => this.renderText());
          }
        } else {
          if (this.channelService.convoNotFound.indexOf(channelString) > -1) {
            child.classList.remove('cannotRender');
            return;
          }
          const find = this.channelQuery.getEntity(channelString);
          if (find) {
            if (isLocalhost()) {
              return;
            }
            const href = window.parent.location.href.split('?')[0];
            const path = `/conversations/${channelString}`;
            child.href = `${href}?path=${path}`;
            child.innerHTML = `#${find.name}`;
            child.classList.remove('cannotRender');

            const span = document.createElement('span');
            span.innerHTML = child.outerHTML;
            child.parentNode.replaceChild(span, child);
            this.navigateWithPath(span, path);
          } else {
            if (this.channelQuery.storeLoaded()) {
              this.onDestroy();
              this.channelService
                .getDetailsSequential(channelString, this.meQuery.getMe().userUuid)
                .subscribe(channel => {
                  if (channel) {
                    const href = window.parent.location.href.split('?')[0];
                    const path = `/conversations/${channelString}`;
                    child.href = `${href}?path=${path}`;
                    child.innerHTML = `#${channel.name}`;
                    child.classList.remove('canotRender');

                    const span = document.createElement('span');
                    span.innerHTML = child.outerHTML;
                    child.parentNode.replaceChild(span, child);
                    this.navigateWithPath(span, path);
                  }
                });
            } else {
              this.channelQuery.loaded$
                .pipe(
                  filter(x => x),
                  take(1),
                  takeUntil(this.subcription$)
                )
                .subscribe(() => this.renderText());
            }
          }
        }
      } else if (type === MatchType.MENTION.toString()) {
        const mentionString = child.getAttribute('data-user-chatUuid');

        const hyperId = child.getAttribute('data-hyper-id');
        if (hyperId) {
          const hyper = this.hyperspaceQuery.getHyperByHyperspaceId(hyperId);
          if (hyper) {
            const find = hyper.allMembers.find(x => x.userUuid === mentionString);
            if (find) {
              child.innerHTML = `@${find.displayName}`;
              child.setAttribute('data-user-uuid', find.identityUuid);
              child.classList.remove('cannotRender');
              this.trackingClickShowMention(child, find);
            }
          } else {
            this.hyperspaceQuery
              .selectEntity(hyperId)
              .pipe(
                filter(x => x != null),
                take(1),
                takeUntil(this.subcription$)
              )
              .subscribe(() => this.renderText());
          }
        } else {
          const find = this.userQuery.getUserByChatUuid(mentionString);
          if (find) {
            this.tweakDomUserMention(child, find);
          } else {
            if (this.userQuery.storeLoaded()) {
              this.onDestroy();
              this.userService.findOneByUuidAndUserTypeSequentially(mentionString, 'chatId').subscribe(user => {
                if (user) {
                  this.tweakDomUserMention(child, user);
                }
              });
            } else {
              // wait getallUser api done
              this.userQuery
                .selectStoreLoaded()
                .pipe(
                  filter(x => x),
                  take(1),
                  takeUntil(this.subcription$)
                )
                .subscribe(() => this.renderText());
            }
          }
        }
      } else if (type === MatchType.LINK.toString()) {
        try {
          const path = child.getAttribute('data-path');
          this.navigateWithPath(child, path);
        } catch (error) {
          console.log(error);
          child.classList.remove('canotRender');
        }
      } else if (type === MatchType.HIGHLIGHT_LINK.toString()) {
        try {
          const path = child.getAttribute('data-path');
          if (path?.startsWith('storage://')) {
            child.style.cursor = 'pointer';
            const fileKey = path.replace('storage://', '');
            this.trackingClickLinkFile(child, <InfoFileMarkdown>{
              fileKey: fileKey,
              fileName: child.innerText
            });
          }
        } catch (error) {
          console.log(error);
          child.classList.remove('canotRender');
        }
      }
    });
  }

  private onDestroy() {
    this.subcription$?.next(true);
    this.subcription$?.complete();
    this.subcription$ = null;
  }

  private navigateWithPath(child: HTMLElement, path: string, query = {}) {
    child.addEventListener('click', $event => {
      $event.preventDefault();
      if (path.includes(';')) {
        path = path.split(';')[0];
      }

      if (path.includes('?')) {
        path = path.split('?')[0];
      }

      if (path.startsWith('/#/')) {
        path = '/' + path.split('/#/')[1];
      }

      this.router.navigate([path], { queryParams: query, queryParamsHandling: 'merge' });
    });
  }

  private trackingClickShowMention(child: HTMLElement, user: User) {
    child.addEventListener('click', event => {
      event.stopPropagation();
      this.showProfile.emit(<InfoShowMention>{
        xPosition: event.x,
        yPosition: event.y,
        member: user
      });
    });
  }

  private trackingClickLinkFile(child: HTMLElement, info: InfoFileMarkdown) {
    child.addEventListener('click', event => {
      event.stopPropagation();
      this.downloadFileWithFileKey.emit(info);
    });
  }

  private tweakDomUserMention(child: HTMLElement, user: User, isHandleClick = true) {
    child.innerHTML = `@${user.displayName}`;
    child.setAttribute('data-user-uuid', user.identityUuid);
    child.classList.remove('not-member');
    if (this.participants != null) {
      if (user.memberStatus === MemberStatus.disabled) {
        child.classList.add('disabled');
      } else {
        if (!this.participants.includes(user.userUuid)) {
          child.classList.add('not-member');
        }
      }
    }
    child.classList.remove('cannotRender');
    if (isHandleClick) {
      this.trackingClickShowMention(child, user);
    }
    this.mapDomMentionUser[user.userUuid] = {
      child,
      user
    };
  }
}
