import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IdentityProfileService, Member, OrgMemberService } from '@b3networks/api/auth';
import { ExtensionBase } from '@b3networks/api/bizphone';
import { WebrtcQuery, WebrtcService } from '@b3networks/api/call';
import { ExtensionQuery } from '@b3networks/api/callcenter';
import {
  Channel,
  ChannelHyperspace,
  ChannelHyperspaceService,
  ChannelQuery,
  ChannelService,
  ChannelType,
  CreateConvoGroupReq,
  GroupType as GroupTypeWorkspace,
  ReqUpdateUsersChannelHyper,
  RequestNamespacesHyper,
  RoleType,
  RoleUserHyperspace,
  UpdateChannelReq
} from '@b3networks/api/chat';
import { CacheMediaQuery } from '@b3networks/api/common';
import { FileService } from '@b3networks/api/file';
import { MeQuery as LicenseMeQuery } from '@b3networks/api/license';
import {
  HyperspaceQuery,
  MeQuery,
  StarService,
  User,
  UserHyperspace,
  UserQuery,
  UserService
} from '@b3networks/api/workspace';
import { DestroySubscriberComponent, NetworkService, X } from '@b3networks/shared/common';
import { OrgChartDialogComponent } from '@b3networks/shared/ui/portal';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RemoveMemberComponent } from '../../remove-member/remove-member.component';
import { SupportedConvo } from './../../../core/adapter/convo-helper.service';

@Component({
  selector: 'b3n-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent extends DestroySubscriberComponent implements OnInit, OnChanges {
  readonly RoleType = RoleType;
  readonly GroupTypeWorkspace = GroupTypeWorkspace;
  readonly ChannelType = ChannelType;

  @Input() member: User;
  @Input() convo: SupportedConvo; // optional to render action blocks
  @Input() isDialog: boolean;

  @Output() closeDialog = new EventEmitter<boolean>();

  me: User;
  userHyperspace$: Observable<UserHyperspace> = of(null);
  isGeneral: boolean;
  infoMember$: Observable<User>;
  selectExtension$: Observable<ExtensionBase>;
  hasPhoneFeature$: Observable<boolean> = this.licenseMeQuery.hasDeviceWebRTCLicense$;
  isStarred$: Observable<boolean>;

  backgroundImage: string;

  private _identityUuid: string;
  private _id: string;

  constructor(
    private router: Router,
    private meQuery: MeQuery,
    private channelService: ChannelService,
    private channelQuery: ChannelQuery,
    private toastService: ToastService,
    public dialog: MatDialog,
    private channelHyperspaceService: ChannelHyperspaceService,
    private hyperspaceQuery: HyperspaceQuery,
    private identityProfileService: IdentityProfileService,
    private userQuery: UserQuery,
    private userService: UserService,
    private orgMemberService: OrgMemberService,
    private extensionQuery: ExtensionQuery,
    private cacheMediaQuery: CacheMediaQuery,
    private fileService: FileService,
    private webrtcQuery: WebrtcQuery,
    private networkService: NetworkService,
    private webrtcService: WebrtcService,
    private licenseMeQuery: LicenseMeQuery,
    private starService: StarService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['convo'] && this._id !== this.convo?.id) {
      this._id = this.convo.id;
      this.isGeneral = (<Channel>this.convo)?.isGeneral;
      this.isStarred$ = this.channelQuery.selectChannel(this.convo.id)?.pipe(map(x => x.isStarred));
    }

    if (changes['member'] && this._identityUuid !== this.member?.uuid) {
      this._identityUuid = this.member?.uuid;
      if (this.member.hasPhoto) {
        this.backgroundImage = `url('${this.member.photoUrl}')`;
      }
      this.cdr.detectChanges();
    }

    if (changes['member']) {
      const isHyperChannel = !!(<ChannelHyperspace>this.convo)?.hyperspaceId;
      let tapOne = false;
      this.userHyperspace$ = isHyperChannel
        ? this.hyperspaceQuery.selectHyperByHyperspaceId((<ChannelHyperspace>this.convo)?.hyperspaceId).pipe(
            filter(x => x != null),
            map(hyper => hyper.allMembers?.find(x => x.userUuid === this.member.userUuid)),
            tap(member => {
              if (!tapOne && member?.isCurrentOrg) {
                tapOne = true;
                this.infoMember$ = this.userQuery.selectUserByChatUuid(member.userUuid);
                this.fetchDetailUser(this.member);
                this.selectExtension$ = this.extensionQuery.selectExtensionByUser(member.identityUuid);
              }
            })
          )
        : of(null);

      this.selectExtension$ =
        this.member?.userUuid && !isHyperChannel
          ? this.extensionQuery.selectExtensionByUser(this.member.uuid)
          : of(null);

      this.infoMember$ =
        this.member?.userUuid && !isHyperChannel ? this.userQuery.selectUserByChatUuid(this.member.userUuid) : of(null);

      if (!isHyperChannel && this.member && !this.member.isBot) {
        this.fetchDetailUser(this.member);
      }
    }
  }

  ngOnInit(): void {
    this.meQuery.me$
      .pipe(
        filter(x => x != null),
        take(1)
      )
      .subscribe(me => (this.me = me));
  }

  unstarConvo($event) {
    $event.stopPropagation();
    this.starService.unstarChannelTeamChat(this.convo.id).subscribe(() => {
      this.channelService.updateOneChannel(<Channel>{
        id: this.convo.id,
        isStarred: false
      });
    });
  }

  starConvo($event) {
    $event.stopPropagation();
    this.starService.starChannelTeamChat(this.convo.id).subscribe(() => {
      this.channelService.updateOneChannel(<Channel>{
        id: this.convo.id,
        isStarred: true
      });
    });
  }

  handleCall(extKey: string, mobileNumber: string) {
    if ((extKey && mobileNumber) || (!extKey && !mobileNumber)) return;
    extKey && this.call(extKey);
    mobileNumber && this.call(mobileNumber);
  }

  call(number: string) {
    if (!this.webrtcQuery.UA?.isRegistered()) {
      this.toastService.error(
        'Sorry, an error has occurred when we try to fulfill your request. Please try again in a few minutes.'
      );
      return;
    }

    if (this.webrtcQuery.isBusy) {
      this.toastService.error('You are on a call process.');
      return;
    }

    if (!this.networkService.isOnline) {
      this.toastService.warning(
        "Your computer seems to be offline. We'll keep trying to reconnect, or you can try refresh your browser",
        10e3
      );
      return;
    }

    const user = this.userQuery.getUserByChatUuid(this.member.userUuid);
    this.webrtcService.makeCallOutgoing(number, user as User);
  }

  createOrGetConvo(user: User) {
    if ((<ChannelHyperspace>this.convo)?.hyperspaceId) {
      this.toastService.warning('Direct chat is coming soon!');
    } else {
      const dmChannel = this.channelQuery.findChannelDirectChatWithMe(user.userUuid);

      if (dmChannel) {
        this.router.navigate(['conversations', dmChannel.id], {
          queryParamsHandling: 'merge'
        });
      } else {
        this.createChannel(user);
      }
    }
  }

  invite() {
    const req = <UpdateChannelReq>{
      add: [this.member.userUuid]
    };
    this.channelService.addOrRemoveParticipants(this.convo.id, req).subscribe();
  }

  inviteChannelHyper() {
    const req = <ReqUpdateUsersChannelHyper>{
      hyperspaceId: (<ChannelHyperspace>this.convo).hyperspaceId,
      hyperchannelId: this.convo.id,
      add: [
        <RequestNamespacesHyper>{
          namespaceId: this.member.orgUuid,
          users: [
            {
              id: this.member.userUuid,
              role: RoleUserHyperspace.member
            }
          ]
        }
      ]
    };

    this.channelHyperspaceService.updateUsersChannelHyper(req).subscribe(
      res => {
        this.toastService.success('Invite member successfully!');
      },
      error => {
        this.toastService.error(error.message || 'Invite member failed!');
      }
    );
  }

  remove(user: User) {
    if (this.convo.type === ChannelType.dm || !this.convo.isMember) {
      this.toastService.error('You have no permission to do this');
    } else {
      this.dialog.open(RemoveMemberComponent, {
        width: '600px',
        data: { convo: this.convo, member: user }
      });
    }
  }

  removeChannelHyper(user: User) {
    if (this.convo.type === ChannelType.dm || !this.convo.isMember) {
      this.toastService.error('You have no permission to do this');
    } else {
      this.dialog.open(RemoveMemberComponent, {
        width: '600px',
        data: { convo: this.convo, member: user }
      });
    }
  }

  onOpenOrgChartDialog(identityUuid: string) {
    this.dialog.open(OrgChartDialogComponent, {
      data: { identityUuid },
      restoreFocus: false,
      disableClose: true,
      width: '1000px'
    });
  }

  private fetchDetailUser(user: User) {
    if (!user?.uuid) {
      // unknown user
      return;
    }

    const { uuid, isTemporary } = user;
    const ui = this.userQuery.getUiState(user.uuid);

    !!user && isTemporary && this.userService.findOneByUuidAndUserTypeSequentially(user.userUuid, 'chatId').subscribe();

    !ui?.loadedTeams &&
      this.identityProfileService.getTeams(uuid).subscribe(teams => {
        this.userService.updateUsers2Store([<User>{ uuid, teams }]);
        this.userService.updateUserViewState(user.uuid, {
          loadedTeams: true
        });
      });

    !ui?.loadedDetailFromAuth &&
      this.orgMemberService.getMember(X.orgUuid, uuid).subscribe((member: Member) => {
        const { about } = member;
        this.userService.updateUsers2Store([<User>{ uuid, about }]);
        this.userService.updateUserViewState(user.uuid, {
          loadedDetailFromAuth: true
        });
      });
  }

  private createChannel(user: User) {
    const me = this.meQuery.getMe();
    if (me && user) {
      this.channelService
        .createChannel(
          <CreateConvoGroupReq>{
            type: ChannelType.dm,
            participants: [me.userUuid, user.userUuid]
          },
          me.userUuid
        )
        .subscribe(
          newConvo => {
            this.router.navigate(['conversations', newConvo.id], {
              queryParamsHandling: 'merge'
            });

            this.dialog.closeAll();
          },
          _ => {
            console.error('Error when ebstablish new directchat');
          }
        );
    }
  }
}
