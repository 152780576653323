<h1 mat-dialog-title>
  Leave
  {{ convo.isEmail ? 'Conversation' : 'Channel' }}
</h1>
<div mat-dialog-content>
  <div class="flex column gap-4">
    <div class="flex align-items-center">
      <span class="mr-4"> Are you sure you wish to leave </span>
      <mat-icon *ngIf="convo.privacy === Privacy.private" class="s-16">lock</mat-icon>
      <mat-icon *ngIf="convo.privacy !== Privacy.private" class="s-16">tag</mat-icon>
      <h3>
        {{ convo.isEmail ? convo?.description : convo?.name }}
      </h3>
      ?
    </div>

    <p *ngIf="!convo.isEmail">
      If you leave the private channel, you will no longer be able to see any of its messages. To rejoin the private
      channel, you will have to be re-invited.
    </p>
  </div>
</div>
<div mat-dialog-actions class="flex justify-content-end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="warn" (click)="submit()">Leave</button>
</div>
