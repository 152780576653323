<div class="container flex column gap-8">
  <div class="search flex column">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <input
        [formControl]="searchCtr"
        #inputSearch
        matInput
        placeholder="Search discussion"
        keyDownEvent
        [currentSelect]="currentSelect"
        [totalRow]="totalRow"
        (changeSelect)="onChangeSelect($event)"
        (enterSelect)="onEnterSelect($event)"
      />
      <mat-icon *ngIf="searchCtr.value" matSuffix class="icon s-16" (click)="searchCtr.setValue('')"> clear </mat-icon>
      <mat-icon *ngIf="!searchCtr.value" matSuffix class="icon s-16"> search </mat-icon>
    </mat-form-field>
  </div>
  <div *ngIf="!loading; else loadingThread" class="body spacer flex column gap-8">
    <ng-container *ngIf="threadsActived$ | async as threads; else loadingThread">
      <ng-container *ngIf="threads.length > 0; else noResult">
        <div
          class="item flex align-items-center gap-8"
          *ngFor="let item of threads; let i = index; trackBy: trackByThread"
          (click)="openThread($event, item)"
          [class.current-select]="currentSelect === i"
        >
          <mat-icon class="icon s-18" svgIcon="thread_outline"></mat-icon>
          <span class="name" [innerHTML]="item.name | highlight: searchCtr.value"></span>
        </div>
      </ng-container>
      <ng-template #noResult>
        <div class="thread-loadding flex column justify-content-center align-items-center gap-8">
          <mat-icon class="thread_outline-icon" svgIcon="threads"></mat-icon>
          <span class="secondary-text">No Discussions</span>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #loadingThread>
  <div class="thread-loadding flex justify-content-center align-items-center">
    <mat-spinner [diameter]="35"></mat-spinner>
  </div>
</ng-template>
