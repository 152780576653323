import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CallManagement, WebrtcQuery, WebrtcService } from '@b3networks/api/call';
import { ExtensionQuery, Me, MeQuery, MeService, SystemStatusCode, TxnType } from '@b3networks/api/callcenter';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppQuery } from '../../core/state/app.query';
import { AppService } from '../../core/state/app.service';
import { KeypadComponent } from '../keypad/keypad.component';

@Component({
  selector: 'b3n-manage-phone-dialog',
  templateUrl: './manage-phone-dialog.component.html',
  styleUrls: ['./manage-phone-dialog.component.scss']
})
export class ManagePhoneDialogComponent extends DestroySubscriberComponent implements OnInit {
  callManagement$: Observable<CallManagement>;

  displayMember: Observable<string>;
  displayStatus: string;
  isEndedCall: boolean;
  me: Me;

  readonly isLoadingTemplate$ = this.appQuery.isLoadingTemplate$;
  readonly isShowPopup$ = this.appQuery.isShowPopup$;
  readonly TxnType = TxnType;
  readonly defaultWidthLeft = 350;
  readonly defaultWidthRight = 450;
  readonly endedStatuses = ['agentMarkCallDone', 'hangupBySupervisor', 'ended', 'callback', 'voicemail'];

  get isNotACW() {
    const me = this.meQuery.getValue().meCallcenter;
    return !this.endedStatuses.includes(me?.assignedTxn?.status) && me?.systemStatus !== SystemStatusCode.acw;
  }

  constructor(
    private webrtcQuery: WebrtcQuery,
    private webrtcService: WebrtcService,
    private extensionQuery: ExtensionQuery,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ManagePhoneDialogComponent>,
    private meQuery: MeQuery,
    private meService: MeService,
    private appService: AppService,
    private appQuery: AppQuery,
    private ngZone: NgZone
  ) {
    super();
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    let txnUuid: string;
    this.meQuery.meCallcenter$.pipe(takeUntil(this.destroySubscriber$)).subscribe(me => {
      if (me) {
        if (me?.assignedTxn?.txnUuid) {
          if (txnUuid && me.assignedTxn.txnUuid !== txnUuid) {
            // TODO: notify new call
            return;
          }
          txnUuid = me?.assignedTxn?.txnUuid;
        }
        this.me = me;
      }
    });

    this.callManagement$ = this.webrtcQuery.session$.pipe(
      switchMap(session => (session ? this.webrtcQuery.callManagement$ : of(null))),
      tap(call => {
        if (call) {
          this.displayMember = this.getDisplayMember(call);
          this.displayStatus = this.getDisplayStatus(call);
        }
      })
    );
    this.webrtcQuery.session$.pipe(takeUntil(this.destroySubscriber$)).subscribe(session => {
      this.isEndedCall = !session;
      if (!session) {
        const isShowPopup = this.appQuery.getValue().popupState?.isShowPopup;
        if (!isShowPopup) {
          this.ngZone.run(() => {
            this.dialogRef.close();
          });
        }
      }
    });
  }

  onFinishCall() {
    this.appService.clearIntervalTime();
    const popupState = this.appQuery.getValue()?.popupState;
    this.appService.update({
      popupState: {
        ...popupState,
        isLoadingTemplate: false,
        isShowPopup: false,
        tag: {},
        systemStatusExpiredAt: null,
        remainTime: null
      }
    });
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
    // update system status ACW to Free
    this.meService.get().subscribe();
  }

  ended() {
    this.webrtcService.doRejectCall();
    const isShowPopup = this.appQuery.getValue().popupState?.isShowPopup;
    if (!isShowPopup) {
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    }
  }

  accept() {
    this.webrtcService.doAnswerIncoming();
  }

  toggleHold() {
    this.webrtcService.doToggleHold();
  }

  toggleMute() {
    this.webrtcService.doToggleMute();
  }

  dialpad() {
    this.dialog.open(KeypadComponent, {
      width: '320px',
      data: {
        isDTMF: true
      }
    });
  }

  zoomOut() {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }

  private getDisplayMember(call: CallManagement): Observable<string> {
    if (call.isRemote) {
      const extKeyOrNumber = this.webrtcQuery?.session?.remote_identity?.uri?.user;
      try {
        if (Number(extKeyOrNumber)) {
          const number = Number(extKeyOrNumber);
          const remoteExtIdentity = number + '';
          if (remoteExtIdentity) {
            if (remoteExtIdentity.length > 8) {
              try {
                const formatNumber = parsePhoneNumber(remoteExtIdentity);
                return of(formatNumber.formatInternational());
              } catch (error) {
                return of(remoteExtIdentity);
              }
            } else {
              // ext
              return this.extensionQuery.selectEntity(remoteExtIdentity, 'displayText').pipe(
                map(ext => {
                  return ext ? ext : '#' + remoteExtIdentity;
                })
              );
            }
          }
        } else {
          return of(extKeyOrNumber);
        }
      } catch (er) {
        return of(null);
      }
      return of(null);
    } else {
      let displayName = call?.member?.displayName;
      if (displayName) {
        const ext = this.extensionQuery.getExtensionByKey(call?.member?.displayName.replace('+', ''));
        if (ext) {
          displayName = `${ext.extLabel} (#${ext.extKey})`;
        }
      }
      return of(displayName);
    }
  }

  private getDisplayStatus(call: CallManagement) {
    return '';
  }
}
