import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Channel, ChannelQuery, ChannelService, ChannelUI } from '@b3networks/api/chat';
import { FileDetail, MediaQuery, MediaService } from '@b3networks/api/workspace';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import {
  Observable,
  debounceTime,
  filter,
  finalize,
  fromEvent,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  timer
} from 'rxjs';
import { MenuMessageInfo, MenuMsg, RightCLickMessage } from '../../../core/state/app-state.model';
import { ConversationFilesThumbnailComponent } from './conversation-files-thumbnail/conversation-files-thumbnail.component';

@Component({
  selector: 'b3n-conversation-files',
  templateUrl: './conversation-files.component.html',
  styleUrls: ['./conversation-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationFilesComponent extends DestroySubscriberComponent implements OnChanges, OnInit {
  @Input() channel: Channel;

  @Output() closeDialog = new EventEmitter();

  @ViewChild('viewport') viewport: ElementRef<HTMLElement>;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChildren(ConversationFilesThumbnailComponent)
  convoFilesThumbnailComponent: QueryList<ConversationFilesThumbnailComponent>;

  loadedFiles$: Observable<boolean>;
  files$: Observable<FileDetail[]>;
  hasMore$: Observable<boolean>;
  loadingMore = false;

  private _id: string;

  constructor(
    private mediaService: MediaService,
    private mediaQuery: MediaQuery,
    private channelQuery: ChannelQuery,
    private channelService: ChannelService,
    private elr: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }
  ngOnInit(): void {
    this.initFilesChannel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel.id !== this._id) {
      this._id = this.channel.id;
      this.loadedFiles$ = this.channelQuery.selectUIState(this.channel.id, 'file').pipe(map(file => file?.loaded));

      let tapFirst = false;
      this.files$ = this.mediaQuery.selectThumbnailsByConvo(this.channel.id).pipe(
        tap(files => {
          if (!tapFirst && files.length > 0) {
            tapFirst = true;
            this.trackingScrollEvent();
          }
        })
      );
      this.hasMore$ = this.channelQuery.selectUIState(this.channel.id, 'file').pipe(map(x => x?.hasMore));
    }
  }

  onRightClickMessage($event: RightCLickMessage) {
    const menu = this.elr.nativeElement.querySelector('.right-click-files-menu') as HTMLElement;
    menu.style.left = $event.xPosition + 5 + 'px';
    menu.style.top = $event.yPosition + 5 + 'px';

    if (this.matMenuTrigger) {
      this.matMenuTrigger.menuData = { item: $event };
      this.matMenuTrigger.openMenu();
    }
  }

  handleRightClick(item: RightCLickMessage, menu: MenuMessageInfo) {
    const find = this.convoFilesThumbnailComponent.find(x => x.uuid === item.uuid);
    if (find) {
      switch (menu.key) {
        case MenuMsg.downloadFile: {
          find.download();
          break;
        }
        case MenuMsg.showInChat: {
          find.jumpToMessage();
          break;
        }
        default:
          break;
      }
    }
  }

  trackByFile(_: number, item: FileDetail) {
    return item.mediaId;
  }

  onLoadMoreFiles() {
    if (this.loadingMore) {
      return;
    }

    const uiFile = this.channelQuery.getChannelUiState(this.channel.id)?.file;
    if (!uiFile.hasMore) {
      return;
    }

    const nextPage = (uiFile?.page || 1) + 1;
    this.loadingMore = true;
    this.cdr.detectChanges();
    this.mediaService
      .getThumbnails(this.channel.id, nextPage, uiFile.perPage)
      .pipe(
        finalize(() => {
          this.loadingMore = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe(list => {
        this.channelService.updateChannelViewState(this.channel.id, <Partial<ChannelUI>>{
          file: {
            ...uiFile,
            page: nextPage,
            hasMore: list.length === uiFile.perPage
          }
        });
      });
  }

  private trackingScrollEvent() {
    timer(300)
      .pipe(
        switchMap(() => fromEvent(this.viewport?.nativeElement, 'scroll')),
        debounceTime(10),
        takeUntil(this.destroySubscriber$)
      )
      .subscribe(event => {
        const target = event.target as HTMLElement;
        if (target.scrollTop + target.clientHeight + 10 >= target.scrollHeight) {
          this.onLoadMoreFiles();
        }
      });

    setTimeout(() => {
      this.channelQuery
        .selectUIState(this.channel.id, 'file')
        .pipe(
          filter(x => x.loaded),
          take(1)
        )
        .subscribe(() => {
          const hasScroll = this.viewport?.nativeElement.scrollHeight > this.viewport?.nativeElement.clientHeight;
          if (!hasScroll) {
            this.onLoadMoreFiles();
          }
        });
    }, 600);
  }

  private initFilesChannel() {
    const uiFile = this.channelQuery.getChannelUiState(this.channel.id);
    if (!uiFile?.file?.loaded) {
      const page = uiFile?.file?.page || 1;
      const perPage = uiFile?.file?.perPage || 10;
      this.mediaService.getThumbnails(this.channel.id, page, perPage).subscribe(list => {
        this.channelService.updateChannelViewState(this.channel.id, <Partial<ChannelUI>>{
          file: {
            page,
            perPage,
            loaded: true,
            hasMore: list.length === perPage
          }
        });
      });
    }
  }
}
