import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProductName'
})
export class FilterByProductNamePipe implements PipeTransform {
  transform(products: Array<unknown>, productName: string): any {
    if (!productName) {
      return products;
    }
    return products?.filter(p => {
      return p['name'].toLocaleLowerCase().indexOf(productName.toLocaleLowerCase()) > -1;
    });
  }
}
