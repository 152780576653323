<div
  class="convo item-convo sub-convo"
  [routerLink]="menu.routerLink"
  routerLinkActive="active"
  (click)="closeSidebar($event)"
>
  <ng-container *ngIf="menu.agent; else menuIcon">
    <b3n-agent-avatar [agent]="menu.agent"></b3n-agent-avatar>
  </ng-container>
  <ng-template #menuIcon>
    <mat-icon *ngIf="menu.icon" class="icon s-16">{{ menu.icon }}</mat-icon>
    <mat-icon *ngIf="!menu.icon" class="icon s-16" style="color: green">lens</mat-icon>
  </ng-template>

  <div class="convo__headline">
    <span class="convo__headline__text">
      {{ menu.displayText }}
    </span>

    <div *ngIf="menu.count > 0">{{ menu.count }}</div>
  </div>
</div>
