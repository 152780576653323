import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChatMessage, InteractiveBlockType } from '@b3networks/api/chat';

@Component({
  selector: 'csh-webhook-message',
  templateUrl: './webhook-message.component.html',
  styleUrls: ['./webhook-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebhookMessageComponent {
  @Input() message: ChatMessage;
  @Input() participants: string[];

  readonly BlockType = InteractiveBlockType;

  constructor() {}
}
