import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InteractiveBlock } from '@b3networks/api/chat';

@Component({
  selector: 'b3n-webhook-markdown',
  templateUrl: './webhook-markdown.component.html',
  styleUrls: ['./webhook-markdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebhookMarkdownComponent {
  @Input() block: InteractiveBlock;
  @Input() participants: string[];

  constructor() {}
}
