import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ChannelHyperspaceService,
  ChannelHyperspaceUI,
  ChannelService,
  ChannelUI,
  ChatMessage,
  ChatService,
  ConvoType,
  MessageBody,
  MsgType,
  SystemType
} from '@b3networks/api/chat';
import { ConversationGroupQuery, ConversationGroupService, ConversationGroupUI } from '@b3networks/api/workspace';
import { OutputContentQuill } from '@b3networks/shared/common';
import { InfoShowMention } from '../../../../core/state/app-state.model';
import { AppService } from '../../../../core/state/app.service';
import { CshQuillEditorComponent, QuillEditorInput } from '../../../quill-editor/quill-editor.component';
import { ConfigMessageOption } from '../../chat-message.component';

@Component({
  selector: 'b3n-built-text-message',
  templateUrl: './built-text-message.component.html',
  styleUrls: ['./built-text-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuiltTextMessageComponent implements OnChanges {
  @ViewChild('quill') quill: CshQuillEditorComponent;

  @Input() message: ChatMessage;
  @Input() isEditing: boolean;
  @Input() participants: string[];
  @Input() configMessageOption: ConfigMessageOption;
  @Input() parentElr?: HTMLElement; // use intersection to lazy loading
  @Input() isExpand: boolean;
  @Input() isSubstring: boolean;

  @Output() edited = new EventEmitter<boolean>();
  @Output() showProfile = new EventEmitter<InfoShowMention>();
  @Output() jumpToMessage = new EventEmitter<string>();
  @Output() seeAllPinned = new EventEmitter<boolean>();
  @Output() mentionsChanged = new EventEmitter<string[]>();
  @Output() expandMsg = new EventEmitter<boolean>();

  // builtTextMessage: OutputProcessMessage;
  quillInput = <QuillEditorInput>{
    enableMention: true,
    enableEmoji: true
  };

  readonly MessageType = MsgType;

  constructor(
    private convoGroupService: ConversationGroupService,
    private convoGroupQuery: ConversationGroupQuery,
    private channelService: ChannelService,
    private channelHyperspaceService: ChannelHyperspaceService,
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private appService: AppService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isEditing'] && this.isEditing) {
      this.quillInput = { ...this.quillInput, context: this.message.body.text };
    }
  }

  send() {
    this.quill.handleEnterMessage();
  }

  handleEnterMessage(data: OutputContentQuill) {
    const message = new ChatMessage(this.message);
    message.body = new MessageBody({ text: data?.msg });
    message.st = SystemType.EDIT;
    if (message?.messageReply) {
      if (message.messageReply?.metadata) {
        delete message?.messageReply.metadata;
      }
    }
    this.chatService.send(message);
    this.resetEdit();

    setTimeout(() => {
      this.appService.triggerFocusQuillEdior();
    });

    if (data?.mentions?.length > 0 && this.message.ct === ConvoType.groupchat) {
      this.mentionsChanged.emit(data.mentions);
    }
  }

  resetEdit() {
    this.edited.emit(true);
    if (this.message.isFromChannel) {
      if (this.message?.hs) {
        this.channelHyperspaceService.updateChannelViewState(this.message.channelId, <ChannelHyperspaceUI>{
          editingMessageId: null
        });
      } else {
        this.channelService.updateChannelViewState(this.message.channelId, <ChannelUI>{
          editingMessageId: null
        });
      }
    } else {
      if (this.message.ct === ConvoType.email) {
        const convo = this.convoGroupQuery.getConvosByChildId(this.message.channelId);
        if (convo[0]) {
          this.convoGroupService.updateConvoViewState(convo[0].conversationGroupId, <ConversationGroupUI>{
            editingMessageId: null
          });
        }
      } else {
        this.convoGroupService.updateConvoViewState(this.message.channelId, <ConversationGroupUI>{
          editingMessageId: null
        });
      }
    }
    this.cdr.markForCheck();
  }
}
