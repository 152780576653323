<div
  *ngIf="!noIntersectionObserver"
  [intersectionObserverDom]="parentElr"
  class="flex justify-content-center align-items-center image"
  [ngStyle]="style"
  [style.background-image]="bgImage ? bgImage : ''"
  (render)="onRenderImg()"
>
  <mat-spinner *ngIf="!loaded || mediaService.loadingMap?.[message.id]" diameter="40"></mat-spinner>
  <mat-icon *ngIf="loaded && !bgImage && !mediaService.loadingMap?.[message.id]" class="not-loaded-image">
    broken_image
  </mat-icon>
</div>

<div
  *ngIf="noIntersectionObserver"
  class="flex justify-content-center align-items-center image"
  [ngStyle]="style"
  [style.background-image]="bgImage ? bgImage : ''"
>
  <mat-spinner *ngIf="mediaService.loadingMap?.[message.id]" diameter="40"></mat-spinner>
</div>
