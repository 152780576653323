<h1 mat-dialog-title>Invite members&nbsp;<csh-convo-name [channel]="convo" [iconSize]="'s-24'"></csh-convo-name></h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label *ngIf="convo.type === GroupType.WhatsApp || convo.type === GroupType.SMS">Agent</mat-label>
    <mat-label *ngIf="convo.type !== GroupType.WhatsApp && convo.type !== GroupType.SMS">Members</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let member of sortBy(members); trackBy: trackBy"
        [removable]="removable"
        (removed)="remove(member)"
      >
        <b3n-avatar-user
          [member]="member"
          [showStatus]="false"
          [disabledClick]="true"
          [showDisplayName]="true"
        ></b3n-avatar-user>
        <mat-icon class="s-20 px-8" matChipRemove *ngIf="removable">close</mat-icon>
      </mat-chip-row>
      <input
        #memberInput
        [formControl]="memberCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
      />
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let member of sortBy(filteredMembers | async); trackBy: trackBy" [value]="member">
        <b3n-avatar-user
          [member]="member"
          [showStatus]="true"
          [showDisplayName]="true"
          [disabledClick]="true"
          [keyword]="key"
        ></b3n-avatar-user>
      </mat-option>
      <mat-option *ngIf="(filteredMembers | async)?.length === 0" disabled>No members available</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions class="right-floated">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" [loading]="processing" (click)="submit()">{{ ctaButton }}</button>
</div>
