import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaUI } from '../wa-template-message.component';

@Component({
  selector: 'b3n-wa-template-document',
  templateUrl: './wa-template-document.component.html',
  styleUrls: ['./wa-template-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaTemplateDocumentComponent implements OnInit {
  @Input() mediaUI: MediaUI;
  @Output() download = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
