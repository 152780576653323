<div
  class="convo_header flex justify-content-start align-items-center gap-4"
  [class.noHover]="noHoverChannelName || channel.isPersonalBookmark"
  [matTooltip]="!noHoverChannelName ? (channel.type === ChannelType.dm ? null : 'Get channel details') : null"
  [matTooltipDisabled]="channel.isPersonalBookmark"
>
  <ng-container [ngSwitch]="channel.type">
    <ng-container *ngSwitchCase="ChannelType.PERSONAL">
      <mat-icon *ngIf="!channel.isSvg; else svgIcon" [ngClass]="channel.classIcon">{{ channel.icon }}</mat-icon>
      <ng-template #svgIcon>
        <mat-icon [svgIcon]="channel.icon"></mat-icon>
      </ng-template>
      <div class="convo_header__name">
        {{ channel.displayName | titlecase }}
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="ChannelType.THREAD">
      <mat-icon *ngIf="!channel.isSvg; else svgIcon" [ngClass]="channel.classIcon">{{ channel.icon }}</mat-icon>
      <ng-template #svgIcon>
        <mat-icon [svgIcon]="channel.icon"></mat-icon>
      </ng-template>
      <span
        *ngIf="highlight; else default"
        class="convo_header__name"
        [innerHTML]="channel.displayName | highlight: highlight"
      >
      </span>
      <ng-template #default>
        <div class="convo_header__name">
          {{ channel.displayName }}
        </div>
      </ng-template>

      <ng-container *ngIf="isShowParentThread">
        <div
          *ngIf="channel.parentId | selectConvo | async as parent"
          class="convo_header__sub-name flex align-items-center gap-3"
          (click)="openParent($event, parent.id)"
        >
          <mat-icon class="icon-parent">{{ parent.icon }}</mat-icon>
          <div class="title">
            {{ parent?.name }}
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ChannelType.gc">
      <mat-icon *ngIf="!channel.isSvg; else svgIcon" [ngClass]="channel.classIcon">{{ channel.icon }}</mat-icon>
      <ng-template #svgIcon>
        <mat-icon [svgIcon]="channel.icon"></mat-icon>
      </ng-template>
      <span
        *ngIf="highlight; else default"
        class="convo_header__name"
        [innerHTML]="channel.displayName | highlight: highlight"
      >
      </span>
      <ng-template #default>
        <div class="convo_header__name">
          {{ channel.displayName }}
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="ChannelType.dm">
      <ng-container *ngIf="channel.nameUserDirect; else asyncUser">
        <ng-container *ngIf="channel.isBot; else teamchat">
          <mat-icon class="material-symbols-rounded">smart_toy </mat-icon>
          <div class="convo_header__name">
            {{ channel.displayName }}
          </div>
        </ng-container>
        <ng-template #teamchat>
          <ng-container *ngIf="{ isOnline: channel.directChatUsers.otherUuid | userOnline | async } as data">
            <mat-icon
              class="icon status material-symbols-rounded fill"
              [class]="data.isOnline ? 'status__online' : 'status__offline'"
            >
              {{ channel.icon }}
            </mat-icon>
            <span
              *ngIf="highlight; else default"
              class="convo_header__name"
              [innerHTML]="channel.displayName | highlight: highlight"
            >
            </span>
            <ng-template #default>
              <div class="convo_header__name">
                {{ channel.displayName }}
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #asyncUser>
        <ng-container *ngIf="channel?.directChatUsers.otherUuid | selectUser | async as directUser">
          <ng-container *ngIf="directUser.isBot; else teamchat">
            <mat-icon class="material-symbols-rounded">smart_toy </mat-icon>
            <div class="convo_header__name">
              {{ directUser.displayName }}
            </div>
          </ng-container>
          <ng-template #teamchat>
            <ng-container *ngIf="{ isOnline: directUser.userUuid | userOnline | async } as data">
              <mat-icon
                class="icon status material-symbols-rounded fill"
                [class]="data.isOnline ? 'status__online' : 'status__offline'"
              >
                {{ channel.icon }}
              </mat-icon>
              <span
                *ngIf="highlight; else default"
                class="convo_header__name"
                [innerHTML]="directUser.displayName | highlight: highlight"
              >
              </span>
              <ng-template #default>
                <div class="convo_header__name">
                  {{ directUser.displayName }}
                </div>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!isHideStarred">
      <ng-container *ngIf="[ChannelType.gc, ChannelType.dm].includes(channel.type)">
        <mat-icon
          class="icon star-channel s-24 material-symbols-rounded fill"
          *ngIf="channel.isStarred"
          (click)="unstarConvo($event); $event.stopPropagation()"
          [matTooltip]="noHoverChannelName ? 'Unstar this channel' : ''"
        >
          star
        </mat-icon>
        <mat-icon
          class="icon star-channel s-24 material-symbols-rounded"
          *ngIf="!channel.isStarred"
          (click)="starConvo($event); $event.stopPropagation()"
          [matTooltip]="noHoverChannelName ? 'Star this channel' : ''"
        >
          star_border
        </mat-icon>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
