<div *ngIf="!isEndedCall; else loadJourney" class="icon-pending expaned flex align-items-center gap-8">
  <mat-spinner [diameter]="16"></mat-spinner>
  <div>Call journey will be available after call ends.</div>
</div>
<ng-template #loadJourney>
  <ng-container *ngIf="!txnHistory; else viewJourney">
    <ng-container *ngIf="isReachMaxRetry; else waiting">
      <div class="icon-pending expaned flex align-items-center gap-8">
        <mat-icon class="icon-cancel icon s-16">cancel</mat-icon>
        <div class="container flex gap-8">
          <span>Failed to generate call journey.</span>
          <a href="javacript:void" (click)="checkEndedFromMsData(txn)">Try again</a>
        </div>
      </div>
    </ng-container>
    <ng-template #waiting>
      <div class="icon-pending expaned flex align-items-center gap-8">
        <mat-spinner [diameter]="16"></mat-spinner>
        <div class="collecting flex gap-3">
          <span>Generating call journey </span>
          <div class="loading-dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #viewJourney>
    <ng-container [ngSwitch]="txnHistory?.type">
      <ng-container *ngSwitchCase="CallType.incoming">
        <b3n-txn-incoming
          [txn]="txn"
          [txnHistory]="txnHistory"
          [isChild]="true"
          [timezone]="timezone"
        ></b3n-txn-incoming>
      </ng-container>
      <ng-container *ngSwitchCase="CallType.outgoing">
        <b3n-txn-outgoing
          [txn]="txn"
          [txnHistory]="txnHistory"
          [isChild]="true"
          [timezone]="timezone"
        ></b3n-txn-outgoing>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="icon-pending expaned flex align-items-center gap-8">
          <mat-icon class="icon-cancel icon s-16">cancel</mat-icon>
          <div>Not supported</div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>
