import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import {
  AttachmentMessageData,
  Channel,
  ChannelQuery,
  ChannelService,
  ChannelType,
  ChannelUI,
  ChatMessage,
  ChatService,
  ConfigStore,
  ConvoType,
  CopyMessageRequest,
  FilterConvoMessageRangeRequest,
  FilterConvoMessageReq,
  HistoryMessage,
  HistoryMessageQuery,
  HistoryMessageRange,
  HistoryMessageService,
  IParticipant,
  MessageBody,
  MsgType,
  NameChannelPersonal,
  PinMessageQuery,
  PinMessageRequest,
  PinMessageService,
  ReplyMessage,
  TimeService,
  UserType,
  ViewChannelSection
} from '@b3networks/api/chat';
import { TxnQuery } from '@b3networks/api/inbox';
import { RequestLeaveQuery } from '@b3networks/api/leave';
import { MeQuery, MediaService, UserQuery, UserService } from '@b3networks/api/workspace';
import { ActiveIframeService, IdleService, NetworkService, WindownActiveService } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { HashMap, guid } from '@datorama/akita';
import { Observable, Subject, filter } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MessageReceiveProcessor } from '../../../core/service/message-receive.processor';
import { PreviewHistoryMessageQuery } from '../../../core/service/preview-history-message/preview-history-message.query';
import { PreviewHistoryMessageService } from '../../../core/service/preview-history-message/preview-history-message.service';
import { MenuMessageInfo, MenuMsg, RightCLickMessage } from '../../../core/state/app-state.model';
import { AppQuery } from '../../../core/state/app.query';
import { AppService } from '../../../core/state/app.service';
import { ChatMessageComponent, ConfigMessageOption } from '../../chat-message/chat-message.component';
import { ConversationContentVirtualScrollV2Component } from '../../conversation-content-virtual-scroll/conversation-content-virtual-scroll-v2.component';
import {
  CreateDiscussionComponent,
  CreateDiscussionInput
} from '../../dialog/create-discussion/create-discussion.component';
import { DeleteMessageComponent, DeleteMessageData } from '../../dialog/delete-message/delete-message.component';
import { PinMessageComponent, PinMessageData } from '../../dialog/pin-message/pin-message.component';

@Component({
  selector: 'b3n-conversation-content',
  templateUrl: './conversation-content.component.html',
  styleUrls: ['./conversation-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationContentComponent
  extends ConversationContentVirtualScrollV2Component
  implements OnDestroy, OnChanges
{
  @Input() channel: Channel;
  @Input() initWithMessageId: string;

  configMessageOption: ConfigMessageOption = {
    useBookmark: true
  };

  mapPinMessage$: Observable<HashMap<boolean>>;
  channel$: Observable<Channel>;
  participants$: Observable<string[]>;
  viewDate$: Observable<number>;
  isViewChatSection$: Observable<boolean>;

  convoType: ConvoType;

  private _id: string;
  private _domSelected: HTMLElement;
  private _destroyTracking$ = new Subject();

  readonly ChannelType = ChannelType;

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChildren(ChatMessageComponent) chatMessageComponents: QueryList<ChatMessageComponent>;

  get id(): string {
    return this._id;
  }

  get convo(): Channel {
    return this.channel;
  }

  constructor(
    private router: Router,
    private channelQuery: ChannelQuery,
    private channelService: ChannelService,
    private requestLeaveQuery: RequestLeaveQuery,
    private toastService: ToastService,
    private userQuery: UserQuery,
    private userService: UserService,
    private mediaService: MediaService,
    private pinMessageQuery: PinMessageQuery,
    private pinMessageService: PinMessageService,
    messageQuery: HistoryMessageQuery,
    messageService: HistoryMessageService,
    chatService: ChatService,
    elr: ElementRef,
    timeService: TimeService,
    messageReceiveProcessor: MessageReceiveProcessor,
    meQuery: MeQuery,
    windownActiveService: WindownActiveService,
    txnQuery: TxnQuery,
    networkService: NetworkService,
    dialog: MatDialog,
    previewHistoryMessageService: PreviewHistoryMessageService,
    previewHistoryMessageQuery: PreviewHistoryMessageQuery,
    activeIframeService: ActiveIframeService,
    cdr: ChangeDetectorRef,
    appService: AppService,
    appQuery: AppQuery,
    idleService: IdleService,
    ngZone: NgZone
  ) {
    super(
      messageQuery,
      messageService,
      chatService,
      elr,
      timeService,
      messageReceiveProcessor,
      meQuery,
      windownActiveService,
      txnQuery,
      previewHistoryMessageService,
      previewHistoryMessageQuery,
      activeIframeService,
      dialog,
      cdr,
      appQuery,
      appService,
      idleService,
      ngZone
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel) {
      if (this.channel && this.channel.id !== this._id) {
        this._id = this.channel.id;

        this.viewDate$ = this.channelQuery.selectUIState(this.channel.id, 'viewDate');
        this.isViewChatSection$ = this.channelQuery
          .selectUIState(this._id, 'viewChannelSection')
          .pipe(map(x => x === ViewChannelSection.chat));
        this.mapPinMessage$ = this.pinMessageQuery.selectPinByChannel(this.channel.id).pipe(
          map(pins =>
            pins.reduce((a, b) => {
              a[b.messageId] = true;
              return a;
            }, <HashMap<boolean>>{})
          )
        );
        this.jumpToMessage = this.initWithMessageId;
        this.isViewLastSeen = false;
        this.configMessageOption = {
          ...this.configMessageOption,
          isBookmarkChannel: this.channel.isPersonalBookmark,
          isMyChannel: this.channel?.isMyChannel
        };
        this.onConvoChanged();

        if (this.channel.type === ChannelType.dm) {
          const user = this.userQuery.getUserByChatUuid(this.channel.directChatUsers.otherUuid);
          if (!!user && user.isTemporary) {
            this.userService.findOneByUuidAndUserTypeSequentially(user.userUuid, 'chatId').subscribe();
          }
        }

        setTimeout(() => {
          this._destroyTracking$.next(true);
          this.trackingNavigateMessage();
        }, 1000);
      }
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._destroyTracking$.next(true);
    this._destroyTracking$.complete();
  }

  override doneFetchHistory(): void {
    this.participants$ = this.channelQuery
      .selectPropertyChannel(this._id, 'participants')
      .pipe(map(ps => ps?.map(x => x.userID) || []));
  }

  onFocusQuillEditor() {
    this.appService.triggerFocusQuillEdior();
  }

  addBookmarkMessage($event: ChatMessage) {
    const bookmark = this.channelQuery.getPersonalChannel(NameChannelPersonal.BOOKMARKS);
    if (!bookmark) {
      return;
    }
    this.channelService
      .copyMessage(<CopyMessageRequest>{
        srcMessage: {
          convo: $event.channelId,
          id: $event.id,
          ct: $event.ct
        },
        dstConvoId: bookmark.id,
        options: {
          shouldDeduplicate: true
        }
      })
      .subscribe(
        () => this.toastService.success('Add to Bookmarks successfully'),
        err => this.toastService.error(err.error)
      );
  }

  onJumpToMessage($event: string) {
    this.channelService.updateNavigateToMsg(this._id, $event);
  }

  onSeeAllPinned() {
    const triggerSeeAllPinned = this.appQuery.getValue()?.triggerSeeAllPinned;
    this.appService.update({
      triggerSeeAllPinned: !triggerSeeAllPinned
    });
  }

  saveEdtingMsgId2Store(msgId: string) {
    this.updateUIState(this.id, {
      editingMessageId: msgId
    });
  }

  replyingMessage($event: ReplyMessage) {
    this.channelService.updateChannelViewState(this.id, {
      replyingMessage: $event
    });

    const state = this.appQuery.getValue();
    this.appService.update({
      quillEditor: {
        ...state.quillEditor,
        triggerfocus: !state.quillEditor.triggerfocus
      },
      triggerScrollBottomView: !state.triggerScrollBottomView
    });
  }

  removeBookmarkMessage($event: ChatMessage) {
    const msgClone = $event.extraData.linkedMessages.messageBookmark;
    if (!msgClone) {
      return;
    }
    this.channelService
      .deleteCopy(<CopyMessageRequest>{
        srcMessage: {
          convo: msgClone.channelId,
          id: msgClone.id,
          ct: msgClone.ct
        },
        dstConvoId: $event.channelId,
        options: {
          shouldDeduplicate: true
        }
      })
      .subscribe(
        () => this.toastService.success('Remove bookmark successfully'),
        err => this.toastService.error(err.error)
      );
  }

  // mention from edit message
  onMentionsChanged(mentions: string[]) {
    // const notMembers: string[] =
    //   mentions.filter(m => !this._channel.participants.some(x => x.userID === m) && m !== 'everyone') || [];
    // if (notMembers.length > 0) {
    //   const dialogRef = this.dialog.open(ConfirmInviteComponent, {
    //     data: <InputConfirmInviteDialog>{ members: notMembers, convo: this._channel }
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       const req = <UpdateChannelReq>{
    //         add: notMembers
    //       };
    //       this.channelService.addOrRemoveParticipants(this._channel.id, req).subscribe();
    //     }
    //   });
    // }
  }

  getHistories$(id: string, req: FilterConvoMessageReq): Observable<HistoryMessage> {
    return this.messageService.getChannelHistory(id, req);
  }

  getRangeHistories$(req: FilterConvoMessageRangeRequest, config: ConfigStore): Observable<HistoryMessageRange> {
    return this.messageService.getChannelRangeHistory(req, config);
  }

  selectUiState$<K extends keyof ChannelUI>(propety?: K): Observable<ChannelUI[K]> {
    return this.channelQuery.selectUIState(this.id, propety);
  }

  selectIsDisconnected$(): Observable<boolean> {
    return this.channelQuery.isDisconnected$;
  }

  isDisconnectedStore(): boolean {
    return this.channelQuery.storeIsDisconnected();
  }

  addMsgLeaveUserDM() {
    if (this.channel?.type === ChannelType.dm && !!this.channel?.directChatUsers?.otherUuid) {
      const user = this.userQuery.getUserByChatUuid(this.channel.directChatUsers.otherUuid);
      const requestLeaveNow = this.requestLeaveQuery.getEntity(user?.identityUuid)?.requestLeaveNow;
      if (requestLeaveNow) {
        const me = this.meQuery.getMe();
        const msg = ChatMessage.createMessage(
          this.channel,
          new MessageBody({
            text: `<@${user.userUuid}> is on ${requestLeaveNow.displayText}`
          }),
          me.userUuid,
          MsgType.system
        );
        msg.id = guid();
        msg.ts = (this.messageQuery.getlastestMsg(this.channel.id)[0]?.ts || new Date().getTime()) + 1;
        this.messageService.addMessage(new ChatMessage(msg));
      }
    }
  }

  selectParticipiants$(): Observable<IParticipant[]> {
    return this.channelQuery.selectPropertyChannel(this.id, 'participants');
  }

  getUIState(id: string) {
    return this.channelQuery.getChannelUiState(id);
  }

  resetUIState(id: string) {
    return this.channelService.resetChannelViewStateHistory(id);
  }

  updateUIState(id: string, newState: Partial<any>) {
    this.channelService.updateChannelViewState(id, newState);
  }

  updateChannel(id: string, newState: Partial<Channel>) {
    this.channelService.updateChannel([<Channel>{ id, ...newState }]);
  }

  focusQuillEditorApp() {
    // this.appService.triggerFocusQuillEdior();
  }

  menuClosed($event) {
    if (this._domSelected) {
      this._domSelected.style.backgroundColor = null;
    }
  }

  onRightClickMessage($event: RightCLickMessage) {
    const menu = this.elr.nativeElement.querySelector('.right-click-message-menu') as HTMLElement;
    menu.style.left = $event.xPosition + 5 + 'px';
    menu.style.top = $event.yPosition + 5 + 'px';

    if (this.matMenuTrigger) {
      // hover msg
      const dom = $event.elr.nativeElement.querySelector('.container');
      if (dom) {
        dom.style.backgroundColor = 'var(--b3n-hover)';
        this._domSelected = dom;
      }
      this.matMenuTrigger.menuData = { item: $event };
      this.matMenuTrigger.openMenu();
    }
  }

  handleRightClick(item: RightCLickMessage, menu: MenuMessageInfo) {
    item.message = this.messageQuery.getEntity(item.message.clientId);
    switch (menu.key) {
      case MenuMsg.copyText: {
        const selectedText = window.getSelection().toString().trim();
        if (selectedText?.length > 0) {
          navigator.clipboard.writeText(selectedText);
        } else if (menu.targetCopy) {
          if (menu.targetCopy) {
            navigator.clipboard.writeText(menu.targetCopy);
          }
        }
        break;
      }
      case MenuMsg.copyLinkAddress: {
        if (menu.targetLink) {
          navigator.clipboard.writeText(menu.targetLink);
        }
        break;
      }
      case MenuMsg.removePreviewLink: {
        const clone = new ChatMessage({
          ...item.message,
          body: {
            ...item.message?.body,
            data: null
          }
        });
        this.messageReceiveProcessor.pushEventMessage(clone);

        // update store
        const msg = this.messageQuery.getEntity(item.message?.id);
        if (msg) {
          this.messageService.updateMessage(clone);
        }
        const previewMsg = this.previewHistoryMessageQuery.getEntity(item.message?.id);
        if (previewMsg) {
          this.previewHistoryMessageService.updateMessage(clone);
        }
        break;
      }
      case MenuMsg.reply: {
        this.replyingMessage(menu.dataReply);
        break;
      }
      case MenuMsg.jumpFromBookmark: {
        const msgBM = item.message?.messageBookmark;
        if (msgBM) {
          if (msgBM?.ct === ConvoType.THREAD) {
            // this.router.navigate(['conversations', msgBM.convo, 'messages', msgBM.id]);
          } else {
            this.router.navigate(['conversations', msgBM.convo, 'messages', msgBM.id]);
          }
        }
        break;
      }
      // case MenuMsg.jumpReply: {
      //   this.onJumpToMessage(item.message.messageReply?.id);
      //   break;
      // }
      case MenuMsg.removeBookmark: {
        this.removeBookmarkMessage(item.message);
        break;
      }
      // case MenuMsg.addBookmark: {
      //   this.addBookmarkMessage(item.message);
      //   break;
      // }
      // case MenuMsg.copyLink: {
      //   try {
      //     const origin = !isLocalhost()
      //       ? window.parent.location.origin + `/#/${X.orgUuid}/home`
      //       : window.location.origin + '/#';
      //     this.clipboard.copy(`${origin}/conversations/${item.message.convo}/messages/${item.message.id}`);
      //     this.toastService.success('Copy link successfully!');
      //   } catch (error) {
      //     console.log('🚀 ~ error', error);
      //   }
      //   break;
      // }
      case MenuMsg.editMessage: {
        const find = this.chatMessageComponents.find(x => x.message.id === item.message.id);
        if (find) {
          find.edit();
        }
        break;
      }
      case MenuMsg.deleteMessage: {
        if (item.message.metadata?.deletedAt > 0 || item.message.user !== this.meQuery.getMe().userUuid) {
          return;
        }

        this.dialog
          .open(DeleteMessageComponent, {
            width: '600px',
            data: <DeleteMessageData>{
              message: item.message,
              isPinned: menu.deleteMessage?.isPinned
            }
          })
          .afterClosed()
          .subscribe(res => {
            if (res && res.isConfirm) {
              const message = ChatMessage.createDeleteMessage(item.message);
              this.chatService.send(message);

              if (item.message?.mt == MsgType.attachment) {
                const data: AttachmentMessageData = item.message.body.data?.attachment || item.message.body.data;
                if (data && data.mediaId) {
                  this.mediaService.deleteMediaFromStorage(data.mediaId, item.message.channelId).subscribe();
                }
              }

              if (menu?.deleteMessage?.isPinned) {
                this.pinMessageService
                  .unpinMessage(<PinMessageRequest>{
                    convoId: item.message.channelId,
                    convoType: item.message.ct,
                    userType: UserType.TeamMember,
                    messageId: item.message.id
                  })
                  .subscribe(
                    () => {
                      this.toastService.success(`Unpin message successfully!`);
                    },
                    err => this.toastService.error(err?.message)
                  );
              }
            }
            this.appService.triggerFocusQuillEdior();
          });
        break;
      }

      case MenuMsg.createThread: {
        this.dialog.open(CreateDiscussionComponent, {
          data: <CreateDiscussionInput>{
            message: item.message
          },
          width: '700px',
          disableClose: true
        });
        break;
      }
      case MenuMsg.unpinMsg:
      case MenuMsg.pinMsg: {
        this.dialog
          .open(PinMessageComponent, {
            data: <PinMessageData>{
              message: item.message,
              isPin: menu.key === MenuMsg.pinMsg
            },
            width: '600px'
          })
          .afterClosed()
          .subscribe();
        break;
      }

      default:
        break;
    }
  }

  private trackingNavigateMessage() {
    this.channelQuery
      .selectUIState(this.id, 'jumpMessageId')
      .pipe(
        takeUntil(this._destroyTracking$),
        filter(msgId => msgId != null)
      )
      .subscribe(msgId => {
        console.log('msgId: ', msgId);
        if (msgId) {
          this.jumpToMessage = msgId;
          this.onConvoChanged();
        }
      });
  }
}
