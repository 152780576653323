import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { RequestGetAllComment } from './history-txn.model';
import { HistoryTxnQuery } from './history-txn.query';
import { HistoryTxnStore } from './history-txn.store';

@Injectable({
  providedIn: 'root'
})
export class HistoryTxnService {
  constructor(private http: HttpClient, private store: HistoryTxnStore, private query: HistoryTxnQuery) {}

  allComments(orgUuid: string, txnUuid: string, req: RequestGetAllComment) {
    return this.http.post<any[]>(`inbox/private/v2/txn/${orgUuid}/${txnUuid}/comments/public`, req).pipe(
      tap(responses => {
        console.log('responses: ', responses);
      })
    );
  }
}
