import { Pipe, PipeTransform } from '@angular/core';
import { Channel, ChannelQuery, ChannelType } from '@b3networks/api/chat';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'selectChannel'
})
export class SelectChannelPipe implements PipeTransform {
  constructor(private channelQuery: ChannelQuery) {}

  transform(channelId: string) {
    if (!channelId) {
      return of(
        new Channel(<Channel>{
          type: ChannelType.gc,
          name: 'Unknown channel'
        })
      );
    }
    return this.channelQuery.selectChannel(channelId).pipe(
      map(
        c =>
          c ||
          new Channel(<Channel>{
            type: ChannelType.gc,
            name: 'Unknown channel'
          })
      )
    );
  }
}
