import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Extension } from '@b3networks/api/bizphone';
import { CallManagement, WebrtcQuery, WebrtcService } from '@b3networks/api/call';
import { MeQuery as CCMeQuery, DelegatedCallerId, ExtensionQuery } from '@b3networks/api/callcenter';
import { Contact } from '@b3networks/api/contact';
import { MeQuery } from '@b3networks/api/workspace';
import { DestroySubscriberComponent, NetworkService } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ManagePhoneDialogComponent } from '../manage-phone-dialog/manage-phone-dialog.component';

@Component({
  selector: 'b3n-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class CallComponent extends DestroySubscriberComponent implements OnInit {
  @Input() isDialog: boolean;
  @Input() closeDialogAfterCall = true;

  extension$: Observable<Extension>;
  delegatedCallerIds$: Observable<DelegatedCallerId[]>;
  callManagement$: Observable<CallManagement>;
  callStatusLabel$: Observable<string>;

  callerId: string;
  isManagePhoneOpening: boolean;

  @Output() closeDialog = new EventEmitter<any>();

  constructor(
    private meQuery: MeQuery,
    private networkService: NetworkService,
    private toastService: ToastService,
    private meCallcenterQuery: CCMeQuery,
    private extensionQuery: ExtensionQuery,
    private webrtcService: WebrtcService,
    private dialog: MatDialog,
    private webrtcQuery: WebrtcQuery,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.extension$ = this.extensionQuery.me$.pipe(
      tap(extension => {
        if (extension) {
          this.callerId = extension.callerId;
        }
      })
    );

    this.delegatedCallerIds$ = this.meCallcenterQuery.extensionKey$.pipe(
      filter(ext => !!ext),
      switchMap(extKey => this.extensionQuery.selectDelegatedCallerIdsForExt(extKey))
    );

    this.callManagement$ = this.webrtcQuery.session$.pipe(
      switchMap(session => (session ? this.webrtcQuery.callManagement$ : of(null))),
      tap(call => {
        if (call) {
          this.callStatusLabel$ = this._getCallStatusLabelObs(call);
        }
      })
    );

    this.webrtcQuery.isManagePhoneOpening$
      .pipe(
        takeUntil(this.destroySubscriber$),
        distinctUntilChanged(),
        tap(isOpening => {
          this.isManagePhoneOpening = isOpening;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.webrtcQuery.callDurationChanged$
      .pipe(
        takeUntil(this.destroySubscriber$),
        tap(_ => {
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  openManagePhoneDialog() {
    this.webrtcQuery.isManagePhoneOpening$.next(true);
    this.dialog
      .open(ManagePhoneDialogComponent, {
        data: {},
        minWidth: '350px',
        maxWidth: '800px',
        disableClose: true,
        height: '600px',
        panelClass: 'manage-call'
      })
      .afterClosed()
      .subscribe(_ => {
        const isOpenDialog = this.dialog?.openDialogs?.some(
          d => d.componentInstance instanceof ManagePhoneDialogComponent
        );

        if (!isOpenDialog) {
          this.webrtcQuery.isManagePhoneOpening$.next(false);
        }
      });
  }

  makeCallTo(number: string) {
    if (!this.webrtcQuery.UA?.isRegistered()) {
      this.toastService.error(
        'Sorry, an error has occurred when we try to fulfill your request. Please try again in a few minutes.'
      );
      return;
    }

    if (this.webrtcQuery.isBusy) {
      this.toastService.error('You are on a call process.');
      return;
    }

    if (!this.networkService.isOnline) {
      this.toastService.warning(
        "Your computer seems to be offline. We'll keep trying to reconnect, or you can try refresh your browser",
        10e3
      );
      return;
    }

    let text = number.trim();
    if (text.startsWith('+')) {
      text = text.slice(1, text.length);
    }

    this.webrtcService.makeCallOutgoing(
      number,
      new Contact({
        displayName: `+${text}`
      })
    );

    this.ngZone.run(() => {
      this.closeDialog && this.closeDialog.emit();
    });
  }

  private _getCallStatusLabelObs(call: CallManagement): Observable<string> {
    if (call.isRemote) {
      const extKeyOrNumber = this.webrtcQuery?.session?.remote_identity?.uri?.user;

      try {
        if (Number(extKeyOrNumber)) {
          const number = Number(extKeyOrNumber);
          const remoteExtIdentity = number + '';
          if (remoteExtIdentity) {
            if (remoteExtIdentity.length > 8) {
              // phone
              return of(remoteExtIdentity);
            } else {
              // ext
              return this.extensionQuery.selectEntity(remoteExtIdentity, 'displayText').pipe(
                map(ext => {
                  return ext ? ext : '#' + remoteExtIdentity;
                })
              );
            }
          }
        } else {
          return of(extKeyOrNumber);
        }
      } catch (er) {
        return of(null);
      }

      return of(null);
    } else {
      let displayName = call?.member?.displayName;

      if (displayName) {
        const ext = this.extensionQuery.getExtensionByKey(call?.member?.displayName.replace('+', ''));

        if (ext) {
          displayName = `${ext.extLabel} (#${ext.extKey})`;
        }
      }

      return of(displayName);
    }
  }
}
