const PER_PAGE_CHILDREN = 5;
export const PER_PAGE_LEG = 50;

export class BlockJourney {
  id: string;
  time: number;
  title: string[]; // html
  isFlowBlockAA: boolean;
  hasRecording: boolean;

  // style icon
  sgvIcon?: string;
  icon?: string;
  fontSizeIcon = 18;
  isOutline = false;
  colorIcon = '#000000';
  backgroundIcon = '#E0FAD9';

  parent?: string;
  children?: BlockJourney[] = [];
  isExpanded: boolean;
  expandedWithCount = PER_PAGE_CHILDREN; // -1 is all

  txnChild: string; // txn child

  navigateTxnUuid: {
    label?: string; // default 'Go to TXN'
    txnUuid: string;
  };

  get hasChildren() {
    return this.children?.length > 0;
  }

  get hasMore() {
    return this.children?.length > 0 && this.expandedWithCount !== -1 && this.expandedWithCount < this.children?.length;
  }

  constructor(obj?: Partial<BlockJourney>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj.isFlowBlockAA) {
        this.expandedWithCount = -1;
      }

      if (obj.children) {
        this.children = obj.children?.map(x => new BlockJourney(x));
      }
    }
  }

  loadMoreChildren() {
    if (this.expandedWithCount !== -1) {
      this.expandedWithCount += PER_PAGE_CHILDREN;
    }
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  addChild(block: BlockJourney) {
    this.children.push(block);
  }

  addChildren(blocks: BlockJourney[]) {
    this.children = [...this.children, ...blocks];
  }
}

export interface TitleStyle {}
