import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IdentityProfileQuery } from '@b3networks/api/auth';
import { Pageable } from '@b3networks/api/common';
import { ContactService } from '@b3networks/api/contact';
import { CallType, GetReportV4Payload, Period, ReportV4Code, TxnEndInbox, V4Service } from '@b3networks/api/data';
import { Txn, TxnQuery, TxnService } from '@b3networks/api/inbox';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { addMinutes, subMinutes } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { catchError, filter, map, of, take, takeUntil } from 'rxjs';
import { BlockJourney } from '../../../model/journey-block.model';

@Component({
  selector: 'b3n-txn-child',
  templateUrl: './txn-child.component.html',
  styleUrls: ['./txn-child.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TxnChildComponent extends DestroySubscriberComponent implements OnInit {
  @Input() count: number;

  // for children
  @Input() parent: BlockJourney;

  txn: Txn;
  timezone: string;
  isEndedCall: boolean;
  isReachMaxRetry: boolean;
  txnHistory: TxnEndInbox;

  readonly CallType = CallType;

  constructor(
    private txnQuery: TxnQuery,
    private txnService: TxnService,
    private contactService: ContactService,
    private v4Service: V4Service,
    private profileQuery: IdentityProfileQuery,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const txn = this.txnQuery.getTxn(this.parent.txnChild);
    if (!txn) {
      this.txnService
        .getDetailTxnV2(this.parent.txnChild)
        .pipe(
          catchError(() => of(null)),
          map(data => {
            if (data.contact) {
              this.contactService.updateContacts2Store([data.contact]);
            }
            return data.txn;
          })
        )
        .subscribe();
    }

    this.timezone = this.profileQuery.currentOrg.utcOffset;

    this.isEndedCall = false;
    this.txnQuery
      .selectEntity$(this.parent.txnChild)
      .pipe(
        filter(txn => txn?.isClosedV2),
        take(1),
        takeUntil(this.destroySubscriber$)
      )
      .subscribe(txn => {
        this.txn = txn;
        this.isEndedCall = true;
        this.cdr.markForCheck();
        this.checkEndedFromMsData(txn);
      });
  }

  checkEndedFromMsData(txn: Txn, index = 0) {
    if (index > 10) {
      this.isReachMaxRetry = true;
      this.cdr.markForCheck();
      return;
    }

    if (index === 0) {
      this.isReachMaxRetry = false;
    }

    const req = <GetReportV4Payload>{
      startTime: format(
        subMinutes(utcToZonedTime(new Date(txn.createdAt), this.timezone), 30),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      endTime: format(
        addMinutes(utcToZonedTime(new Date(txn?.endedAt || Date.now()), this.timezone), 30),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      filter: {
        txnState: 'ended',
        txnUuid: txn.txnUuid
      }
    };

    this.txnHistory = null;
    this.v4Service
      .getReportData<TxnEndInbox>(Period.dump, ReportV4Code.txnJourney.txn, req, new Pageable(1, 5), false)
      .subscribe(
        data => {
          this.txnHistory = data.rows?.map(item => new TxnEndInbox(item))?.find(x => x.txnUuid === txn.txnUuid);

          if (!this.txnHistory) {
            setTimeout(() => {
              index++;
              this.checkEndedFromMsData(txn, index);
            }, 5000);
          }
          this.cdr.markForCheck();
        },
        err => {
          if (!this.txnHistory) {
            setTimeout(() => {
              index += 2;
              this.checkEndedFromMsData(txn, index);
            }, 5000);
          }
        }
      );
  }
}
