import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Channel, ChannelService, ChatService, ViewChannelSection } from '@b3networks/api/chat';
import { CacheMediaQuery } from '@b3networks/api/common';
import { FileService, RequestUploadData } from '@b3networks/api/file';
import { FileDetail, MediaService } from '@b3networks/api/workspace';
import { X, download, downloadData, fileImage, getFileExtension, getFileType } from '@b3networks/shared/common';
import { guid } from '@datorama/akita';
import { of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { MenuMessageInfo, MenuMsg, RightCLickMessage } from '../../../../core/state/app-state.model';

@Component({
  selector: 'b3n-conversation-files-thumbnail',
  templateUrl: './conversation-files-thumbnail.component.html',
  styleUrls: ['./conversation-files-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationFilesThumbnailComponent implements OnInit {
  @Input() file: FileDetail;
  @Input() channel: Channel;
  @Input() parentElr?: any; // use intersection to lazy loading image;

  @Output() closeDialog = new EventEmitter();
  @Output() rightClickMessage = new EventEmitter<RightCLickMessage>();

  isImgFile: boolean;
  logoFileType: string;
  progressing = false;
  noIntersectionObserver = true;
  backgroundImage: string;
  loaded: boolean;

  uuid = guid();

  constructor(
    public mediaService: MediaService,
    private fileService: FileService,
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private channelService: ChannelService,
    private cacheMediaQuery: CacheMediaQuery
  ) {}

  ngOnInit() {
    this.detectFile();
    if (this.isImgFile && this.file?.uri) {
      let keyMedia: string, isStorage: boolean;
      if (this.file.uri.startsWith('storage://')) {
        keyMedia = this.file.uri.replace('storage://', '');
        isStorage = true;
      } else if (this.file.uri.startsWith('legacy://')) {
        keyMedia = this.file.uri.replace('legacy://', '');
      } else if (this.file?.mediaId) {
        keyMedia = this.file?.mediaId;
      }

      const url = this.cacheMediaQuery.getMediaByKey(keyMedia, isStorage)?.url;
      this.noIntersectionObserver = !!url;
      this.backgroundImage = url ? `url('${url}')` : null;
      this.cdr.markForCheck();
    } else {
      this.backgroundImage = `url('${this.logoFileType}')`;
    }
  }

  onRightClick(event: MouseEvent) {
    const data = <RightCLickMessage>{
      uuid: this.uuid,
      xPosition: event.clientX,
      yPosition: event.clientY,
      menus: [
        <MenuMessageInfo>{
          key: MenuMsg.showInChat,
          value: 'Show in chat',
          icon: 'move_up',
          order: 1
        },
        <MenuMessageInfo>{
          key: MenuMsg.downloadFile,
          value: 'Download',
          icon: 'save_alt',
          order: 2
        }
      ]
    };

    event.preventDefault();
    data.menus = [...data.menus].sort((a, b) => a.order - b.order);
    this.rightClickMessage.emit(data);
  }

  onRenderImg(index = 0) {
    if (index > 8) {
      // stop
      return;
    }

    if (this.file.uri.startsWith('storage://')) {
      const keyWithOrgUuid = this.file.uri.replace('storage://', '');
      const session = this.chatService.state?.session;
      this.fileService
        .getThumbnailMediaStorageUuid(keyWithOrgUuid, <RequestUploadData>{
          chatUserId: session.chatUser,
          orgUuid: X.orgUuid,
          wssToken: session.token,
          chatServer: session.addr,
          convoType: this.channel.type
        })
        .pipe(
          finalize(() => {
            this.loaded = true;
            this.cdr.markForCheck();
          })
        )
        .subscribe(
          url => {
            this.backgroundImage = url ? `url('${url}')` : null;
            this.cdr.markForCheck();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 423) {
              setTimeout(() => {
                this.onRenderImg(index + 1);
              }, 2000);
            }
          }
        );
    } else if (this.file.uri.startsWith('legacy://')) {
      const mediaUuid = this.file.uri.replace('legacy://', '');
      this.mediaService
        .getMediaImgThumbnail(mediaUuid, this.channel.id)
        .pipe(
          finalize(() => {
            this.loaded = true;
            this.cdr.markForCheck();
          })
        )
        .subscribe(res => {
          this.backgroundImage = res?.url ? `url('${res?.url}')` : null;
          this.cdr.markForCheck();
        });
    } else if (this.file?.mediaId) {
      this.mediaService
        .getMediaImgThumbnail(this.file.mediaId, this.channel.id)
        ?.pipe(
          finalize(() => {
            this.loaded = true;
            this.cdr.markForCheck();
          })
        )
        ?.subscribe(res => {
          this.backgroundImage = res?.url ? `url('${res?.url}')` : null;
          this.cdr.markForCheck();
        });
    }
  }

  jumpToMessage($event?: Event) {
    $event?.stopPropagation();
    $event?.preventDefault();
    this.channelService.updateChannelViewState(this.channel.id, {
      viewChannelSection: ViewChannelSection.chat
    });

    setTimeout(() => {
      this.channelService.updateNavigateToMsg(this.channel.id, this.file.msgId);
    }, 200);
  }

  zoom($event) {
    $event.stopPropagation();
    if (this.isImgFile) {
      let originalImg$ = of<string>(null);
      let isRevokeUrl: boolean;
      if (this.file?.uri.startsWith('storage://')) {
        const keyWithOrgUuid = this.file.uri.replace('storage://', '');
        originalImg$ = this.fileService.downloadFileV3(keyWithOrgUuid).pipe(
          map(resp => {
            const file = new Blob([new Uint8Array(resp.body)], {
              type: `${resp.headers.get('content-type')}`
            });
            isRevokeUrl = true;
            return URL.createObjectURL(file);
          })
        );
      } else if (this.file?.uri.startsWith('legacy://')) {
        const mediaUuid = this.file.uri.replace('legacy://', '');
        originalImg$ = this.mediaService.getMediaImgOriginal(mediaUuid, this.channel.id).pipe(map(resp => resp?.url));
      } else if (this.file?.mediaId) {
        originalImg$ = this.mediaService
          .getMediaImgOriginal(this.file.mediaId, this.channel.id)
          .pipe(map(resp => resp?.url));
      }
      originalImg$?.subscribe(url => {
        const image = new Image();
        image.src = url;
        const viewer = new Viewer(image, {
          hidden: () => {
            if (isRevokeUrl) {
              URL.revokeObjectURL(url);
            }
            viewer.destroy();
          },
          toolbar: {
            zoomIn: 4,
            zoomOut: 4,
            oneToOne: 4,
            reset: 4,
            rotateLeft: 4,
            rotateRight: 4,
            flipHorizontal: 4,
            flipVertical: 4
          }
        });
        viewer.show();
      });
    }
  }

  download($event?: Event) {
    $event?.stopPropagation();

    if (this.file?.uri) {
      this.progressing = true;
      if (this.file.uri.startsWith('storage://')) {
        const keyWithOrgUuid = this.file.uri.replace('storage://', '');
        this.fileService
          .downloadFileV3(keyWithOrgUuid)
          .pipe(
            finalize(() => {
              this.progressing = false;
              this.cdr.markForCheck();
            })
          )
          .subscribe(resp => {
            const file = new Blob([new Uint8Array(resp.body)], {
              type: `${resp.headers.get('content-type')}`
            });
            downloadData(file, this.file.name);
          });
      } else if (this.file.uri.startsWith('legacy://')) {
        const mediaUuid = this.file.uri.replace('legacy://', '');
        this.mediaService
          .getMediaImgOriginal(mediaUuid, this.channel.id)
          .pipe(
            finalize(() => {
              this.progressing = false;
              this.cdr.markForCheck();
            })
          )
          .subscribe(res => {
            download(res['url'], this.file.name);
          });
      }
    }
  }

  private detectFile() {
    const fileType = this.getFileType();

    if (!fileType) {
      return false;
    }

    this.isImgFile = fileImage.indexOf(fileType) > -1;
    this.logoFileType = getFileType(fileType);
    return true;
  }

  private getFileType() {
    let fileType;

    if (this.file.fileType) {
      fileType = this.file.fileType?.toLowerCase().split('/')?.pop();
    }

    if (!fileType) {
      fileType = getFileExtension(this.file?.name);
    }

    return fileType;
  }
}
