<div class="container flex column">
  <ng-container [ngSwitch]="system.type">
    <ng-container *ngSwitchCase="SystemMsgType.pin">
      <span>
        <ng-container *ngIf="message.user | selectUser | async as user"> {{ user.displayName }} </ng-container>pinned
        <a (click)="jumpToMsg($event, system?.pin?.messageId)">a message</a> to this channel. See all
        <a (click)="openAllPinned($event)">pinned messsages.</a>
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="SystemMsgType.unpin">
      <span>
        <ng-container *ngIf="message.user | selectUser | async as user"> {{ user.displayName }} </ng-container>unpinned
        <a (click)="jumpToMsg($event, system?.unpin?.messageId)">a message</a> to this channel. See all
        <a (click)="openAllPinned($event)">pinned messsages.</a>
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="red">Error, please report!. messageId is {{ message.id }} </span>
    </ng-container>
  </ng-container>
</div>
