<div class="main-text">
  <ng-container *ngIf="message.state.isErrorFormat; else load">
    <span class="normal-text red-fg" [innerHTML]="message.body?.text | safe: 'html'"></span>
  </ng-container>
  <ng-template #load>
    <csh-interactive-loop
      [messageId]="message.id"
      [components]="firstComponents"
      [parent]="null"
      (showProfile)="showProfile.emit($event)"
    ></csh-interactive-loop>
  </ng-template>
</div>
