<ng-container *ngIf="channel">
  <ng-container *ngIf="isApprovalBot$ | async; else personalChannel">
    <div class="convo__footer approvalBot flex justify-content-center align-items-center gap-16">
      <button mat-flat-button color="primary" [loading]="isStarting" (click)="newRequest()">New request</button>
    </div>
  </ng-container>

  <ng-template #personalChannel>
    <ng-container *ngIf="channel?.isPersonalChat; else normalChannel">
      <div class="convo__footer personalChannel"></div>
    </ng-container>
  </ng-template>

  <ng-template #normalChannel>
    <ng-container *ngIf="channel.isArchived; else closedChannel">
      <div class="convo__footer notMember flex justify-content-center align-items-center gap-8">
        <div class="warning-label flex justify-content-center align-items-center gap-5">
          <ng-container *ngIf="channel.archivedBy | selectUser | async as archivedBy">
            <pre class="message_mention">@{{ archivedBy?.displayName }}</pre>
          </ng-container>
          <span> has archived this channel</span>
        </div>
      </div>
    </ng-container>
    <ng-template #closedChannel>
      <ng-container *ngIf="channel.isClosed; else openChannel">
        <div class="convo__footer notMember flex justify-content-center align-items-center gap-8">
          <div class="warning-label flex justify-content-center align-items-center gap-5">
            <ng-container *ngIf="channel.closedL2?.by; else systemClose">
              <ng-container *ngIf="channel.closedL2.by | selectUser | async as closedBy">
                <pre class="message_mention">@{{ closedBy?.displayName }}</pre>
              </ng-container>
            </ng-container>
            <ng-template #systemClose>
              <span>System</span>
            </ng-template>
            <span> has closed this channel</span>
          </div>
        </div>
      </ng-container>
      <ng-template #openChannel>
        <ng-container *ngIf="channel.isMember || channel.isThreadChat; else noMemberChannel">
          <div
            class="quill channel"
            *ngIf="{ replyingMessage: replyingMessage$ | async } as data"
            [class.hasReply]="data?.replyingMessage"
          >
            <div class="flex column">
              <div
                *ngIf="userTypings$ | async as userTypings"
                class="user-typing"
                [style.visibility]="userTypings?.length > 0 ? 'visible' : 'hidden'"
              >
                <span class="user">{{ userTypings | userTyping }}</span>
                {{ userTypings?.length > 1 ? 'are' : 'is' }} typing
              </div>

              <div *ngIf="!!data?.replyingMessage" class="reply-msg flex justify-content-start align-items-center">
                <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start none">
                  <div>
                    Replying to
                    <pre>@{{ data.replyingMessage.user }}</pre>
                  </div>
                  <div class="reply-msg_text">{{ data.replyingMessage.text }}</div>
                </div>
                <div>
                  <mat-icon class="cursor-pointer icon material-symbols-rounded" (click)="removeReply()">clear</mat-icon>
                </div>
              </div>
              <div class="flex justify-content-space-between align-items-center gap-8">
                <csh-quill-editor
                  [data]="quillEditorData"
                  (messaged)="handleEnterMessage($event)"
                  [convoType]="channel.convoType"
                  [convoId]="channel.id"
                  (uploadedFiles)="onUploadFile($event)"
                  (textChanged)="onTextChanged($event)"
                  (enterEditLastMessage)="handleEditLastMessage($event)"
                ></csh-quill-editor>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #noMemberChannel>
          <div class="convo__footer notMember flex justify-content-center align-items-center gap-8">
            <div class="warning-label flex justify-content-center align-items-center gap-8">
              <span>You are viewing</span>
              <button mat-raised-button color="primary" (click)="joinConvo()">Join channel</button>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-container>
