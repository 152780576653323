import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BlockJourney } from '../../../model/journey-block.model';

@Component({
  selector: 'b3n-txn-journey-block',
  templateUrl: './txn-journey-block.component.html',
  styleUrls: ['./txn-journey-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TxnJourneyBlockComponent {
  @Input() blocks: BlockJourney[];
  @Input() count: number;
  @Input() isChild: boolean; // child is txn

  // for children
  @Input() parent: BlockJourney;

  constructor(private router: Router) {}

  trackBy(_, item: BlockJourney) {
    return item?.id;
  }

  goToTxn(txnUuid: string) {
    this.router.navigate(['txn', txnUuid]);
  }
}
