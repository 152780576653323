import { ChangeDetectionStrategy, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatMessage } from '@b3networks/api/chat';
import { ConfigMessageOption } from '../../chat-message/chat-message.component';

export interface DeleteMessageData {
  message: ChatMessage;
  isPinned: boolean;
}

@Component({
  selector: 'csh-delete-message',
  templateUrl: './delete-message.component.html',
  styleUrls: ['./delete-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteMessageComponent implements OnInit {
  configMessageOption: ConfigMessageOption = {
    isHideAction: true,
    noHoverAffect: true
  };

  constructor(
    private dialogRef: MatDialogRef<DeleteMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteMessageData,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  delete() {
    this.ngZone.run(() => {
      this.dialogRef.close({ isConfirm: true });
    });
  }
}
