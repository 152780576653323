import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ChatMessage, TimeService, UserType } from '@b3networks/api/chat';
import { ConversationGroupQuery, HyperspaceQuery, Integration, User, UserQuery } from '@b3networks/api/workspace';
import { DestroySubscriberComponent, UNKNOWN_USER } from '@b3networks/shared/common';
import { Observable, map, tap } from 'rxjs';
import { InfoShowMention } from '../../../core/state/app-state.model';

@Component({
  selector: 'csh-reply-message',
  templateUrl: './reply-message.component.html',
  styleUrls: ['./reply-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplyMessageComponent extends DestroySubscriberComponent implements OnChanges {
  @Input() msgReply: ChatMessage;
  @Input() participants: string[];
  @Input() parentElr?: HTMLElement;

  @Output() jumpToMessage = new EventEmitter<string>();
  @Output() showProfile: EventEmitter<InfoShowMention> = new EventEmitter<InfoShowMention>();

  user$: Observable<User | Integration>;
  user: User | Integration;

  private _preUser: string;

  readonly datePipeEn: DatePipe = new DatePipe('en-SG');

  constructor(
    public timeService: TimeService,
    private userQuery: UserQuery,
    private conversationGroupQuery: ConversationGroupQuery,
    private hyperspaceQuery: HyperspaceQuery
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['msgReply']) {
      this.selectUser();
    }
  }

  navigateMessage($event: MouseEvent, replyMessage: ChatMessage) {
    $event.stopPropagation();
    $event.preventDefault();
    this.jumpToMessage.emit(replyMessage.id);
  }

  private selectUser() {
    if (this.msgReply?.user === this._preUser) {
      return;
    }
    this._preUser = this.msgReply.user;

    if (this.msgReply.ut === UserType.Customer) {
      const conversion = this.conversationGroupQuery.getConvo(this.msgReply.channelId);
      this.user = conversion?.customerInfo?.name ? new User({ displayName: conversion.customerInfo.name }) : null;
      if (!this.user) {
        this.user$ = this.conversationGroupQuery.selectConvo(this.msgReply.channelId).pipe(
          map(convo => new User({ displayName: convo?.customerInfo?.name || UNKNOWN_USER })),
          tap(user => (this.user = user))
        );
      }
    } else if (this.msgReply.ut === UserType.Webhook || this.msgReply.ut === UserType.TeamBot) {
      this.user = this.userQuery.getUserByChatUuid(this.msgReply.user);
      if (!this.user) {
        this.user$ = this.userQuery.selectUserByChatUuid(this.msgReply.user).pipe(
          map(
            user =>
              user ||
              new User({
                displayName: 'Unknown Bot'
              })
          ),
          tap(user => (this.user = user))
        );
      }
    } else {
      if (this.msgReply.hs) {
        const hs = this.hyperspaceQuery.getHyperByHyperspaceId(this.msgReply.hs);
        this.user = hs?.allMembers?.find(x => x.userUuid === this.msgReply.user);
        if (!this.user) {
          this.user$ = this.hyperspaceQuery.selectHyperByHyperspaceId(this.msgReply.hs).pipe(
            map(
              hyper =>
                hyper?.allMembers?.find(x => x.userUuid === this.msgReply.user) ||
                new User({ displayName: 'Disabled User' })
            ),
            tap(user => (this.user = user))
          );
        }
      } else {
        this.user = this.userQuery.getUserByChatUuid(this.msgReply.user);
        if (!this.user) {
          this.user$ = this.userQuery.selectUserByChatUuid(this.msgReply.user).pipe(
            map(x => x || new User({ displayName: 'Unknown User' })),
            tap(user => (this.user = user))
          );
        }
      }
    }
  }
}
