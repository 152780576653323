import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { User } from '@b3networks/api/workspace';
import { SupportedConvo } from './../../core/adapter/convo-helper.service';

@Component({
  selector: 'csh-render-member-menu',
  templateUrl: './render-member-menu.component.html',
  styleUrls: ['./render-member-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderMemberMenuComponent {
  @ViewChild('profileMenu', { static: true }) menu: MatMenu;

  @Input() member: User;
  @Input() convo: SupportedConvo; // optional to render action blocks
  @Input() isDialog: boolean;

  constructor() {}
}
