<h1 mat-dialog-title>
  <div class="dialog-title">Edit {{ data.isThread ? 'Discussion' : 'Channel' }} Name</div>
</h1>

<div mat-dialog-content>
  <mat-form-field fxFill>
    <mat-label>Name</mat-label>
    <input
      matInput
      placeholder="Enter name"
      [formControl]="textField"
      (keydown.enter)="submit()"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="textField.hasError('required')">
      Name {{ data.isThread ? 'discussion' : 'channel' }} is required
    </mat-error>
    <mat-error *ngIf="textField.hasError('maxlength')"> Maximum 30 characters </mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions class="flex justify-content-end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button [loading]="saving" [disabled]="textField.invalid" color="primary" (click)="submit()">
    Rename
  </button>
</div>
