import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChatSharedCoreModule } from '@b3networks/chat/shared/core';
import { ChatSharedLivechatModule } from '@b3networks/chat/shared/livechat';
import { ChatSharedWhatsappModule } from '@b3networks/chat/shared/whatsapp';
import { CommsSharedModule } from '@b3networks/comms/shared';
import { SharedAuthModule } from '@b3networks/shared/auth';
import { SharedCommonModule } from '@b3networks/shared/common';
import { ButtonLoadingModule, SharedUiMaterialModule } from '@b3networks/shared/ui/material';
import { SharedUiPortalModule } from '@b3networks/shared/ui/portal';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ChipViewComponent } from 'libs/shared/ui/standalone/src/lib/component/chip/chip.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { UiScrollModule } from 'ngx-ui-scroll';
import { CaseActivitiesComponent } from './component/case-detail/case-activities/case-activities.component';
import { CaseActivityComponent } from './component/case-detail/case-activities/case-activity/case-activity.component';
import { NewCommentComponent } from './component/case-detail/case-activities/new-comment/new-comment.component';
import { CaseRelatedComponent } from './component/case-detail/case-related/case-related.component';
import { SelectOwnerOrgTicketComponent } from './component/dialog/select-owner-org-ticket/select-owner-org-ticket.component';
import { TxnCallCustomerComponent } from './component/dialog/txn-call-customer/txn-call-customer.component';
import { EditorComponent } from './component/editor/editor.component';
import { StoreCaseComponent } from './component/store-case/store-case.component';
import { TxnCallComponent } from './component/txn-call/txn-call.component';
import { TxnChildComponent } from './component/txn-call/txn-child/txn-child.component';
import { TxnIncomingGeneratingComponent } from './component/txn-call/txn-incoming/txn-incoming-generating/txn-incoming-generating.component';
import { TxnIncomingComponent } from './component/txn-call/txn-incoming/txn-incoming.component';
import { TxnJourneyBlockComponent } from './component/txn-call/txn-journey-block/txn-journey-block.component';
import { TxnOutgoingGeneratingComponent } from './component/txn-call/txn-outgoing/txn-outgoing-generating/txn-outgoing-generating.component';
import { TxnOutgoingComponent } from './component/txn-call/txn-outgoing/txn-outgoing.component';
import { TxnProgressingCallComponent } from './component/txn-call/txn-progressing-call/txn-progressing-call.component';
import { TxnContentWhatsappComponent } from './component/txn-content-whatsapp/txn-content-whatsapp.component';
import { TxnContentComponent } from './component/txn-content/txn-content.component';
import { TxnActionsComponent } from './component/txn-details/txn-actions/txn-actions.component';
import { TxnBaseInfoComponent } from './component/txn-details/txn-base-info/txn-base-info.component';
import { TxnVerifiedComponent } from './component/txn-details/txn-base-info/txn-verified/txn-verified.component';
import { TxnDetailsCallComponent } from './component/txn-details/txn-details-call/txn-details-call.component';
import { TxnDetailsSupportCenterComponent } from './component/txn-details/txn-details-support-center/txn-details-support-center.component';
import { TxnDetailsWhatsappComponent } from './component/txn-details/txn-details-whatsapp/txn-details-whatsapp.component';
import { TxnDetailsComponent } from './component/txn-details/txn-details.component';
import { TxnDeviceInfoComponent } from './component/txn-details/txn-device-info/txn-device-info.component';
import { SearchMemberComponent } from './component/txn-details/txn-information/search-member/search-member.component';
import { TxnInformationComponent } from './component/txn-details/txn-information/txn-information.component';
import { TxnRelatedToComponent } from './component/txn-details/txn-related-to/txn-related-to.component';
import { TxnFooterComponent } from './component/txn-footer/txn-footer.component';
import { TxnManageContactComponent } from './component/txn-manage-contact/txn-manage-contact.component';
import { TxnSupportCenterComponent } from './component/txn-support-center/txn-support-center.component';
import { TxnToastSupportCenterComponent } from './component/txn-support-center/txn-toast-support-center/txn-toast-support-center.component';
import { TxnTransferTransactionComponent } from './component/txn-transfer-transaction/txn-transfer-transaction.component';
import { TxnViewDateComponent } from './component/txn-view-date/txn-view-date.component';
import { UploadDialogComponent } from './component/upload-dialog/upload-dialog.component';
import { LazyLoadV2Directive } from './directive/lazyload-dom.directive';
import { MiddleclickDirective } from './directive/middlelcik.directive';
import { ResultCallbackPipe } from './pipe/result-callback.pipe';
import { StatusQueuePipe } from './pipe/status-queue.pipe';

const COMPONENTS = [
  TxnContentComponent,
  TxnFooterComponent,
  TxnDetailsComponent,
  TxnDeviceInfoComponent,
  TxnInformationComponent,
  TxnSupportCenterComponent,
  UploadDialogComponent,
  SearchMemberComponent,
  EditorComponent,

  CaseActivitiesComponent,
  CaseRelatedComponent,
  CaseActivityComponent,
  NewCommentComponent,
  StoreCaseComponent,
  TxnTransferTransactionComponent,
  TxnManageContactComponent,
  TxnCallComponent,
  TxnDetailsCallComponent,
  TxnProgressingCallComponent,
  TxnIncomingComponent,
  TxnOutgoingComponent,
  TxnJourneyBlockComponent,
  TxnIncomingGeneratingComponent,
  TxnOutgoingGeneratingComponent,
  TxnChildComponent,
  TxnActionsComponent,
  TxnBaseInfoComponent,
  TxnViewDateComponent,
  TxnCallCustomerComponent,
  TxnContentWhatsappComponent,
  TxnDetailsWhatsappComponent,
  TxnDetailsSupportCenterComponent,
  TxnVerifiedComponent,
  TxnToastSupportCenterComponent,
  TxnVerifiedComponent,
  TxnRelatedToComponent,
  SelectOwnerOrgTicketComponent
];

const DIRECTIVE = [LazyLoadV2Directive, MiddleclickDirective];

const PIPE = [StatusQueuePipe, ResultCallbackPipe];
const importStandalone = [ChipViewComponent];

@NgModule({
  declarations: [COMPONENTS, DIRECTIVE, PIPE],
  exports: [COMPONENTS, DIRECTIVE, PIPE],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,

    SharedCommonModule,
    SharedAuthModule,

    SharedUiPortalModule,
    SharedUiMaterialModule,
    ButtonLoadingModule,

    CommsSharedModule,
    ChatSharedCoreModule,

    ChatSharedWhatsappModule,
    ChatSharedLivechatModule,

    CKEditorModule,
    FlexLayoutModule,
    UiScrollModule,
    NgxMatIntlTelInputComponent,

    ...importStandalone
  ]
})
export class ChatSharedTxnsModule {}
