import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * This scroll loading is used for the container to scroll vertically and load additional elements when scrolling to the bottom of the page.
 * How to use:
 *
 *  Container:
 *    <div
 *      shc-infinity-loading
 *      [disabledLoad]="true/false" // Default is false, used to prevent scrolling to the bottom to load more items.
 *      [loading]="true/false" // Show spinner when loading status.
 *      (loadMore)="loadMore()" // Catch action when scroll to bottom container.
 *      class="flex flex-col" // Should set the container to arrange items vertically.
 *    >
 *      {list items...}
 *    </div>
 */
@Component({
  selector: '[shc-infinity-loading], shc-infinity-loading',
  template: `<ng-content></ng-content>
    <div *ngIf="loading" class="spinner-wrapper"><mat-spinner color="primary" diameter="20"></mat-spinner></div>`,
  styles: ['.spinner-wrapper { display: flex; justify-content: center; align-items: center;}']
})
export class InfinityLoadingComponent {
  @Input() disabledLoad = false;
  @Input() loading = false;
  @Output() loadMore = new EventEmitter();
  constructor() {}

  @HostListener('scroll', ['$event'])
  onScroll(event: Event & { target: HTMLElement }) {
    if (this.loading || this.disabledLoad) return;
    const { scrollTop, clientHeight, scrollHeight } = event?.target || {};
    scrollTop + clientHeight === scrollHeight && this.loadMore.emit();
  }
}
