import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelService, ChatMessage, CreateThreadRequest, ParentThreadInfo } from '@b3networks/api/chat';
import { MeQuery } from '@b3networks/api/workspace';
import { MyErrorStateMatcher } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { finalize } from 'rxjs';
import { AppQuery } from '../../../core/state/app.query';
import { AppService } from '../../../core/state/app.service';
import { ConfigMessageOption } from '../../chat-message/chat-message.component';

export interface CreateDiscussionInput {
  message: ChatMessage;
  text: string;
}

@Component({
  selector: 'b3n-create-discussion',
  templateUrl: './create-discussion.component.html',
  styleUrls: ['./create-discussion.component.scss']
})
export class CreateDiscussionComponent implements OnInit {
  creating: boolean;
  configMessageOption: ConfigMessageOption = {
    isHideAction: true,
    noHoverAffect: true
  };
  description: UntypedFormControl = this.fb.control('', Validators.maxLength(1001));
  nameControl: UntypedFormControl = this.fb.control(
    '',
    Validators.compose([Validators.required, Validators.maxLength(31)])
  );
  matcher = new MyErrorStateMatcher();

  readonly MAX_LENGTH = 50;

  constructor(
    private appService: AppService,
    private appQuery: AppQuery,
    private dialogRef: MatDialogRef<CreateDiscussionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateDiscussionInput,
    private meQuery: MeQuery,
    private fb: UntypedFormBuilder,
    private channelService: ChannelService,
    private toastService: ToastService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    const text = this.data?.text?.slice(0, 30);
    this.nameControl.setValue(text);
  }

  create() {
    this.creating = true;
    const req = <CreateThreadRequest>{
      thread: {
        details: {
          name: this.nameControl.value,
          description: this.description.value
        },
        parent: <ParentThreadInfo>{
          message: {
            id: this.data.message.id,
            convo: this.data.message.convo
          }
        }
      }
    };

    this.channelService
      .createThread(req, this.meQuery.getMe().userUuid)
      .pipe(finalize(() => (this.creating = false)))
      .subscribe(
        channel => {
          this.toastService.success('Create discussion successfully!');
          this.ngZone.run(() => {
            this.dialogRef.close();
            this.channelService.updateChannelViewState(channel.parentId, {
              activeThreadId: channel.id
            });

            // docheck viewport
            const state = this.appQuery.getValue();
            this.appService.update({
              triggerDoCheckScroll: !state.triggerDoCheckScroll
            });
          });
        },
        err => {
          this.toastService.error(err?.error);
        }
      );
  }
}
