<div class="document flex justify-content-between align-items-center">
  <div class="flex align-items-center gap-8">
    <img width="32px" [src]="mediaUI.logoFile" />
    <div class="flex column">
      <span class="semi-bold">{{ mediaUI.fileName }}</span>
      <span class="description">{{ mediaUI.ext | uppercase }} . {{ mediaUI.size | fileSize }}</span>
    </div>
  </div>
  <button mat-icon-button matTooltip="Download" (click)="download.emit()">
    <mat-icon>save_alt</mat-icon>
  </button>
</div>
