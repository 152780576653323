import { Pipe, PipeTransform } from '@angular/core';

const SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(sizeInBytes: number): string {
    if (!sizeInBytes) {
      return null;
    }
    let i = 0;
    while (sizeInBytes >= 1024) {
      sizeInBytes /= 1024;
      i++;
    }
    return `${sizeInBytes.toFixed(2)} ${SIZE_UNITS[i]}`;
  }
}
