<div [formGroup]="fg">
  <h3 class="mb-4" [ngStyle]="!title && { display: 'none' }" [style.fontWeight]="highlightTitle ? 450 : 0">
    {{ title }}
  </h3>
  <div class="flex !flex-col items-stretch justify-start gap-2 action-bar">
    <mat-form-field>
      <mat-label>Action</mat-label>
      <mat-select
        placeholder="Action"
        formControlName="action"
        [id]="'action' + (selectUpward ? '__up' : '')"
        disableOptionCentering="true"
        (valueChange)="isDisableChange.emit(false)"
      >
        <mat-option *ngFor="let action of actions; trackBy: trackActionId" [value]="action.key">{{
          action.value
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="hasTts">
      <mat-form-field *ngIf="isAction('RECORD_VOICEMAIL')">
        <mat-label>Notify me via email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          (ngModelChange)="isDisableChange.emit(false)"
          placeholder="Notify me via email"
          required
        />
        <mat-error *ngIf="fg.controls['email']?.errors?.['required']">Email is required</mat-error>
        <mat-error *ngIf="fg.controls['email']?.errors?.['email']">Invalid email pattern</mat-error>
      </mat-form-field>
      <cos-tts-config
        *ngIf="fg.controls['tts'].value && actionsToShowTTS.includes(fg.controls['action'].value)"
        [text2Speech]="fg.controls['tts'].value"
        [fg]="fg"
        (changed)="change($event, fg.controls['msg'].value)"
        [isMobileApp]="isMobileApp"
      ></cos-tts-config>
    </ng-container>

    <mat-form-field *ngIf="isAction('FORWARD_TO_FLOW')">
      <mat-label>Select flow</mat-label>
      <mat-select placeholder="Select flow" formControlName="flowUuid" required>
        <mat-option *ngFor="let flow of flows" [value]="flow.uuid">{{ flow.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="fg.controls['flowUuid']?.errors?.['required']">Flow is required</mat-error>
    </mat-form-field>
  </div>
</div>
