import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestDetailLeaves } from '@b3networks/api/leave';
import { User } from '@b3networks/api/workspace';

export interface ConfirmDisableNotifyData {
  user: User;
  requestLeaveNow: RequestDetailLeaves;
}

@Component({
  selector: 'b3n-confirm-disable-notify',
  templateUrl: './confirm-disable-notify.component.html',
  styleUrls: ['./confirm-disable-notify.component.scss']
})
export class ConfirmDisableNotifyComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDisableNotifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDisableNotifyData,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  cancel() {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  confirm() {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
