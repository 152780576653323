<div class="flex column">
  <ng-container *ngIf="configMessageOption.isBookmarkChannel && !!message.messageBookmark; else normalMessage">
    <b3n-built-text-message
      [message]="message.messageBookmark"
      [participants]="participants"
      [isEditing]="isEditing"
      [isExpand]="isExpand"
      [parentElr]="parentElr"
      [configMessageOption]="configMessageOption"
      [isSubstring]="isSubstring"
      (edited)="edited.emit($event)"
      (mentionsChanged)="mentionsChanged.emit($event)"
      (showProfile)="showProfile.emit($event)"
      (jumpToMessage)="jumpToMessage.emit($event)"
      (expandMsg)="expandMsg.emit($event)"
      (seeAllPinned)="seeAllPinned.emit($event)"
    ></b3n-built-text-message>
  </ng-container>
  <ng-template #normalMessage>
    <b3n-built-text-message
      [message]="message"
      [participants]="participants"
      [isEditing]="isEditing"
      [isExpand]="isExpand"
      [parentElr]="parentElr"
      [configMessageOption]="configMessageOption"
      [isSubstring]="isSubstring"
      (edited)="edited.emit($event)"
      (mentionsChanged)="mentionsChanged.emit($event)"
      (showProfile)="showProfile.emit($event)"
      (jumpToMessage)="jumpToMessage.emit($event)"
      (expandMsg)="expandMsg.emit($event)"
      (seeAllPinned)="seeAllPinned.emit($event)"
    ></b3n-built-text-message>
  </ng-template>
  <ng-container *ngIf="message.attachmentUI?.isFailed || isTimeoutResponse">
    <span class="text-failed mat-caption red-fg">
      Failed to send message.
      <ng-container *ngIf="message.ct !== ConvoType.whatsapp">
        Click to <a href="javascript:;" (click)="resend()">resend</a> or
        <a (click)="removeUnsend(message)">remove</a>
      </ng-container>
    </span>
  </ng-container>
</div>
