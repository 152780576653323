<div class="toast flex justify-content-space-between align-items-top gap-8">
  <div class="flex align-items-top gap-8">
    <mat-icon svgIcon="b3n_check_circle" class="s-24"> </mat-icon>
    <div class="flex column">
      <p>{{ txn.displayText }}</p>
      <a href="javascript:;" (click)="goToDetail()">View details</a>
    </div>
  </div>

  <mat-icon class="icon s-20 cursor-pointer" (click)="removeToast()">close</mat-icon>
</div>
