import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CacheMessageQuery,
  CacheMessageService,
  Channel,
  ChannelService,
  ChatMessage,
  PinMessageQuery,
  ViewChannelSection
} from '@b3networks/api/chat';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { Observable, switchMap } from 'rxjs';
import { AppQuery } from '../../../../core/state/app.query';
import { AppService } from '../../../../core/state/app.service';
import { ConfigMessageOption } from '../../../chat-message/chat-message.component';
import { PinMessageComponent, PinMessageData } from '../../../dialog/pin-message/pin-message.component';

@Component({
  selector: 'b3n-pinned-message-menu',
  templateUrl: './pinned-message-menu.component.html',
  styleUrls: ['./pinned-message-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PinnedMessageMenuComponent extends DestroySubscriberComponent implements OnChanges {
  @Input() channel: Channel;
  @Input() isLoadingPinned: boolean;

  @Output() closeMenu = new EventEmitter();

  pinned$: Observable<ChatMessage[]>;

  currentSelect = 0;
  totalRow = 0;
  configMessageOption: ConfigMessageOption = {
    isHideAction: true,
    noShowThread: true,
    noHoverAffect: true,
    noShowPreview: true
  };

  private _id: string;

  constructor(
    private elr: ElementRef,
    private dialog: MatDialog,
    private pinMessageQuery: PinMessageQuery,
    private cacheMessageService: CacheMessageService,
    private channelService: ChannelService,
    private appQuery: AppQuery,
    private appService: AppService,
    private cacheMessageQuery: CacheMessageQuery,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel && this.channel.id !== this._id) {
      this._id = this.channel.id;
      this.pinned$ = this.pinMessageQuery.selectPinByChannelSort(this.channel.id).pipe(
        switchMap(pinned => {
          return this.cacheMessageQuery.selectManyMessages(pinned.map(x => x.messageId));
        })
      );
      this.cdr.markForCheck();
    }
  }

  trackByPinned(_, item: ChatMessage) {
    return item?.channelId;
  }

  unpin($event, message: ChatMessage) {
    $event.stopPropagation();
    this.dialog
      .open(PinMessageComponent, {
        data: <PinMessageData>{
          message: message,
          isPin: false
        },
        width: '600px'
      })
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.closeMenu.emit(true);
        }
      });
  }

  jumpToMessage(message: ChatMessage) {
    this.channelService.updateChannelViewState(this.channel.id, {
      viewChannelSection: ViewChannelSection.chat
    });

    setTimeout(() => {
      this.channelService.updateNavigateToMsg(message.convo, message.id);
    }, 200);
  }

  onEnterSelect($event) {
    this.elr.nativeElement.querySelector('.convo.item-convo.current-select')?.dispatchEvent(new Event('click'));
  }

  onChangeSelect($event) {
    this.currentSelect = $event;
    setTimeout(() => {
      document.querySelector('.convo.item-convo.current-select')?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest'
      });
    });
  }
}
