import {
  InfoMarkdown,
  RegExpPattern,
  fileAudio,
  fileImage,
  fileVideo,
  getFileExtension,
  getFileType,
  humanFileSize,
  markdownUtil
} from '@b3networks/shared/common';
import { format } from 'date-fns';
import { MsgType, SystemMsgType } from '../../enums.model';
import { AttachmentMessageData, AttachmentMessageDataV2, StatusMessage } from '../chat-message-attachment.model';
import { PreviewMessageData } from '../chat-message-data.model';
import { IMessBodyData } from '../chat-message-imess.model';
import { InteractiveMessageData } from '../chat-message-interactive.model';
import { SystemMessageData } from '../chat-message-system.model';
import { ChatMessage, MessageBody } from '../chat-message.model';
import { ChatMessageAttachmentUI, PreviewMessageUI } from '../chat-messsage-ui.model';

export const SYSTEM_TYPE_MSG_CUSTOM_RENDER = [SystemMsgType.pin, SystemMsgType.unpin];

export const MSG_TYPE_BUILDER = {
  [MsgType.message]: (m: ChatMessage) => {
    if (!m.body?.text) {
      m.state.isErrorFormat = true;
      m.body = new MessageBody({
        ...m.body,
        text: 'Unrenderable message!'
      });
      return;
    }
    initPreviewMessage(m);
    // build markdown
    const config = <InfoMarkdown>{
      hyperId: m.hs,
      noCode: m.aclActions.isReplyMessage,
      noSingleCode: m.aclActions.isReplyMessage
    };
    m.buildMsg.builtTextMessage = buildMarkdownMessage(m, config);
  },
  [MsgType.system]: (m: ChatMessage) => {
    const system = m.body?.data as SystemMessageData;
    m.state.isCustomRender = SYSTEM_TYPE_MSG_CUSTOM_RENDER.includes(system?.type);
    if (!m.state.isCustomRender) {
      if (m.isStore) {
        const txt = system && !!system.text ? system.text : m.body?.text;
        if (!txt) {
          m.state.isErrorFormat = true;
          m.body = new MessageBody({
            ...m.body,
            text: 'Unrenderable message!'
          });
          return;
        }
      }

      // build markdown
      const config = <InfoMarkdown>{
        hyperId: m.hs,
        noCode: m.aclActions.isReplyMessage,
        noSingleCode: m.aclActions.isReplyMessage
      };
      m.buildMsg.builtTextMessage = buildMarkdownMessage(m, config);
    }
  },
  [MsgType.attachment]: (m: ChatMessage) => {
    m.attachmentUI.attachmentData = (<AttachmentMessageDataV2>m.body.data)?.attachment || m.body.data;
    buildAttachment(m);

    // build markdown
    const config = <InfoMarkdown>{
      hyperId: m.hs,
      noCode: m.aclActions.isReplyMessage,
      noSingleCode: m.aclActions.isReplyMessage
    };
    m.buildMsg.builtTextMessage = buildMarkdownMessage(m, config);
  },
  [MsgType.imess]: (m: ChatMessage) => {
    if (m.state.isInteractiveV2) {
      // interactive
      m.state.isErrorFormat =
        !m.body?.data ||
        typeof m.body?.data === 'string' ||
        !(<IMessBodyData>m.body?.data)?.components ||
        (<IMessBodyData>m.body?.data)?.components.length === 0;
      if (m.state.isErrorFormat) {
        m.body = new MessageBody({ ...m?.body, text: 'Unrenderable message!' });
        return;
      }
    } else {
      // webhook
      m.state.isErrorFormat =
        !m.body?.data || typeof m.body?.data === 'string' || !(<InteractiveMessageData>m.body?.data)?.blocks;
      if (m.state.isErrorFormat) {
        m.body = new MessageBody({
          ...m?.body,
          text: 'Unrenderable message!'
        });
        return;
      }
      m.webhookUI.interactiveData = m.body.data;
      m.webhookUI.renderHeaderMarkdown = markdownUtil(m.body?.text);
    }
  }
};

export function buildMarkdownMessage(m: ChatMessage, config: InfoMarkdown) {
  const transferHTML = getTextMsg(m, config);
  if (!m.attachmentUI?.isFailed && m.aclActions?.isEditedMessage) {
    const isUrl = m?.body?.text && m.body.text.match(RegExpPattern.URL)?.length > 0;
    if (!isUrl) {
      const date = format(m?.metadata?.editedAt, "MMM d 'at' HH:mm:ss");
      transferHTML.text += `<small class="tooltip-edited tooltip-custom" data-tooltip='${date}'> (edited)</small>`;
    }
  }
  return transferHTML;
}

function getTextMsg(m: ChatMessage, config: InfoMarkdown) {
  let textMessage = m.body.text;
  if ([MsgType.system, MsgType.imess, MsgType.webhook].includes(m.mt) && !!(m.body?.data as SystemMessageData)?.text) {
    textMessage = (m.body?.data as SystemMessageData).text;
  }
  if (m.mt === MsgType.system) {
    textMessage = getTextSystemMsg(m);
  }
  return markdownUtil(textMessage, config);
}

function getTextSystemMsg(cloneMessage: ChatMessage) {
  const data: SystemMessageData = cloneMessage.body?.data;
  let text = cloneMessage.body?.text;
  switch (data?.type) {
    // case SystemMsgType.pin:
    //   {
    //     const pinInfo: PinOrUnpinData = data.pin;
    //     text = `<@${cloneMessage.user}> pinned <ACTIONS/jumpToMessage/${pinInfo.msgId}> to this channel. See all <ACTIONS/seeAllPinned>.`;
    //   }
    //   break;

    // case SystemMsgType.unpin:
    //   {
    //     const pinInfo: PinOrUnpinData = data.unpin;
    //     text = `<@${cloneMessage.user}> unpinned <ACTIONS/jumpToMessage/${pinInfo.msgId}> to this channel. See all <ACTIONS/seeAllPinned>.`;
    //   }
    //   break;

    default:
      if ((cloneMessage.body?.data as SystemMessageData)?.text) {
        text = (cloneMessage.body?.data as SystemMessageData).text;
      }
      break;
  }

  return text;
}

function initPreviewMessage(m: ChatMessage) {
  const data = <PreviewMessageData>m.body?.data;
  m.state.isPreviewMsg = Boolean(data?.desc || data?.icon || data?.image || data?.title);
  if (m.state.isPreviewMsg) {
    const obj = <PreviewMessageUI>{
      ...m.previewMessageUI
    };
    obj.previewData = m.body.data as PreviewMessageData;
    obj.backgroundImage =
      obj.previewData.image && obj.previewData?.image?.indexOf('https') >= 0 ? `url('${obj.previewData.image}')` : '';
    const match = m.body.text?.match(RegExpPattern.YOUTUBE);
    obj.isYoutube = !!m.body?.text && !!match;
    const links = m.body.text?.match(RegExpPattern.URL);
    obj.extractUrl = links && links.length > 0 ? links[0] : '#';
    if (!obj.isYoutube) {
      if (obj.previewData.desc) {
        const div = document.createElement('div');
        div.innerHTML =
          obj.previewData.desc?.length > 200 ? obj.previewData.desc?.slice(0, 200) + '...' : obj.previewData.desc;
        obj.htmlDecode = div.childNodes.length === 0 ? '' : div.childNodes[0].nodeValue || '';
      }
    } else {
      obj.embededUrlYoutube = match ? `https://www.youtube.com/embed/${match[1]}?autoplay=1&mute=1` : '';
      obj.srcDocYoutube = match
        ? `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${match[1]}?autoplay=1&mute=1><img src=https://img.youtube.com/vi/${match[1]}/mqdefault.jpg><span>▶</span></a>`
        : '';
    }

    m.previewMessageUI = <PreviewMessageUI>{
      ...m.previewMessageUI,
      ...obj
    };
  }
}
function buildAttachment(m: ChatMessage) {
  if (m.attachmentUI.attachmentData) {
    const obj = <ChatMessageAttachmentUI>{
      ...m.attachmentUI
    };
    obj.fileType = getFileTypeMsg(obj.attachmentData);
    if (obj.fileType) {
      obj.isVideo = fileVideo.indexOf(obj.fileType) > -1;
      obj.isAudio = fileAudio.indexOf(obj.fileType) > -1;
      obj.isImgFile = fileImage.indexOf(obj.fileType) > -1;
    }
    obj.isFailed = (<AttachmentMessageDataV2>m.body.data)?.waSentStatus?.status === StatusMessage.failed;
    obj.logoFileType = getFileType(obj.fileType);
    const size = humanFileSize(obj.attachmentData.size);
    obj.sizeFile = size === 'NaN undefined' ? null : size;
    obj.isStorage = !!obj.attachmentData?.uri && obj.attachmentData.uri.startsWith('storage://');
    obj.keyWithOrgUuid = obj.isStorage
      ? obj.attachmentData.uri.replace('storage://', '')
      : obj.attachmentData.mediaUuid
      ? obj.attachmentData.mediaUuid
      : obj.attachmentData.fileUuid;

    m.attachmentUI = {
      ...m.attachmentUI,
      ...obj
    };
  }
}

function getFileTypeMsg(data: AttachmentMessageData) {
  let fileType;
  if (data.fileType) fileType = data.fileType?.toLowerCase().split('/')?.pop();
  if (!fileType) fileType = getFileExtension(data?.name);
  return fileType;
}
