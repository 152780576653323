<div
  *ngIf="hasIntersectionObserver"
  [intersectionObserverDom]="parentElr"
  (render)="onRenderImg()"
  (click)="onShowProfile($event, member)"
  class="container-avatar flex justify-content-start align-items-center gap-8"
  [style.width.px]="width"
  [ngClass]="{
    disabled: disabledClick,
    large: isLarge,
    small: isSmall,
  }"
  [class]="panelClass"
>
  <ng-container *ngIf="!backgroundImage; else hasAvatar">
    <ng-container *ngIf="!member.isBot; else integrateBot">
      <span class="no-avatar flex justify-content-center align-items-center">
        {{ member.displayName | firstWord }}
        <ng-container *ngTemplateOutlet="userStatusView"></ng-container>
      </span>
    </ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="displayNameTemplate"></ng-container>
</div>

<div
  *ngIf="!hasIntersectionObserver"
  (click)="onShowProfile($event, member)"
  class="container-avatar flex justify-content-start align-items-center gap-8"
  [style.width.px]="width"
  [ngClass]="{
    disabled: disabledClick,
    large: isLarge,
    small: isSmall,
  }"
  [class]="panelClass"
>
  <ng-container *ngIf="!member.hasPhoto || !backgroundImage; else hasAvatar">
    <span *ngIf="!member.isBot; else integrateBot" class="no-avatar flex justify-content-center align-items-center">
      {{ member.displayName | firstWord }}
      <ng-container *ngTemplateOutlet="userStatusView"></ng-container>
    </span>
  </ng-container>

  <ng-container *ngTemplateOutlet="displayNameTemplate"></ng-container>
</div>

<ng-template #displayNameTemplate>
  <ng-container *ngIf="showDisplayName">
    <div class="display-name" *ngIf="!keyword; else highlightText">{{ member.displayName }}</div>
    <ng-template #highlightText>
      <div class="display-name" [innerHTML]="member.displayName | highlight: keyword"></div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #hasAvatar>
  <div class="avatar" [style.background-image]="backgroundImage">
    <ng-container *ngTemplateOutlet="userStatusView"></ng-container>
  </div>
</ng-template>

<ng-template #userStatusView>
  <ng-container *ngIf="showStatus">
    <ng-container *ngIf="{ isOnline: member.userUuid | userOnline | async } as data">
      <div class="status" [class.online]="data.isOnline"></div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #integrateBot>
  <mat-icon class="icon-smart-toy">smart_toy</mat-icon>
</ng-template>
