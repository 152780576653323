<h1 mat-dialog-title>Invite Member</h1>

<ng-container *ngIf="data.convoId | selectChannel | async as channel; else spinner">
  <div mat-dialog-content>
    <div class="container">
      <pre class="message_mention">@{{ data.user?.displayName }}</pre>
      is not in <strong><csh-convo-name [channel]="channel"></csh-convo-name></strong> yet. Do you want to invite
      him/her?
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button (click)="cancel()" color="primary" mat-button>No</button>
    <button mat-button [loading]="isLoading" (click)="invite()">Yes</button>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="spinner-container flex justify-content-center align-items-center">
    <mat-spinner [diameter]="40" [strokeWidth]="2"></mat-spinner>
  </div>
</ng-template>
