<ng-container *ngIf="{ viewChannelSection: viewChannelSection$ | async } as data">
  <div [id]="channel.id" *ngIf="channel" class="container flex column">
    <div class="convo_header spacer flex justify-content-space-between align-items-center gap-16">
      <div class="flex align-items-center" style="overflow: hidden">
        <ng-container *ngIf="!isNotFullScreen">
          <csh-menu-toggle></csh-menu-toggle>
        </ng-container>

        <ng-container *ngIf="hasBackAction">
          <div class="flex align-items-center gap-8">
            <mat-icon (click)="backEvent.emit(true)" class="icon action s-22 material-icons-outlined" matTooltip="Back">
              arrow_back
            </mat-icon>

            <mat-divider vertical class="divider-vertical"></mat-divider>
          </div>
        </ng-container>

        <div class="spacer flex gap-8">
          <div
            class="convo_header__title flex justify-content-start align-items-center gap-4"
            (click)="openDetailConvo(channel)"
          >
            <b3n-channel-name
              [channel]="channel"
              [isHideStarred]="true"
              [isShowParentThread]="!isNotFullScreen"
            ></b3n-channel-name>
          </div>

          <ng-container *ngIf="showSelectView && !channel.isPersonalBookmark">
            <ng-container *ngIf="!isNotFullScreen; else selectView">
              <mat-tab-group
                class="convo_header__tab"
                animationDuration="0"
                [selectedIndex]="data.viewChannelSection === ViewChannelSection.chat ? 0 : 1"
                (selectedIndexChange)="onTabChanged($event)"
              >
                <mat-tab label="Chat"> </mat-tab>
                <mat-tab label="Files"> </mat-tab>
              </mat-tab-group>
            </ng-container>
            <ng-template #selectView>
              <mat-tab-group class="convo_header__tab" [matMenuTriggerFor]="menuView" #menuTrigger="matMenuTrigger">
                <mat-tab>
                  <ng-template mat-tab-label>
                    {{ data.viewChannelSection === ViewChannelSection.chat ? 'Chat' : 'File' }}
                    <mat-icon class="s-22">arrow_drop_down</mat-icon>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div [ngSwitch]="channel.type" class="convo_header__actions flex justify-content-end align-items-center gap-10">
        <ng-container *ngSwitchCase="ChannelType.gc">
          <mat-icon (click)="openSearchMessages(channel)" class="icon-info" matTooltip="Search message">
            search
          </mat-icon>

          <ng-container *ngIf="{ countPinned: countPinned$ | async } as data">
            <mat-icon
              class="icon-info"
              #triggerMenuPinned="matMenuTrigger"
              [matMenuTriggerFor]="menuPinned"
              (onMenuOpen)="onMenuOpenPinned()"
              matTooltip="Pinned messages"
              [matBadge]="data.countPinned"
              [matBadgeHidden]="data.countPinned === 0"
              matBadgeSize="small"
              matBadgeColor="warn"
            >
              keep
            </mat-icon>
          </ng-container>

          <!-- <ng-container *ngIf="{ countThread: countThread$ | async } as data">
          <mat-icon
            class="icon-info "
            matTooltip="Discussions"
            svgIcon="threads"
            [matMenuTriggerFor]="menuThread"
            #triggerMenuThread="matMenuTrigger"
            (onMenuOpen)="onMenuOpenThread()"
            matTooltip="Discussions"
            [svgIcon]="'threads'"
            [matBadge]="data.countThread"
            [matBadgeHidden]="data.countThread === 0"
            matBadgeSize="small"
            matBadgeColor="warn"
          >
          </mat-icon>
        </ng-container> -->

          <ng-container *ngIf="isNotFullScreen">
            <mat-icon
              (click)="openFullscreen(channel)"
              class="icon-info"
              matTooltip="Full screen"
              svgIcon="b3n_full_screen"
            >
            </mat-icon>

            <mat-icon (click)="hideWidget.emit(true)" class="icon-info" matTooltip="Hide"> close </mat-icon>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="ChannelType.dm">
          <mat-icon (click)="openSearchMessages(channel)" class="icon-info" matTooltip="Search message">
            search
          </mat-icon>

          <!-- <ng-container *ngIf="{ countPinned: countPinned$ | async } as data">
            <mat-icon
              class="icon-info"
              #triggerMenuPinned="matMenuTrigger"
              [matMenuTriggerFor]="menuPinned"
              (onMenuOpen)="onMenuOpenPinned()"
              matTooltip="Pinned messages"
              [matBadge]="data.countPinned"
              [matBadgeHidden]="data.countPinned === 0"
              matBadgeSize="small"
              matBadgeColor="warn"
            >
              keep
            </mat-icon>
          </ng-container> -->

          <!-- <ng-container *ngIf="{ countThread: countThread$ | async } as data">
        <mat-icon
          class="icon-info "
          matTooltip="Discussions"
          svgIcon="threads"
          [matMenuTriggerFor]="menuThread"
          #triggerMenuThread="matMenuTrigger"
          (onMenuOpen)="onMenuOpenThread()"
          matTooltip="Discussions"
          [svgIcon]="'threads'"
          [matBadge]="data.countThread"
          [matBadgeHidden]="data.countThread === 0"
          matBadgeSize="small"
          matBadgeColor="warn"
        >
        </mat-icon>
      </ng-container> -->

          <ng-container *ngIf="isNotFullScreen">
            <mat-icon
              (click)="openFullscreen(channel)"
              class="icon-info"
              matTooltip="Full screen"
              svgIcon="b3n_full_screen"
            >
            </mat-icon>

            <mat-icon (click)="hideWidget.emit(true)" class="icon-info" matTooltip="Hide"> close </mat-icon>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="ChannelType.THREAD">
          <mat-icon class="icon-info" matTooltip="Jump to message" (click)="jumpToMessage.emit(channel)">
            move_up
          </mat-icon>

          <ng-container *ngIf="isNotFullScreen">
            <mat-icon
              (click)="openFullscreen(channel)"
              matTooltip="Full screen"
              class="icon-info"
              matTooltip="Full screen"
              svgIcon="b3n_full_screen"
            >
            </mat-icon>

            <mat-icon (click)="hideWidget.emit(true)" class="icon-info" matTooltip="Hide discussion"> close </mat-icon>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault> </ng-container>
      </div>
    </div>
  </div>

  <mat-menu #menuPinned="matMenu" class="pinned-container">
    <b3n-pinned-message-menu
      [channel]="channel"
      [isLoadingPinned]="isLoadingPinned"
      (closeMenu)="triggerMenuPinned.closeMenu()"
    ></b3n-pinned-message-menu>
  </mat-menu>

  <mat-menu #menuThread="matMenu" class="thread-menu">
    <b3n-thread-menu [channel]="channel" (selectThread)="onSelectThread($event)"></b3n-thread-menu>
  </mat-menu>

  <mat-menu #menuView="matMenu">
    <button
      mat-menu-item
      *ngIf="data.viewChannelSection === ViewChannelSection.files"
      (click)="changedViewChannel(ViewChannelSection.chat)"
    >
      <span>Chat</span>
    </button>
    <button
      mat-menu-item
      *ngIf="data.viewChannelSection === ViewChannelSection.chat"
      (click)="changedViewChannel(ViewChannelSection.files)"
    >
      <span>Files</span>
    </button>
  </mat-menu>
</ng-container>
