import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ModeSidebar } from '../../core/state/app-state.model';
import { AppQuery } from '../../core/state/app.query';
import { AppService } from '../../core/state/app.service';

@Component({
  selector: 'csh-menu-toggle',
  templateUrl: './menu-toggle.component.html',
  styleUrls: ['./menu-toggle.component.scss']
})
export class MenuToggleComponent implements OnInit {
  toggleLeftSidebar$: Observable<boolean>;
  isOverModeLeftSidebar$: Observable<boolean>;
  mentionCountTeamChat$: Observable<number>;

  constructor(
    private renderer: Renderer2,
    private appQuery: AppQuery,
    private appService: AppService,
    private elr: ElementRef
  ) {}

  ngOnInit() {
    this.mentionCountTeamChat$ = this.appQuery.mentionCountTeamChat$;
    this.toggleLeftSidebar$ = this.appQuery.showLeftSidebar$;
    this.isOverModeLeftSidebar$ = this.appQuery.modeLeftSidebar$.pipe(
      map(mode => mode === ModeSidebar.over),
      tap(isOverMode => {
        this.renderer.setStyle(this.elr?.nativeElement, 'display', isOverMode ? 'block' : 'none');
      })
    );
  }

  viewMenu(toggleInfoBtn: boolean) {
    const mode = this.appQuery.getValue()?.modeRightSidebar;
    if (mode === ModeSidebar.over) {
      this.appService.update({
        showLeftSidebar: !toggleInfoBtn
      });
    }
  }
}
