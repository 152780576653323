import { RoutingMode } from '../inboxes/inboxes.model';
import { ChatFlowCfg, ChatRoutingInbox, ChatRoutingMode } from '../sourceChannel/sourceChannel.model';

export class WidgetCustomer {
  googleClientId: string;
  isPublicHoliday: boolean;
  isAfterWorkingHours: boolean;
  metadata: WidgetMetaData;

  // new flow
  workingHoursCfg: WidgetConfig;
  publicHolidayCfg: WidgetConfig;
  afterWorkingHoursCfg: WidgetConfig;

  // old flow
  // TODO: remove
  title: string;
  welcomMsg: string;
  customerVerification: CustomerVerificationType[];

  constructor(obj?: Partial<Widget>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.['workingHoursCfg']) this.workingHoursCfg = new WidgetConfig(obj.workingHoursCfg);
      if (obj?.['publicHolidayCfg']) this.publicHolidayCfg = new WidgetConfig(obj.publicHolidayCfg);
      if (obj?.['afterWorkingHoursCfg']) this.afterWorkingHoursCfg = new WidgetConfig(obj.afterWorkingHoursCfg);
    }
  }

  get widgetCfg() {
    return this.isPublicHoliday
      ? this.publicHolidayCfg
      : this.isAfterWorkingHours
      ? this.afterWorkingHoursCfg
      : this.workingHoursCfg;
  }

  get isVisitorCustomer() {
    return !this.widgetCfg?.customerVerification?.length;
  }
}

export class Widget extends WidgetCustomer {
  uuid: string;
  orgUuid: string;
  name: string;
  inboxUuid: string;
  routingMode: RoutingMode;
  createdAt: number;
  updatedAt: number;
  inboxName?: string;
  lastActivities: WidgetLastActivity[];
  status: StatusWidget;

  constructor(obj: Partial<Widget>) {
    super(obj);
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get isMultipleRouting() {
    return this.widgetCfg?.chatRoutingMode === ChatRoutingMode.MULTIPLE;
  }

  get isSingleRouting() {
    return this.widgetCfg?.chatRoutingMode === ChatRoutingMode.SINGLE;
  }

  get listInboxesRouting() {
    if (this.widgetCfg?.chatRoutingMode === ChatRoutingMode.MULTIPLE) {
      return this.widgetCfg?.multipleInboxes;
    } else if (this.widgetCfg?.chatRoutingMode === ChatRoutingMode.SINGLE) {
      return [
        <ChatRoutingInbox>{
          buttonText: this.widgetCfg?.singleInbox,
          inboxUuid: this.widgetCfg.singleInbox
        }
      ];
    }

    return [];
  }
}

export interface WidgetLastActivity {
  url: string;
  lastUsed: number;
}

export class WidgetConfig extends ChatFlowCfg {
  enabled: boolean;

  constructor(obj?: Partial<WidgetConfig>) {
    super(obj);
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export interface StoreWidgetRequest {
  name: string;
  inboxUuid: string;
  routingMode: RoutingMode;
  title: string;
  welcomMsg: string;
  customerVerification: CustomerVerificationType[];
  afterWorkingHoursCfg: WidgetConfig;
  publicHolidayCfg: WidgetConfig;
  metadata: WidgetMetaData;
}

export enum StatusWidget {
  active = 'active',
  inactive = 'inactive'
}

export enum CustomerVerificationType {
  google = 'google',
  microsoft = 'microsoft',
  singpass = 'singpass',
  email = 'email'
}

export enum PostMessageType {
  visitor = 'visitor'
}

export interface PostMessageData {
  code: string;
  state: string;
  type: PostMessageType;
}

export interface InformationCustomer {
  name: string;
  email: string;
  number?: string;
  customerVerificationType?: CustomerVerificationType;
  inboxUuid?: string;
}

export interface GetWidgetReq {
  inboxUuid: string;
}
export interface WidgetMetaData {
  primary_color: string;
  text_color: string;
  font_family: string;
}
