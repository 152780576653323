<h1 mat-dialog-title>Delete mesasge</h1>

<div mat-dialog-content>
  <p *ngIf="!data.isPinned">Are you sure to delete it? This action cannot be undone.</p>
  <p *ngIf="data.isPinned">
    This message has been pinned to the channel. Are you sure to delete it? This action cannot be undone.
  </p>
  <div class="mesasge-container">
    <csh-chat-message
      [id]="data.message.clientId"
      [configMessageOption]="configMessageOption"
      [previousMessage]="null"
      [message]="data.message"
    ></csh-chat-message>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button (click)="delete()" color="warn">{{ !data.isPinned ? 'Delete' : 'Unpin & Delete' }}</button>
</div>
