<div *ngIf="isChild && isLoading; else loadJourney" class="icon-pending expaned flex align-items-center gap-8">
  <mat-spinner [diameter]="16"></mat-spinner>
  <div class="collecting flex gap-3">
    <span>Generating call journey </span>
    <div class="loading-dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>
<ng-template #loadJourney>
  <div class="container flex column" [class.parent]="!isChild">
    <b3n-txn-journey-block [blocks]="blocks" [count]="1" [isChild]="isChild"></b3n-txn-journey-block>
  </div>
</ng-template>
