<ng-container *ngIf="!block.builtTextMessage.isTriggerDirective; else trigger">
  <div
    class="markdown"
    [innerHTML]="block.builtTextMessage.text | safe: 'html'"
    [style.borderLeft]="'3px solid #e0e0e0'"
    [style.borderLeftColor]="block.color"
  ></div>
</ng-container>
<ng-template #trigger>
  <div
    class="markdown"
    lazyloadUnknown
    [text]="block.builtTextMessage.text"
    [participants]="participants"
    [innerHTML]="block.builtTextMessage.text | safe: 'html'"
    [style.borderLeft]="'3px solid #e0e0e0'"
    [style.borderLeftColor]="block.color"
  ></div>
</ng-template>
