import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HashMap } from '@datorama/akita';
import { AppState } from './app-state.model';
import { AppStore } from './app.store';

@Injectable({ providedIn: 'root' })
export class AppService {
  cacheHidedImage: HashMap<boolean> = {}; // msgId -> boolean

  private _intervalTime: any;

  constructor(private appStore: AppStore, private http: HttpClient) {}

  update(app: Partial<AppState>) {
    this.appStore.update(state => {
      return { ...state, ...app };
    });
  }

  triggerFocusQuillEdior() {
    const state = this.appStore.getValue();
    this.update({
      quillEditor: {
        ...state.quillEditor,
        triggerfocus: !state.quillEditor.triggerfocus
      }
    });
  }

  countDownPopup() {
    this._intervalTime = setInterval(() => {
      const popupState = this.appStore.getValue().popupState;
      const remainTime = (popupState?.systemStatusExpiredAt - new Date().getTime()) / 1000;
      if (remainTime > 0) {
        this.update({
          popupState: {
            ...popupState,
            remainTime: Math.round(remainTime)
          }
        });
      } else {
        this.clearIntervalTime();
      }
    }, 1000);
  }

  clearIntervalTime() {
    if (this._intervalTime) {
      clearInterval(this._intervalTime);
      this._intervalTime = null;
    }

    const popupState = this.appStore.getValue().popupState;
    this.update({
      popupState: {
        ...popupState,
        systemStatusExpiredAt: null,
        remainTime: null
      }
    });
  }
}
