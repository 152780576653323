import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ConvoType } from '@b3networks/api/chat';
import { CacheMediaQuery } from '@b3networks/api/common';
import { FileService } from '@b3networks/api/file';
import { Integration, User } from '@b3networks/api/workspace';
import { InfoShowMention } from '../../../core/state/app-state.model';

@Component({
  selector: 'b3n-avatar-user',
  templateUrl: './avatar-user.component.html',
  styleUrls: ['./avatar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarUserComponent implements OnChanges {
  @Input() member: User;
  @Input() disabledClick: boolean;
  @Input() parentElr: HTMLElement;
  @Input() isLarge: boolean;
  @Input() isSmall: boolean;
  @Input() showStatus: boolean;
  @Input() showDisplayName: boolean;
  @Input() keyword: string;
  @Input() width: number;
  @Input() panelClass?: string;

  @Output() showProfile = new EventEmitter<InfoShowMention>();

  hasIntersectionObserver: boolean;
  backgroundImage: string;
  isBot: boolean;

  private _identityUuid: string;
  readonly ConvoType = ConvoType;

  constructor(
    private fileService: FileService,
    private cacheMediaQuery: CacheMediaQuery,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['member'] && this._identityUuid !== this.member?.uuid) {
      this._identityUuid = this.member?.uuid;
      this.isBot = this.member?.isBot;
      let url: string;
      if (this.member.hasPhoto) {
        url = this.cacheMediaQuery.getMediaByKey(this.member.photoUrl, false)?.url;
      }

      if (this.parentElr) {
        this.hasIntersectionObserver = this.member.hasPhoto && !url;
        this.backgroundImage = url ? `url('${url}')` : null;
      } else {
        this.backgroundImage = url ? `url('${url}')` : null;
        if (this.member.hasPhoto) {
          this.onRenderImg();
        }
      }
      this.cdr.markForCheck();
    }
  }

  onShowProfile($event, user: User | Integration) {
    $event.stopPropagation();
    this.showProfile.emit(<InfoShowMention>{
      xPosition: $event.x,
      yPosition: $event.y,
      member: user
    });
  }

  onRenderImg(index = 0) {
    // if (isLocalhost()) {
    //   return;
    // }

    if (index > 8) {
      return;
    }

    const link = this.member.photoUrl;
    this.fileService.getThumbnailPublicImage(link).subscribe(
      url => {
        if (url) {
          this.backgroundImage = `url('${url}')`;
          this.cdr.markForCheck();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 423) {
          setTimeout(() => {
            this.onRenderImg(index + 1);
          }, 2000);
        }
      }
    );
  }
}
