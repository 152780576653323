import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelService, UpdateChannelReq } from '@b3networks/api/chat';
import { User } from '@b3networks/api/workspace';
import { finalize } from 'rxjs';

export interface ConfirmInviteUserData {
  user: User;
  convoId: string;
}

@Component({
  selector: 'b3n-confirm-invite-user',
  templateUrl: './confirm-invite-user.component.html',
  styleUrls: ['./confirm-invite-user.component.scss']
})
export class ConfirmInviteUserComponent implements OnInit {
  isLoading: boolean;

  constructor(
    private dialogRef: MatDialogRef<ConfirmInviteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmInviteUserData,
    private channelService: ChannelService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  cancel() {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  invite() {
    this.isLoading = true;
    const req = <UpdateChannelReq>{
      add: [this.data.user.userUuid]
    };
    this.channelService
      .addOrRemoveParticipants(this.data.convoId, req)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.ngZone.run(() => {
          this.dialogRef.close(true);
        });
      });
  }
}
