export enum MailBoxAction {
  HANGUP = 'HANGUP',
  HANGUPANDPLAYMSG = 'HANGUPANDPLAYMSG',
  RECORD_NUMBER = 'RECORD_NUMBER',
  RECORD_VOICEMAIL = 'RECORD_VOICEMAIL',
  FORWARD_TO_ANOTHER = 'FORWARD_TO_ANOTHER',
  RING_DEVICES = 'RING_DEVICES',
  HANGUP_WITH_MSG = 'HANGUP_WITH_MSG',
  FORWARD_TO_FLOW = 'FORWARD_TO_FLOW',

  // unanswerred,busy flow
  CALL_FORWARDING = 'CALL_FORWARDING',
  CALL_DELEGATION = 'CALL_DELEGATION'
}
