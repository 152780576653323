import { TxnChannel } from '../txn/txn.model';

export class Inbox {
  uuid: string;
  name: string;
  routingMode: RoutingMode;
  queueUuid: string;
  teams: string[];
  createdAt: number;
  updatedAt: number;
  workingHour?: Schedule;
  enableSendTranscript?: boolean;
  afterWorkingHourActions?: ActionAfterWorkingHour[];
  channel: TxnChannel;
  slaPolicyUuid: string;

  // UI
  meIdentity: string; // inbox new
  myTeam: string[]; // inbox old
  agents: string[];
  pendingCountTxn: number;

  icon: string;
  iconName: string;
  isNoSvg: boolean;
  classIcon: string;

  constructor(obj: Partial<Inbox>) {
    if (obj) {
      Object.assign(this, obj);

      if (!obj.pendingCountTxn) {
        this.pendingCountTxn = 0;
      }
      this.icon = this.generateIcon();
    }
  }

  withMyTeam(teams: string[]) {
    this.myTeam = teams;
    return this;
  }

  withMyIdentity(meIdentity: string) {
    this.meIdentity = meIdentity;
    return this;
  }

  get isMyInbox() {
    return this.myTeam?.some(team => this.teams?.includes(team)) || this.agents?.includes(this.meIdentity);
  }

  private generateIcon() {
    switch (this.channel) {
      case TxnChannel.livechat:
        this.isNoSvg = false;
        this.iconName = 'Live Chat';
        return 'b3n_inbox_livechat';
      case TxnChannel.supportCenter:
        this.isNoSvg = false;
        this.iconName = 'Support Ticket';
        return 'b3n_inbox_ticket';
      case TxnChannel.call:
        this.iconName = 'Call';
        this.isNoSvg = false;
        return 'b3n_inbox_call';
      case TxnChannel.whatsapp:
        this.isNoSvg = false;
        this.iconName = 'WhatsApp';
        return 'b3n_inbox_whatsapp';
      case 'email':
        this.isNoSvg = false;
        this.iconName = 'Mail';
        return 'b3n_inbox_mail';
      default:
        this.isNoSvg = false;
        this.iconName = 'Live Chat';
        return 'b3n_inbox_livechat';
    }
  }
}

export interface StoreInboxRequest {
  name: string;
  routingMode: RoutingMode;

  queueUuid?: string;
  teams?: string[];
  agentUuids?: string[];
}

export interface UpdateInboxRoutingModeReq {
  routingMode: RoutingMode;
}

export interface StoreInboxRequestV5 {
  name: string;
  routingMode: RoutingMode;
  channel: TxnChannel;
  queueUuid?: string;
  agentUuids?: string[];
  enableSendTranscript: boolean;
}

export interface SupplierInboxInfo {
  inboxName: string;
  inboxUuid: string;
}

export enum RoutingMode {
  manual = 'manual',
  auto = 'auto'
}

export enum AssignmentMode {
  manual = 'manual',
  auto = 'auto'
}

export enum ChannelType {
  CALL = 'call',
  LIVE_CHAT = 'livechat',
  SUPPORT_CENTER = 'supportCenter',
  WHATSAPP = 'whatsapp'
}

export enum WeekDay {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

export interface Schedule {
  timezone?: string;
  shifts: ShiftData[];
  phCountryCode?: string;
  groupHolidayUuid?: string;
}

export interface ShiftData {
  dayOfWeek: WeekDay;
  timeRanges: TimeRange[];
}

export interface TimeRange {
  from: string;
  to: string;
}

export interface AfterWorkingHourRequest {
  actions: ActionAfterWorkingHour[];
}

export interface ActionAfterWorkingHour {
  type: TypeCannedResponse;
  data: {
    cannedResponseId: string;
  };
}

export enum TypeCannedResponse {
  sendCannedResponse = 'sendCannedResponse',
  closeTxn = 'closeTxn'
}

export interface LiveChatConfig {
  autoEndAssignedChatInHour: number;
  autoEndWaitingChatInHour: number;
}

export class OrgInboxConfig {
  livechatCfg: LiveChatConfig;
  constructor(obj: Partial<OrgInboxConfig>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
