<div class="main-text flex justify-content-start align-items-start">
  <div class="summary-message flex column gap-025">
    <div class="header flex justify-content-center align-items-center" style="flex: 1 1 40px">
      <span class="label">Summary chatbot</span>
    </div>
    <div class="body">
      <csh-normal-message [message]="message"></csh-normal-message>
    </div>
  </div>
</div>
