import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IdentityProfileService } from '@b3networks/api/auth';
import { ExtensionBase } from '@b3networks/api/bizphone';
import { WebrtcQuery, WebrtcService } from '@b3networks/api/call';
import { ExtensionQuery } from '@b3networks/api/callcenter';
import {
  Channel,
  ChannelQuery,
  ChannelService,
  ChannelType,
  HistoryMessageService,
  Privacy,
  UpdateChannelReq
} from '@b3networks/api/chat';
import { MeQuery, User, UserQuery, UserService } from '@b3networks/api/workspace';
import { DestroySubscriberComponent, NetworkService } from '@b3networks/shared/common';
import { OrgChartDialogComponent } from '@b3networks/shared/ui/portal';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, filter, takeUntil, tap } from 'rxjs';
import { ArchiveConvoComponent } from '../../../archive-convo/archive-convo.component';
import { EditDescriptionComponent } from '../../../edit-description/edit-description.component';
import { LeaveConvoComponent } from '../../../leave-convo/leave-convo.component';
import { RenameConversationComponent } from '../../../rename-conversation/rename-conversation.component';

@Component({
  selector: 'b3n-channel-details-about',
  templateUrl: './channel-details-about.component.html',
  styleUrls: ['./channel-details-about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelDetailsAboutComponent extends DestroySubscriberComponent implements OnInit, OnChanges {
  @Input() channel: Channel;

  @Output() hiddenDialog = new EventEmitter<boolean>();
  @Output() closeDialog = new EventEmitter<boolean>();

  archiving: boolean;
  directChatUser$: Observable<User>;
  selectExtension$: Observable<ExtensionBase>;

  private _id: string;

  readonly ChannelType = ChannelType;

  constructor(
    private router: Router,
    private meQuery: MeQuery,
    private userQuery: UserQuery,
    private userService: UserService,
    private extensionQuery: ExtensionQuery,
    private identityProfileService: IdentityProfileService,
    private dialog: MatDialog,
    private webrtcQuery: WebrtcQuery,
    private webrtcService: WebrtcService,
    private toastService: ToastService,
    private networkService: NetworkService,
    private channelService: ChannelService,
    private channelQuery: ChannelQuery,
    private messageService: HistoryMessageService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel.id !== this._id) {
      this._id = this.channel.id;

      if (this.channel.type === ChannelType.dm) {
        let _uuid: string;
        this.directChatUser$ = this.userQuery.selectUserByChatUuid(this.channel?.directChatUsers?.otherUuid).pipe(
          filter(user => user != null),
          tap(user => {
            if (_uuid !== user.userUuid) {
              _uuid = user.userUuid;

              if (!user.isBot) {
                this.selectExtension$ = this.extensionQuery.selectExtensionByUser(user.identityUuid);
                this.fetchTeam(user);
                this.cdr.markForCheck();
              }
            }
          })
        );
      }
    }
  }

  ngOnInit() {}

  archiveChannel() {}
  transferRole() {}

  editDescription() {
    this.dialog.open(EditDescriptionComponent, {
      width: '600px',
      data: this.channel
    });
  }

  editChannelName() {
    this.dialog.open(RenameConversationComponent, {
      width: '600px',
      data: this.channel
    });
  }

  openChannel() {
    this.channelService.archivedOrUnarchiveChannel(this.channel.id, false).subscribe(_ => {
      this.channelService.updateOneChannel(<Channel>{
        id: this.channel.id,
        archivedAt: null,
        archivedBy: null
      });
    });
    this.closeDialog.emit(true);
  }

  closeChannel() {
    this.dialog
      .open(ArchiveConvoComponent, {
        width: '600px',
        data: this.channel,
        autoFocus: false
      })
      .afterClosed()
      .subscribe(isArchived => {
        if (isArchived) this.closeDialog.emit(true);
      });
  }

  leaveChannel() {
    if (this.channel.privacy === Privacy.public) {
      this.leaveConvoAndNavigateGeneral(this.channel);
    } else {
      const dialog = this.dialog.open(LeaveConvoComponent, {
        width: '600px',
        data: this.channel,
        autoFocus: false
      });
      dialog
        .afterClosed()
        .pipe(takeUntil(this.destroySubscriber$))
        .subscribe(result => {
          if (result) {
            this.leaveConvoAndNavigateGeneral(this.channel);
            this.closeDialog.emit(true);
          }
        });
    }
  }

  joinChannel() {
    const req = <UpdateChannelReq>{
      add: [this.meQuery.getMe().userUuid]
    };
    this.channelService.addOrRemoveParticipants(this._id, req).subscribe(() => this.closeDialog.emit(true));
  }

  onOpenOrgChartDialog(identityUuid: string) {
    this.hiddenDialog.emit(true);
    this.dialog
      .open(OrgChartDialogComponent, {
        data: { identityUuid },
        restoreFocus: false,
        disableClose: true,
        width: '1000px'
      })
      .afterClosed()
      .subscribe(() => this.hiddenDialog.emit(false));
  }

  makeCallTo(number: string, user: User) {
    if (!this.webrtcQuery.UA?.isRegistered()) {
      this.toastService.error(
        'Sorry, an error has occurred when we try to fulfill your request. Please try again in a few minutes.'
      );
      return;
    }

    if (this.webrtcQuery.isBusy) {
      this.toastService.error('You are on a call process.');
      return;
    }

    if (!this.networkService.isOnline) {
      this.toastService.warning(
        "Your computer seems to be offline. We'll keep trying to reconnect, or you can try refresh your browser",
        10e3
      );
      return;
    }

    this.webrtcService.makeCallOutgoing(number, user as User);
  }

  private fetchTeam(user: User) {
    const ui = this.userQuery.getUiState(user.uuid);
    if (!ui?.loadedTeams) {
      this.identityProfileService.getTeams(user.identityUuid).subscribe(teams => {
        this.userService.updateUsers2Store([
          <User>{
            uuid: user.uuid,
            teams: teams
          }
        ]);
        this.userService.updateUserViewState(user.uuid, {
          loadedTeams: true
        });
      });
    }
  }

  private leaveConvoAndNavigateGeneral(convo: Channel) {
    const me = this.meQuery.getMe();
    if (me) {
      const req = <UpdateChannelReq>{
        del: [me.userUuid]
      };
      this.channelService.addOrRemoveParticipants(convo.id, req).subscribe(_ => {
        // clear message, because websocket not send msg
        this.messageService.cleanupConvoMessages([convo.id]);
        this.channelService.resetChannelViewStateHistory(convo.id);

        const general = this.channelQuery.getGeneral();
        this.router.navigate(['conversations', general[0].id], {
          queryParamsHandling: 'merge'
        });
        this.channelService.closeConversation(convo.id);
        this.closeDialog.emit(true);
      });
    }
  }
}
