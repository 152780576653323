import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Channel, ChannelQuery, ChannelService, RequestRangeThreadsByParent } from '@b3networks/api/chat';
import { MeQuery } from '@b3networks/api/workspace';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { Observable, debounceTime, finalize, fromEvent, switchMap, takeUntil, tap, timer } from 'rxjs';

const LIMIT_PAGE = 50;

interface GroupThread {
  id: string;
  channels: Channel[];
}

@Component({
  selector: 'b3n-thread-closed-menu',
  templateUrl: './thread-closed-menu.component.html',
  styleUrls: ['./thread-closed-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadClosedMenuComponent extends DestroySubscriberComponent {
  @Input() channel: Channel;

  @Output() selectThread = new EventEmitter<Channel>();

  @ViewChild('viewport') viewport: ElementRef<HTMLElement>;

  loading: boolean;
  threads$: Observable<Channel[]>;
  lastestThread: Channel;

  private _id: string;
  private _loadingMore: boolean;

  constructor(
    private meQuery: MeQuery,
    private channelService: ChannelService,
    private channelQuery: ChannelQuery,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  initFetchThreadClose() {
    if (this.channel && this.channel.id !== this._id) {
      this._id = this.channel.id;

      let tapFirst = false;
      this.threads$ = this.channelQuery.selectThreadsCLose(this.channel.id).pipe(
        tap(threads => {
          this.lastestThread = threads?.[threads.length - 1];
          if (!tapFirst) {
            tapFirst = true;
            this.trackingScrollEvent();
          }
        })
      );
      const rangeThreadsParent = this.channelQuery.getChannelUiState(this.channel.id)?.rangeThreadsParent;
      if (!rangeThreadsParent?.loadedFirst) {
        this.loadFirst();
      }
    }
  }

  openThread($event: MouseEvent, thread: Channel) {
    $event.stopPropagation();
    this.selectThread.emit(thread);
  }

  trackByThread(_, item: Channel) {
    return item.id;
  }

  trackByGroup(_, item: GroupThread) {
    return item.id;
  }

  private loadFirst() {
    const backupId = this.channel.id;
    this.loading = true;
    const req = <RequestRangeThreadsByParent>{
      parentId: this.channel.id,
      limit: LIMIT_PAGE
    };
    this.channelService
      .getCloseThreadsByParent(req, this.meQuery.getMe().userUuid)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
          const rangeThreadsParent = this.channelQuery.getChannelUiState(backupId)?.rangeThreadsParent;
          this.channelService.updateChannelViewState(backupId, {
            rangeThreadsParent: {
              ...rangeThreadsParent,
              loadedFirst: true
            }
          });
        })
      )
      .subscribe();
  }

  private onLoadMoreThread() {
    const rangeThreadsParent = this.channelQuery.getChannelUiState(this.channel.id)?.rangeThreadsParent;
    if (rangeThreadsParent?.hasMoreBackward && this.lastestThread) {
      if (this._loadingMore) {
        return;
      }

      this._loadingMore = true;
      const req = <RequestRangeThreadsByParent>{
        parentId: this.channel.id,
        limit: LIMIT_PAGE,
        to: this.lastestThread.closedL2.id
      };
      this.channelService
        .getCloseThreadsByParent(req, this.meQuery.getMe().userUuid)
        .pipe(finalize(() => (this._loadingMore = false)))
        .subscribe();
    }
  }

  private trackingScrollEvent() {
    timer(300)
      .pipe(
        switchMap(() => fromEvent(this.viewport?.nativeElement, 'scroll')),
        debounceTime(10),
        takeUntil(this.destroySubscriber$)
      )
      .subscribe(event => {
        const target = event.target as HTMLElement;
        if (target.scrollTop + target.clientHeight + 15 >= target.scrollHeight) {
          this.onLoadMoreThread();
        }
      });
  }
}
