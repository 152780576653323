import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { map, tap } from 'rxjs';
import { ChannelType } from '../inboxes/inboxes.model';
import { SupplierInfo } from './supplier.model';
import { SuppliersStore } from './supplier.store';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  constructor(private http: HttpClient, private store: SuppliersStore) {}

  setActive(id: string) {
    this.store.setActive(id);
  }

  removeActive(id: string | ID) {
    this.store.removeActive(id);
  }

  getSupplierInboxes(supplierOrgUuid: string, channel: ChannelType) {
    const params = new HttpParams().append('channel', channel);
    return this.http
      .get<SupplierInfo[]>(`inbox/private/v2/inboxes/supplier/${supplierOrgUuid}`, {
        params: params
      })
      .pipe(
        map(suppliers =>
          suppliers.map(
            s =>
              new SupplierInfo({
                ...s,
                supplierOrgUuid
              })
          )
        ),
        tap(supplierInboxes => {
          this.store.upsertMany(supplierInboxes, { baseClass: SupplierInfo });
        })
      );
  }
}
