import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastTxnCreated } from '@b3networks/api/inbox';
import { ToastData, ToastService } from '@b3networks/shared/ui/toast';

@Component({
  selector: 'b3n-custom-toast-wa',
  templateUrl: './custom-toast-wa.component.html',
  styleUrls: ['./custom-toast-wa.component.scss']
})
export class CustomToastWaComponent implements OnInit, OnDestroy {
  @Input() txn: ToastTxnCreated;
  @Input() data: ToastData;

  private _intervalId: any;

  constructor(private router: Router, private toastService: ToastService) {}

  ngOnInit(): void {
    this._intervalId = setTimeout(() => this.removeToast(), this.data?.duration);
  }

  goToDetail() {
    this.removeToast();
    this.router.navigate(['txn', this.txn?.txnUuid]);
  }

  removeToast() {
    this.toastService.removeToast(this.data.guid);
  }

  ngOnDestroy() {
    clearTimeout(this._intervalId);
  }
}
