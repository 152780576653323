<div class="container flex column">
  <h2 style="text-align: center">Incoming call</h2>
  <div
    *ngIf="me && me.assignedTxn"
    class="body spacer"
    fxLayout="column"
    fxLayoutAlign="start none"
    fxLayoutGap="1.6rem"
  >
    <div fxLayout="column" fxLayoutAlign="start none">
      <div fxLayout="row" fxLayoutGap="0.8rem">
        <strong>Queue:</strong>
        <span>{{ me.assignedTxn?.queue?.label }}</span>
      </div>

      <div fxLayout="row" fxLayoutGap="0.8rem">
        <strong>Number:</strong>
        <span> {{ me.assignedTxn.customerNumber }}</span>
      </div>

      <div fxLayout="row" fxLayoutGap="0.8rem">
        <strong>Call State:</strong>
        <span>
          {{
            (me.assignedTxn.status === 'talking' && me.systemStatus === SystemStatusCode.acw
              ? 'Wrapping'
              : me.assignedTxn.status === 'forcehangupbyuser'
              ? 'Force hangup by user'
              : me.assignedTxn.status
            ) | titlecase
          }}
        </span>
      </div>
    </div>

    <div
      *ngIf="
        !!me.assignedTxn.queue.agentWorkflowConfig.script && !me.assignedTxn?.queue?.agentWorkflowConfig?.disableScript
      "
      fxLayout="column"
      fxLayoutAlign="start none"
      fxLayoutGap="0.8rem"
    >
      <strong>Answer Script:</strong>
      <div class="answer-script">
        {{ me.assignedTxn.queue.agentWorkflowConfig.script }}
      </div>
    </div>

    <ng-container *ngIf="!me.assignedTxn?.queue?.agentWorkflowConfig?.disableNotes && templateFields">
      <div class="full-width" fxLayout="column" fxLayoutAlign="start none" fxLayoutGap="0.8rem">
        <strong>Agent note:</strong>
        <ng-container *ngFor="let field of templateFields; let i = index">
          <ng-container *ngIf="!field.isBackbone">
            <div fxLayout="column" fxLayoutAlign="start none">
              <b3n-template-field [field]="field" [ctrl]="this.formGroup.controls[field.key]"></b3n-template-field>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="action" fxLayoutAlign="end center" fxLayoutGap="1.6rem">
    <button [disabled]="isNotACW" mat-raised-button color="primary" (click)="finishAcw()">
      Finish call
      <ng-container *ngIf="!isNotACW && remainTime$ | async as remainTime"> ({{ remainTime }}s) </ng-container>
    </button>
  </div>
</div>
