import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Channel, ChannelQuery, ChannelService } from '@b3networks/api/chat';
import { MeQuery } from '@b3networks/api/workspace';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { Observable, finalize, map, startWith, tap } from 'rxjs';
import { ConvoHelperService } from '../../../../../core/adapter/convo-helper.service';
import { ConfigMessageOption } from '../../../../chat-message/chat-message.component';

@Component({
  selector: 'b3n-thread-active-menu',
  templateUrl: './thread-active-menu.component.html',
  styleUrls: ['./thread-active-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadActiveMenuComponent extends DestroySubscriberComponent implements OnChanges, AfterViewInit {
  @Input() channel: Channel;

  @Output() selectThread = new EventEmitter<Channel>();

  @ViewChild('inputSearch') inputSearch: ElementRef<HTMLInputElement>;

  threadsActived$: Observable<Channel[]>;

  loading: boolean;
  searchCtr = new FormControl();
  currentSelect = 0;
  totalRow = 0;
  configMessageOption: ConfigMessageOption = {
    isHideAction: true,
    noShowThread: true,
    noHoverAffect: true
  };

  private _id: string;

  constructor(
    private elr: ElementRef,
    private meQuery: MeQuery,
    private channelService: ChannelService,
    private channelQuery: ChannelQuery,
    private convoHelperService: ConvoHelperService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputSearch?.nativeElement?.focus();
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel && this.channel.id !== this._id) {
      this._id = this.channel.id;

      const loadedActiveThread = this.channelQuery.getChannelUiState(this.channel.id)?.loadedActiveThread;
      if (!loadedActiveThread) {
        this.loading = true;
        this.channelService
          .getActiveThreadsByChannel(this.channel.id, this.meQuery.getMe().userUuid)
          .pipe(
            finalize(() => {
              this.loading = false;
              this.cdr.detectChanges();
            })
          )
          .subscribe();
      }

      this.threadsActived$ = this.searchCtr.valueChanges.pipe(
        startWith(''),
        map(search => search?.trim() || ''),
        map(search => {
          this.currentSelect = 0;
          const option = {
            keys: ['name'],
            threshold: 0.1,
            includeScore: true
          };
          return (
            this.convoHelperService
              .getSearchableThreadsContains(search, this.channel.id, -1, option)
              ?.result?.sort((a, b) => a.score - b.score)
              ?.map(x => x.item) || []
          );
        }),
        tap(list => {
          this.totalRow = list.length;
          this.currentSelect = 0;
          setTimeout(() => {
            this.elr.nativeElement.querySelector('.item.current-select')?.scrollIntoView({
              block: 'nearest',
              inline: 'nearest'
            });
          });
        })
      );
    }
  }

  onFocusInput() {
    setTimeout(() => {
      this.inputSearch.nativeElement.focus();
    }, 300);
  }

  onMenuOpen() {
    this.searchCtr.setValue(this.searchCtr.value);
    this.onFocusInput();
  }

  openThread($event: MouseEvent, thread: Channel) {
    $event.stopPropagation();
    this.selectThread.emit(thread);
  }

  trackByThread(_, item: Channel) {
    return item.id;
  }

  onEnterSelect($event) {
    this.elr.nativeElement.querySelector('.item.current-select')?.dispatchEvent(new Event('click'));
  }

  onChangeSelect($event) {
    this.currentSelect = $event;
    setTimeout(() => {
      document.querySelector('.item.current-select')?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest'
      });
    });
  }
}
