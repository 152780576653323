import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Channel,
  ChannelHyperspace,
  ChannelHyperspaceService,
  ChannelService,
  ChatMessage,
  ChatService,
  MessageBody,
  MsgType,
  ReqUpdateMetaDataHyper,
  UpdateChannelReq
} from '@b3networks/api/chat';
import { ConversationGroup, ConversationGroupService, MeQuery, User } from '@b3networks/api/workspace';
import { MyErrorStateMatcher } from '@b3networks/shared/common';
import { finalize } from 'rxjs/operators';
import { SupportedConvo } from '../../core/adapter/convo-helper.service';
import { MessageConstants } from '../../core/constant/message.const';

@Component({
  selector: 'csh-edit-description',
  templateUrl: './edit-description.component.html',
  styleUrls: ['./edit-description.component.scss']
})
export class EditDescriptionComponent implements OnInit {
  me: User;
  // desc: string;
  description: UntypedFormControl = this.fb.control('', Validators.maxLength(1001));
  processing: boolean;
  matcher = new MyErrorStateMatcher();

  constructor(
    private fb: UntypedFormBuilder,
    private meQuery: MeQuery,
    private chatService: ChatService,
    private convoGroupService: ConversationGroupService,
    private channelHyperspaceService: ChannelHyperspaceService,
    private channelService: ChannelService,
    @Inject(MAT_DIALOG_DATA) public convo: SupportedConvo,
    private dialogRef: MatDialogRef<EditDescriptionComponent>,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.description.setValue(this.convo.description);
    this.me = this.meQuery.getMe();
  }

  submit() {
    this.processing = true;
    if (this.convo instanceof ConversationGroup) {
      this.convoGroupService
        .updateDescription(this.convo.id, this.description.value)
        .pipe(finalize(() => (this.processing = false)))
        .subscribe(_ => {
          const message = ChatMessage.createMessage(
            this.convo,
            new MessageBody({
              text: MessageConstants.EDIT_MESSAGE(this.me, this.description.value)
            }),
            this.me.userUuid,
            MsgType.system
          );

          this.chatService.send(message);

          this.ngZone.run(() => {
            this.dialogRef.close();
          });
        });
    } else if (this.convo instanceof Channel) {
      const req = <UpdateChannelReq>{
        description: this.description.value
      };

      this.channelService
        .updateNameOrDescriptionChannel(this.convo.id, req)
        .pipe(finalize(() => (this.processing = false)))
        .subscribe(_ =>
          this.ngZone.run(() => {
            this.dialogRef.close();
          })
        );
    } else if (this.convo instanceof ChannelHyperspace) {
      const req = <ReqUpdateMetaDataHyper>{
        hyperspaceId: this.convo.hyperspaceId,
        hyperchannelId: this.convo.id,
        description: this.description.value
      };

      this.channelHyperspaceService
        .updateChannelHyper(req)
        .pipe(finalize(() => (this.processing = false)))
        .subscribe(_ =>
          this.ngZone.run(() => {
            this.dialogRef.close();
          })
        );
    }
  }
}
