import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from './app-state.model';
import { AppStore } from './app.store';

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<AppState> {
  showMainSidebar$ = this.select(state => state.showMainSidebar);
  sidebarTabActive$ = this.select(state => state.sidebarTabActive);
  emailUWState$ = this.select(state => state.emailUWState);
  hasMoreEndTxns$ = this.select(state => state.endTxnsUWState?.hasMore);
  hasMoreEndTxnsOrg$ = this.select(state => state.endTxnsUWOrgState?.hasMore);
  showRightSidebar$ = this.select(state => state.showRightSidebar);
  showLeftSidebar$ = this.select(state => state.showLeftSidebar);
  modeLeftSidebar$ = this.select(state => state.modeLeftSidebar).pipe(distinctUntilChanged());
  modeRightSidebar$ = this.select(state => state.modeRightSidebar).pipe(distinctUntilChanged());
  mentionCountTeamChat$ = this.select(state => state.mentionCountTeamChat);
  quillEditor$ = this.select(state => state.quillEditor);
  triggerScrollBottomView$ = this.select(state => state.triggerScrollBottomView);
  triggerDoCheckScroll$ = this.select(state => state.triggerDoCheckScroll); // when resize height content msg, should be trigger docheck to update height viewport
  memberMenu$ = this.select('memberMenu');
  isShowPopup$ = this.select(state => state.popupState?.isShowPopup);
  isLoadingTemplate$ = this.select(state => state.popupState?.isLoadingTemplate);
  systemStatusExpiredAt$ = this.select(state => state.popupState?.systemStatusExpiredAt);
  remainTime$ = this.select(state =>
    state.popupState?.systemStatusExpiredAt > 0 ? state.popupState?.remainTime : null
  );
  tag$ = this.select(state => state.popupState?.tag);
  isLeftChatSidebar$ = this.select(state => state.isLeftChatSidebar);
  isCollapseAssignedToMe$ = this.select(state => state.isCollapseAssignedToMe);
  isCollapseAllInbox$ = this.select(state => state.isCollapseAllInbox);
  loadingQueryTxn$ = this.select(state => state.loadingQueryTxn);
  initHeightTxn$ = this.select(state => state.initHeightTxn);
  viewTxn$ = this.select(state => state.viewTxn);
  triggerRefreshTxn$ = this.select(state => state.triggerRefreshTxn);
  triggerScrollUpTxn$ = this.select(state => state.triggerScrollUpTxn);
  triggerRefreshQueryTxn$ = this.select(state => state.triggerRefreshQueryTxn);
  triggerRefreshActiveCall$ = this.select(state => state.triggerRefreshActiveCall);
  triggerSeeAllPinned$ = this.select(state => state.triggerSeeAllPinned);
  triggerIsScrollDownTxn$ = this.select(state => state.triggerIsScrollDownTxn);
  customerTxnReports$ = this.select(state => state.customerTxnReports);
  collapseSidebar$ = this.select(state => state.collapseSidebar);
  submittedTicket$ = this.select(state => state.submittedTicket);
  splitMode$ = this.select(state => state.splitMode);
  isFetchingTxnDetail$ = this.select(state => state.isFetchingTxnDetail);
  isSmallBreakpont$ = this.select(state => state.isSmallBreakpont);

  constructor(protected override store: AppStore) {
    super(store);
  }

  getValueStore() {
    return this.getValue();
  }

  endTxnsUWState() {
    return this.getValue()?.endTxnsUWState;
  }

  endTxnsUWOrgState() {
    return this.getValue()?.endTxnsUWOrgState;
  }
}
