import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ChannelQuery,
  ChatMessage,
  ChatService,
  ConfigStore,
  ConvoType,
  FilterConvoMessageRangeRequest,
  HistoryMessageQuery,
  HistoryMessageService,
  MsgType,
  PinMessageRequest,
  PinMessageService,
  ReplyMessage,
  TimeService,
  UserType
} from '@b3networks/api/chat';
import { ContactQuery } from '@b3networks/api/contact';
import {
  ConversationGroupQuery,
  HyperspaceQuery,
  Integration,
  MeQuery,
  MediaService,
  User,
  UserQuery,
  UserService
} from '@b3networks/api/workspace';
import { UNKNOWN_USER, X, isLocalhost } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { differenceInMinutes } from 'date-fns';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageConstants } from '../../core/constant/message.const';
import { InfoShowMention, MenuMessageInfo, MenuMsg, RightCLickMessage } from '../../core/state/app-state.model';
import { AppQuery } from '../../core/state/app.query';
import { AppService } from '../../core/state/app.service';
import {
  CreateDiscussionComponent,
  CreateDiscussionInput
} from '../dialog/create-discussion/create-discussion.component';
import { DeleteMessageComponent, DeleteMessageData } from '../dialog/delete-message/delete-message.component';
import { PinMessageComponent, PinMessageData } from '../dialog/pin-message/pin-message.component';

enum MessagePosition {
  first,
  middle
}

export interface ConfigMessageOption {
  isHideAction?: boolean; // in delete mode, preview msg not use action
  noHoverAffect?: boolean; // hover message
  fullDate?: boolean;
  useBookmark?: boolean;
  isBookmarkChannel?: boolean;
  isMyChannel?: boolean;
  noShowThread?: boolean;
  noShowPreview?: boolean;
}

@Component({
  selector: 'csh-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnChanges {
  @Input() message: ChatMessage;
  @Input() previousMessage: ChatMessage | null; // dont remove null , trigger onChange to update position render msg and fetch info user
  @Input() participants: string[];
  @Input() isMyChannel: boolean;
  @Input() parentElr?: HTMLElement; // use intersection to lazy loading image;
  @Input() enableEditing: boolean;
  @Input() isPinned: boolean;
  @Input() isHighlight: boolean;
  @Input() configMessageOption: ConfigMessageOption = {
    isHideAction: false,
    noHoverAffect: false,
    fullDate: false,
    useBookmark: false,
    isBookmarkChannel: false,
    noShowThread: false,
    noShowPreview: false
  };

  @Output() edited = new EventEmitter<boolean>();
  @Output() showProfile: EventEmitter<InfoShowMention> = new EventEmitter<InfoShowMention>();
  @Output() mentionsChanged = new EventEmitter<string[]>();
  @Output() addBookmarkMessage = new EventEmitter<ChatMessage>();
  @Output() removeBookmarkMessage = new EventEmitter<ChatMessage>();
  @Output() replyingMessage = new EventEmitter<ReplyMessage>();
  @Output() jumpToMessage = new EventEmitter<string>();
  @Output() seeAllPinned = new EventEmitter<boolean>();
  @Output() rightClickMessage = new EventEmitter<RightCLickMessage>();
  @Output() saveEdtingMsgId2Store = new EventEmitter<string>();
  @Output() removeHightLight = new EventEmitter<boolean>();

  readonly ConvoType = ConvoType;
  readonly MessagePosition = MessagePosition;
  readonly MsgType = MsgType;

  messageRender: ChatMessage;
  user$: Observable<User>;
  user: User;
  nameConvo$: Observable<string>;
  nameConvo: string;
  position: MessagePosition = MessagePosition.middle;
  positionAction: string;
  msgReply: ChatMessage;

  allowEditDeleteMessage = false;
  allowUseReplyAction = false;
  allowUseCreateThreadAction = false;
  allowPinOrUnpinAction = false;
  allowRemoveBookmark = false;
  allowCopyLink = false;
  withinLimitV2: boolean;

  isEditing = false;
  defaultAllowActions = false;
  isShowActions = false;
  isExpand: boolean;
  canJumpChannel: boolean;
  isShowingMenu: boolean;

  private _preUser: string;
  private _isShowmore: boolean;
  private _backupMessage: ChatMessage; // of this.message

  constructor(
    private dialog: MatDialog,
    private meQuery: MeQuery,
    private userQuery: UserQuery,
    private userService: UserService,
    private convoGroupQuery: ConversationGroupQuery,
    private channelQuery: ChannelQuery,
    private chatService: ChatService,
    private timeService: TimeService,
    private hyperspaceQuery: HyperspaceQuery,
    private router: Router,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private historyMessageService: HistoryMessageService,
    private historyMessageQuery: HistoryMessageQuery,
    private mediaService: MediaService,
    private elr: ElementRef,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
    private appQuery: AppQuery,
    private contactQuery: ContactQuery,
    private pinMessageService: PinMessageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isHighlight'] && this.isHighlight) {
      setTimeout(() => {
        this.removeHightLight.emit(true);
      }, 2000);
    }

    if (changes['enableEditing'] && !this.configMessageOption.isBookmarkChannel) {
      if (this.enableEditing) {
        this.edit();
      } else {
        this.isEditing = false;
        this.checkShowActions();
      }
    }

    // update position message
    if (changes['previousMessage']) {
      if (
        this.message &&
        this.previousMessage &&
        this.previousMessage.mt !== MsgType.system && // ignore system msg
        !this.message.hasThread &&
        !this.message?.hasMessageReply && // no reply
        this.message.ut !== UserType.TeamBot &&
        this.message.ct !== ConvoType.personal &&
        this.message.user === this.previousMessage.user &&
        ![MsgType.summary].includes(this.message.mt) &&
        differenceInMinutes(this.message.ts, this.previousMessage.ts) < 30
      ) {
        this.position = MessagePosition.middle;
        this.positionAction = '-19px';
      } else {
        if (this.message.mt === MsgType.system) {
          this.position = MessagePosition.middle;
          this.positionAction = '-19px';
        } else {
          this.position = MessagePosition.first;
          this.positionAction = this.message?.indexMessageReply > -1 ? '-71px' : '-43px';
          this.selectUser();
        }
      }
    }

    if (changes['message']) {
      this.initMessage();
    }
  }

  menuOpenedActions() {
    this.isShowingMenu = true;
    this.withinLimitV2 = this.checkTimeoutDeleteEditMsg();
    this.cdr.markForCheck();
  }

  onRightClick(event: MouseEvent) {
    const data = <RightCLickMessage>{
      xPosition: event.clientX,
      yPosition: event.clientY,
      message: this.message,
      elr: this.elr,
      menus: []
    };

    const selectedText = window.getSelection().toString().trim();
    if (selectedText) {
      const contentCopy = selectedText;
      data.menus.push(<MenuMessageInfo>{
        key: MenuMsg.copyText,
        value: 'Copy selected text',
        icon: 'content_copy',
        targetCopy: contentCopy,
        order: 3
      });

      // has link
      const urlRegex = /(https?:\/\/[^\s]+)/gi;
      if (contentCopy?.match(urlRegex)) {
        const link = contentCopy.match(urlRegex)?.[0];
        data.menus.push(<MenuMessageInfo>{
          key: MenuMsg.copyLinkAddress,
          value: 'Copy link address',
          icon: 'b3n_copy_link',
          isSvg: true,
          targetLink: link,
          order: 4
        });
      }

      event.preventDefault();
      data.menus = [...data.menus].sort((a, b) => a.order - b.order);
      this.rightClickMessage.emit(data);
    } else {
      if (this.defaultAllowActions) {
        const body = this.elr.nativeElement?.querySelector('.content_body .main-text');
        const contentCopy = body?.outerText;
        data.menus.push(<MenuMessageInfo>{
          key: MenuMsg.copyText,
          value: 'Copy message',
          icon: 'content_copy',
          classIcon: 'material-symbols-rounded',
          isSvg: false,
          size: 22,
          targetCopy: contentCopy,
          order: 3
        });

        // has link
        const urlRegex = /(https?:\/\/[^\s]+)/gi;
        if (contentCopy?.match(urlRegex)) {
          const link = contentCopy.match(urlRegex)?.[0];
          data.menus.push(<MenuMessageInfo>{
            key: MenuMsg.copyLinkAddress,
            value: 'Copy link address',
            icon: 'b3n_copy_link',
            isSvg: true,
            targetLink: link,
            order: 4
          });
        }

        if (this.isShowActions && this.message.mt === MsgType.message) {
          const dataMsg = this.message.body?.data;
          const isPreviewMsg = dataMsg?.desc || dataMsg?.icon || dataMsg?.image || dataMsg?.title;
          if (isPreviewMsg) {
            data.menus.push(<MenuMessageInfo>{
              key: MenuMsg.removePreviewLink,
              value: 'Remove preview link',
              icon: 'delete_sweep',
              order: 5
            });
          }
        }
      }

      // follow condition on HTML
      this.withinLimitV2 = this.checkTimeoutDeleteEditMsg();
      if (
        this.isShowActions &&
        (this.allowRemoveBookmark ||
          this.message.aclActions.allowUseAddBookmark ||
          this.allowUseReplyAction ||
          this.allowUseCreateThreadAction ||
          (this.allowEditDeleteMessage && this.withinLimitV2))
      ) {
        if (this.allowPinOrUnpinAction) {
          if (!this.isPinned) {
            data.menus.push(<MenuMessageInfo>{
              key: MenuMsg.pinMsg,
              value: 'Pin message',
              icon: 'keep',
              isSvg: false,
              classIcon: 'material-symbols-rounded',
              size: 25,
              order: 4.1
            });
          } else {
            data.menus.push(<MenuMessageInfo>{
              key: MenuMsg.unpinMsg,
              value: 'Unpin message',
              icon: 'keep_off',
              isSvg: false,
              classIcon: 'material-symbols-rounded',
              size: 25,
              order: 4.2
            });
          }
        }

        if (this.allowUseReplyAction) {
          const text =
            this.message?.mt === MsgType.attachment
              ? this.message?.body.text
              : this.elr.nativeElement.querySelector(`.content_body .main-text`)?.innerText;
          let userName = this.user?.displayName;
          if (!userName) {
            userName = this.userQuery.getUserByChatUuid(this.message.user)?.displayName;
          }

          data.menus.push(<MenuMessageInfo>{
            key: MenuMsg.reply,
            value: 'Reply',
            icon: 'reply',
            classIcon: 'material-symbols-rounded',
            size: 25,
            isSvg: false,
            dataReply: <ReplyMessage>{
              user: userName,
              text: this.getTextInline(text),
              message: new ChatMessage(this.message)
            },
            order: 2
          });
        }

        // if (this.allowUseCreateThreadAction) {
        //   const text =
        //     this.message?.mt === MsgType.attachment
        //       ? this.message?.body.text
        //       : this.elr.nativeElement.querySelector(`.content_body .main-text`)?.innerText;
        //   data.menus.push(<MenuMessageInfo>{
        //     key: MenuMsg.createThread,
        //     value: 'Create discussion',
        //     icon: 'thread_outline',
        //     isSvg: true,
        //     dataReply: <ReplyMessage>{
        //       text: this.getTextInline(text),
        //       message: new ChatMessage(this.message)
        //     },
        //     order: 2.5
        //   });
        // }

        if (this.configMessageOption.isBookmarkChannel && this.canJumpChannel) {
          data.menus.push(<MenuMessageInfo>{
            key: MenuMsg.jumpFromBookmark,
            value: 'Jump to channel',
            icon: 'move_up',
            isSvg: false,
            classIcon: 'material-symbols-rounded',
            order: 7
          });
        }

        if (this.allowRemoveBookmark) {
          data.menus.push(<MenuMessageInfo>{
            key: MenuMsg.removeBookmark,
            value: 'Remove bookmarks',
            icon: 'bookmark_remove',
            classIcon: 'material-symbols-rounded',
            isSvg: false,
            order: 8
          });
        }

        if (this.allowEditDeleteMessage && this.withinLimitV2) {
          if (this.message.mt === MsgType.message) {
            data.menus.push(<MenuMessageInfo>{
              key: MenuMsg.editMessage,
              value: 'Edit message',
              icon: 'edit',
              classIcon: 'material-symbols-rounded',
              isSvg: false,
              order: 1
            });
          }
          data.menus.push(<MenuMessageInfo>{
            key: MenuMsg.deleteMessage,
            value: 'Delete message',
            icon: 'delete',
            classIcon: 'inherit-color mat-warn',
            classText: 'b3n-warn',
            deleteMessage: {
              isPinned: this.isPinned
            },
            order: 6
          });
        }
      }
    }

    if (
      !this.message.hs &&
      [ConvoType.direct, ConvoType.personal, ConvoType.groupchat, ConvoType.THREAD].includes(this.message.ct) &&
      data.menus?.length > 0
    ) {
      event.preventDefault();

      data.menus = [...data.menus].sort((a, b) => a.order - b.order);
      this.rightClickMessage.emit(data);
    }
  }

  onMentionsChanged($event) {
    this.mentionsChanged.emit($event);
  }

  onEditedMessage(event) {
    this.isEditing = false;
    this.checkShowActions();
    this.edited.emit(true);
  }

  jumpChannel() {
    const msg = this.message?.messageBookmark;
    if (msg) {
      this.router.navigate(['conversations', msg.channelId, 'messages', msg.id]);
    }
  }

  copyLinkMessage() {
    try {
      const origin = !isLocalhost()
        ? window.parent.location.origin + `/#/${X.orgUuid}/home`
        : window.location.origin + '/#';
      if (this.message.ct === ConvoType.groupchat) {
        this.clipboard.copy(`${origin}/conversations/${this.message.channelId}/messages/${this.message.id}`);
      } else if (this.message.ct === ConvoType.THREAD) {
        this.clipboard.copy(`${origin}/discussion/${this.message.channelId}/messages/${this.message.id}`);
      }
      this.toastService.success('Copy link successfully!');
    } catch (error) {
      console.log('🚀 ~ error', error);
    }
  }

  addBookmark() {
    this.addBookmarkMessage.emit(this.message);
  }

  deleteBookmark() {
    this.removeBookmarkMessage.emit(this.message);
  }

  edit() {
    this.isEditing = true;
    this.checkShowActions();
    this.saveEdtingMsgId2Store.emit(this.message?.id);
    this.cdr.markForCheck();
  }

  delete() {
    if (this.message.metadata?.deletedAt > 0 || this.message.user !== this.meQuery.getMe().userUuid) {
      return;
    }

    this.dialog
      .open(DeleteMessageComponent, {
        width: '600px',
        data: <DeleteMessageData>{
          message: this.message,
          isPinned: this?.isPinned
        }
      })
      .afterClosed()
      .subscribe(res => {
        console.log('res: ', res);
        if (res && res.isConfirm) {
          const message = ChatMessage.createDeleteMessage(this.message);
          this.chatService.send(message);

          if (this.message?.mt == MsgType.attachment) {
            const { attachmentData } = this.message?.attachmentUI || {};
            if (attachmentData && attachmentData.mediaId) {
              this.mediaService.deleteMediaFromStorage(attachmentData.mediaId, this.message.channelId).subscribe();
            }
          }

          if (this.isPinned) {
            this.pinMessageService
              .unpinMessage(<PinMessageRequest>{
                convoId: this.message.convo,
                convoType: this.message.ct,
                userType: UserType.TeamMember,
                messageId: this.message.id
              })
              .subscribe(
                () => {
                  this.toastService.success(`Unpin message successfully!`);
                },
                err => this.toastService.error(err?.message)
              );
          }
        }

        this.appService.triggerFocusQuillEdior();
      });
  }

  createDiscussion() {
    const text =
      this.message?.mt === MsgType.attachment
        ? this.message?.body.text
        : this.elr.nativeElement.querySelector('.content_body .main-text')?.innerText;
    this.dialog
      .open(CreateDiscussionComponent, {
        data: <CreateDiscussionInput>{
          message: this.message,
          text: this.getTextInline(text)
        },
        width: '700px',
        disableClose: true
      })
      .afterClosed()
      .subscribe(() => {});
  }

  replyMessage() {
    const text =
      this.message?.mt === MsgType.attachment
        ? this.message?.body.text
        : this.elr.nativeElement.querySelector('.content_body .main-text')?.innerText;
    let userName = this.user?.displayName;
    if (!userName) {
      userName = this.userQuery.getUserByChatUuid(this.message.user)?.displayName;
    }
    const data = <ReplyMessage>{
      user: userName,
      text: this.getTextInline(text),
      message: new ChatMessage(this.message)
    };
    this.replyingMessage.emit(data);
  }

  pinMsg(isPin: boolean) {
    this.dialog.open(PinMessageComponent, {
      data: <PinMessageData>{
        message: this.message,
        isPin: isPin
      },
      width: '600px'
    });
  }

  onShowProfile($event, user: User | Integration) {
    $event.stopPropagation();
    this.showProfile.emit(<InfoShowMention>{
      xPosition: $event.x,
      yPosition: $event.y,
      member: user
    });
  }

  async onExpandMsg(isExpand: boolean) {
    if (this._isShowmore) {
      return;
    }
    this._isShowmore = true;
    if (!isExpand) {
      this.message = new ChatMessage(this._backupMessage);
    } else {
      const cloneChild: ChatMessage =
        this.configMessageOption.isBookmarkChannel && !!this.message.messageBookmark
          ? this.message.messageBookmark
          : this.message;

      let msg: ChatMessage;
      const bookmarkExpandMap = this.historyMessageQuery.getValue().bookmarkExpandMap;
      if (bookmarkExpandMap?.[cloneChild.id]) {
        msg = bookmarkExpandMap[cloneChild.id];
      } else {
        const history = await firstValueFrom(
          this.historyMessageService
            .getChannelRangeHistory(
              <FilterConvoMessageRangeRequest>{
                convoId: cloneChild.channelId,
                limit: 1,
                fromInclusive: true,
                toInclusive: true,
                from: cloneChild.id,
                to: cloneChild.id
              },
              <ConfigStore>{
                isNoStore: true,
                noLoading: true
              }
            )
            .pipe(
              catchError(err => {
                this.toastService.error(err.error);
                return of(null);
              })
            )
        );
        if (!history) {
          this._isShowmore = false;
          return;
        }
        msg = history.messages?.[0];
        this.historyMessageService.updateBookmarkExpandMap({
          [msg.id]: msg
        });
      }

      const messageChild = new ChatMessage({ ...msg });
      delete messageChild.pf;
      const indexMessageBookmark = this.message?.indexMessageBookmark;
      const data = new ChatMessage(this.message);
      data.extraData.linkedMessages.snapshots[indexMessageBookmark] = messageChild;

      this._backupMessage = new ChatMessage(this.message);
      this.message = new ChatMessage(data);
    }
    this.initMessage();
    this.isExpand = isExpand;
    this._isShowmore = false;

    // focus quill + docheck viewport
    const state = this.appQuery.getValue();
    this.appService.update({
      quillEditor: {
        ...state.quillEditor,
        triggerfocus: !state.quillEditor.triggerfocus
      },
      triggerDoCheckScroll: !state.triggerDoCheckScroll
    });

    this.cdr.markForCheck();
  }

  private initMessage() {
    this.msgReply = this.message?.messageReply;
    this.messageRender =
      this.configMessageOption.isBookmarkChannel && !this.message.messageBookmark?.isSubstring
        ? this.message.messageBookmark
        : this.message;

    if (this.configMessageOption.isBookmarkChannel) {
      this.selectConvo();
    }

    this.checkShowActions();
    this.allowRemoveBookmark = this.configMessageOption.isBookmarkChannel;
    this.allowUseReplyAction =
      !this.configMessageOption.isBookmarkChannel && this.message.aclActions.allowUseReplyAction;
    this.allowUseCreateThreadAction =
      !this.configMessageOption.isBookmarkChannel && this.message.aclActions.allowUseCreateThreadAction;
    this.allowEditDeleteMessage =
      this.message.user === this.meQuery.getMe().userUuid &&
      !this.configMessageOption.isBookmarkChannel &&
      this.message.aclActions.allowEditDeleteMessage;
    this.allowPinOrUnpinAction =
      !this.configMessageOption.isBookmarkChannel && this.message.aclActions.allowPinOrUnpinAction;
    this.allowCopyLink =
      !this.configMessageOption.isBookmarkChannel &&
      this.message.id &&
      [ConvoType.groupchat, ConvoType.THREAD].includes(this.message.ct);
    this.withinLimitV2 = this.checkTimeoutDeleteEditMsg();
  }

  private selectUser() {
    const msg = this.configMessageOption.isBookmarkChannel ? this.message.messageBookmark : this.message;
    if (msg.user === this._preUser) {
      return;
    }
    this._preUser = msg.user;

    if (msg.ut === UserType.Customer) {
      const conversion = this.convoGroupQuery.getConvo(msg.channelId);
      this.user = conversion?.customerInfo?.name ? new User({ displayName: conversion.customerInfo.name }) : null;
      if (!this.user) {
        this.user$ = this.contactQuery.selectEntity(conversion?.customerInfo?.uuid).pipe(
          map(contact => new User({ displayName: contact?.displayName || UNKNOWN_USER })),
          tap(user => (this.user = user))
        );
      }
    } else if (msg.ut === UserType.Webhook || msg.ut === UserType.TeamBot) {
      this.user = this.userQuery.getUserByChatUuid(msg.user);
      if (!this.user) {
        this.user$ = this.userQuery.selectUserByChatUuid(msg.user).pipe(
          map(
            user =>
              user ||
              new User({
                displayName: 'Unknown Bot',
                isBot: true
              })
          ),
          tap(user => (this.user = user))
        );
        const isFetchedApi = this.userQuery.getValue()?.loaded;
        if (isFetchedApi) {
          this.userService.findOneByUuidAndUserTypeSequentially(msg.user, 'chatId').subscribe();
        }
      }
    } else {
      if (msg.hs) {
        const hs = this.hyperspaceQuery.getHyperByHyperspaceId(msg.hs);
        this.user = hs?.allMembers?.find(x => x.userUuid === msg.user);
        if (!this.user) {
          this.user$ = this.hyperspaceQuery.selectHyperByHyperspaceId(msg.hs).pipe(
            map(
              hyper =>
                hyper?.allMembers?.find(x => x.userUuid === msg.user) || new User({ displayName: 'Disabled User' })
            ),
            tap(user => (this.user = user))
          );
        }
      } else {
        this.user = this.userQuery.getUserByChatUuid(msg.user);
        if (!this.user) {
          this.user$ = this.userQuery.selectUserByChatUuid(msg.user).pipe(
            map(x => x || new User({ displayName: 'Unknown User' })),
            tap(user => (this.user = user))
          );
          const isFetchedApi = this.userQuery.getValue()?.loaded;
          if (isFetchedApi) {
            this.userService.findOneByUuidAndUserTypeSequentially(msg.user, 'chatId').subscribe();
          }
        }
      }
    }
  }

  private selectConvo() {
    const msg = this.message?.messageBookmark;
    if ([ConvoType.groupchat, ConvoType.THREAD].includes(msg?.ct)) {
      this.nameConvo = this.channelQuery.getEntity(msg.channelId)?.displayName;
      this.canJumpChannel = !!this.nameConvo;
      if (!this.nameConvo) {
        this.nameConvo$ = this.channelQuery.selectEntity(msg.channelId, 'displayName').pipe(
          map(x => {
            this.canJumpChannel = !!x;
            return x || 'Unknown Channel';
          })
        );
      }
    } else if (msg?.ct === ConvoType.direct) {
      this.canJumpChannel = true;
    }
  }

  private getTextInline(text: string) {
    text = text?.split('<br>')?.join(' ');
    text = text?.split('\n')?.join(' ');
    return text;
  }

  private checkShowActions() {
    this.defaultAllowActions =
      !this.message.aclActions.isDeletedMessage && !this.isEditing && !this.configMessageOption.isHideAction;
    this.isShowActions = this.configMessageOption.isMyChannel && this.defaultAllowActions;
  }

  private checkTimeoutDeleteEditMsg() {
    if (!this.allowEditDeleteMessage) {
      return false;
    }
    const deta = this.timeService.nowInMillis() - this.message.ts;
    return deta < MessageConstants.TIMEOUT;
  }
}

export interface InfoFileMarkdown {
  fileKey: string;
  fileName: string;
}
