<!-- // dont remove main-text , use in reply -->
<div class="main-text flex justify-content-start align-items-start">
  <div class="prechat-survey flex column gap-025">
    <div class="header flex justify-content-center align-items-center" style="flex: 1 1 40px">
      <span class="label">Prechat survey</span>
    </div>
    <div class="body">
      <div class="flex column gap-025">
        <span class="label">Your name:</span>
        <span class="value">{{ message?.body?.data?.name }}</span>
      </div>
      <div class="flex column gap-025">
        <span class="label">E-mail:</span>
        <span class="value">
          <a href="mailto:{{ message?.body?.data?.email }}" target="_blank">{{ message?.body?.data?.email }}</a>
        </span>
      </div>
      <div class="flex column gap-025" *ngIf="message?.body?.data?.number">
        <span class="label">Number:</span>
        <span class="value">{{ message.body.data.number }}</span>
      </div>
    </div>
  </div>
</div>
