import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import {
  CallQueueInboxData,
  CallbackQueueInboxData,
  GetReportV4Payload,
  Leg,
  Period,
  ReportV4Code,
  StatusQueue,
  TxnEndInbox,
  V4Service
} from '@b3networks/api/data';
import { Txn, TxnQuery, TxnService } from '@b3networks/api/inbox';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { addHours, subHours } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Observable, catchError, combineLatest, of } from 'rxjs';
import { PER_PAGE_LEG } from '../../../model/journey-block.model';

@Component({
  selector: 'b3n-txn-incoming',
  templateUrl: './txn-incoming.component.html',
  styleUrls: ['./txn-incoming.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TxnIncomingComponent extends DestroySubscriberComponent implements OnInit {
  @Input() txn: Txn;
  @Input() txnHistory: TxnEndInbox;
  @Input() timezone: string;
  @Input() isChild: boolean;

  viewDate$: Observable<number>;

  legs: Leg[] = [];
  callbackData: CallbackQueueInboxData;
  hasCallback: boolean;
  hasVoicemail: boolean;
  enterQueue: CallQueueInboxData;

  isFetchingLegs = true;

  constructor(
    private txnQuery: TxnQuery,
    private txnService: TxnService,
    private v4Service: V4Service,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.viewDate$ = this.txnQuery.selectUIState(this.txn.txnUuid, 'viewDate');

    this.isFetchingLegs = true;
    this.checkEnterQueue(this.txn);
  }

  private checkEnterQueue(txn: Txn) {
    const req = <GetReportV4Payload>{
      startTime: format(
        subHours(utcToZonedTime(new Date(this.txn.createdAt), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      endTime: format(
        addHours(utcToZonedTime(new Date(this.txn?.endedAt || Date.now()), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      filter: {
        txnUuid: txn.txnUuid
      }
    };
    this.enterQueue = null;
    this.hasCallback = false;
    this.hasVoicemail = false;
    this.v4Service
      .getReportData<CallQueueInboxData>(
        Period.dump,
        ReportV4Code.txnJourney.enterQueue,
        req,
        new Pageable(1, 5),
        false
      )
      .subscribe(
        data => {
          this.enterQueue = data.rows?.find(x => x.txnUuid === txn.txnUuid);

          this.hasCallback = this.enterQueue?.result === StatusQueue.callbackRequest;

          this.hasVoicemail = this.enterQueue?.result === StatusQueue.voicemail;

          if (this.enterQueue) {
            this.txnService.updateTxnViewState(txn.txnUuid, {
              enterQueue: this.enterQueue
            });
            if (this.enterQueue?.entered || this.enterQueue?.timeout) {
              this.fetchLegs(txn);
            } else {
              if (this.hasCallback) {
                this.isFetchingLegs = true;
                this.fetchCallbackData(txn);
              } else {
                this.isFetchingLegs = false;
              }
            }
          } else {
            this.txnService.updateTxnViewState(txn.txnUuid, {
              enterQueue: <CallQueueInboxData>{
                answerTime: this.txnHistory?.endTime,
                txnUuid: this.txnHistory.txnUuid,
                entered: false,
                callerId: this.txnHistory?.from?.number
              }
            });
            this.fetchLegs(txn);
          }

          this.cdr.markForCheck();
        },
        err => {}
      );
  }

  private fetchLegs(txn: Txn, page = 1) {
    if (txn.txnUuid !== this.txn?.txnUuid) {
      return;
    }

    if (page === 1) {
      this.isFetchingLegs = true;
    }

    if (page === 1) {
      this.legs = [];
    }

    const req = <GetReportV4Payload>{
      startTime: format(
        subHours(utcToZonedTime(new Date(this.txn.createdAt), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      endTime: format(
        addHours(utcToZonedTime(new Date(this.txn?.endedAt || Date.now()), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      filter: {
        txnUuid: txn.txnUuid
      },
      scope: 'org'
    };

    combineLatest([
      this.v4Service.getReportData<Leg>(
        Period.dump,
        ReportV4Code.txnJourney.leg,
        req,
        new Pageable(page, PER_PAGE_LEG),
        false
      ),
      this.callback$(txn)
    ]).subscribe(
      ([data, callbackData]) => {
        if (this.hasCallback && callbackData) {
          this.callbackData = callbackData?.rows?.find(x => x.triggerTxnUuid === txn.txnUuid);
        }

        const dataLegs = data.rows?.map(x => new Leg({ ...x }));
        if (page === 1) {
          // remove first leg
          dataLegs.shift();
        }
        this.legs = [...this.legs, ...dataLegs];

        if (data.rows.length === PER_PAGE_LEG) {
          // get more
          page++;
          this.fetchLegs(txn, page);
        } else {
          this.isFetchingLegs = false;
        }
        this.cdr.markForCheck();
      },
      err => {
        this.isFetchingLegs = false;
      }
    );
  }

  private fetchCallbackData(txn: Txn) {
    this.callback$(txn).subscribe(
      callbackData => {
        if (this.hasCallback && callbackData) {
          this.callbackData = callbackData?.rows?.find(x => x.triggerTxnUuid === txn.txnUuid);
        }
        this.isFetchingLegs = false;
        this.cdr.markForCheck();
      },
      err => {
        this.isFetchingLegs = false;
      }
    );
  }

  private callback$(txn: Txn) {
    const reqCallBack = <GetReportV4Payload>{
      startTime: format(
        subHours(utcToZonedTime(new Date(this.txn.createdAt), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      endTime: format(
        addHours(utcToZonedTime(new Date(this.txn?.endedAt || Date.now()), this.timezone), 5),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        {
          timeZone: this.timezone
        }
      ),
      filter: {
        triggerTxnUuid: txn.txnUuid
      }
    };

    return this.v4Service
      .getReportData<CallbackQueueInboxData>(
        Period.dump,
        ReportV4Code.txnJourney.callback,
        reqCallBack,
        new Pageable(1, 5),
        false
      )
      .pipe(catchError(() => of(null)));
  }
}
