import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgentQuery } from '@b3networks/api/callcenter';
import { Channel, ChannelService, GroupType, Privacy, UpdateChannelReq } from '@b3networks/api/chat';
import { AssignLeftReq, TxnService } from '@b3networks/api/inbox';
import { ConversationGroup, MeQuery, TXN_SECTION, User, UserQuery, UserStatus } from '@b3networks/api/workspace';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, startWith } from 'rxjs/operators';
import { SupportedConvo } from '../../core/adapter/convo-helper.service';

const DEFAULT_SIZE = 5;

@Component({
  selector: 'csh-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss']
})
export class InviteMemberComponent implements OnInit {
  readonly TXN_SECTION = TXN_SECTION;
  readonly GroupType = GroupType;
  readonly Privacy = Privacy;
  readonly UserStatus = UserStatus;
  me: User;
  members: User[] = [];
  removable = true;
  addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];
  memberCtrl = new UntypedFormControl();
  filteredMembers: Observable<User[]>;
  key = '';

  generalButtonOptions = {
    active: false,
    spinnerSize: 18,
    raised: true,
    stroked: false,
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate'
  };

  ctaButton: string;
  processing: boolean;

  @ViewChild('memberInput') memberInput: ElementRef;

  constructor(
    private meQuery: MeQuery,
    private userQuery: UserQuery,
    private agentQuery: AgentQuery,
    private txnService: TxnService,
    private channelService: ChannelService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public convo: SupportedConvo,
    private dialogRef: MatDialogRef<InviteMemberComponent>,
    private ngZone: NgZone
  ) {
    this.ctaButton =
      this.convo.type === GroupType.WhatsApp || this.convo.type === GroupType.SMS
        ? this.convo.members?.length === 1
          ? 'Assign'
          : 'Transfer'
        : 'Invite';
  }

  ngOnInit() {
    this.me = this.meQuery.getMe();
    this.updateFilteredMembers();
  }

  updateFilteredMembers() {
    const userIds =
      this.convo instanceof ConversationGroup
        ? this.convo.members.map(m => m.chatUserUuid)
        : this.convo instanceof Channel
        ? this.convo.participants.map(m => m.userID)
        : [];
    const listUser = this.userQuery.getAllUserByChatUuid(userIds);
    this.filteredMembers = this.memberCtrl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      startWith(this.memberCtrl.value),
      map((name: string | null) => {
        if (typeof name === 'string' && name) {
          return this.filter(name);
        }
        return this.userQuery
          .getAllUsers()
          .filter(
            member =>
              member.identityUuid !== this.me.identityUuid &&
              !this.members.map(m => m.identityUuid).includes(member.identityUuid) &&
              !listUser?.map(m => m.identityUuid).includes(member.identityUuid)
          )
          .slice(0, DEFAULT_SIZE);
      })
    );
  }

  sortBy(members: User[]) {
    return members?.sort((a, b) => a?.displayName?.trim()?.localeCompare(b?.displayName?.trim()));
  }

  trackBy(_, member: User) {
    return member != null ? member.identityUuid : null;
  }

  selected(event: MatAutocompleteSelectedEvent) {
    if ((this.convo.type === GroupType.WhatsApp || this.convo.type === GroupType.SMS) && this.members.length > 0) {
      return;
    }

    if (!this.members.includes(event.option.value)) {
      this.members.push(event.option.value);
      this.updateFilteredMembers();
    }
    this.memberInput.nativeElement.value = '';
    this.memberCtrl.setValue(null);
    this.memberCtrl.disable();
    this.memberCtrl.enable();

    setTimeout(() => {
      this.memberInput.nativeElement.focus();
    }, 300);
  }

  filter(name: string) {
    this.key = name;

    const userIds =
      this.convo instanceof ConversationGroup
        ? this.convo.members.map(m => m.chatUserUuid)
        : this.convo instanceof Channel
        ? this.convo.participants.map(m => m.userID)
        : [];
    const users = this.userQuery.getAllUserByChatUuid(userIds);
    return this.userQuery
      .getAllUsersContains(name, -1)
      .filter(
        member =>
          member.identityUuid !== this.me.identityUuid &&
          !this.members.map(m => m.identityUuid).includes(member.identityUuid) &&
          !users?.map(m => m.identityUuid).includes(member.identityUuid)
      );
  }

  remove(member: User) {
    const index = this.members.indexOf(member);

    if (index >= 0) {
      this.members.splice(index, 1);
    }
  }

  submit() {
    if (!this.members || this.members.length === 0) {
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
      return;
    }

    this.processing = true;
    if (this.convo.type === GroupType.WhatsApp || this.convo.type === GroupType.SMS) {
      const req = <AssignLeftReq>{
        agentUuid: this.members[0].identityUuid,
        txnUuid: this.convo.id
      };

      this.txnService.assign(req).subscribe(_ => {});

      this.processing = false;
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    } else {
      const req = <UpdateChannelReq>{
        add: this.members.map(x => x.userUuid)
      };
      this.channelService
        .addOrRemoveParticipants(this.convo.id, req)
        .pipe(finalize(() => (this.processing = false)))
        .subscribe(
          _ => {
            // https://github.com/angular/components/issues/13640
            this.ngZone.run(() => {
              this.dialogRef.close();
            });
          },
          err => this.toastService.error('Cannot invite member, try again!')
        );
    }
  }
}
