import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonLoadingModule, SharedUiMaterialModule } from '@b3networks/shared/ui/material';
import { AnnouncementsComponent } from './announcements.component';
import { StoreAnnouncementComponent } from './store-announcement/store-announcement.component';

@NgModule({
  declarations: [AnnouncementsComponent, StoreAnnouncementComponent],
  imports: [CommonModule, SharedUiMaterialModule, FormsModule, ReactiveFormsModule, ButtonLoadingModule],
  exports: [AnnouncementsComponent, StoreAnnouncementComponent]
})
export class AnnouncementsModule {}
