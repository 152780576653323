<quill-editor
  customToolbarPosition="bottom"
  trackChanges="all"
  [modules]="modules"
  [formats]="formats"
  [placeholder]="data.placeholder"
  [preserveWhitespace]="true"
  (onEditorCreated)="onCreatedQuill($event)"
  [sanitize]="false"
  class="editor"
>
</quill-editor>
<div class="toolbar flex justify-content-end align-items-center gap-8">
  <mat-icon
    class="icon s-24 action material-symbols-rounded"
    *ngIf="data.showCannedRespInboxBy && (cannedResponses$ | async)?.length > 0"
    [class.disabled]="websocketStatus !== SocketStatus.opened"
    [matMenuTriggerFor]="cannedResponseMenu"
    matTooltip="Canned Response"
  >
    assistant
  </mat-icon>
  <input
    type="file"
    id="fileInput"
    multiple
    style="display: none"
    [(ngModel)]="file"
    (change)="upload($event)"
    #fileUpload
  />

  <mat-icon
    class="icon s-24 action material-symbols-rounded"
    *ngIf="data.enableEmoji"
    [class.disabled]="websocketStatus !== SocketStatus.opened"
    (click)="triggerShowEmojiBox()"
    matTooltip="Emoji"
  >
    mood
  </mat-icon>

  <mat-icon
    class="icon s-24 action material-symbols-rounded"
    *ngIf="data.enableUpload"
    [class.disabled]="websocketStatus !== SocketStatus.opened"
    (click)="fileUpload.click()"
    matTooltip="Upload"
  >
    attach_file
  </mat-icon>

  <mat-icon
    class="icon s-24 action material-symbols-rounded fill"
    *ngIf="data.showSendButton"
    [class.disabled]="websocketStatus !== SocketStatus.opened || isDisableSend || isDisabled"
    color="primary"
    (click)="handleEnterMessage()"
    matTooltip="Send message"
  >
    send
  </mat-icon>
</div>

<mat-menu class="cannedResponseMenu" #cannedResponseMenu="matMenu">
  <button mat-menu-item (click)="addCannedResponse(item?.content)" *ngFor="let item of cannedResponses$ | async">
    {{ item.name }}
  </button>
</mat-menu>
