import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Channel, ChannelService, ChannelType } from '@b3networks/api/chat';
import { StarService } from '@b3networks/api/workspace';

@Component({
  selector: 'b3n-channel-name',
  templateUrl: './channel-name.component.html',
  styleUrls: ['./channel-name.component.scss']
})
export class ChannelNameComponent implements OnInit {
  @Input() channel: Channel;
  @Input() isShowParentThread: boolean;
  @Input() isHideStarred: boolean;
  @Input() highlight: string;
  @Input() noHoverChannelName: boolean;

  readonly ChannelType = ChannelType;

  constructor(
    private router: Router,
    private starService: StarService,
    private dialog: MatDialog,
    private channelService: ChannelService
  ) {}

  ngOnInit() {}

  openParent($event, parentId: string) {
    $event.stopPropagation();
    this.channelService.updateChannelViewState(parentId, {
      activeThreadId: this.channel.id
    });
    this.router.navigate(['conversations', parentId]);
  }

  unstarConvo($event) {
    $event.stopPropagation();
    this.starService.unstarChannelTeamChat(this.channel.id).subscribe(() => {
      this.channelService.updateOneChannel(<Channel>{
        id: this.channel.id,
        isStarred: false
      });
    });
  }

  starConvo($event) {
    $event.stopPropagation();
    this.starService.starChannelTeamChat(this.channel.id).subscribe(() => {
      this.channelService.updateOneChannel(<Channel>{
        id: this.channel.id,
        isStarred: true
      });
    });
  }
}
