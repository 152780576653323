<h1 mat-dialog-title>Edit Channel Description</h1>
<div mat-dialog-content>
  <mat-form-field>
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10"
      [formControl]="description"
      [errorStateMatcher]="matcher"
    ></textarea>
    <mat-error *ngIf="description.hasError('maxlength')"> Maximum 1000 characters </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions class="flex justify-content-end">
  <button mat-stroked-button mat-dialog-close class="btnCancel">Cancel</button>
  <button mat-flat-button [loading]="processing" [disabled]="description.invalid" color="primary" (click)="submit()">
    Save
  </button>
</div>
