import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChatMessage } from '@b3networks/api/chat';

@Component({
  selector: 'csh-summary-message',
  templateUrl: './summary-message.component.html',
  styleUrls: ['./summary-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryMessageComponent {
  @Input() message: ChatMessage;

  constructor() {}
}
