<div class="container flex column gap-8" (click)="$event.stopPropagation()" >
  <mat-tab-group animationDuration="0ms" (selectedIndexChange)="onChange($event)" [selectedIndex]="selectedIndex">
    <mat-tab label="Active">
      <b3n-thread-active-menu
        [channel]="channel"
        (click)="$event.stopPropagation()"
        (selectThread)="selectThread.emit($event)"
      >
      </b3n-thread-active-menu>
    </mat-tab>
    <mat-tab label="Close">
      <b3n-thread-closed-menu
        [channel]="channel"
        (click)="$event.stopPropagation()"
        (selectThread)="selectThread.emit($event)"
      ></b3n-thread-closed-menu>
    </mat-tab>
  </mat-tab-group>
</div>
