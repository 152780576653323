<h1 mat-dialog-title>{{ titleDialog }}</h1>

<div mat-dialog-content>
  <ng-container *ngIf="loading; else limitCreateWa">
    <div class="loading flex justify-content-center align-items-center">
      <mat-spinner diameter="35"></mat-spinner>
    </div>
  </ng-container>

  <ng-template #limitCreateWa>
    <div *ngIf="isLimitCreateWa$ | async; else activeTxn" class="limit-create-wa">
      You've reached your capacity limit. Triggering a WhatsApp back transaction is currently unavailable. Please ensure
      existing tasks are managed before initiating further actions.
    </div>
  </ng-template>

  <ng-template #activeTxn>
    <ng-container *ngIf="txnActive; else viewForm">
      <div class="active-txns flex column gap-16">
        <span class="secondary-text">
          You cannot WhatsApp back to this customer because there is an active transaction.
        </span>
        <div class="flex column gap-8">
          <div class="flex gap-8 align-items-center">
            <label>TXN UUID:</label>
            <button
              class="label box"
              mat-button
              matTooltip="Click to copy"
              [cdkCopyToClipboard]="txnActive.txnUuid"
              (click)="$event.stopPropagation()"
            >
              {{ txnActive.txnUuid | slice: 0:8 }}
            </button>
            <mat-icon
              *ngIf="txnActive.hasPermission"
              class="icon s-16 cursor-pointer"
              matTooltip="Redirect to this transaction"
              (click)="navigateTxn(txnActive)"
              svgIcon="b3n_direct_link"
            >
            </mat-icon>
          </div>
          <div class="flex gap-8">
            <label>Inbox:</label>
            <ng-container *ngIf="txnActive?.inbox; else emptyText">
              <div>{{ txnActive.inbox?.name }}</div>
            </ng-container>
          </div>
          <div class="flex align-items-center gap-8">
            <label>Assigned agent:</label>
            <ng-container *ngIf="txnActive.lastAssignedAgents?.length > 0; else emptyText">
              <ng-container
                *ngIf="
                  txnActive.lastAssignedAgents[txnActive.lastAssignedAgents.length - 1]
                    | selectUser: false
                    | async as member
                "
              >
                <b3n-avatar-user
                  [member]="member"
                  [showStatus]="false"
                  [disabledClick]="true"
                  [isSmall]="true"
                  [showDisplayName]="false"
                ></b3n-avatar-user>
                <span>{{ member.displayName }}</span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #viewForm>
    <div class="flex gap-16">
      <div [formGroup]="form" class="left flex column gap-16">
        <mat-form-field [hideRequiredMarker]="true">
          <mat-label>Sender ID</mat-label>
          <mat-select formControlName="wabaPhoneNumberId" [compareWith]="compareNumbers">
            <mat-option *ngFor="let item of numberWa$ | async" [value]="item">{{ item.displayPhoneNumber }}</mat-option>
          </mat-select>
          <mat-error *ngIf="wabaPhoneNumberId.invalid">Field is required</mat-error>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="true">
          <mat-label>WhatsApp Inbox</mat-label>
          <mat-select formControlName="inboxUuid" [compareWith]="compareInbox">
            <mat-option *ngFor="let item of inboxes$ | async" [value]="item">{{ item.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="inboxUuid.invalid">Field is required</mat-error>
        </mat-form-field>

        <div class="flex gap-8">
          <mat-form-field [hideRequiredMarker]="true" class="w-full" *ngIf="templates$ | async as templates">
            <mat-label>
              <span class="flex align-items-center gap-4">
                Message template
                <mat-icon
                  class="s-15 material-icons-outlined"
                  matTooltip="Create and manage your WhatsApp Message Templates in your WhatsApp Business account. Only show your approved message template(s)."
                >
                  info
                </mat-icon>
              </span>
            </mat-label>
            <mat-select
              formControlName="template"
              [compareWith]="compareTemplate"
              [disabled]="templates.length === 0 || refreshing"
            >
              <mat-option *ngIf="templates.length === 0" value="null">No data available</mat-option>
              <mat-option *ngFor="let item of templates" [value]="item">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="pt-4">
            <button mat-icon-button [loading]="refreshing" (click)="onRefresh()" [matTooltip]="tooltipRefresh">
              <mat-icon class="s-20">refresh</mat-icon>
            </button>
          </div>
        </div>

        <div
          class="accordion"
          *ngIf="
            template.value &&
            (templateValue?.component?.header?.format === ComponentStructureFormat.IMAGE ||
              templateValue?.component?.header?.format === ComponentStructureFormat.VIDEO ||
              templateValue?.component?.header?.format === ComponentStructureFormat.DOCUMENT ||
              templateValue?.component?.header?.hasParameters ||
              templateValue?.component?.body?.hasParameters ||
              buttonsHasParameter)
          "
        >
          <mat-expansion-panel
            class="mat-elevation-z0"
            *ngIf="
              templateValue?.component?.header?.format === ComponentStructureFormat.IMAGE ||
              templateValue?.component?.header?.format === ComponentStructureFormat.VIDEO ||
              templateValue?.component?.header?.format === ComponentStructureFormat.DOCUMENT ||
              templateValue?.component?.header?.hasParameters
            "
            expanded
          >
            <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
              <mat-panel-title>Header</mat-panel-title>
            </mat-expansion-panel-header>

            <div
              class="flex column gap-8 btn-upload-container"
              *ngIf="
                templateValue?.component?.header?.format === ComponentStructureFormat.IMAGE ||
                templateValue?.component?.header?.format === ComponentStructureFormat.VIDEO ||
                templateValue?.component?.header?.format === ComponentStructureFormat.DOCUMENT
              "
              [ngSwitch]="templateValue?.component?.header?.format"
            >
              <div class="file-name" *ngIf="fileUI">{{ file?.name }}</div>

              <ng-container *ngSwitchCase="ComponentStructureFormat.IMAGE">
                <input
                  #uploadFile
                  type="file"
                  hidden
                  (change)="onFileChange($event, ComponentStructureFormat.IMAGE)"
                  accept="image/png, image/jpg, image/jpeg"
                />
                <div class="btn-upload" (click)="uploadFile.click()">
                  <span class="title">{{ fileUI ? 'Choose another' : 'Upload' }} image</span>
                  <span class="sub-title" *ngIf="!fileUI">Max file size {{ MAX_FILE_SIZE }}MB</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="ComponentStructureFormat.VIDEO">
                <input
                  #uploadFile
                  type="file"
                  hidden
                  (change)="onFileChange($event, ComponentStructureFormat.VIDEO)"
                  accept="video/mp4"
                />
                <div class="btn-upload" (click)="uploadFile.click()">
                  <span class="title">{{ fileUI ? 'Choose another' : 'Upload' }} video</span>
                  <span class="sub-title" *ngIf="!fileUI">Max file size {{ MAX_FILE_SIZE }}MB</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="ComponentStructureFormat.DOCUMENT">
                <input
                  #uploadFile
                  type="file"
                  hidden
                  (change)="onFileChange($event, ComponentStructureFormat.DOCUMENT)"
                  accept="application/pdf"
                />
                <div class="btn-upload" (click)="uploadFile.click()">
                  <span class="title">{{ fileUI ? 'Choose another' : 'Upload' }} file</span>
                  <span class="sub-title" *ngIf="!fileUI">Max file size {{ MAX_FILE_SIZE }}MB</span>
                </div>
              </ng-container>
            </div>

            <div
              class="flex column gap-8"
              *ngIf="templateValue?.component?.header?.hasParameters"
              [formGroup]="formHeader"
            >
              <mat-form-field
                [hideRequiredMarker]="true"
                *ngFor="let item of templateValue.component.header.arrayParams"
              >
                <mat-label>Content of {{ item }}</mat-label>
                <input matInput [formControlName]="item" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0" expanded *ngIf="templateValue?.component?.body?.hasParameters">
            <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
              <mat-panel-title>Body</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="flex column gap-8" [formGroup]="formBody">
              <mat-form-field [hideRequiredMarker]="true" *ngFor="let item of templateValue.component.body.arrayParams">
                <mat-label>Content of {{ item }}</mat-label>
                <input matInput [formControlName]="item" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0" expanded *ngIf="buttonsHasParameter">
            <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
              <mat-panel-title>Button</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex column gap-8" [formGroup]="formButton">
              <ng-container *ngFor="let button of templateValue.component.buttons; let i = index">
                <mat-form-field [hideRequiredMarker]="true" *ngFor="let item of button.arrayParams">
                  <mat-label>{{ button.text }}</mat-label>
                  <input
                    matInput
                    [formControlName]="item"
                    (focus)="activeBtns[i] = true"
                    (blur)="activeBtns[i] = false"
                  />
                </mat-form-field>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <div class="right flex column">
        <div class="title text-center">Preview Message</div>
        <div class="preview flex column" [class]="(isDarkTheme$ | async) ? 'dark' : 'light'">
          <ng-container *ngIf="!template.value; else showTemplate">
            <div class="message text-center">Please choose a message template to preview!</div>
          </ng-container>
          <ng-template #showTemplate>
            <div class="message flex column" *ngIf="messageHeader || messageBody">
              <ng-container *ngIf="templateValue.component as component">
                <ng-container *ngIf="component.header && messageHeader">
                  <div
                    class="header text"
                    *ngIf="component.header.format === ComponentStructureFormat.TEXT"
                    [innerHTML]="messageHeader"
                  ></div>

                  <img
                    class="header image"
                    *ngIf="component.header.format === ComponentStructureFormat.IMAGE"
                    [src]="messageHeader"
                  />

                  <video
                    controls
                    class="header video"
                    *ngIf="component.header.format === ComponentStructureFormat.VIDEO"
                    [src]="messageHeader"
                  ></video>

                  <div class="header document" *ngIf="component.header.format === ComponentStructureFormat.DOCUMENT">
                    <div class="flex justify-content-start align-items-center gap-8">
                      <img width="32px" [src]="fileUI ? fileUI.iconUrl : 'assets/icons/attachment/attach_file.png'" />
                      <div class="flex column justify-content-start align-items-start gap-3">
                        <span class="semi-bold">{{ file ? file.name : 'Example.pdf' }}</span>
                        <span class="description" *ngIf="!fileUI">PDF . 0 KB</span>
                        <span class="description" *ngIf="fileUI">
                          {{ fileUI.ext | uppercase }} . {{ fileUI.size | fileSize }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="content flex column">
                  <ng-container *ngIf="component.body && messageBody">
                    <div class="body" [innerHTML]="messageBody"></div>
                  </ng-container>
                  <div class="buttons" *ngIf="component.buttons">
                    <ng-container *ngFor="let b of component.buttons; let i = index">
                      <div class="button" [class.active]="activeBtns[i]">
                        <mat-icon svgIcon="{{ b.icon }}"></mat-icon>
                        <span>{{ b.text }}</span>
                      </div>
                      <mat-divider *ngIf="i < component.buttons.length - 1"></mat-divider>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="component.footer && component.footer.text">
                    <div class="footer" [innerHTML]="component.footer?.text"></div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="!loading" mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">Cancel</button>
  <button
    *ngIf="!txnActive && (isLimitCreateWa$ | async) === false"
    mat-flat-button
    color="primary"
    [disabled]="form.invalid || formBody.invalid || formHeader.invalid || formButton.invalid"
    [loading]="fetching || uploading"
    (click)="createWa()"
  >
    Send
  </button>
</div>

<ng-template #emptyText>
  <div>-</div>
</ng-template>

<ng-template #toast let-txn="txn" let-toastData="toastData">
  <b3n-custom-toast-wa [txn]="txn" [data]="toastData"></b3n-custom-toast-wa>
</ng-template>
