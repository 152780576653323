import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AgentService, Me, RequestFinishTxnAcwV2, SystemStatusCode } from '@b3networks/api/callcenter';
import { BACKBONE_SUPPORTED, BackboneFieldsType, FieldType, FormQuery, UserDefinedField } from '@b3networks/api/data';
import { DestroySubscriberComponent, WindownActiveService } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, finalize } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppQuery } from '../../../core/state/app.query';
import { TemplateFieldComponent } from '../template-field/template-field.component';

@Component({
  selector: 'b3n-inbound-txn',
  templateUrl: './inbound-txn.component.html',
  styleUrls: ['./inbound-txn.component.scss']
})
export class InboundTxnComponent extends DestroySubscriberComponent implements OnInit, OnChanges {
  @ViewChildren(TemplateFieldComponent) comps: QueryList<TemplateFieldComponent>;

  readonly ObjectKeys = Object.keys;
  readonly SystemStatusCode = SystemStatusCode;
  readonly endedStatuses = ['agentMarkCallDone', 'hangupBySupervisor', 'ended', 'callback', 'voicemail'];

  loading: boolean;
  templateFields: UserDefinedField[] = [];
  formGroup = this.fb.group({});

  remainTime$: Observable<number>;

  @Input() me: Me;

  @Output() finishCall = new EventEmitter<boolean>();

  get isNotACW() {
    return !this.endedStatuses.includes(this.me?.assignedTxn?.status) && this.me?.systemStatus !== SystemStatusCode.acw;
  }

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private agentService: AgentService,
    private formQuery: FormQuery,
    private appQuery: AppQuery,
    private windownActiveService: WindownActiveService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['me'] && this.me) {
      this.showNotificationIfNeeded(this.me);
    }
  }

  ngOnInit() {
    this.remainTime$ = this.appQuery.remainTime$;

    let _preValue: number;
    this.appQuery.systemStatusExpiredAt$.pipe(takeUntil(this.destroySubscriber$)).subscribe(systemStatusExpiredAt => {
      if (!systemStatusExpiredAt && !!_preValue) {
        console.log('windowActiveStatus: ', this.windownActiveService.windowActiveStatus);
        if (this.windownActiveService.windowActiveStatus) {
          this.finishAcw();
        } else {
          this.finishCall.emit(true);
        }
      }
      _preValue = systemStatusExpiredAt;
    });

    const templateUuid = this.me?.assignedTxn?.queue?.agentWorkflowConfig?.noteTemplateId;
    const template = this.formQuery.getEntity(templateUuid);
    const formOrder = template?.formOrder || [];
    this.templateFields = [];
    formOrder.forEach(key => {
      const data = template.fields?.find(x => x.key === key);
      if (data) {
        this.templateFields.push(data);
      }
    });

    this.templateFields.forEach(field => {
      this.setValueFormControl(field);
    });
  }

  finishAcw() {
    if (!this.loading) {
      this.loading = true;
      this.agentService
        .finishTxnAcwV2(this.buildRequest())
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          res => {
            this.toastService.success('Call has been finished');
            this.finishCall.emit(true);
          },
          err => {
            this.toastService.error(err.message);
            this.finishCall.emit(true);
          }
        );
    }
  }

  private showNotificationIfNeeded(me: Me) {
    if (!this.me || !this.me.assignedTxn || !me.assignedTxn) {
      return;
    }
    if (this.me.assignedTxn.txnUuid === me.assignedTxn.txnUuid) {
      return;
    }
    this.toastService.success(`You have been assigned to new incoming call from ${me.assignedTxn.customerNumber}`);
  }

  private buildRequest() {
    const values = {};
    const templateUuid = this.me?.assignedTxn?.queue?.agentWorkflowConfig?.noteTemplateId;
    const template = this.formQuery.getEntity(templateUuid);
    template.fields?.forEach(field => {
      const prop = field.key;
      const form = this.formGroup.get(prop);
      if (form && form.valid) {
        values[prop] = form.value;
      } else {
        if (BACKBONE_SUPPORTED.includes(prop)) {
          if (prop === BackboneFieldsType.inboxName) {
            values[prop] = this.me?.assignedTxn?.queue?.label;
          } else if (prop === BackboneFieldsType.inboxUuid) {
            values[prop] = this.me?.assignedTxn?.queue?.uuid;
          } else if (prop === BackboneFieldsType.txnUuid) {
            values[prop] = this.me.assignedTxn.txnUuid;
          }
        }
      }
    });

    const noteTemplateId = this.me?.assignedTxn?.queue?.agentWorkflowConfig?.noteTemplateId;
    const request = <RequestFinishTxnAcwV2>{
      noteVersion: '2',
      noteTemplateId,
      tag: values,
      txnUuid: this.me.assignedTxn.txnUuid
    };

    return request;
  }

  private setValueFormControl(field: UserDefinedField) {
    const tag = this.appQuery.getValue().popupState?.tag || {};
    const value = tag?.[field.key] as any;
    const control = this.fb.control('');
    switch (field?.type) {
      case FieldType.text:
        if (!field.isBackbone) {
          control.setValidators(Validators.maxLength(256));
        }
        control.setValue(value);
        break;
      case FieldType.textarea:
        if (!field.isBackbone) {
          control.setValidators(Validators.maxLength(2000));
        }
        control.setValue(value);
        break;
      case FieldType.long:
        if (!field.isBackbone) {
          control.setValidators([
            Validators.max(1000000000000000),
            Validators.min(-1000000000000000),
            Validators.pattern(/^\d+$/)
          ]);
        }
        control.setValue(value);
        break;
      case FieldType.double:
        if (!field.isBackbone) {
          control.setValidators([Validators.max(1000000000000000), Validators.min(-1000000000000000)]);
        }
        control.setValue(value);
        break;
      case FieldType.options:
        control.setValue(value);
        break;
    }
    control.markAsTouched();
    this.formGroup.addControl(field.key, control);
  }
}
