<div class="main-text">
  <ng-container *ngIf="!message.state.isErrorFormat; else errorFormat">
    <ng-container *ngIf="!message.webhookUI?.renderHeaderMarkdown.isTriggerDirective; else triggerBuildMarkdown">
      <b><span class="markdown" [innerHTML]="message.webhookUI?.renderHeaderMarkdown.text | safe: 'html'"></span></b>
    </ng-container>
    <ng-template #triggerBuildMarkdown>
      <b
        ><span
          class="markdown"
          lazyloadUnknown
          [text]="message.webhookUI?.renderHeaderMarkdown.text"
          [participants]="participants"
          [innerHTML]="message.webhookUI?.renderHeaderMarkdown.text | safe: 'html'"
        ></span
      ></b>
    </ng-template>
    <ng-container *ngIf="message.webhookUI.interactiveData">
      <section *ngFor="let section of message.webhookUI.interactiveData.blocks">
        <ng-container *ngIf="section.text" [ngSwitch]="section.text.type">
          <b3n-webhook-markdown
            *ngSwitchCase="BlockType.markdown"
            [block]="section"
            [participants]="participants"
          ></b3n-webhook-markdown>
          <div *ngSwitchCase="BlockType.plaintext">{{ section.text.text }}</div>
        </ng-container>
      </section>
    </ng-container>
  </ng-container>
  <ng-template #errorFormat>
    <span class="normal-text red-fg" [innerHTML]="message.body?.text | safe: 'html'"></span>
  </ng-template>
</div>
