import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Channel } from '@b3networks/api/chat';
import { User } from '@b3networks/api/workspace';
import Fuse from 'fuse.js';
import { startWith } from 'rxjs';
import { InfoShowMention } from '../../../../core/state/app-state.model';
import { AppService } from '../../../../core/state/app.service';
import { InviteMemberComponent } from '../../../invite-member/invite-member.component';

@Component({
  selector: 'b3n-channel-details-members',
  templateUrl: './channel-details-members.component.html',
  styleUrls: ['./channel-details-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelDetailsMembersComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('inputSearch') inputSearch: ElementRef;

  @Input() channel: Channel;
  @Input() members: User[] = [];

  @Output() hiddenDialog = new EventEmitter<boolean>();

  displayMember: User[] = [];
  isSearching: boolean;
  searchCtrl = new FormControl();

  readonly DEFAULT_THRESHOLD = 0.3;

  constructor(private dialog: MatDialog, private appService: AppService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['members']) {
      this.searchCtrl.setValue(this.searchCtrl.value);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputSearch?.nativeElement?.focus();
    }, 300);
  }

  ngOnInit() {
    this.searchCtrl.valueChanges.pipe(startWith('')).subscribe(key => {
      this.isSearching = true;
      const list = this.members;
      if (key?.trim()) {
        const options = {
          keys: ['displayName', 'email', 'mobileNumber'],
          threshold: this.DEFAULT_THRESHOLD,
          includeScore: true
        };
        const fuse = new Fuse(list, options);
        const data = fuse.search(key);
        this.displayMember = data?.sort((a, b) => a.score - b.score)?.map(search => search?.item);
      } else {
        this.displayMember = list;
      }
      this.isSearching = false;
    });
  }

  trackByFiltered(_, item: User) {
    return item?.uuid;
  }

  inviteMember() {
    this.dialog.open(InviteMemberComponent, {
      width: '600px',
      data: this.channel,
      autoFocus: false
    });
  }

  onShowProfile(event, member: User) {
    event.stopPropagation();
    this.appService.update({
      memberMenu: <InfoShowMention>{
        xPosition: event.x,
        yPosition: event.y,
        member: member,
        convo: this.channel
      }
    });
  }
}
