import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CacheMedia, CacheMediaQuery, CacheMediaService } from '@b3networks/api/common';
import { FileService } from '@b3networks/api/file';
import { MediaService } from '@b3networks/api/workspace';
import { filter, map, tap } from 'rxjs';
import { MediaUI } from '../wa-template-message.component';

declare const videojs: any;

@Component({
  selector: 'b3n-wa-template-video',
  templateUrl: './wa-template-video.component.html',
  styleUrls: ['./wa-template-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaTemplateVideoComponent implements OnInit {
  @Input() mediaUI: MediaUI;
  @Input() parentElr: HTMLElement;
  @Output() download = new EventEmitter<void>();

  isError: boolean;
  player: any;
  options: any = {
    controls: true,
    paused: true,
    playbackRates: [0.25, 0.5, 1, 1.5, 2],
    userActions: {
      hotkeys: true
    }
  };

  constructor(
    private elr: ElementRef,
    public mediaService: MediaService,
    private cacheMediaQuery: CacheMediaQuery,
    private cacheMediaService: CacheMediaService,
    private fileService: FileService
  ) {}

  ngOnInit() {}

  onRenderVideo() {
    if (!this.mediaUI.s3Key) {
      return;
    }

    // check store
    const url = this.cacheMediaQuery.getMediaByKey(this.mediaUI.s3Key, true)?.url;
    if (url) {
      this.initPlayer(url);
      return;
    }

    this.fileService
      .downloadFileV3(this.mediaUI.s3Key, {})
      .pipe(
        map(resp => {
          const file = new Blob([new Uint8Array(resp.body)], {
            type: `${resp.headers.get('content-type')}`
          });
          return URL.createObjectURL(file);
        }),
        tap(src => {
          this.cacheMediaService.add(new CacheMedia({ key: this.mediaUI.s3Key, url: src }));
        })
      )
      .pipe(filter(src => src != null))
      ?.subscribe(src => this.initPlayer(src));
  }

  private initPlayer(src: string) {
    this.elr.nativeElement?.querySelector('.video-js')?.addEventListener('error', event => {
      console.log(event.target.error.message);
      this.isError = true;
    });

    this.options.sources = [{ src, type: 'video/mp4' }];
    this.player = videojs(this.elr.nativeElement?.querySelector('.video-js'), this.options, () => {});

    // add download button
    this.player.on('play', () => {
      const hasDownloadBtn = this.elr.nativeElement.querySelector('.vjs-download-button');
      if (!hasDownloadBtn) {
        const button = document.createElement('button');
        button.classList.add('vjs-download-button');
        button.innerHTML = `<span class='material-icons' style='font-size: 20px;cursor: pointer;'>download</span>`;
        button.onclick = () => this.download.emit();

        const backplayRateBtn = this.elr.nativeElement.querySelector('.vjs-playback-rate');
        if (backplayRateBtn) {
          backplayRateBtn.parentNode.insertBefore(button, backplayRateBtn.nextSibling);
        }
      }
    });
  }
}
