<div class="extensions__wrapper">
  <div floatLabel="never" class="search-input">
    <input
      placeholder="Search for name or extension key"
      matInput
      type="text"
      [formControl]="searchExtensionFC"
      autocomplete="off"
    />
  </div>
  <div
    shc-infinity-loading
    [disabledLoad]="isDisableLoadMore"
    [loading]="isLoading"
    class="extension__wrapper"
    (loadMore)="onLoadExt(searchExtensionFC.value)"
  >
    <div *ngFor="let ext of listExts" class="extension">
      <button mat-mini-fab class="mat-elevation-z1 btn-fab avatar" [matTooltip]="ext.extLabel">
        <span>{{ ext.extLabel | firstWord }}</span>
      </button>
      <div class="info">
        <span class="name">{{ ext.extLabel }}</span>
        <span class="sub-name">Ext: {{ ext.extKey }}</span>
      </div>
      <div class="action">
        <mat-icon
          (click)="delegatedCallerIds.length === 0 ? callExtension(ext.extKey, extension.callerId) : openMenu()"
          svgIcon="b3n_call"
          color="primary"
          class="cursor-pointer"
        >
        </mat-icon>
        <div
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menuDelegate"
          [matMenuTriggerData]="{ list: delegatedCallerIds, extKey: ext.extKey, callerId: extension.callerId }"
        ></div>
      </div>
    </div>
  </div>
</div>

<mat-menu #menuDelegate="matMenu" class="pannel-delegate-ext">
  <ng-template matMenuContent let-list="list" let-extKey="extKey" let-callerId="callerId">
    <ng-container *ngIf="callerId">
      <button mat-menu-item (click)="callExtension(extKey, callerId)">Call as myself</button>
      <mat-divider> </mat-divider>
    </ng-container>
    <button mat-menu-item *ngFor="let item of list" (click)="callExtension(extKey, item.number)">
      {{
        (item.extKey | selectExtensionCallcenter | async)?.displayText ||
          'Extension ' + item.extKey + ' (' + item.extKey + ')'
      }}
      : {{ item.number }}
    </button>
  </ng-template>
</mat-menu>
