<div *ngIf="ctrl" fxLayout="column" fxLayoutAlign="start none">
  <ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="FieldType.text">
      <span>{{ field.label }}</span>
      <mat-form-field appearance="outline">
        <input type="text" matInput (keydown)="onKeydown($event)" [formControl]="ctrl" />
        <mat-error *ngIf="ctrl.hasError('maxlength')"> Maximum 256 characters</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="FieldType.textarea">
      <span>{{ field.label }}</span>
      <mat-form-field appearance="outline">
        <textarea matInput (keydown)="onKeydown($event)" [formControl]="ctrl"></textarea>
        <mat-error *ngIf="ctrl.hasError('maxlength')"> Maximum 2000 characters</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="FieldType.double">
      <span>{{ field.label }}</span>
      <mat-form-field appearance="outline">
        <input type="number" matInput (keydown)="onKeydown($event)" [formControl]="ctrl" />
        <mat-error *ngIf="ctrl.hasError('max')"> Maximum 10^15</mat-error>
        <mat-error *ngIf="ctrl.hasError('min')"> Minimum -10^15</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="FieldType.long">
      <span>{{ field.label }}</span>
      <mat-form-field appearance="outline">
        <input type="number" matInput (keydown)="onKeydown($event)" [formControl]="ctrl" />
        <mat-error *ngIf="ctrl.hasError('max')"> Maximum 10^15</mat-error>
        <mat-error *ngIf="ctrl.hasError('min')"> Minimum -10^15</mat-error>
        <mat-error *ngIf="ctrl.hasError('pattern')"> Please enter a valid integer number</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="FieldType.options">
      <span>{{ field.label }}</span>
      <mat-form-field appearance="outline">
        <mat-select [formControl]="ctrl">
          <mat-option *ngIf="field.options.length === 0">No option</mat-option>
          <mat-option *ngFor="let option of field.options" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>
</div>
