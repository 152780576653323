<div class="convo-name">
  <ng-container *ngIf="!channel?.isGroupChat; else groupName"> @{{ channel.description.trim() }} </ng-container>
  <ng-template #groupName>
    <div class="flex">
      <span class="private-icon">
        <mat-icon *ngIf="!channel.isSvg; else svgIcon" [ngClass]="[channel.classIcon, iconSize]">{{
          channel.icon
        }}</mat-icon>
        <ng-template #svgIcon>
          <mat-icon [svgIcon]="channel.icon" [ngClass]="iconSize"></mat-icon>
        </ng-template>
      </span>
      <span>{{ channel.name.trim() }}</span>
    </div>
  </ng-template>
</div>
