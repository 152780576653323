import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Channel } from '@b3networks/api/chat';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { ThreadActiveMenuComponent } from './thread-active-menu/thread-active-menu.component';
import { ThreadClosedMenuComponent } from './thread-closed-menu/thread-closed-menu.component';

@Component({
  selector: 'b3n-thread-menu',
  templateUrl: './thread-menu.component.html',
  styleUrls: ['./thread-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadMenuComponent extends DestroySubscriberComponent implements OnChanges {
  @Input() channel: Channel;
  @Output() selectThread = new EventEmitter<Channel>();

  @ViewChild(ThreadActiveMenuComponent) threadActiveMenuComponent: ThreadActiveMenuComponent;
  @ViewChild(ThreadClosedMenuComponent) threadClosedMenuComponent: ThreadClosedMenuComponent;
  @ViewChild(MatTabGroup) mtTabGroup: MatTabGroup;

  selectedIndex = 0;
  private _id: string;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel?.id !== this._id) {
      this._id = this.channel.id;
      this.selectedIndex = 0;
      this.cdr.detectChanges();
    }
  }

  onChange($event) {
    if ($event === 0) {
      this.threadActiveMenuComponent.onFocusInput();
      this.selectedIndex = 0;
    } else if ($event === 1) {
      this.threadClosedMenuComponent.initFetchThreadClose();
      this.selectedIndex = 1;
    }
  }

  onMenuOpen() {
    this.threadActiveMenuComponent?.onMenuOpen();
  }
}
