<div class="item flex align-items-center gap-8" [class.cursor-pointer]="isImgFile" 
(contextmenu)="onRightClick($event)">
  <ng-container *ngIf="!isImgFile; else imageWidget">
    <div
      class="thumbnail flex justify-content-center align-items-center"
      [style.background-image]="backgroundImage"
    ></div>
  </ng-container>
  <ng-template #imageWidget>
    <div
      *ngIf="!noIntersectionObserver"
      [intersectionObserverDom]="parentElr"
      class="thumbnail flex justify-content-center align-items-center"
      [style.background-image]="backgroundImage ? backgroundImage : ''"
      (render)="onRenderImg()"
      (click)="zoom($event)"
    >
      <mat-spinner *ngIf="!loaded || mediaService.loadingMap?.[file.msgId]" diameter="20"></mat-spinner>
      <span
        *ngIf="loaded && !backgroundImage && !mediaService.loadingMap?.[file.msgId]"
        class="material-icons not-loaded-image"
      >
        broken_image
      </span>
    </div>
    <div
      *ngIf="noIntersectionObserver"
      class="thumbnail flex justify-content-center align-items-center"
      [style.background-image]="backgroundImage ? backgroundImage : ''"
      (click)="zoom($event)"
    >
      <mat-spinner *ngIf="mediaService.loadingMap?.[file.msgId]" diameter="20"></mat-spinner>
    </div>
  </ng-template>

  <div class="spacer flex column">
    <span class="title">
      <shc-slice-text [text]="file.name" [limitContentLength]="53"></shc-slice-text>
    </span>
    <ng-container *ngIf="file.userId | selectUser | async as user">
      <span class="sub-title">
        Shared by {{ user.displayName }} on {{ file.createdTime | date: "MMM d 'at' h:mm a" }}
      </span>
    </ng-container>
  </div>
  <div class="actions flex">
    <button *ngIf="file?.msgId" mat-icon-button matTooltip="Show in chat" (click)="jumpToMessage($event)">
      <mat-icon class="icon action">move_up</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Download" [loading]="progressing" (click)="download($event)">
      <mat-icon class="icon action">save_alt</mat-icon>
    </button>
  </div>
</div>
