<ng-container *ngIf="message.previewMessageUI?.previewData">
  <br />
  <table class="preview-table" [style.borderColor]="message.previewMessageUI.isYoutube ? '#ff5252' : '#40c4ff'">
    <tbody>
      <!-- Youtube -->
      <ng-container *ngIf="message.previewMessageUI.isYoutube; else others">
        <tr>
          <td>
            <strong>
              <a [href]="message.previewMessageUI.extractUrl | safe: 'resourceUrl'" target="_blank">
                <mat-icon class="youtube-logo" svgIcon="youtube"></mat-icon>
                <br />
                {{ message.previewMessageUI.previewData.title.trim() }}
              </a>
            </strong>
          </td>
        </tr>
        <tr>
          <td>
            <iframe
              width="444"
              height="250"
              [src]="message.previewMessageUI.embededUrlYoutube | safe: 'resourceUrl'"
              [srcdoc]="message.previewMessageUI.srcDocYoutube | safe: 'html'"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </td>
        </tr>
      </ng-container>
      <ng-template #others>
        <tr>
          <td style="text-align: justify">
            <strong>
              <a [href]="message.previewMessageUI.extractUrl | safe: 'resourceUrl'" target="_blank">
                <img
                  *ngIf="message.previewMessageUI.previewData.icon.indexOf('https') >= 0"
                  class="preview-logo"
                  [src]="message.previewMessageUI.previewData.icon | safe: 'resourceUrl'"
                />
                {{ message.previewMessageUI.previewData.title.trim() }}
              </a>
            </strong>
            <br />
            <span class="mat-caption">{{ message.previewMessageUI.htmlDecode }}</span>
          </td>
          <td>
            <div
              *ngIf="message.previewMessageUI.backgroundImage"
              class="preview-img"
              [style.background-image]="message.previewMessageUI.backgroundImage"
            ></div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</ng-container>
