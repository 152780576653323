import { HashMap } from '@datorama/akita';
import { RingMode } from '../../enums';
import { Text2SpeechEntry } from './cr-config.model';
import { TtsConfig } from './tts-config';

const DEFAULT_MOH_MP3 = 'https://ui.b3networks.com/samples/sample_moh.mp3';
const DEFAULT_MOH = `<url background='true'>${DEFAULT_MOH_MP3}</url>`;

export interface MOHConfigs {
  entries: Text2SpeechEntry[];
  ringMode: string;
  ringTime: number;
}

export class RingConfig {
  activatedDevices: string[]; // v2 ring mode
  blockAnonymous: boolean;
  enableCallWaiting: boolean;
  enableCallBack: boolean;
  enableAutoAttendant: false;
  ringTime: number;
  busyRef: 'busy' | 'hangup';
  ringMode: RingMode;
  unansweredRef: 'unanswered' | 'hangup';
  speedDial: HashMap<string>;
  version: 'v2' | null; // set to v2 when update v2 property
  mohConfigs: MOHConfigs;
  defaultMoh: string; // "<url background='true'>https://ui.b3networks.com/samples/sample_moh.mp3</url>"
  moh: string;

  //ui
  isValidators?: boolean;

  constructor(obj?: Partial<RingConfig>) {
    if (obj) {
      Object.assign(this, obj);
    }

    if (this.defaultMoh === DEFAULT_MOH_MP3) {
      this.defaultMoh = DEFAULT_MOH;
    }

    if (!this.moh || !this.moh.startsWith('<url')) {
      const isDefault = !this.moh || this.moh === DEFAULT_MOH_MP3;
      this.moh = `<url ${isDefault ? "background='true'" : "acl='private'"}>${this.moh || DEFAULT_MOH_MP3}</url>`;
    }
  }

  get defaultMOHConfig(): TtsConfig {
    return new TtsConfig(this.defaultMoh);
  }

  get mohConfig(): TtsConfig | null {
    return this.moh ? new TtsConfig(this.moh) : null;
  }

  // moh is empty then system use default MOH to play.
  get isUsingDefaultMOH() {
    return !this.moh || this.defaultMOHConfig.msgUrl === this.mohConfig.msgUrl;
  }
}
