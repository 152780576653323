<div class="container flex column">
  <ng-container *ngIf="loadedFiles$ | async; else loading">
    <ng-container *ngIf="files$ | async as files; else loading">
      <ng-container *ngIf="files?.length > 0; else noData">
        <div class="files flex column" #viewport>
          <ng-container *ngFor="let file of files; let i = index; trackBy: trackByFile">
            <b3n-conversation-files-thumbnail
              [channel]="channel"
              [file]="file"
              [parentElr]="viewport"
              (closeDialog)="closeDialog.emit($event)"
              (rightClickMessage)="onRightClickMessage($event)"
            ></b3n-conversation-files-thumbnail>
            <mat-divider *ngIf="i !== files.length - 1"></mat-divider>
          </ng-container>
          <ng-container *ngIf="loadingMore">
            <mat-divider></mat-divider>
            <div class="loading-more flex justify-content-center align-items-center">
              <mat-spinner [diameter]="35"></mat-spinner>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #noData>
        <div class="flex column justify-content-center align-items-center noData gap-8">
          <mat-icon class="s-64">upload_file</mat-icon>
          <p class="text-center">
            There are no files to see here right now! But there could be — drag and drop any file into the message pane
            to add it to this conversation.
          </p>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div class="loading flex justify-content-center align-items-center">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </ng-template>
</div>

<div class="right-click-files-menu" style="visibility: hidden; position: fixed" [matMenuTriggerFor]="rightMenu"></div>
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngFor="let menu of item.menus">
      <button mat-menu-item (click)="handleRightClick(item, menu)">
        <mat-icon
          *ngIf="!menu.isSvg; else svgIcon"
          class="icon"
          [ngClass]="menu.size ? 's-' + menu.size : ''"
          [ngClass]="menu.classIcon"
        >
          {{ menu.icon }}
        </mat-icon>
        <ng-template #svgIcon>
          <mat-icon class="icon" [ngClass]="menu.size ? 's-' + menu.size : ''" [svgIcon]="menu.icon"></mat-icon>
        </ng-template>
        <span>{{ menu.value }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
