<div mat-dialog-content class="keypad flex column justify-content-none align-items-center">
  <mat-form-field class="keypad__input">
    <input
      phoneNumPattern
      #inputPhone
      matInput
      type="text"
      [(ngModel)]="phoneNumber"
      (keypress)="doPress($event)"
      (keyup.enter)="btnCall()"
    />
  </mat-form-field>
  <div class="keypad__numbers flex flex-wrap justify-content-start align-items-center gap-10">
    <div
      *ngFor="let key of [].constructor(9); let i = index"
      fxFlex="0 1 calc(33.3% - 0.67rem)"
      class="number-region flex column justify-content-center align-items-center"
      [class.no-margin-right]="(i + 1) % 3 === 0"
    >
      <div class="num" (click)="inputNumber((i + 1).toString())">
        <div class="digit-only">{{ i + 1 }}</div>
      </div>
    </div>

    <div fxFlex="0 1 calc(33.3% - 0.67rem)" class="number-region flex column justify-content-center align-items-center">
      <div class="num" (click)="inputNumber('*')">
        <div class="digit-only">*</div>
      </div>
    </div>
    <div fxFlex="0 1 calc(33.3% - 0.67rem)" class="number-region flex column justify-content-center align-items-center">
      <div class="num" (click)="inputNumber('0')">
        <div class="digit-only">0</div>
        <div class="text">+</div>
      </div>
    </div>
    <div fxFlex="0 1 calc(33.3% - 0.67rem)" class="number-region flex column justify-content-center align-items-center">
      <div class="num" (click)="inputNumber('#')">
        <div class="digit-only">#</div>
      </div>
    </div>
  </div>

  <div class="keypad__actions flex justify-content-start align-items-center gap-10">
    <span fxFlex="0 1 calc(33.3% - 0.67rem)"> </span>
    <div fxFlex="0 1 calc(33.3% - 0.67rem)" class="flex column justify-content-center align-items-center">
      <ng-container *ngIf="data?.isDTMF; else dialPhone">
        <button mat-raised-button color="primary" matTooltip="##2" (click)="doTransfer()">Transfer</button>
      </ng-container>
      <ng-template #dialPhone>
        <button mat-icon-button class="num-call" (click)="btnCall()">
          <mat-icon>phone</mat-icon>
        </button>
      </ng-template>
    </div>
    <div
      fxFlex="0 1 calc(33.3% - 0.67rem)"
      class="no-margin-right flex column justify-content-center align-items-center"
    >
      <button mat-icon-button (click)="btnDelete()" *ngIf="phoneNumber">
        <mat-icon>backspace</mat-icon>
      </button>
    </div>
  </div>
</div>
