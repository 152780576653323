<div class="container flex column gap-8">
  <div class="search flex column">
    <mat-form-field appearance="outline" hideRequiredMarker="true" class="no-validator">
      <input [formControl]="searchCtrl" #inputSearch matInput placeholder="Find members" [formControl]="searchCtrl" />
      <mat-icon matSuffix *ngIf="searchCtrl.value" class="s-20 p-8 cursor-pointer" (click)="searchCtrl.setValue('')"
        >clear</mat-icon
      >
      <mat-icon matSuffix *ngIf="!searchCtrl.value" class="s-20 p-8">search</mat-icon>
    </mat-form-field>
  </div>
  <div class="members-container">
    <div
      *ngIf="!channel.isGeneral && channel.isMember"
      (click)="inviteMember()"
      class="add-member-container flex align-items-center gap-3"
    >
      <mat-icon matTooltip="Add">person_add</mat-icon>
      <span>Add Members</span>
    </div>
    <mat-action-list *ngIf="displayMember.length > 0; else noData" class="members spacer pt-0">
      <mat-list-item
        *ngFor="let member of displayMember; let i = index; trackBy: trackByFiltered"
        class="member"
        (click)="onShowProfile($event, member)"
      >
        <div class="full-width flex justify-content-center align-items-center gap-8">
          <b3n-avatar-user
            class="spacer"
            [member]="member"
            [showStatus]="true"
            [panelClass]="'small-1'"
            [showDisplayName]="true"
            [keyword]="searchCtrl.value"
            [disabledClick]="true"
          ></b3n-avatar-user>
          <ng-container *ngIf="channel.createdBy === member.userUuid">
            <b3n-chip-view [text]="'Owner'" [color]="'blue'"></b3n-chip-view>
          </ng-container>
        </div>
      </mat-list-item>
    </mat-action-list>
  </div>
  <ng-template #noData>
    <div *ngIf="displayMember.length === 0" class="noData flex justify-content-center">
      <div class="secondary-text">No result</div>
    </div>
  </ng-template>
</div>
