<div class="keypad__wrapper">
  <div floatLabel="never" class="keypad-input">
    <input
      placeholder="Enter a number to call"
      matInput
      phoneNumPattern
      type="text"
      #inputDial
      [formControl]="numberFC"
      (keyup.enter)="delegatedCallerIds.length === 0 ? call(extension.callerId) : openMenu()"
      autocomplete="off"
    />
    <mat-icon
      class="icon-backspace"
      *ngIf="!!numberFC.value"
      matSuffix
      mat-icon-button
      aria-label="backspace"
      (click)="clearNumber()"
    >
      backspace
    </mat-icon>
  </div>
  <div class="keypad-module">
    <div class="module">
      <mat-icon class="icon s-20">phone_in_talk</mat-icon>
      <span>Caller ID: </span>
      <span class="text">{{ callerId }}</span>
    </div>
    <div class="module">
      <mat-icon class="icon s-20">voicemail</mat-icon>
      <span>Call recording: </span>
      <span class="text" *ngIf="extension">
        {{ extension.crConfig.isEnableIncoming || extension.crConfig.isEnableOutgoing ? 'Enabled' : 'Disabled' }}
      </span>
    </div>
    <div class="module">
      <mat-icon class="icon s-20">verified_user</mat-icon>
      <span>DNC: </span>
      <span class="text" *ngIf="extension"> {{ extension.dncStatus }} </span>
    </div>
  </div>
  <div class="keypad-numbers">
    <div *ngFor="let key of [].constructor(9); let i = index" (click)="insertNumber(i + 1)" class="number__wrapper">
      <div class="number">
        <div class="digit">{{ i + 1 }}</div>
      </div>
    </div>
    <div class="number__wrapper" (click)="insertNumber('*')">
      <div class="number">
        <div class="digit">*</div>
      </div>
    </div>
    <div class="number__wrapper" (click)="insertNumber('0')">
      <div class="number">
        <div class="digit">0</div>
        <div class="text">+</div>
      </div>
    </div>
    <div class="number__wrapper" (click)="insertNumber('#')">
      <div class="number">
        <div class="digit">#</div>
      </div>
    </div>
  </div>
  <div class="keypad-call">
    <ng-container *ngIf="{ extensionHasCallerId: extensionHasCallerId$ | async } as data">
      <span class="secondary-text error-callerid" *ngIf="!data.extensionHasCallerId && delegatedCallerIds.length === 0">
        Please configure caller ID first
      </span>
      <div class="call__wrapper">
        <button
          color="primary"
          mat-flat-button
          class="call-btn"
          (click)="delegatedCallerIds.length === 0 ? call(extension.callerId) : openMenu()"
          [disabled]="(!data.extensionHasCallerId && delegatedCallerIds.length === 0) || !numberFC.value"
        >
          <span class="call-text"> CALL </span>
        </button>
        <div
          class="menu-delegate"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menuDelegate"
          [matMenuTriggerData]="{ list: delegatedCallerIds }"
        ></div>
      </div>
    </ng-container>
  </div>
</div>

<mat-menu #menuDelegate="matMenu" class="pannel-delegate">
  <ng-template matMenuContent let-list="list">
    <ng-container *ngIf="callerId">
      <button mat-menu-item (click)="call(callerId)">Call as myself</button>
      <mat-divider> </mat-divider>
    </ng-container>
    <button mat-menu-item *ngFor="let item of list" (click)="call(item.number)">
      {{
        (item.extKey | selectExtensionCallcenter | async)?.displayText ||
          'Extension ' + item.extKey + ' (' + item.extKey + ')'
      }}
      : {{ item.number }}
    </button>
  </ng-template>
</mat-menu>
