import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastData } from '../../config/toast-configs';

@Component({
  selector: 'b3n-list-toasts',
  templateUrl: './list-toasts.component.html',
  styleUrls: ['./list-toasts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListToastsComponent implements OnInit {
  private _list: ToastData[] = [];

  set list(value) {
    this._list = value;
  }

  get list() {
    return this._list.sort((a, b) => b.time - a.time) || [];
  }

  constructor(public cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  trackByItem(_, item: ToastData) {
    return item?.guid;
  }
}
