import { Pipe, PipeTransform } from '@angular/core';

enum TxnChannel {
  livechat = 'livechat',
  whatsapp = 'whatsapp',
  sms = 'sms',
  email = 'email',
  supportCenter = 'supportCenter',
  call = 'call'
}

@Pipe({
  name: 'typeTxn'
})
export class TypeTxnPipe implements PipeTransform {
  transform(channel: TxnChannel): string {
    switch (channel) {
      case TxnChannel.call:
        return 'Call';
      case TxnChannel.livechat:
        return 'Live Chat';
      case TxnChannel.supportCenter:
        return 'Support Ticket';
      case TxnChannel.email:
        return 'Email';
      case TxnChannel.sms:
        return 'SMS';
      case TxnChannel.whatsapp:
        return 'WhatsApp';
      default:
        return channel;
    }
  }
}
