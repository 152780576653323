import { IdentityProfile, LoginSessionResponse, ProfileOrg } from '@b3networks/api/auth';
import { getFileType } from '@b3networks/shared/common';

export enum TypeContact {
  member = 'member',
  customer = 'customer'
}

export interface LandingData {
  profiles: IdentityProfile;
  currentOrg: ProfileOrg;
  session: LoginSessionResponse;
}

export class UIFileUpload {
  iconUrl: string;
  ext: string;
  size: number;
  width?: number;
  height?: number;

  constructor(file: File) {
    if (file) {
      this.iconUrl = getFileType(file.type);
      this.ext = file.name.split('.').pop();
      this.size = file.size;
    }
  }
}
