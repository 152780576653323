import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommsSharedModule } from '@b3networks/comms/shared';
import { SharedCommonModule } from '@b3networks/shared/common';
import { ButtonLoadingModule, SharedUiMaterialModule } from '@b3networks/shared/ui/material';
import { SharedUiPortalModule } from '@b3networks/shared/ui/portal';
import { ChipViewComponent } from '@b3networks/shared/ui/standalone';
import { SharedUiToastModule } from '@b3networks/shared/ui/toast';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { UiScrollModule } from 'ngx-ui-scroll';
import { IntersectionObserverDomDirective, LazyLoadUnknownDirective } from '../standalone';
import { AgentAvatarComponent } from './components/agent-avatar/agent-avatar.component';
import { ArchiveConvoComponent } from './components/archive-convo/archive-convo.component';
import { CallComponent } from './components/call/call.component';
import { ExtensionsComponent } from './components/call/extensions/extensions.component';
import { KeypadComponent as CallKeypadComponent } from './components/call/keypad/keypad.component';
import { ChannelNameComponent } from './components/channel-name/channel-name.component';
import { AttachmentMessageComponent } from './components/chat-message/attachment-message/attachment-message.component';
import { AudioFileMessageComponent } from './components/chat-message/audio-file-message/audio-file-message.component';
import { AvatarUserComponent } from './components/chat-message/avatar-user/avatar-user.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { InfoThreadMessageComponent } from './components/chat-message/info-thread-message/info-thread-message.component';
import { InteractiveButtonComponent } from './components/chat-message/interactive-message/interactive-button/interactive-button.component';
import { InteractiveDetailComponent } from './components/chat-message/interactive-message/interactive-detail/interactive-detail.component';
import { InteractiveInputComponent } from './components/chat-message/interactive-message/interactive-input/interactive-input.component';
import { InteractiveUploadComponent } from './components/chat-message/interactive-message/interactive-input/interactive-upload/interactive-upload.component';
import { InteractiveLoopComponent } from './components/chat-message/interactive-message/interactive-loop/interactive-loop.component';
import { InteractiveMessageComponent } from './components/chat-message/interactive-message/interactive-message.component';
import { InteractiveTextComponent } from './components/chat-message/interactive-message/interactive-text/interactive-text.component';
import { BuiltTextMessageComponent } from './components/chat-message/normal-message/built-text-message/built-text-message.component';
import { NormalMessageComponent } from './components/chat-message/normal-message/normal-message.component';
import { SystemMessageCustomComponent } from './components/chat-message/normal-message/system-message-custom/system-message-custom.component';
import { PreChatSurveyMessageComponent } from './components/chat-message/pre-chat-survey-message/pre-chat-survey-message.component';
import { PreViewMessageComponent } from './components/chat-message/preview-message/preview-message.component';
import { ReplyMessageComponent } from './components/chat-message/reply-message/reply-message.component';
import { SummaryMessageComponent } from './components/chat-message/summary-message/summary-messagecomponent';
import { VideoFileMessageComponent } from './components/chat-message/video-file-message/video-file-message.component';
import { WaTemplateDocumentComponent } from './components/chat-message/wa-template-message/wa-template-document/wa-template-document.component';
import { WaTemplateImageComponent } from './components/chat-message/wa-template-message/wa-template-image/wa-template-image.component';
import { WaTemplateMessageComponent } from './components/chat-message/wa-template-message/wa-template-message.component';
import { WaTemplateVideoComponent } from './components/chat-message/wa-template-message/wa-template-video/wa-template-video.component';
import { WebhookMarkdownComponent } from './components/chat-message/webhook-message/webhook-markdown/webhook-markdown.component';
import { WebhookMessageComponent } from './components/chat-message/webhook-message/webhook-message.component';
import { ConferenceRoomComponent } from './components/conference-room/conference-room.component';
import { ConfirmInviteHyperspaceComponent } from './components/confirm-invite-hyperspace/confirm-invite-hyperspace.component';
import { ConversationContentVirtualScrollComponent } from './components/conversation-content-virtual-scroll/conversation-content-virtual-scroll.component';
import { ConversationContentComponent } from './components/conversation/conversation-content/conversation-content.component';
import { ConversationFilesThumbnailComponent } from './components/conversation/conversation-files/conversation-files-thumbnail/conversation-files-thumbnail.component';
import { ConversationFilesComponent } from './components/conversation/conversation-files/conversation-files.component';
import { ConversationFooterComponent } from './components/conversation/conversation-footer/conversation-footer.component';
import { ConversationHeaderComponent } from './components/conversation/conversation-header/conversation-header.component';
import { PinnedMessageMenuComponent } from './components/conversation/conversation-header/pinned-message-menu/pinned-message-menu.component';
import { ThreadActiveMenuComponent } from './components/conversation/conversation-header/thread-menu/thread-active-menu/thread-active-menu.component';
import { ThreadClosedMenuComponent } from './components/conversation/conversation-header/thread-menu/thread-closed-menu/thread-closed-menu.component';
import { ThreadMenuComponent } from './components/conversation/conversation-header/thread-menu/thread-menu.component';
import { ConvoNameComponent } from './components/convo-name/convo-name.component';
import { CreateConvoComponent } from './components/create-convo/create-convo.component';
import { ChannelDetailsAboutComponent } from './components/dialog/channel-details/channel-details-about/channel-details-about.component';
import { ChannelDetailsMembersComponent } from './components/dialog/channel-details/channel-details-members/channel-details-members.component';
import { ChannelDetailsComponent } from './components/dialog/channel-details/channel-details.component';
import { ConfirmInviteComponent } from './components/dialog/confirm-invite/confirm-invite.component';
import { CreateDiscussionComponent } from './components/dialog/create-discussion/create-discussion.component';
import { CustomToastWaComponent } from './components/dialog/custom-toast-wa/custom-toast-wa.component';
import { DeleteMessageComponent } from './components/dialog/delete-message/delete-message.component';
import { DirectDetailsComponent } from './components/dialog/direct-details/direct-details.component';
import { InteractiveDialogComponent } from './components/dialog/interactive-dialog/interactive-dialog.component';
import { PinMessageComponent } from './components/dialog/pin-message/pin-message.component';
import { SearchDialogComponent } from './components/dialog/search-dialog/search-dialog.component';
import { TxnWaBackComponent } from './components/dialog/txn-wa-back/txn-wa-back.component';
import { TxnWaOutboundComponent } from './components/dialog/txn-wa-outbound/txn-wa-outbound.component';
import { EditDescriptionComponent } from './components/edit-description/edit-description.component';
import { EmailMenuItemComponent } from './components/email-menu-item/email-menu-item.component';
import { InviteMemberHyperComponent } from './components/invite-member-hyper/invite-member-hyper.component';
import { InviteMemberComponent } from './components/invite-member/invite-member.component';
import { KeypadComponent } from './components/keypad/keypad.component';
import { LeaveConvoComponent } from './components/leave-convo/leave-convo.component';
import { ManagePhoneDialogComponent } from './components/manage-phone-dialog/manage-phone-dialog.component';
import { MenuToggleRightComponent } from './components/menu-toggle-right/menu-toggle-right.component';
import { MenuToggleComponent } from './components/menu-toggle/menu-toggle.component';
import { PhoneDialogComponent } from './components/phone-dialog/phone-dialog.component';
import { CallbackTxnComponent } from './components/popup-call/callback-txn/callback-txn.component';
import { InboundTxnComponent } from './components/popup-call/inbound-txn/inbound-txn.component';
import { ManualOutgoingTxnComponent } from './components/popup-call/manual-outgoing-txn/manual-outgoing-txn.component';
import { OutboundTxnComponent } from './components/popup-call/outbound-txn/outbound-txn.component';
import { PopUpProcessorComponent } from './components/popup-call/pop-up-processor.component';
import { TemplateFieldComponent } from './components/popup-call/template-field/template-field.component';
import { ConfirmDisableNotifyComponent } from './components/quill-editor/confirm-disable-notify/confirm-disable-notify.component';
import { ConfirmInviteUserComponent } from './components/quill-editor/confirm-invite-user/confirm-invite-user.component';
import { CshQuillEditorComponent } from './components/quill-editor/quill-editor.component';
import { RemoveMemberComponent } from './components/remove-member/remove-member.component';
import { RenameConversationComponent } from './components/rename-conversation/rename-conversation.component';
import { MemberDetailComponent } from './components/render-member-menu/member-detail/member-detail.component';
import { RenderMemberMenuComponent } from './components/render-member-menu/render-member-menu.component';
import { RenderMemberV2Component } from './components/render-member-v2/render-member-v2.component';
import {
  RightSidebarAction,
  RightSidebarActions,
  RightSidebarComponent,
  RightSidebarContent,
  RightSidebarExtend,
  RightSidebarHeader,
  RightSidebarInfo,
  RightSidebarPrimary,
  RightSidebarSubTitle,
  RightSidebarTitle
} from './components/right-sidebar/right-sidebar.component';
import { UploadDialogV2Component } from './components/upload-dialog-v2/upload-dialog-v2.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { WhatsappCannedResponseComponent } from './components/whatsapp-canned-response/whatsapp-canned-response.component';
import { GetRoleChannelHyperPipe } from './core/pipe/get-role-channel-hyper.pipe';
import { GetRolePipe } from './core/pipe/get-role.pipe';
import { GetThumbnailsPipe } from './core/pipe/get-thumbnails.pipe';
import { SelectConvoPipe } from './core/pipe/name-channel.pipe';
import { SelectChannelPipe } from './core/pipe/select-channel.pipe';
import { SelectContactPipe } from './core/pipe/select-contact.pipe';
import { SelectExtensionPipe } from './core/pipe/select-ext.pipe';
import { SelectInboxPipe } from './core/pipe/select-inbox.pipe';
import { SelectUserPipe } from './core/pipe/select-user.pipe';
import { StarConvoPipe } from './core/pipe/star-convo.pipe';
import { StatusTxnPipe } from './core/pipe/status-txn.pipe';
import { TransferDatePipe } from './core/pipe/transfer-date.pipe';
import { UserHyperTypingPipe } from './core/pipe/user-hyper-typing.pipe';
import { UserTypingPipe } from './core/pipe/user-typing.pipe';

const PRIVATE_COMPONENTS = [
  NormalMessageComponent,
  WebhookMessageComponent,
  PreChatSurveyMessageComponent,
  SummaryMessageComponent,
  InteractiveMessageComponent,
  InteractiveButtonComponent,
  InteractiveInputComponent,
  InteractiveTextComponent,
  InteractiveLoopComponent,
  InteractiveDetailComponent,
  InteractiveUploadComponent
];

const PUBLIC_COMPONENTS = [
  AttachmentMessageComponent,
  KeypadComponent,
  RightSidebarComponent,
  RightSidebarHeader,
  RightSidebarContent,

  UploadDialogComponent,
  UploadDialogV2Component,
  ArchiveConvoComponent,
  InviteMemberComponent,
  LeaveConvoComponent,
  EditDescriptionComponent,
  RemoveMemberComponent,
  AvatarUserComponent,

  ChatMessageComponent,
  DeleteMessageComponent,
  RenderMemberMenuComponent,
  ConvoNameComponent,
  ConfirmInviteComponent,
  WhatsappCannedResponseComponent,
  RenameConversationComponent,
  WebhookMarkdownComponent,
  SearchDialogComponent,
  CshQuillEditorComponent,
  ConversationContentVirtualScrollComponent,

  EmailMenuItemComponent,
  AgentAvatarComponent,
  VideoFileMessageComponent,
  AudioFileMessageComponent,
  ConfirmDisableNotifyComponent,

  PhoneDialogComponent,
  ConferenceRoomComponent,
  ManagePhoneDialogComponent,
  InviteMemberHyperComponent,
  ConfirmInviteHyperspaceComponent,
  CreateConvoComponent,
  MenuToggleComponent,
  MenuToggleRightComponent,
  InteractiveDialogComponent,
  ReplyMessageComponent,
  PreViewMessageComponent,
  CallComponent,
  CallKeypadComponent,
  ExtensionsComponent,

  PopUpProcessorComponent,
  InboundTxnComponent,
  OutboundTxnComponent,
  CallbackTxnComponent,
  ManualOutgoingTxnComponent,
  TemplateFieldComponent,
  RenderMemberV2Component,
  ChannelDetailsComponent,
  ChannelDetailsAboutComponent,
  ChannelDetailsMembersComponent,
  CreateDiscussionComponent,
  InfoThreadMessageComponent,
  ConversationHeaderComponent,
  ConversationContentComponent,
  ConversationFooterComponent,
  PinMessageComponent,
  ChannelNameComponent,
  ConfirmInviteUserComponent,
  SystemMessageCustomComponent,
  BuiltTextMessageComponent,
  PinnedMessageMenuComponent,
  ThreadMenuComponent,
  ThreadClosedMenuComponent,
  ThreadActiveMenuComponent,
  TxnWaBackComponent,
  TxnWaOutboundComponent,
  CustomToastWaComponent,
  WaTemplateMessageComponent,
  WaTemplateImageComponent,
  WaTemplateVideoComponent,
  WaTemplateDocumentComponent,
  ConversationFilesComponent,
  ConversationFilesThumbnailComponent,
  DirectDetailsComponent,
  MemberDetailComponent
];

const PIPES = [
  GetThumbnailsPipe,
  StarConvoPipe,
  GetRolePipe,
  SelectUserPipe,
  UserTypingPipe,
  GetRoleChannelHyperPipe,
  UserHyperTypingPipe,
  TransferDatePipe,
  SelectExtensionPipe,
  SelectContactPipe,
  SelectConvoPipe,
  SelectChannelPipe,
  SelectInboxPipe,
  StatusTxnPipe
];

const DIRECTIVES = [
  RightSidebarTitle,
  RightSidebarSubTitle,
  RightSidebarAction,
  RightSidebarInfo,
  RightSidebarActions,
  RightSidebarPrimary,
  RightSidebarExtend
];

const importStandalone = [ChipViewComponent];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule,
    SharedCommonModule,
    SharedUiMaterialModule,
    ButtonLoadingModule,
    UiScrollModule,
    InfiniteScrollModule,
    CommsSharedModule,
    RouterModule,
    SharedUiPortalModule,
    SharedUiToastModule,
    FlexLayoutModule,

    IntersectionObserverDomDirective,
    LazyLoadUnknownDirective,

    ...importStandalone
  ],
  declarations: [PRIVATE_COMPONENTS, PUBLIC_COMPONENTS, DIRECTIVES, PIPES],
  exports: [PUBLIC_COMPONENTS, DIRECTIVES, PIPES]
})
export class ChatSharedCoreModule {}
