<ng-container *ngIf="extension$ | async as extension">
  <div *ngIf="delegatedCallerIds$ | async as delegatedCallerIds" class="call__container">
    <mat-tab-group mat-align-tabs="start" animationDuration="0">
      <mat-tab label="Keypad">
        <b3n-keypad
          [extension]="extension"
          [callerId]="callerId"
          [delegatedCallerIds]="delegatedCallerIds"
          [closeDialogAfterCall]="closeDialogAfterCall"
          (makeCallTo)="makeCallTo($event)"
          (openManagePhoneDialog)="openManagePhoneDialog()"
        ></b3n-keypad>
      </mat-tab>
      <mat-tab label="Extensions">
        <b3n-extensions
          [extension]="extension"
          [delegatedCallerIds]="delegatedCallerIds"
          [closeDialogAfterCall]="closeDialogAfterCall"
          (makeCallTo)="makeCallTo($event)"
          (openManagePhoneDialog)="openManagePhoneDialog()"
        >
        </b3n-extensions>
      </mat-tab>
    </mat-tab-group>

    <ng-container *ngIf="!isManagePhoneOpening && !isDialog">
      <div class="call-status__wrapper" *ngIf="callManagement$ | async as state">
        <div class="avatar">
          <mat-icon>person</mat-icon>
        </div>
        <div class="info">
          <p class="label" *ngIf="callStatusLabel$ | async as callStatusLabel">
            <span *ngIf="state.isRoom"> Meeting Room: </span>
            {{ callStatusLabel | replace: '+':' ' }}
          </p>
          <p class="duration" *ngIf="state.timerCall.second">
            {{ state.timerCall.second | duration }}
          </p>
        </div>
        <div class="expand" (click)="openManagePhoneDialog()">
          <mat-icon class="s-16">fullscreen</mat-icon>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
