import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Channel,
  ChannelQuery,
  ChannelService,
  ChannelType,
  ChatMessage,
  RequestSearchCriteria,
  ViewChannelSection
} from '@b3networks/api/chat';
import { UserQuery } from '@b3networks/api/workspace';
import { HashMap } from '@datorama/akita';
import { subDays, subMonths } from 'date-fns';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { InfoShowMention } from '../../../core/state/app-state.model';
import { AppService } from '../../../core/state/app.service';
import { ConfigMessageOption } from '../../chat-message/chat-message.component';

export interface InputSearchDialog {
  key: string;
  channel: Channel;
  isPersonalBookmark: boolean;
}

const PAGE_LIMIT = 50;
const RANGE_MONTH = 3;
const RANGE_SEARCH_SERVER_DAYS = 10;

@Component({
  selector: 'b3n-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inputSearch') inputSearch: ElementRef;

  // ui
  configMessageOption: ConfigMessageOption = {
    isHideAction: true,
    noHoverAffect: true,
    fullDate: true
  };
  loading: boolean;
  groupResult: Array<ChatMessage[]> = [];
  form: RequestSearchCriteria;

  textSearch = this.fb.control('');

  key: UntypedFormControl = this.fb.control('');
  selectedCtr = this.fb.control([]);
  lastFrom: number;
  expandTime: boolean;
  hasMore: boolean;
  placeholder = 'Search keyword';

  loadingLoadMore: boolean;

  readonly RANGE_MONTH = RANGE_MONTH;

  private mapBookmark: HashMap<string> = {}; // extra messageId -> messageId
  private destroySubscriber$ = new Subject();
  private stopPollingSearch$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private channelService: ChannelService,
    private channelQuery: ChannelQuery,
    private userQuery: UserQuery,
    private elr: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: InputSearchDialog,
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    private appService: AppService,
    private ngZone: NgZone
  ) {
    this.placeholder = this.getChannelPlaceholder(this.data.channel);
  }

  ngOnInit() {
    const search = this.channelQuery.getChannelUiState(this.data.channel.id)?.search;
    if (search) {
      this.textSearch.setValue(search.keyword);
      this.key.setValue(search.keyword);
      this.form = search.form;
      this.lastFrom = search.lastFrom;
      this.hasMore = search.hasMore;
      this.expandTime = search.expandTime;
      this.groupResult = search.groupResult;
      this.selectedCtr.setValue([search.selectMsg]);
      this.mapBookmark = search.mapBookmark;
      this.loading = false;
    }

    setTimeout(() => {
      this.initForm();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputSearch.nativeElement.focus();
    });
  }

  ngOnDestroy() {
    this.destroySubscriber$.next(true);
    this.destroySubscriber$.complete();

    this.stopPollingSearch$.next(true);
    this.stopPollingSearch$.complete();
  }

  compareObjects(a: ChatMessage, b: ChatMessage) {
    return a.id === b.id;
  }

  trackByMessage(_, msg: ChatMessage) {
    return msg.id;
  }

  trackByGroup(index: number, _) {
    return index;
  }

  onLoadContinue() {
    const fromMillis = this.expandTime
      ? subDays(this.form.fromMillis, RANGE_SEARCH_SERVER_DAYS).getTime()
      : this.form.fromMillis;
    const toMillis = this.expandTime ? this.form.fromMillis - 1 : this.lastFrom;
    const req = <RequestSearchCriteria>{
      ...this.form,
      fromMillis: fromMillis,
      toMillis: toMillis
    };
    const maxTimeQuery = subMonths(toMillis, RANGE_MONTH);
    this.pollingSearch(req, 0, maxTimeQuery.getTime());
  }

  onShowProfile(event: InfoShowMention) {
    this.appService.update({
      memberMenu: <InfoShowMention>{
        ...event,
        convo: this.data.channel
      }
    });
  }

  onMenuClosed() {
    const menu = this.elr.nativeElement.querySelector('.trigger-mention-menu') as HTMLElement;
    if (menu) {
      menu.style.display = 'none';
    }
  }

  private initForm() {
    this.key.valueChanges
      .pipe(
        filter(key => !!key),
        debounceTime(300)
      )
      .pipe(takeUntil(this.destroySubscriber$))
      .subscribe((key: string) => {
        this.stopPollingSearch$.next(true);
        const toMillis = new Date().getTime();
        this.form = <RequestSearchCriteria>{
          message: key?.trim(),
          convoIDs: [this.data.channel.id],
          userIDs: [],
          limit: PAGE_LIMIT,
          toMillis,
          fromMillis: subDays(new Date(), RANGE_SEARCH_SERVER_DAYS).getTime()
        };
        this.groupResult = [];
        this.mapBookmark = {};
        const maxTimeQuery = subMonths(new Date(), RANGE_MONTH);
        this.pollingSearch(this.form, 0, maxTimeQuery.getTime());
      });
  }

  valueChange(message: ChatMessage) {
    this.channelService.updateChannelViewState(this.data.channel.id, {
      search: {
        keyword: this.key.value,
        form: this.form,
        hasMore: this.hasMore,
        lastFrom: this.lastFrom,
        expandTime: this.expandTime,
        groupResult: this.groupResult,
        selectMsg: message,
        mapBookmark: this.mapBookmark
      }
    });

    this.channelService.updateChannelViewState(this.data.channel.id, {
      viewChannelSection: ViewChannelSection.chat
    });

    setTimeout(() => {
      if (this.data.isPersonalBookmark) {
        this.navigateChannelWithMsg(this.data.channel.id, this.mapBookmark[message.id]);
      } else {
        this.navigateChannelWithMsg(this.data.channel.id, message.id);
      }
    }, 200);

    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  private navigateChannelWithMsg(id: string, msgId: string) {
    this.channelService.updateNavigateToMsg(id, msgId);
  }

  private pollingSearch(req: RequestSearchCriteria, totalQueryMore: number, maxTimeQueryMore: number) {
    if (req.toMillis < req.fromMillis) {
      console.error('req.toMillis < req.fromMillis');
      this.hasMore = false;
      this.loading = false;
      return;
    }

    if (req.toMillis < new Date(this.data.channel?.createdAt).getTime()) {
      console.log('Stop: req.toMillis < channel?.createdAt');
      this.hasMore = false;
      this.loading = false;
      return;
    }

    if (req.toMillis < maxTimeQueryMore) {
      console.log('Stop: req.toMillis < maxTimeQueryMore');
      this.hasMore = true;
      this.loading = false;
      return;
    }

    this.loading = true;
    this.expandTime = false;
    this.channelService
      .search(req)
      .pipe(takeUntil(this.stopPollingSearch$))
      .subscribe(
        result => {
          this.form = req;
          result.messages = result?.messages?.map(m => new ChatMessage(m));
          if (result.messages.length > 0) {
            if (this.data.isPersonalBookmark) {
              const arr: ChatMessage[] = [];
              result.messages.forEach(item => {
                const root = item.messageBookmark;
                if (root) {
                  arr.push(root);
                  this.mapBookmark[root.id] = item.id;
                }
              });
              totalQueryMore += arr.length;
              this.groupResult.push(arr);
            } else {
              totalQueryMore += result.messages.length;
              this.groupResult.push(result.messages);
            }
          }

          this.expandTime = result.messages?.length !== PAGE_LIMIT; // when get more , if expandTime is true, from sub 10 days
          this.lastFrom = !this.expandTime ? new Date(result.fromMillis).getTime() : null;
          // stop
          if (totalQueryMore >= PAGE_LIMIT) {
            console.log('Stop: totalQueryMore >= PAGE_LIMIT', totalQueryMore);
            this.loading = false;
            this.hasMore = true;
            return;
          } else {
            console.log('Next: totalQueryMore < PAGE_LIMIT', totalQueryMore);
            const reqNew = <RequestSearchCriteria>{
              ...req,
              fromMillis: this.expandTime
                ? subDays(this.form.fromMillis, RANGE_SEARCH_SERVER_DAYS).getTime()
                : this.form.fromMillis,
              toMillis: this.expandTime ? this.form.fromMillis - 1 : this.lastFrom
            };
            this.pollingSearch(reqNew, totalQueryMore, maxTimeQueryMore);
          }
        },
        _ => {
          this.loading = false;
        }
      );
  }

  private getChannelPlaceholder(convo: Channel) {
    let placeholder: string;
    switch (convo.type) {
      case ChannelType.dm: {
        const directChatUsers = this.userQuery.getUserByChatUuid(convo.directChatUsers?.otherUuid);
        placeholder = `Search @${directChatUsers?.displayName || 'Unknown User'}`;
        break;
      }
      case ChannelType.gc:
        placeholder = `Search #${convo.displayName}`;
        break;
      default:
        placeholder = `Search ${convo.displayName}`;
        break;
    }
    return placeholder;
  }
}
