<div *ngIf="loadingHistory" class="ui-loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<ng-container *ngIf="isViewChatSection$ | async">
  <div *ngIf="viewDate$ | async as viewDate" class="viewing-date flex justify-content-center align-items-center">
    <span class="date">{{ viewDate | viewDate }}</span>
  </div>
</ng-container>

<div *ngIf="errApiLoadFirst" fxFill fxLayoutAlign="center center">
  <ng-container *ngIf="refetchHistory$ | async"></ng-container>
  <button color="primary" mat-icon-button (click)="refetchHistory()">
    <mat-icon class="icon-refresh">refresh</mat-icon>
  </button>
</div>

<div *ngIf="progressing" class="loading-viewport flex justify-content-center align-items-center">
  <mat-spinner [diameter]="70" [strokeWidth]="5"></mat-spinner>
</div>

<ng-container *ngIf="isEmptyMessagePersonal && convo.type === ChannelType.PERSONAL">
  <div class="empty-container flex column justify-content-center align-items-center gap-8">
    <mat-icon class="large-icon">book</mat-icon>

    <div class="flex column justify-content-center align-items-center">
      <div class="text-1">No Bookmarks</div>
      <div class="text-2">Bookmark the messages so you can find them quickly over here</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="{ mapPinMessage: mapPinMessage$ | async, participants: participants$ | async } as data">
  <div #viewport class="messages-viewport" *ngIf="reload && datasource" tabindex="0">
    <div
      *uiScroll="let message of datasource; let i = index"
      class="msg-item"
      [id]="message?.current?.clientId"
      [attr.data-ts]="message?.current?.ts"
      [class.hight-light]="message?.current?.clientId === hoverMessage"
    >
      <div *ngIf="!message.isFake; else fakeMsg">
        <div class="group-by-date" *ngIf="message.pre && message.isDiffDate">
          <span>{{ message.current?.ts | viewDate }}</span>
        </div>

        <div class="msg-item__content">
          <div
            *ngIf="isShowNewMessage && !!message.current?.id && newMessageId === message.current.id"
            class="new-message flex justify-content-center align-items-center"
          >
            <span>New messages</span>
          </div>
          <csh-chat-message
            [message]="message.current"
            [previousMessage]="message.pre"
            [parentElr]="message.current.mt === MsgType.attachment ? viewport : null"
            [participants]="data.participants"
            [enableEditing]="editingMessageId && message.current?.id ? editingMessageId === message.current.id : false"
            [configMessageOption]="configMessageOption"
            [isPinned]="data.mapPinMessage?.[message.current?.id]"
            [isHighlight]="hoverMessage ? message?.current?.clientId === hoverMessage : false"
            (showProfile)="onShowProfile($event)"
            (mentionsChanged)="onMentionsChanged($event)"
            (edited)="focusQuillEditorApp()"
            (addBookmarkMessage)="addBookmarkMessage($event)"
            (removeBookmarkMessage)="removeBookmarkMessage($event)"
            (replyingMessage)="replyingMessage($event)"
            (jumpToMessage)="onJumpToMessage($event)"
            (rightClickMessage)="onRightClickMessage($event)"
            (seeAllPinned)="onSeeAllPinned()"
            (saveEdtingMsgId2Store)="saveEdtingMsgId2Store($event)"
            (removeHightLight)="hoverMessage = null"
          ></csh-chat-message>
        </div>
      </div>

      <ng-template #fakeMsg>
        <div style="height: 20px"></div>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="enableScrollBottom$ | async">
  <button class="read-message" mat-mini-fab color="primary" (click)="scrollToLastestMessage()">
    <mat-icon class="material-symbols-rounded">expand_more</mat-icon>
  </button>
</ng-container>

<!-- matTooltip="Scroll to lastest message" -->
<!-- <a id="back2Bottom" *ngIf="convo.unreadCount > 0" (click)="scrollToUnreadMsg()"> You have new messages </a> -->

<div
  class="right-click-message-menu"
  (menuClosed)="menuClosed($event)"
  style="visibility: hidden; position: fixed"
  [matMenuTriggerFor]="rightMenu"
></div>
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngFor="let menu of item.menus">
      <button mat-menu-item (click)="handleRightClick(item, menu)">
        <mat-icon
          *ngIf="!menu.isSvg; else svgIcon"
          class="icon"
          [ngClass]="menu.size ? 's-' + menu.size : ''"
          [ngClass]="menu.classIcon"
        >
          {{ menu.icon }}
        </mat-icon>
        <ng-template #svgIcon>
          <mat-icon class="icon" [ngClass]="menu.size ? 's-' + menu.size : ''" [svgIcon]="menu.icon"></mat-icon>
        </ng-template>
        <span [ngClass]="menu.classText">{{ menu.value }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
