<h1 mat-dialog-title>{{ data.isPin ? 'Pin Mesasge' : 'Unpin Mesasge' }}</h1>

<div mat-dialog-content>
  <div class="flex column gap-8">
    <ng-container *ngIf="data.isPin; else unpin">
      <h3 *ngIf="!isNotAllowPin; else max">Are you sure you want to pin this message?</h3>
      <ng-template #max>
        <span>
          You have reached the maximum limit for pinned messages.
          <!-- <span class="reach-max">Maximum pinned is {{ MAX_PIN_SIZE }} messages</span> -->
        </span>
      </ng-template>
    </ng-container>
    <ng-template #unpin>
      <p>Are you sure you want to unpin this message?</p>
    </ng-template>
    <div *ngIf="!isNotAllowPin" class="mesasge-container">
      <csh-chat-message
        [id]="data.message?.clientId"
        [configMessageOption]="configMessageOption"
        [previousMessage]="null"
        [message]="data.message"
      ></csh-chat-message>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    [loading]="loading"
    [hidden]="isNotAllowPin"
    (click)="submit()"
    [color]="data.isPin ? 'primary' : 'warn'"
  >
    {{ data.isPin ? 'Pin' : 'Unpin' }}
  </button>
</div>
