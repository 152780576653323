<div
  [ngSwitch]="message.mt"
  [class.system_message]="message.mt === MessageType.system"
  class="normal_message_container main-text"
>
  <ng-container *ngSwitchCase="MessageType.system">
    <ng-container *ngIf="message.state.isCustomRender; else normalText">
      <b3n-system-message-custom
        [message]="message"
        (jumpToMessage)="jumpToMessage.emit($event)"
        (seeAllPinned)="seeAllPinned.emit($event)"
      ></b3n-system-message-custom>
    </ng-container>
    <ng-template #normalText>
      <ng-container *ngIf="!message.buildMsg?.builtTextMessage?.isTriggerDirective; else trigger">
        <span class="normal-text" [innerHTML]="message.buildMsg?.builtTextMessage?.text | safe: 'html'"></span>
      </ng-container>
      <ng-template #trigger>
        <span
          class="normal-text"
          lazyloadUnknown
          [text]="message.buildMsg?.builtTextMessage?.text"
          [innerHTML]="message.buildMsg?.builtTextMessage?.text | safe: 'html'"
          (showProfile)="showProfile.emit($event)"
          (jumpToMessage)="jumpToMessage.emit($event)"
          (seeAllPinned)="seeAllPinned.emit($event)"
        ></span>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="message.buildMsg?.builtTextMessage; else bug">
      <ng-container *ngIf="!message.buildMsg?.builtTextMessage?.isTriggerDirective; else trigger">
        <span
          class="normal-text"
          *ngIf="!isEditing"
          [innerHTML]="message.buildMsg?.builtTextMessage?.text | safe: 'html'"
          [class.sending]="!message.id"
          [class.red-fg]="message.state.isErrorFormat"
        ></span>
      </ng-container>
      <ng-template #trigger>
        <span
          class="normal-text"
          *ngIf="!isEditing"
          lazyloadUnknown
          [text]="message.buildMsg?.builtTextMessage?.text"
          [participants]="participants"
          [innerHTML]="message.buildMsg?.builtTextMessage?.text | safe: 'html'"
          [class.sending]="!message.id"
          (showProfile)="showProfile.emit($event)"
          (jumpToMessage)="jumpToMessage.emit($event)"
          (seeAllPinned)="seeAllPinned.emit($event)"
        ></span>
      </ng-template>

      <ng-container *ngIf="!isEditing && message.hasThread && !configMessageOption?.noShowThread">
        <b3n-info-thread-message
          [message]="message"
          [parentElr]="parentElr"
          (showProfile)="showProfile.emit($event)"
        ></b3n-info-thread-message>
      </ng-container>

      <a
        *ngIf="isSubstring || isExpand"
        href="javascript:void"
        class="show-more"
        (click)="expandMsg.emit(!isExpand)"
        class="tooltip-custom"
        [attr.data-tooltip]="isExpand ? 'Collapse' : 'Expand'"
      >
        ...
      </a>

      <div class="edit-msg" *ngIf="isEditing">
        <csh-quill-editor
          [data]="quillInput"
          [convoType]="message.ct"
          [convoId]="message.id"
          (messaged)="handleEnterMessage($event)"
          [convoId]="message.convo"
          #quill
        ></csh-quill-editor>
        <span class="mat-caption">
          Escape to <a href="javascript:;" (click)="resetEdit()">cancel</a> &#183; enter to
          <a href="javascript:;" (click)="send()">save </a>
        </span>
      </div>

      <ng-container *ngIf="!configMessageOption.noShowPreview && message.state.isPreviewMsg">
        <b3n-pre-view-message [message]="message"></b3n-pre-view-message>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #bug>
  <span class="red"
    >Error, please report!. messageId is {{ message?.id || '...' }} , convoId is {{ message.convo || '...' }}
  </span>
</ng-template>
