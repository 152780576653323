import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'b3n-chip-view',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ChipViewComponent implements OnInit {
  @Input() text: string;
  @Input() type: string = 'outline'; //outline, fill
  @Input() color: string = 'gray'; // gray, yellow, blue, red, green
  @Input() isBorderRounded = false; //border-sm, border-rounded

  className: string;

  ngOnInit(): void {
    this.className = `${this.type} ${this.color} ${this.isBorderRounded ? 'border-rounded' : ''}`;
  }
}
