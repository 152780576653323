import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Extension } from '@b3networks/api/bizphone';
import { WebrtcService } from '@b3networks/api/call';
import { DelegatedCallerId, MeQuery } from '@b3networks/api/callcenter';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { Observable, filter } from 'rxjs';

type KeypadForm = FormGroup<{
  number: FormControl<string>;
}>;

@Component({
  selector: 'b3n-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss']
})
export class KeypadComponent extends DestroySubscriberComponent implements OnInit {
  @Input() extension: Extension;
  @Input() callerId: string;
  @Input() delegatedCallerIds: DelegatedCallerId[] = [];
  @Input() closeDialogAfterCall = true;

  @Output() makeCallTo = new EventEmitter<string>();
  @Output() openManagePhoneDialog = new EventEmitter<any>();

  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  form: KeypadForm;

  extensionHasCallerId$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private webrtcService: WebrtcService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private meQuery: MeQuery
  ) {
    super();

    this.form = this.fb.group({
      number: ['']
    });
  }

  ngOnInit(): void {
    this.extensionHasCallerId$ = this.meQuery.extensionHasCallerId$.pipe(filter(x => !!x));
  }

  call(callerId: string) {
    this.webrtcService.updateUAConfiguration('display_name', callerId);
    this.makeCallTo.emit(this.numberFC.value);

    if (this.closeDialogAfterCall) {
      this.ngZone.run(() => {
        this.dialog.closeAll();
      });
    }

    this.openManagePhoneDialog.emit();
  }

  openMenu() {
    this.matMenuTrigger?.openMenu();
  }

  clearNumber() {
    const number = this.numberFC.value;
    this.numberFC.setValue(number.substr(0, number.length - 1));
  }

  insertNumber(i: number | string) {
    const number = this.numberFC.value;
    this.numberFC.setValue(number + i.toString());
  }

  get numberFC() {
    return this.form.controls.number;
  }
}
