import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApprovalWorkspaceService } from '@b3networks/api/approval';
import { Me, MeService as MeCCService } from '@b3networks/api/callcenter';
import {
  AttachmentMessageData,
  Channel,
  ChannelHyperspace,
  ChannelHyperspaceQuery,
  ChannelHyperspaceService,
  ChannelQuery,
  ChannelService,
  ChannelType,
  ChatMessage,
  ChatService,
  ClosedChannel,
  ConvoType,
  HistoryMessageQuery,
  HistoryMessageService,
  IParticipant,
  MappingHyperData,
  MsgType,
  NewRelatedConvoMessage,
  PinMessage,
  PinMessageService,
  PinOrUnpinData,
  Privacy,
  RoleType,
  Status,
  SystemMessageData,
  SystemMsgType,
  SystemType,
  TimeService,
  TxnMessageData,
  TxnMoveInboxData,
  TypingState,
  ViewUIStateCommon
} from '@b3networks/api/chat';
import {
  ActivitiesService,
  InboxesService,
  Notification,
  NotificationType,
  NotificationsQuery,
  NotificationsService,
  PENDING_TXNS,
  TxnChannel,
  TxnQuery,
  TxnService,
  TxnStatus
} from '@b3networks/api/inbox';
import {
  ConversationGroup,
  ConversationGroupQuery,
  ConversationGroupService,
  ConversationMetadata,
  FileDetail,
  HyperspaceQuery,
  HyperspaceService,
  MeQuery,
  MediaService,
  Member,
  StatusUserResponse,
  User,
  UserQuery,
  UserService,
  UserStatus
} from '@b3networks/api/workspace';
import { APPROVAL_BOT_NAME, RegExpPattern, X } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, Subject, forkJoin, of, timer } from 'rxjs';
import { catchError, filter, share, take } from 'rxjs/operators';
import { ConvoHelperService, SupportedConvo } from '../adapter/convo-helper.service';

import { Contact, ContactService } from '@b3networks/api/contact';
import { MessageConstants } from '../constant/message.const';
import { AppQuery } from '../state/app.query';
import { AppService } from '../state/app.service';
import { MessageNotificationProcessor } from './message-notification.processor';
import { MessageSystemTxnService } from './message-system-txn.service';
import { PreviewHistoryMessageQuery } from './preview-history-message/preview-history-message.query';
import { PreviewHistoryMessageService } from './preview-history-message/preview-history-message.service';

export const SUPPORTED_CONVOS = [
  ConvoType.whatsapp,
  ConvoType.LIVECHAT,
  ConvoType.INTERNAL_SPACE,
  ConvoType.email,
  ConvoType.support_center,
  ConvoType.call
];
export const SUPPORTED_CHANNEL = [ConvoType.direct, ConvoType.groupchat, ConvoType.personal, ConvoType.THREAD];

@Injectable({ providedIn: 'root' })
export class MessageReceiveProcessor {
  private _message$: Subject<ChatMessage> = new Subject();
  private _notification$: Subject<Notification> = new Subject();
  private _updateLastMessageChannel$: Subject<ChatMessage> = new Subject();

  constructor(
    private meQuery: MeQuery,
    private meCCService: MeCCService,
    private chatService: ChatService,
    private messageService: HistoryMessageService,
    private messageQuery: HistoryMessageQuery,
    private previewHistoryMessageQuery: PreviewHistoryMessageQuery,
    private previewHistoryMessageService: PreviewHistoryMessageService,
    private timeService: TimeService,
    private toastService: ToastService,
    private router: Router,
    private txnQuery: TxnQuery,
    private txnService: TxnService,
    private userQuery: UserQuery,
    private userService: UserService,
    private channelHyperspaceQuery: ChannelHyperspaceQuery,
    private channelHyperspaceService: ChannelHyperspaceService,
    private convoGroupQuery: ConversationGroupQuery,
    private convoGroupService: ConversationGroupService,
    private channelService: ChannelService,
    private channelQuery: ChannelQuery,
    private convoHelper: ConvoHelperService,
    private hyperspaceQuery: HyperspaceQuery,
    private hyperspaceService: HyperspaceService,
    private approvalWorkspaceService: ApprovalWorkspaceService,
    private mediaService: MediaService,
    private appQuery: AppQuery,
    private appService: AppService,
    private notificationsQuery: NotificationsQuery,
    private notificationsService: NotificationsService,
    private pinMessageService: PinMessageService,
    private messageNotificationProcessor: MessageNotificationProcessor,
    private messageSystemTxnService: MessageSystemTxnService,
    private inboxesService: InboxesService,
    private contactService: ContactService,
    private activitiesService: ActivitiesService
  ) {}

  pushEventMessage(message: ChatMessage) {
    this._message$.next(message);
  }

  pushEventNotification(notification: Notification) {
    this._notification$.next(notification);
  }

  pushEventUpdateLastMessageChannel(message: ChatMessage) {
    this._updateLastMessageChannel$.next(message);
  }

  onmessage() {
    return this._message$.asObservable().pipe(share());
  }

  onNotification() {
    return this._notification$.asObservable().pipe(share());
  }

  onUpdateLastMessageChannel() {
    return this._updateLastMessageChannel$.asObservable().pipe(share());
  }

  process(message: ChatMessage) {
    if (message.err) {
      this.toastService.error(message.err);
      return;
    }

    if (message.mt === MsgType.case) {
      return;
    }

    if ([...SUPPORTED_CHANNEL, ...SUPPORTED_CONVOS].indexOf(message.ct) === -1 && message.st !== SystemType.STATUS) {
      if (message.mt === MsgType.system) {
        this.handleSystemNoConvoType(message);
      } else {
        console.error(`message ${message.ct} type does not supported yet.`, message);
      }
      return;
    }

    const me = this.meQuery.getMe();
    // for temp message
    if (message.id == null && message.user === me?.userUuid && message.isStore) {
      //add 2 store
      if (!(message.ct === ConvoType.email && message?.body?.data?.type === SystemMsgType.followed)) {
        this.messageService.addMessage(message);
      }
    } else {
      if (message.st) {
        this.handleSystemMessage(message);
      } else {
        switch (message.ct) {
          case ConvoType.direct:
          case ConvoType.groupchat: {
            if (message.hs) {
              const hyper = this.hyperspaceQuery.getHyperByHyperspaceId(message.hs);
              if (!hyper) {
                this.hyperspaceService.getHyperspacesByMember(X.orgUuid).subscribe();
              }

              const channel = this.channelHyperspaceQuery.getChannel(message.channelId);
              if (channel) {
                this.processMsg(channel, message);
              } else {
                this.channelHyperspaceService
                  .getDetails(message.hs, message.channelId, <MappingHyperData>{
                    meUuid: me.userUuid,
                    currentOrg: X.orgUuid
                  })
                  .subscribe(c => {
                    this.calculatorMentionUnreadCount(message, c, me.userUuid);
                    this.checkNotification(message, c);
                  });
              }
            } else {
              const channel = this.channelQuery.getChannel(message.channelId);
              if (channel) {
                this.processMsg(channel, message);
              } else {
                let api$: Observable<User> = of(null);
                if (message.ct === ConvoType.direct) {
                  const chatUserUuid = message.user;
                  const user = this.userQuery.getUserByChatUuid(chatUserUuid);
                  if (!user) {
                    api$ = this.userService
                      .findOneByUuidAndUserTypeSequentially(chatUserUuid, 'chatId')
                      .pipe(catchError(() => of(null)));
                  }
                }

                forkJoin([this.channelService.getDetailsSequential(message.channelId, me.userUuid), api$]).subscribe(
                  ([c, user]: [Channel, User]) => {
                    const channel = this.channelQuery.getEntity(c.id);
                    this.calculatorMentionUnreadCount(message, channel, me.userUuid);
                    this.checkNotification(message, channel);

                    if (user && user?.isBot && user?.displayName === APPROVAL_BOT_NAME) {
                      this.approvalWorkspaceService.checkBot(user.userUuid).subscribe(res => {
                        if (res.isApprovalBot) {
                          this.userService.updateStateUser({
                            approvalBot: user.userUuid
                          });
                        }
                      });
                    }
                  }
                );
              }
            }
            break;
          }
          case ConvoType.THREAD: {
            if (
              message.mt === MsgType.system &&
              (<SystemMessageData>message.body?.data)?.type === SystemMsgType.threadJoin
            ) {
              this.handleSpecificCaseWithMe(message);
            }
            const channel = this.channelQuery.getChannel(message.channelId);
            if (channel) {
              this.processMsg(channel, message);
            }
            break;
          }
          case ConvoType.personal: {
            const channel1 = this.channelQuery.getPersonalChannel(message.channelId);
            if (channel1) {
              this.processMsg(channel1, message);
            } else {
              this.channelQuery
                .selectEntity(message.channelId)
                .pipe(
                  filter(x => x != null),
                  take(1)
                )
                .subscribe(c => this.processMsg(c, message));
            }
            break;
          }
          case ConvoType.INTERNAL_SPACE:
          case ConvoType.whatsapp:
          case ConvoType.LIVECHAT: {
            const convo = this.messageSystemTxnService.addConversation2Store(message);
            if (convo) {
              this.processMsg(convo, message);
            } else {
              console.error('Not found convo', message);
            }
            break;
          }

          case ConvoType.sms: {
            const convo = this.messageSystemTxnService.addConversation2Store(message);
            if (convo) {
              this.processMsg(convo, message);
            } else {
              console.error('Not found convo', message);
            }
            break;
          }
          case ConvoType.support_center: {
            const convo = this.messageSystemTxnService.addConversation2Store(message);
            if (convo) {
              this.processMsg(convo, message);
            } else {
              console.error('Not found convo', message);
            }
            break;
          }
          case ConvoType.call: {
            const convo = this.messageSystemTxnService.addConversation2Store(message);
            if (convo) {
              this.processMsg(convo, message);
            } else {
              console.error('Not found convo', message);
            }
            break;
          }
          case ConvoType.email: {
            const emailConvos = this.convoGroupQuery.getConvosByChildId(message.channelId);
            if (emailConvos && emailConvos.length) {
              this.processMsg(emailConvos[0], message);
            } else {
              this.convoGroupService.getConversationDetail(message.channelId, me.userUuid, true).subscribe();
            }
            break;
          }
          default:
            console.error(`message ${message.ct} type does not supported yet.`, message);
            break;
        }
      }
    }

    // share message
    this.pushEventMessage(message);
  }

  private processMsg(convo: SupportedConvo, message: ChatMessage) {
    const me = this.meQuery.getMe();
    this.addMessageToStore(message, convo, me.userUuid);
    this.calculatorMentionUnreadCount(message, convo, me.userUuid);
    this.checkNotification(message, convo);

    // handle msg by mt
    if (message.mt === MsgType.system) {
      this.handlSystemMessage(convo, message);
    } else if (message.mt === MsgType.attachment) {
      if (message?.id) {
        this.cacheMediaForChannel(message);
      }
    }
  }

  private handleSystemNoConvoType(message: ChatMessage) {
    const system = <SystemMessageData>message.body?.data;
    switch (system?.type) {
      case SystemMsgType.newUser:
        {
          const newUser = system?.newUser;
          if (newUser) {
            const newUser = system.newUser;
            const user = new User(<User>{
              uuid: newUser.memberUuid,
              userUuid: newUser.chatUserId,
              displayName: newUser.displayName,
              role: newUser.role,
              orgUuid: newUser.orgUuid,
              identityUuid: newUser.memberUuid,
              memberStatus: newUser.memberStatus
            });
            this.userService.updateUsers2Store([user]);
          }
        }
        break;

      case SystemMsgType.agentStatus: {
        const agentData = system?.agentData;
        if (agentData) {
          const me = new Me(agentData);
          this.meCCService.updateState({ meCallcenter: me, me: me });
        }
        break;
      }
      case SystemMsgType.notificationSyncComm: {
        const meIdentity = this.meQuery.getMe()?.identityUuid;
        const notification = system?.notification;
        if (notification) {
          const noti = this.notificationsService.addNotification2Store(
            <Notification>{
              ...notification,
              type: notification.type as string,
              isRead: notification.isRead == null ? undefined : notification.isRead,
              isClicked: notification.isClicked == null ? undefined : notification.isClicked
            },
            meIdentity
          );

          if (notification?.channel === TxnChannel.call && notification?.type === NotificationType.accepted) {
            const txnActiveId = this.txnQuery.getActiveId();
            if (notification.txnUuid === txnActiveId) {
              // re-fresh active call data
              const triggerRefreshActiveCall = this.appQuery.getValue()?.triggerRefreshActiveCall;
              this.appService.update({
                triggerRefreshActiveCall: !triggerRefreshActiveCall
              });
            }
          }
          this.pushEventNotification(noti);
        }
        break;
      }
      case SystemMsgType.seenNotifcationSyncComm: {
        const meIdentity = this.meQuery.getMe()?.identityUuid;
        const notificationId = system?.notificationId;
        if (notificationId?.length > 0) {
          notificationId?.forEach(notiId => {
            let noti = this.notificationsQuery.getEntity(notiId);
            if (noti) {
              noti = this.notificationsService.addNotification2Store(
                <Notification>{
                  ...noti,
                  isClicked: true
                },
                meIdentity
              );
              this.pushEventNotification(noti);
            }
          });
        }
        break;
      }
      case SystemMsgType.txnChangedEvt: {
        const txnChangedEvt = system?.txnChangedEvt as TxnMessageData;
        if (txnChangedEvt) {
          this.handlerMessageSystemTxn(txnChangedEvt, message);
        }
        break;
      }
      case SystemMsgType.hyperspaceUpdateUsers: {
        if (message.hs) {
          const hyper = this.hyperspaceQuery.getHyperByHyperspaceId(message.hs);
          if (!hyper) {
            this.hyperspaceService.getHyperspacesByMember(X.orgUuid).subscribe();
          }
        }
        break;
      }
      default: {
        console.error(`new system status and no catched`, message);
        if (message.hs) {
          // new channel hyper
          // TODO: improve
          if (message.channelId) {
            const system = message.body?.data;
            const channelHyper = this.channelHyperspaceQuery.getEntity(message.channelId);
            if (!channelHyper) {
              const channel = new ChannelHyperspace({
                hyperspaceId: message.hs,
                id: message.channelId,
                name: system?.name,
                privacy: system?.privacy,
                type: system?.type
              }).mappingModel(<MappingHyperData>{
                meUuid: this.meQuery.getMe().userUuid,
                currentOrg: X.orgUuid
              });
              this.channelHyperspaceService.updateChannel([channel]);
            }
          }
        }

        break;
      }
    }
  }

  private handleSystemMessage(message: ChatMessage) {
    switch (message.st) {
      case SystemType.SEEN:
        const me = this.meQuery.getMe();
        if (me && message.user === me.userUuid) {
          if (SUPPORTED_CHANNEL.indexOf(message.ct) > -1) {
            if (message.hs) {
              this.channelHyperspaceService.markSeen(message.channelId);
            } else {
              this.channelService.markSeen(message.channelId, message.ts);
            }
          } else {
            if ([ConvoType.LIVECHAT, ConvoType.whatsapp, ConvoType.INTERNAL_SPACE].includes(message.ct)) {
              this.txnService.markSeen(message.channelId);
            } else if (ConvoType.support_center) {
              const txn = this.txnQuery.findTxnByConvo(message.convo);
              if (txn) {
                this.txnService.markSeen(txn.txnUuid);
              }
            } else if (message.ct === ConvoType.email) {
              const emailConvos = this.convoGroupQuery.getConvosByChildId(message.channelId);
              if (emailConvos && emailConvos.length) {
                this.convoGroupService.markSeen(emailConvos[0].id);
              }
            } else {
              this.convoGroupService.markSeen(message.channelId);
            }
          }
        }
        break;
      case SystemType.EDIT:
        if (message.ct === ConvoType.personal && message?.messageBookmark) {
          this.messageService.updateBookmarkExpandMap({
            [message.messageBookmark.id]: null
          });
        }
        this.updateMessageV2(message);
        break;
      case SystemType.DELETE:
        this.updateMessageV2(message);
        if (message.mt === MsgType.attachment) {
          this.mediaService.removeMedias2StoreByMsgId(message.id);
        }
        break;
      case SystemType.PURGE:
        this.removeMessage(message);
        break;
      // only public channel
      case SystemType.CHANNEL_NEW:
      case SystemType.CHANNEL_UPDATE:
        {
          const system = <SystemMessageData>message.body?.data;
          const metadata = system.metadata;
          if (message.isFromChannel && !!metadata) {
            const channel = <Channel>{
              ...metadata,
              privacy: Privacy.public,
              type: ChannelType.gc
            };
            this.channelService.updateChannel([channel]);
          }
        }
        break;
      case SystemType.STATUS:
        {
          const status = message.mt === MsgType.online ? UserStatus.online : UserStatus.offline;
          const map = <StatusUserResponse>{ state: status, ts: message.ts };
          this.userService.updateStatusUserV2({
            [message.user]: map
          });
        }
        break;
      case SystemType.UPDATE:
        {
          this.updateMessageV2(message);
        }
        break;
      default:
        console.error(`new system status and no catched`, message);
        break;
    }
  }

  private handlSystemMessage(convo: SupportedConvo, message: ChatMessage) {
    const system = <SystemMessageData>message?.body?.data;
    if (!system) {
      console.error(`custom message without data`, message);
      return;
    }

    // need to refresh convo when join system include me
    // join (teamchat), joinThread (thread), convoUpdateUsers (hs)
    this.handleSpecificCaseWithMe(message);
    this.messageTypeSystemWithConvo(convo, message);
  }

  private handleSpecificCaseWithMe(message: ChatMessage) {
    const system = <SystemMessageData>message?.body?.data;
    if (!system) {
      return;
    }

    const me = this.meQuery.getMe();
    if (SUPPORTED_CHANNEL.indexOf(message.ct) > -1) {
      if (system.type === SystemMsgType.join && !message.hs) {
        const hasMe = message.body?.data?.join?.indexOf(me.userUuid) > -1;
        if (hasMe) {
          // fetch detail convo
          this.channelService.getDetailsSequential(message.channelId, me.userUuid).subscribe(() => {
            const channel = this.channelQuery.getChannel(message.channelId);
            this.calculatorMentionUnreadCount(message, channel, me.userUuid);
          });
          // refresh my thread list when joined
          this.channelService.getMineByParent(message.channelId, me.userUuid).subscribe();
          return;
        }
      } else if (message.ct === ConvoType.THREAD && system.type === SystemMsgType.threadJoin) {
        const hasMe = system?.threadJoin?.userId === me.userUuid;
        if (hasMe) {
          // fetch detail thread convo
          this.channelService.getDetailsSequential(message.channelId, me.userUuid).subscribe(() => {
            const channel = this.channelQuery.getChannel(message.channelId);
            this.calculatorMentionUnreadCount(message, channel, me.userUuid);
          });
          return;
        }
      } else if (system.type === SystemMsgType.convoUpdateUsers && message.hs) {
        const joined = system?.convoUpdateUsers?.joined || [];
        const hasMe = joined?.some(p => p.id === me.userUuid);
        if (hasMe) {
          this.channelHyperspaceService
            .getDetails(message.hs, message.channelId, <MappingHyperData>{
              meUuid: me.userUuid,
              currentOrg: X.orgUuid
            })
            .subscribe(c => {
              this.calculatorMentionUnreadCount(message, c, me.userUuid);
            });
        }
      }
    }
  }

  private messageTypeSystemWithConvo(convo: SupportedConvo, message: ChatMessage) {
    const me = this.meQuery.getMe();
    const system = <SystemMessageData>message.body?.data;

    if (system.type === SystemMsgType.leave) {
      const hasMe = system?.leave?.indexOf(me.userUuid) > -1;
      if (hasMe) {
        const id = convo instanceof ConversationGroup ? convo?.conversationGroupId : convo?.id;

        // switch convo
        const activeConvo = this.convoHelper.getActiveByChannel(convo);
        if (activeConvo === id) {
          this.router.navigate(['landing']);
          if (SUPPORTED_CHANNEL.includes(message.ct)) {
            this.channelService.removeActive(activeConvo);
          }
          // remove convo
          if (convo instanceof Channel) {
            this.channelService.closeConversation(id);
          } else if (convo instanceof ChannelHyperspace) {
            this.channelHyperspaceService.closeConversation(id);
          } else if (convo instanceof ConversationGroup) {
            this.convoGroupService.closeConversation(id);
          }
        }

        timer(100).subscribe(() => {
          if (convo instanceof Channel) {
            this.channelService.removeAllThreadByParent(convo.id);
          }
        });
      }
    }

    const isConvoGroup = convo instanceof ConversationGroup;

    // post_process message receive for system message
    switch (system?.type) {
      case SystemMsgType.typing:
        this.updateTyping(convo, message);
        break;
      case SystemMsgType.update: {
        const metadata = system?.metadata;
        if (metadata) {
          this.convoHelper.updateConvo(convo, <Channel>metadata);
        }
        break;
      }
      case SystemMsgType.convoUpdateUsers:
        if (message.hs && [ConvoType.groupchat].indexOf(message.ct) > -1) {
          const convoUpdateUsers = system.convoUpdateUsers;
          if (convoUpdateUsers) {
            if (convoUpdateUsers?.joined) {
              const joins = convoUpdateUsers?.joined || [];
              const participantCurrentOrg: IParticipant[] = [
                ...((convo as ChannelHyperspace)?.participantCurrentOrg || [])
              ];
              const participantOtherOrg: IParticipant[] = [
                ...((convo as ChannelHyperspace)?.participantOtherOrg || [])
              ];

              joins.forEach(item => {
                if (item.ns === X.orgUuid) {
                  if (!participantCurrentOrg.some(x => x.userID === item.id)) {
                    participantCurrentOrg.push(<IParticipant>{
                      userID: item.id,
                      role: item.role
                    });
                  }
                } else {
                  if (!participantCurrentOrg.some(x => x.userID === item.id)) {
                    participantOtherOrg.push(<IParticipant>{
                      userID: item.id,
                      role: item.role
                    });
                  }
                }
              });

              this.convoHelper.updateConvo(convo, <ChannelHyperspace>{
                participantCurrentOrg,
                participantOtherOrg
              });
            } else if (convoUpdateUsers?.left) {
              const left = convoUpdateUsers?.left || [];
              const participantCurrentOrg: IParticipant[] = [
                ...((convo as ChannelHyperspace)?.participantCurrentOrg || [])
              ].filter(x => !left.some(l => l.id === x.userID));
              const participantOtherOrg: IParticipant[] = [
                ...((convo as ChannelHyperspace)?.participantOtherOrg || [])
              ].filter(x => !left.some(l => l.id === x.userID));

              this.convoHelper.updateConvo(convo, <ChannelHyperspace>{
                participantCurrentOrg,
                participantOtherOrg
              });
            }
          }
        }
        break;
      case SystemMsgType.join:
        if ([ConvoType.groupchat].indexOf(message.ct) > -1 && me) {
          if (system?.join) {
            // update participant for channel
            const participants = [...((convo as Channel)?.participants || [])];
            const joins = system?.join || [];
            joins.forEach(u => {
              if (!participants.some(x => x.userID === u)) {
                participants.push(<IParticipant>{
                  userID: u,
                  role: ''
                });
              }
            });
            this.convoHelper.updateConvo(convo, <Channel>{ participants: participants });
          }
        }

        if (message.ct === ConvoType.email) {
          const conversationGroup = convo as ConversationGroup;
          const joins: string[] = system?.join || [];
          const origin = <Member[]>conversationGroup.conversations[0].members || [];
          const members = [...origin];

          joins.forEach(u => {
            const findIndex = origin.findIndex(x => x.chatUserUuid === u && x.role === RoleType.followed);
            if (findIndex > -1) {
              members.splice(findIndex, 1);
            }
            members.push(<Member>{
              chatUserUuid: u,
              role: RoleType.member
            });
          });

          const firstConvoChild = new ConversationMetadata({
            ...conversationGroup.conversations[0],
            members: members
          });

          this.convoHelper.updateConvo(convo, <ConversationGroup>{
            conversations: [firstConvoChild]
          });
        }
        break;
      case SystemMsgType.threadJoin:
        if ([ConvoType.THREAD].indexOf(message.ct) > -1 && me) {
          const threadJoin = system?.threadJoin;
          if (threadJoin && threadJoin?.threadId) {
            // update participant for channel
            const participants = [...((convo as Channel)?.participants || [])];

            const joins = [threadJoin.userId] || [];
            joins.forEach(u => {
              if (!participants.some(x => x.userID === u)) {
                participants.push(<IParticipant>{
                  userID: u,
                  role: ''
                });
              }
            });
            this.convoHelper.updateConvo(convo, <Channel>{ participants: participants });
          }
        }
        break;
      case SystemMsgType.threadClose:
        if ([ConvoType.THREAD].includes(message.ct) && me) {
          const threadClose = system?.threadClose;
          if (threadClose && threadClose?.closedId) {
            this.convoHelper.updateConvo(convo, <Channel>{
              closedL2: <ClosedChannel>{
                id: threadClose?.closedId,
                at: +threadClose?.closedAt,
                by: threadClose?.closedBy
              }
            });
          }
        }
        break;
      case SystemMsgType.followed:
        if (message.ct === ConvoType.email) {
          const conversationGroup = convo as ConversationGroup;
          const follows = system?.followed || [];
          const origin = <Member[]>conversationGroup.conversations[0].members || [];
          const members = [...origin];
          let isUpdated = false;

          follows.forEach(u => {
            const findIndex = origin.findIndex(x => x.chatUserUuid === u && x.role === RoleType.member);
            if (findIndex > -1) {
              members.splice(findIndex, 1);
            }
            if (origin.findIndex(x => x.chatUserUuid === u && x.role === RoleType.followed) === -1) {
              isUpdated = true;
              members.push(<Member>{
                chatUserUuid: u,
                role: RoleType.followed
              });
            }
          });

          if (isUpdated) {
            const firstConvoChild = new ConversationMetadata({
              ...conversationGroup.conversations[0],
              members: members
            });

            this.convoHelper.updateConvo(convo, <ConversationGroup>{
              conversations: [firstConvoChild]
            });
          }
        }
        break;
      case SystemMsgType.leave:
        if (message.ct === ConvoType.groupchat && me) {
          if (system?.leave) {
            // update participant for channel
            let participants = (convo as Channel)?.participants || [];
            const leaves = system?.leave || [];
            participants = participants.filter(p => leaves.indexOf(p.userID) === -1);
            this.convoHelper.updateConvo(convo, <Channel>{ participants: participants });
          }
        }

        if (message.ct === ConvoType.email) {
          const conversationGroup = convo as ConversationGroup;
          let participants = conversationGroup?.members || [];
          const system = <SystemMessageData>message?.body?.data;
          const leaves = system?.leave || [];
          participants = participants.filter(p => leaves.indexOf(p.chatUserUuid) === -1);
          const firstConvoChild = new ConversationMetadata({
            ...conversationGroup.conversations[0],
            members: participants
          });

          this.convoHelper.updateConvo(convo, <ConversationGroup>{
            conversations: [firstConvoChild]
          });
        }
        break;
      case SystemMsgType.archived: {
        if (
          [ConvoType.LIVECHAT, ConvoType.support_center, ConvoType.call, ConvoType.INTERNAL_SPACE].includes(message.ct)
        ) {
          const txnMessageData = message.body?.data as TxnMessageData;
          this.handlerMessageSystemTxn(txnMessageData, message);
        }

        const req = isConvoGroup
          ? <ConversationGroup>{
              status: Status.archived,
              archivedBy: message.user
            }
          : <Channel>{ archivedAt: message.ts, archivedBy: message.user };
        this.convoHelper.updateConvo(convo, req);
        break;
      }
      case SystemMsgType.unarchived: {
        if (SUPPORTED_CONVOS.includes(message.ct)) {
          const txnMessageData = message.body?.data as TxnMessageData;
          this.handlerMessageSystemTxn(txnMessageData, message);
        }

        const req1 = isConvoGroup
          ? <ConversationGroup>{
              status: Status.opened,
              archivedBy: null
            }
          : <Channel>{ archivedAt: null, archivedBy: null };
        this.convoHelper.updateConvo(convo, req1);
        break;
      }
      case SystemMsgType.pin: {
        const pinMessageData: PinOrUnpinData = system?.pin;
        if (pinMessageData) {
          const pinInfo = new PinMessage({
            userId: pinMessageData.userId,
            messageId: pinMessageData.messageId,
            at: +pinMessageData?.at,
            convoId: message.convo
          });
          this.pinMessageService.addPin2Store(pinInfo);
        }
        break;
      }
      case SystemMsgType.unpin: {
        const unpinMessage: PinOrUnpinData = system?.unpin;
        if (unpinMessage) {
          this.pinMessageService.removePin2Store(unpinMessage.messageId);
        }
        break;
      }
      case SystemMsgType.move:
        if (message.ct === ConvoType.email) {
          const move = system?.move;
          if (move) {
            this.convoHelper.updateConvo(convo, <ConversationGroup>{
              emailInboxUuid: move?.emailInboxUuid
            });
          }
        }
        break;
      case SystemMsgType.snooze:
        if (message.ct === ConvoType.email) {
          const snooze = system?.snooze;
          if (snooze) {
            this.convoHelper.updateConvo(convo, <ConversationGroup>{
              snoozeFrom: snooze?.snoozeFrom,
              snoozeAt: snooze?.snoozeAt,
              snoozeBy: me.identityUuid
            });
          }
        }
        break;
      case SystemMsgType.convoUpdateMetadata:
        {
          const convoUpdateMetadata = system?.convoUpdateMetadata;
          if (message.hs && convoUpdateMetadata) {
            const desc = convoUpdateMetadata?.description;
            this.convoHelper.updateConvo(convo, <ChannelHyperspace>{ description: desc });
          }
        }
        break;
      // inbox system flow
      case SystemMsgType.created:
      case SystemMsgType.assigned:
      case SystemMsgType.unassigned:
      case SystemMsgType.updateData:
      case SystemMsgType.moveInbox:
      case SystemMsgType.createComment:
      case SystemMsgType.updateComment:
      case SystemMsgType.deleteComment:
      case SystemMsgType.newRelatedConvoMessage: {
        const txnMessageData = message.body?.data as TxnMessageData;
        this.handlerMessageSystemTxn(txnMessageData, message);
        break;
      }
      default:
        console.error(`customized system message unhandled: ${message.body?.data?.type}`, message);
        break;
    }
  }

  handlerMessageSystemTxn(data: TxnMessageData, message: ChatMessage) {
    switch (data.type) {
      // inbox system flow
      case SystemMsgType.created: {
        this.messageSystemTxnService.checkAndStoreTxnContact(data, message);
        break;
      }
      case SystemMsgType.archived: {
        if (
          [ConvoType.LIVECHAT, ConvoType.INTERNAL_SPACE, ConvoType.support_center, ConvoType.call].includes(message.ct)
        ) {
          this.messageSystemTxnService.checkAndStoreTxnContact(data, message);

          let txn = this.txnQuery.getTxn(data?.txnUuid);
          if (!txn) {
            txn = this.txnQuery.findTxnByConvo(message.convo);
          }
          if (txn) {
            this.txnService.updateTxn2Store(data.txnUuid, {
              status: TxnStatus.ended
            });
          }
        }
        break;
      }
      case SystemMsgType.unarchived: {
        if ([ConvoType.LIVECHAT, ConvoType.INTERNAL_SPACE, ConvoType.support_center].includes(message.ct)) {
          this.messageSystemTxnService.checkAndStoreTxnContact(data, message);

          if (data?.txnUuid) {
            if (message.ct === ConvoType.LIVECHAT) {
              const customerConvo = this.convoGroupQuery.getEntity(data?.txnUuid);
              if (customerConvo) {
                this.convoHelper.updateConvo(customerConvo, <ConversationGroup>{
                  status: Status.opened,
                  archivedBy: message.user
                });
              }
            }
          }
        }
        break;
      }
      case SystemMsgType.assigned: {
        this.messageSystemTxnService.checkAndStoreTxnContact(data, message);
        if (data?.txnUuid) {
          const txn = this.txnQuery.getEntity(data?.txnUuid);
          const participant = [...(txn?.lastAssignedAgents || [])];
          const join = data?.assignees || [];
          join.forEach(u => {
            if (!participant.includes(u)) {
              participant.push(u);
            }
          });
          this.txnService.updateTxn2Store(data.txnUuid, {
            lastAssignedAgents: participant
          });
        }

        break;
      }
      case SystemMsgType.unassigned: {
        this.messageSystemTxnService.checkAndStoreTxnContact(data, message);

        if (data?.txnUuid) {
          const txn = this.txnQuery.getEntity(data.txnUuid);
          let participant = [...(txn?.lastAssignedAgents || [])];
          const left = data.assignees || [];
          participant = participant.filter(p => !left.includes(p));
          this.txnService.updateTxn2Store(data.txnUuid, {
            lastAssignedAgents: participant
          });
        }

        break;
      }
      case SystemMsgType.updateData: {
        this.messageSystemTxnService.checkAndStoreTxnContact(data, message);
        break;
      }
      case SystemMsgType.createComment:
      case SystemMsgType.updateComment: {
        const { activityId, type, txnUuid } = data;

        if (data.type === SystemMsgType.createComment) {
          this.txnService.countUnread(txnUuid);
        }

        const loadedDetail = this.txnQuery.getUiState(txnUuid)?.loadedDetail;
        if (loadedDetail) {
          this.activitiesService
            .getActivity(txnUuid, { activityId, activityType: 'comment', typeMsg: type })
            .subscribe();
        }
        break;
      }
      case SystemMsgType.deleteComment: {
        const { activityId, txnUuid } = data;
        const loadedDetail = this.txnQuery.getUiState(txnUuid)?.loadedDetail;

        if (loadedDetail) {
          this.activitiesService.deleteActivity(activityId);
        }
        break;
      }
      case SystemMsgType.moveInbox: {
        const data = message.body?.data as TxnMoveInboxData;
        if (data && data?.txnInfo?.uuid) {
          this.messageSystemTxnService.pushEventMoveInbox(data);

          const txnCurrent = this.txnQuery.getEntity(data?.txnInfo?.uuid);

          // count/discount pending txn before move inbox
          if (txnCurrent && txnCurrent?.inboxUuid !== data.toInbox) {
            if (PENDING_TXNS.includes(txnCurrent?.status)) {
              this.inboxesService.descreasePendingTxn(txnCurrent?.inboxUuid);
            }
          }

          if (PENDING_TXNS.includes(data?.txnInfo?.status)) {
            this.inboxesService.inscreasePendingTxn(data.toInbox);
          }

          const convertData = <TxnMessageData>{
            type: data.type,
            txnUuid: data.txnInfo?.['uuid'],
            inboxUuid: data.txnInfo?.inboxUuid,
            publicConvoId: data.txnInfo?.publicConvoId,
            channel: data.txnInfo?.channel,
            createdAt: data?.createdAt,
            customer: {
              uuid: data.txnInfo?.customer?.uuid,
              displayName: data.txnInfo?.customer?.displayName,
              chatUserId: data.txnInfo?.customer?.chatCustomerId
            },
            status: data.txnInfo?.status,
            assigningTo: null
          };
          this.messageSystemTxnService.checkAndStoreTxnContact(convertData, message, true);

          this.contactService.updateContacts2Store([
            new Contact({
              uuid: data.txnInfo?.customer?.uuid || undefined,
              displayName: data.txnInfo?.customer?.displayName || undefined,
              chatCustomerId: data.txnInfo?.customer?.chatCustomerId || undefined
            })
          ]);
        }
        break;
      }
      case SystemMsgType.newRelatedConvoMessage: {
        const data = message.body?.data as SystemMessageData;
        if (data && data?.newRelatedConvoMessage) {
          const newRelatedConvoMessage = data.newRelatedConvoMessage as NewRelatedConvoMessage;
          this.messageSystemTxnService.checkAndStoreTxnContact(newRelatedConvoMessage, message);

          // new msg of publicConvoId
          if (newRelatedConvoMessage?.relatedConvoMessage) {
            const msg = new ChatMessage(newRelatedConvoMessage?.relatedConvoMessage);
            this.process(msg);
          }
        }
        break;
      }
      default:
        console.error(`customized system message unhandled TXN: ${message.body?.data?.type}`, message);
        break;
    }
  }

  private updateTyping(convo: SupportedConvo, message: ChatMessage) {
    if (convo && this.meQuery.getMe().userUuid !== message.user) {
      const uiState = this.convoHelper.getUIState(convo);
      let userTypings = Object.assign([], uiState.userTypings);
      userTypings = userTypings.filter(
        (s: TypingState) =>
          s.userUuid.toLowerCase() !== message.user.toLowerCase() &&
          s.startAtMillis + MessageConstants.TYPING > this.timeService.nowInMillis()
      );
      userTypings.push(new TypingState(message.user, message.ts));

      this.convoHelper.updateUIState(convo, <ViewUIStateCommon>{
        userTypings: userTypings
      });
    }
  }

  private updateMessageV2(message: ChatMessage) {
    if (this.messageQuery.hasEntity(message?.id)) {
      this.messageService.updateMessageV2(message);
      this.updateLastMessageByEditMsg(message);
    }

    if (this.previewHistoryMessageQuery.hasEntity(message?.id)) {
      this.previewHistoryMessageService.updateMessageV2(message);
    }
  }

  private removeMessage(message: ChatMessage) {
    if (this.messageQuery.hasEntity(message.id)) {
      this.messageService.removeMessage(message);
    }

    if (this.previewHistoryMessageQuery.hasEntity(message.id)) {
      this.previewHistoryMessageService.removeMessage(message);
    }
  }

  private updateLastMessageByEditMsg(message: ChatMessage) {
    if (!message.id) {
      return;
    }

    const lastMsg = this.channelQuery.getEntity(message.channelId)?.lastMessage;
    if (lastMsg && lastMsg?.id && lastMsg.id === message.id) {
      this.updateLastMessage(message);
    }
  }

  private updateLastMessage(message: ChatMessage) {
    if (!message.id) {
      return;
    }

    switch (message.ct) {
      case ConvoType.direct:
      case ConvoType.groupchat:
      case ConvoType.THREAD:
        if (message.hs) {
          this.channelHyperspaceService.updateLastMessage(message.channelId, message);
        } else {
          this.channelService.updateLastMessage(message.channelId, message);
        }

        if (message.ct === ConvoType.THREAD) {
          this.pushEventUpdateLastMessageChannel(message);
        }
        break;
      case ConvoType.LIVECHAT:
      case ConvoType.whatsapp:
      case ConvoType.sms:
      case ConvoType.email:
        this.convoGroupService.updateLastMessage(message.channelId, message);
        break;
      case ConvoType.personal:
        break;
      default:
        console.error(`message ${message.ct} type does not supported yet.`);
        break;
    }
  }

  private addMessageToStore(message: ChatMessage, convo: SupportedConvo, meUserUuid: string) {
    const uiState = this.convoHelper.getUIState(convo);
    if (uiState?.loaded || uiState?.needReceiveLiveMessage) {
      let isMsgFromClient: boolean;
      if (message.isStore) {
        isMsgFromClient = this.messageService.addMessage(message);

        if (message.id && message.ct === ConvoType.THREAD && convo instanceof Channel) {
          this.channelService.updateChannel([<Channel>{ id: message.channelId, emc: (convo.emc || 0) + 1 }]);
        }
      }
      if (isMsgFromClient && convo && !message.body.data && meUserUuid === message.user) {
        const urlMatched = message.body.text?.match(RegExpPattern.URL);
        if (urlMatched) {
          this.convoGroupService.getPreviewLink(urlMatched[0]).subscribe(res => {
            if (res && (res.desc || res.icon || res.image || res.title)) {
              const msg = new ChatMessage(
                Object.assign(
                  {},
                  {
                    ...message,
                    body: {
                      ...message?.body,
                      data: res
                    }
                  }
                )
              );
              msg.st = SystemType.EDIT;
              this.chatService.send(msg);
            }
          });
        }
      }
    } else {
      if (SUPPORTED_CHANNEL.includes(message.ct)) {
        if (message.isStore && message.id) {
          this.updateLastMessage(message);
        }
      }
    }
  }

  private checkNotification(message: ChatMessage, convo: SupportedConvo) {
    // hasNotified
    if (message.isStore) {
      if (message.IsNotified) {
        this.messageNotificationProcessor.showCustomerNotification(message, convo);
      } else {
        if ([ConvoType.LIVECHAT, ConvoType.whatsapp, ConvoType.INTERNAL_SPACE].includes(message.ct)) {
          this.messageNotificationProcessor.showCustomerNotification(message, convo);
        }
      }
    }
  }

  private calculatorMentionUnreadCount(message: ChatMessage, convo: SupportedConvo, meUserUuid: string) {
    if (message.isStore) {
      // hasUnread
      this.countUnreadConvo(message, convo, meUserUuid);

      // hasMention
      if (message.hasMention || message?.ct === ConvoType.direct) {
        this.convoHelper.updateConvo(convo, <Channel>{
          mentionCount: (convo?.mentionCount || 0) + 1
        });
      }
    }
  }

  private countUnreadConvo(message: ChatMessage, convo: SupportedConvo, meUserUuid: string) {
    if (message.user !== meUserUuid) {
      if (message.isCountUnread) {
        const updateChannel = <Channel>{
          unreadCount: (convo?.unreadCount || 0) + 1
        };

        // update last msg
        if (updateChannel.unreadCount === 1 && SUPPORTED_CHANNEL.includes(message.ct)) {
          this.convoHelper.updateUIState(convo, <ViewUIStateCommon>{
            newMessage: message
          });
        }
        this.convoHelper.updateConvo(convo, updateChannel);
      }

      if ([ConvoType.whatsapp, ConvoType.LIVECHAT].includes(message.ct)) {
        this.txnService.countUnread(message.convo);
      }
    }
  }

  private cacheMediaForChannel(message: ChatMessage) {
    const data: AttachmentMessageData = message.body.data?.attachment || message.body.data;
    const uri =
      data?.uri?.startsWith('storage://') || data?.uri?.startsWith('legacy://')
        ? data?.uri
        : data.mediaUuid || data.mediaId || data.fileUuid;
    if (uri) {
      const detail = <FileDetail>{
        convoId: message.convo,
        userId: message.user,
        name: data.name,
        uri: uri,
        fileType: data.fileType,
        size: data.size,
        metadata: {
          width: data.width,
          height: data.height
        },
        mediaId: data.mediaId || data.mediaUuid || data.fileUuid,
        createdTime: message.ts,
        msgId: message.id
      };
      this.mediaService.addMedias2Store([detail]);
    }
  }
}
