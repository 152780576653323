import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheMessageQuery, CacheMessageService, ChatMessage } from '@b3networks/api/chat';
import { PreviewHistoryMessageStore } from './preview-history-message.store';

@Injectable({ providedIn: 'root' })
export class PreviewHistoryMessageService {
  constructor(
    private http: HttpClient,
    private store: PreviewHistoryMessageStore,
    private cacheMessageService: CacheMessageService,
    private cacheMessageQuery: CacheMessageQuery
  ) {}

  removeMessage(message: ChatMessage | string) {
    if (message instanceof ChatMessage) {
      this.store.remove(message.clientId);
    } else {
      this.store.remove(message);
    }
  }

  addMessage2Store(message: ChatMessage) {
    this.store.add(message);
    if (this.cacheMessageQuery.hasEntity(message.id)) {
      this.cacheMessageService.upsertManyMessages([message]);
    }
  }

  upsertManyMessages(messages: ChatMessage[]) {
    this.store.upsertMany(messages, {
      baseClass: ChatMessage
    });

    messages
      .filter(x => this.cacheMessageQuery.hasEntity(x.id))
      ?.forEach(item => {
        this.cacheMessageService.upsertManyMessages([item]);
      });
  }

  updateMessage(message: ChatMessage) {
    this.store.update(message.clientId, message);
  }

  updateMessageV2(message: ChatMessage) {
    this.store.update(message.clientId, message);
    if (this.cacheMessageQuery.hasEntity(message.id)) {
      this.cacheMessageService.upsertManyMessages([message]);
    }
  }

  removeManyMessage(messagesId: string[]) {
    this.store.remove(messagesId);
  }

  cleanupOneConvoMessages(convoUuid: string) {
    this.store.remove((entity: ChatMessage) => entity.channelId === convoUuid);
  }

  cleanupConvoMessages(listConvoLoaded: string[]) {
    this.store.remove((entity: ChatMessage) => {
      return listConvoLoaded.indexOf(entity.channelId) > -1;
    });
  }

  cleanupAllMessage() {
    this.store.remove();
  }
}
