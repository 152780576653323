import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApprovalWorkspaceService } from '@b3networks/api/approval';
import {
  Channel,
  ChannelQuery,
  ChannelService,
  ChannelType,
  ChannelUI,
  ChatMessage,
  ChatService,
  ExtraData,
  HistoryMessageQuery,
  LinkedMessages,
  MessageBody,
  MsgType,
  ReplyMessage,
  SystemMessageData,
  SystemMsgType,
  TimeService,
  TypingState,
  UpdateChannelReq,
  ViewUIStateCommon
} from '@b3networks/api/chat';
import { MeQuery, User, UserQuery } from '@b3networks/api/workspace';
import {
  APPROVAL_BOT_NAME,
  ActiveIframeService,
  IdleService,
  OutputContentQuill,
  WindownActiveService,
  WsState,
  arrayCompare,
  deltaHasContent
} from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { DeltaStatic } from 'quill';
import { Observable, Subject, of, timer } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { MessageConstants } from '../../../core/constant/message.const';
import { AppQuery } from '../../../core/state/app.query';
import { AppService } from '../../../core/state/app.service';
import { ConfirmInviteComponent, InputConfirmInviteDialog } from '../../dialog/confirm-invite/confirm-invite.component';
import { CshQuillEditorComponent, QuillEditorInput } from '../../quill-editor/quill-editor.component';

const SEND_TYPING_TIME = 3 * 1000;

@Component({
  selector: 'b3n-conversation-footer',
  templateUrl: './conversation-footer.component.html',
  styleUrls: ['./conversation-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationFooterComponent implements OnDestroy, OnChanges {
  @Input() channel: Channel;

  private _id: string;
  protected destroyChannel$ = new Subject();

  quillEditorData: QuillEditorInput = <QuillEditorInput>{
    enableMention: true,
    enableEmoji: true,
    enableUpload: true,
    showSendButton: true
  };
  isStarting: boolean;

  archivedBy$: Observable<User>;
  userTypings$: Observable<TypingState[]>;
  isApprovalBot$: Observable<boolean>;
  replyingMessage$: Observable<ReplyMessage>;
  isDeletedReplyingMessage$: Observable<boolean>;

  @Output() uploadedFiles = new EventEmitter<File[]>();

  @ViewChild(CshQuillEditorComponent) quillEditorComponent: CshQuillEditorComponent;

  constructor(
    private meQuery: MeQuery,
    private userQuery: UserQuery,
    private chatService: ChatService,
    private messageQuery: HistoryMessageQuery,
    private timeService: TimeService,
    private channelQuery: ChannelQuery,
    private channelService: ChannelService,
    private idleService: IdleService,
    private dialog: MatDialog,
    private windownActiveService: WindownActiveService,
    private activeIframeService: ActiveIframeService,
    private approvalWorkspaceService: ApprovalWorkspaceService,
    private toastService: ToastService,
    private appQuery: AppQuery,
    private appService: AppService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['channel'] && this.channel && this.channel.id !== this._id) {
      this._id = this.channel.id;

      this.isApprovalBot$ =
        this.channel.type === ChannelType.dm
          ? this.userQuery
              .selectUserByChatUuid(this.channel?.directChatUsers?.otherUuid)
              .pipe(
                switchMap(user =>
                  user?.isBot && user?.displayName === APPROVAL_BOT_NAME
                    ? this.userQuery.approvalBot$.pipe(
                        map(userUuid => userUuid === this.channel?.directChatUsers?.otherUuid)
                      )
                    : of(null)
                )
              )
          : of(null);

      this.userTypings$ = this.channelQuery.selectUIState(this._id, 'userTypings').pipe(
        map(x => x || []),
        map(userTypings => this.getValidTypingUsers(userTypings))
      );

      const context = this.channelQuery.getChannelUiState(this._id)?.draftMsg;
      this.quillEditorData = {
        ...this.quillEditorData,
        context,
        placeholder: this.getChannelPlaceholder(this.channel),
        tagHere: this.channel.type === ChannelType.THREAD
      };

      this.replyingMessage$ = this.channelQuery.selectUIState(this._id, 'replyingMessage');
      this.isDeletedReplyingMessage$ = this.replyingMessage$.pipe(
        switchMap(reply =>
          reply ? this.messageQuery.selectEntity(reply?.message?.id, entity => entity?.metadata?.deletedAt) : of(null)
        ),
        map(deletedAt => !!deletedAt)
      );
      this.archivedBy$ = this.channelQuery.selectPropertyChannel(this._id, 'archivedBy').pipe(
        filter(x => x != null),
        map(
          archivedBy => this.userQuery.getEntity(archivedBy) // identity Uuid
        )
      );

      this.destroyChannel$.next(true);
      this.trackingSendTyping();
      this.trackingRemoveUserTyping();
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy() {
    this.destroyChannel$.next(true);
    this.destroyChannel$.complete();
  }

  removeReply() {
    this.channelService.updateChannelViewState(this._id, {
      replyingMessage: null
    });

    const state = this.appQuery.getValue();
    this.appService.update({
      quillEditor: {
        ...state.quillEditor,
        triggerfocus: !state.quillEditor.triggerfocus
      },
      triggerScrollBottomView: !state.triggerScrollBottomView
    });
  }

  handleEnterMessage(data: OutputContentQuill) {
    const me = this.meQuery.getMe();
    const channel = this.channelQuery.getEntity(this._id);
    const uiState = this.channelQuery.getChannelUiState(channel.id);
    const message = ChatMessage.createMessage(
      channel,
      new MessageBody({ text: data?.msg }),
      me.userUuid,
      MsgType.message
    );

    const replyMsg = uiState?.replyingMessage;
    if (replyMsg) {
      if (replyMsg?.message?.extraData) {
        delete replyMsg.message.extraData;
      }
      if (replyMsg?.message?.metadata) {
        delete replyMsg.message.metadata;
      }
      message.extraData = new ExtraData({
        ...message.extraData,
        linkedMessages: new LinkedMessages(<LinkedMessages>{
          ...message.extraData?.linkedMessages,
          replyTo: replyMsg?.message?.id
        }).addMsgToSnapshots(replyMsg?.message)
      });
    }

    // clear draft
    this.channelService.updateChannelViewState(this._id, {
      draftMsg: null
    });

    const sent = this.chatService.send(message);
    if (sent) {
      const updateUI = <ChannelUI>{};
      if (uiState.lastSeenMsgID) {
        // scroll bottom
        updateUI.lastSeenMsgID = undefined;
      }
      if (replyMsg) {
        updateUI.replyingMessage = undefined;
      }
      this.channelService.updateChannelViewState(this._id, updateUI);

      if (replyMsg) {
        // trigger scroll bottom
        const state = this.appQuery.getValue();
        this.appService.update({
          triggerScrollBottomView: !state.triggerScrollBottomView
        });
      }

      // if (data?.mentions?.length > 0 && channel.isGroupChat && !channel.isGeneral) {
      //   this.checkUserMention(data);
      // }
    }
  }

  onUploadFile(files: File[]) {
    this.uploadedFiles.emit(files);
  }

  newRequest() {
    this.isStarting = true;
    this.cdr.markForCheck();
    this.approvalWorkspaceService
      .start()
      .pipe(
        finalize(() => {
          this.isStarting = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe({ error: err => this.toastService.error(err.message) });
  }

  joinConvo() {
    const me = this.meQuery.getMe();
    if (me) {
      const req = <UpdateChannelReq>{
        add: [me.userUuid]
      };
      this.channelService.addOrRemoveParticipants(this._id, req).subscribe();
    }
  }

  showTypingUsers(userTypings: TypingState[]) {
    return userTypings?.length > 0 ? 'visible' : 'hidden';
  }

  onTextChanged(draftMessage: DeltaStatic) {
    const text = deltaHasContent(draftMessage) ? draftMessage : null;
    this.channelService.updateChannelViewState(this._id, <ViewUIStateCommon>{
      draftMsg: text
    });
  }

  handleEditLastMessage($event: any) {
    const me = this.meQuery.getMe();
    const lastestMessage = this.messageQuery.getlastestMsgByUser(this._id, me.userUuid)[0];
    if (lastestMessage && this.timeService.nowInMillis() - lastestMessage.ts < MessageConstants.TIMEOUT) {
      this.channelService.updateChannelViewState(this._id, <ChannelUI>{
        editingMessageId: lastestMessage.clientId
      });
    }
  }

  private getValidTypingUsers(userTypings: TypingState[]) {
    return userTypings?.filter(
      (s: TypingState) => s.startAtMillis + MessageConstants.TYPING > this.timeService.nowInMillis()
    );
  }

  private checkUserMention(data: OutputContentQuill) {
    const channel = this.channelQuery.getEntity(this._id);
    const notMembers: string[] =
      data.mentions.filter(m => !channel.participants.some(x => x.userID === m) && m !== 'everyone') || [];
    if (notMembers.length > 0) {
      const dialogRef = this.dialog.open(ConfirmInviteComponent, {
        data: <InputConfirmInviteDialog>{ members: notMembers, convo: channel }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const req = <UpdateChannelReq>{
            add: notMembers
          };
          this.channelService.addOrRemoveParticipants(this._id, req).subscribe();
        }
      });
    }
  }

  private trackingRemoveUserTyping() {
    timer(SEND_TYPING_TIME, SEND_TYPING_TIME)
      .pipe(
        takeUntil(this.destroyChannel$),
        filter(_ => this.windownActiveService.windowActiveStatus && this.activeIframeService.isMyIframe)
      )
      .subscribe(_ => {
        const userTyping = this.channelQuery.getChannelUiState(this._id)?.userTypings || [];
        const validTyping = this.getValidTypingUsers(userTyping);
        if (!arrayCompare(userTyping, validTyping)) {
          this.channelService.updateChannelViewState(this._id, <ChannelUI>{
            userTypings: validTyping
          });
        }
      });
  }

  private trackingSendTyping() {
    timer(SEND_TYPING_TIME, SEND_TYPING_TIME)
      .pipe(
        takeUntil(this.destroyChannel$),
        filter(
          () =>
            this.idleService.idleStatus === WsState.active &&
            this.windownActiveService.windowActiveStatus &&
            this.activeIframeService.isMyIframe
        )
      )
      .subscribe(() => {
        const channel = this.channelQuery.getEntity(this._id);
        if (channel && channel?.isMyChannel) {
          const draftMessage = this.channelQuery.getChannelUiState(this._id).draftMsg;
          if (deltaHasContent(draftMessage)) {
            const body = new MessageBody({
              text: '',
              title: '',
              data: <SystemMessageData>{
                text: '',
                type: SystemMsgType.typing
              }
            });

            const me = this.meQuery.getMe();
            const message = ChatMessage.createMessage(channel, body, me.userUuid, MsgType.system).markIsNoStore();
            this.chatService.send(message);
          }
        }
      });
  }

  private getChannelPlaceholder(convo: Channel) {
    let placeholder: string;
    switch (convo.type) {
      case ChannelType.dm:
        {
          if (!convo.nameUserDirect) {
            const user: User = this.userQuery.getUserByChatUuid(convo.directChatUsers.otherUuid);
            placeholder = `Message to @${user?.displayName}`;
          } else {
            placeholder = `Message to @${convo?.displayName}`;
          }
        }
        break;
      case ChannelType.gc:
        placeholder = `Message to #${convo?.displayName}`;
        break;
      default:
        placeholder = `Message to ${convo?.displayName}`;
        break;
    }
    return placeholder;
  }
}
