import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[resizableWidth]'
})
export class ResizableWidthDirective implements AfterViewInit, OnChanges {
  @Input() root: HTMLElement; // other element resize

  @Input() initWidth: number;
  @Input() grabWidth = 8; // px
  @Input() moreSpace = 0; // can add 10px because 1px border to hard to hover resize
  @Input() minWidth = 50; // px
  @Input() maxWidth = 1000;

  @Output() widthChanged = new EventEmitter<number>();

  private _elementResize: HTMLElement;
  private _dragging = false;

  constructor(private elr: ElementRef, private renderer: Renderer2, private ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initWidth'] && this._elementResize && this.initWidth > 0) {
      this.newWidth(this.initWidth);
    }
  }

  ngAfterViewInit(): void {
    this._elementResize = this.root || this.elr?.nativeElement;
    console.log('this._elementResize: ', this._elementResize);
    // this.renderer.setStyle(this._elementResize, 'border-right', this.grabWidth + 'px solid rgba(0, 0, 0, 0.3)');
    this.renderer.setStyle(this._elementResize, 'border-right', this.grabWidth + 'px solid red');

    if (this.initWidth) {
      this.newWidth(this.initWidth);
    }

    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('mousemove', this.mouseMoveG.bind(this), true);
      document.addEventListener('mouseup', this.mouseUpG.bind(this), true);
      this._elementResize.addEventListener('mousedown', this.mouseDown.bind(this), true);
      this._elementResize.addEventListener('mousemove', this.mouseMove.bind(this), true);
    });
  }

  private inDragRegion(evt) {
    console.log('evt.clientX: ', evt.clientX);
    return (
      this._elementResize.clientWidth - evt.clientX + this._elementResize.offsetLeft < this.grabWidth + this.moreSpace
    );
  }

  private newWidth(width: number) {
    let newWidth = Math.max(this.minWidth, width); // check minWidth
    newWidth = Math.min(this.maxWidth, newWidth); // check maxWidth
    this.renderer.setStyle(this._elementResize, 'width', newWidth + 'px');
    this.renderer.setStyle(this._elementResize, 'min-width', newWidth + 'px');
    this.renderer.setStyle(this._elementResize, 'max-width', newWidth + 'px');
    this.widthChanged.emit(newWidth);
  }

  private preventGlobalMouseEvents() {
    this.renderer.setStyle(this._elementResize, 'pointer-events', 'none');
  }

  private restoreGlobalMouseEvents() {
    this.renderer.setStyle(this._elementResize, 'pointer-events', 'auto');
  }

  private mouseMoveG(evt) {
    if (!this._dragging) {
      return;
    }
    console.log('evt: ', evt);
    this.newWidth(evt.clientX - this._elementResize.offsetLeft);
    evt.stopPropagation();
  }

  private mouseUpG(evt) {
    if (!this._dragging) {
      return;
    }
    this.restoreGlobalMouseEvents();
    this._dragging = false;
    evt.stopPropagation();
  }

  private mouseDown(evt) {
    if (this.inDragRegion(evt)) {
      this._dragging = true;
      this.preventGlobalMouseEvents();
      evt.stopPropagation();
    }
  }

  private mouseMove(evt) {
    if (this.inDragRegion(evt) || this._dragging) {
      this.renderer.setStyle(this._elementResize, 'cursor', 'ew-resize');
    } else {
      this.renderer.setStyle(this._elementResize, 'cursor', 'default');
    }
  }
}
