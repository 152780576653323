import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IdentityProfileQuery } from '@b3networks/api/auth';
import { TimerCall } from '@b3networks/api/call';
import { Pageable } from '@b3networks/api/common';
import { Contact } from '@b3networks/api/contact';
import { FileService, S3Service, ScaningStatus, Status, TempUploadRes } from '@b3networks/api/file';
import {
  CaseRelatedTo,
  CasesService,
  ChannelType,
  ComponentStructureFormat,
  Inbox,
  InboxesQuery,
  MeQuery,
  MeService,
  Media,
  ReqCreateTxnWhatsapp,
  RequestFilterCustomerTxnsV2,
  RequestSendMsgWhatsapp,
  TemplateComponent,
  TemplateComponentParameter,
  ToastTxnCreated,
  Txn,
  TxnChannel,
  TxnService,
  TxnStatus,
  TypeMsgWhatsapp,
  WaNumbersQuery,
  WaNumbersService,
  WaTemplateQuery,
  WaTemplateService,
  WhatsappNumbers,
  WhatsappService,
  WhatsappTemplate,
  WhatsappTemplateRegex
} from '@b3networks/api/inbox';
import { TemplateComponentType } from '@b3networks/api/workspace';
import { WidgetMatched, X } from '@b3networks/shared/common';
import { ThemeService } from '@b3networks/shared/ui/theme';
import { ToastData, ToastDataBase, ToastService } from '@b3networks/shared/ui/toast';
import { guid } from '@datorama/akita';
import {
  Observable,
  Subject,
  catchError,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { UIFileUpload } from '../../../core/model/shared.model';
import { CustomToastWaComponent } from '../custom-toast-wa/custom-toast-wa.component';

export interface TxnWaBackData {
  txn: Txn;
  contact: Contact;
}

@Component({
  selector: 'b3n-txn-wa-back',
  templateUrl: './txn-wa-back.component.html',
  styleUrls: ['./txn-wa-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TxnWaBackComponent implements OnInit, OnDestroy {
  @ViewChild('toast') toast: TemplateRef<CustomToastWaComponent>;

  isDarkTheme$: Observable<boolean>;
  isLimitCreateWa$: Observable<boolean>;
  numberWa$: Observable<WhatsappNumbers[]>;
  inboxes$: Observable<Inbox[]>;
  templates$: Observable<WhatsappTemplate[]>;

  loading = true;
  fetching: boolean;
  uploading: boolean;
  refreshing: boolean;

  txnActive: TxnCustom;
  titleDialog = '';
  tooltipRefresh = 'Refresh to get latest template';

  form: FormGroup = this.fb.group({
    wabaPhoneNumberId: [null, [Validators.required]],
    inboxUuid: [null, [Validators.required]],
    template: [null, [Validators.required]]
  });

  formHeader = this.fb.group({});
  messageHeader: string;
  file: File;
  fileUI: UIFileUpload;
  readonly MAX_FILE_SIZE = 5; // 5MB

  formBody = this.fb.group({});
  messageBody: string;

  formButton = this.fb.group({});
  buttonsHasParameter: boolean;
  activeBtns: boolean[];

  readonly WhatsappNumbers = WhatsappNumbers;
  readonly ComponentStructureFormat = ComponentStructureFormat;

  get wabaPhoneNumberId() {
    return this.form.get('wabaPhoneNumberId');
  }

  get inboxUuid() {
    return this.form.get('inboxUuid');
  }

  get template() {
    return this.form.get('template');
  }

  get templateValue() {
    return this.template.value as WhatsappTemplate;
  }

  private _destroyValueChange$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TxnWaBackData,
    private dialogRef: MatDialogRef<TxnWaBackComponent>,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private meQuery: MeQuery,
    private meService: MeService,
    private waNumbersService: WaNumbersService,
    private waNumbersQuery: WaNumbersQuery,
    private whatsappService: WhatsappService,
    private waTemplateQuery: WaTemplateQuery,
    private identityProfileQuery: IdentityProfileQuery,
    private txnService: TxnService,
    private inboxesQuery: InboxesQuery,
    private waTemplateService: WaTemplateService,
    private casesService: CasesService,
    private s3Service: S3Service,
    private fileService: FileService,
    private themeService: ThemeService,
    private toastService: ToastService
  ) {}

  ngOnDestroy(): void {
    this._destroyValueChange$.next(true);
    this._destroyValueChange$.complete();
  }

  ngOnInit() {
    this.isDarkTheme$ = this.themeService.isDarkMode$;
    this.isLimitCreateWa$ = this.meQuery.isLimitCreateWa$;
    this.numberWa$ = this.waNumbersQuery.selectAllNumber();
    this.inboxes$ = this.inboxesQuery.selectInboxByChannel(TxnChannel.whatsapp);

    this.titleDialog = `Send WhatsApp Message to ${this.data.contact?.displayName} ${
      this.data.txn.whatsapp?.waId ? '(' + this.data.txn.whatsapp?.waId + ')' : ''
    }`;

    this.meService.getAgentsMe().subscribe(res => {
      if (res.whatsappAvailableCapacity === 0) {
        this.dialogRef.disableClose = false;
      }
    });

    this.template.valueChanges.subscribe(value => {
      this.initFormParameterTemplate(value);
    });

    this.templates$ = this.waTemplateQuery.selectAllTemplate().pipe(
      tap(templates => {
        this.tooltipRefresh = `Refresh to get latest ${templates?.length > 1 ? 'templates' : 'template'}`;
      })
    );

    this.templates$
      .pipe(
        filter(x => x.length > 0),
        take(1)
      )
      .subscribe(templates => {
        this.template.setValue(templates[0]);
      });

    const req = <RequestFilterCustomerTxnsV2>{
      customerUuid: this.data.contact?.uuid,
      inboxUuids: [],
      channels: [TxnChannel.whatsapp],
      assignees: [],
      statuses: [TxnStatus.assigned, TxnStatus.assigning, TxnStatus.transferring, TxnStatus.waiting]
    };
    this.txnService
      .getTxnByCustomerV2(req, new Pageable(1, 50), { flagFetching: false })
      .pipe(
        switchMap(data => {
          return data?.txns?.length > 0
            ? forkJoin(
                data.txns?.map(x =>
                  this.txnService.getDetailTxnV2(x.txnUuid).pipe(
                    catchError(() => of(null)),
                    map(res => res?.txn)
                  )
                )
              )
            : of(<Txn[]>[]);
        })
      )
      .subscribe({
        next: txns => {
          const isUpperAdmin = this.identityProfileQuery.currentOrg?.isUpperAdmin;
          const findTxnSameWaId = txns?.find(x => x.whatsapp?.waId === this.data.txn?.whatsapp?.waId);
          const inbox = this.inboxesQuery.getInbox(findTxnSameWaId?.inboxUuid);
          this.txnActive = findTxnSameWaId
            ? new TxnCustom({
                ...findTxnSameWaId,
                hasPermission: inbox && (isUpperAdmin || inbox.isMyInbox),
                inbox: inbox
              })
            : null;
          if (!this.txnActive) {
            // select first sender ID
            const numbers = this.waNumbersQuery.getAllNumber();
            if (numbers?.length > 0) {
              this.wabaPhoneNumberId.setValue(numbers[0]);
              this.loading = false;
            }
          } else {
            this.dialogRef.disableClose = false;
            this.loading = false;
          }
          this.cdr.markForCheck();
        },
        error: err => {
          this.loading = false;
          this.toastService.error(err?.message);
          this.cdr.markForCheck();
        }
      });
  }

  navigateTxn(item: Txn) {
    this.router.navigate(['txn', item.txnUuid]);
    this.cancel();
  }

  onRefresh() {
    this.refreshing = true;
    this.cdr.markForCheck();
    this.waTemplateService
      .getAll(true)
      .pipe(
        finalize(() => {
          this.refreshing = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe(() => {
        const selectTemplate = this.template.value;
        if (selectTemplate) {
          this.template.setValue(selectTemplate);
        }
      });
  }

  compareTemplate(o1: WhatsappTemplate, o2: WhatsappTemplate) {
    return o1?.id === o2?.id;
  }

  compareNumbers(o1: WhatsappNumbers, o2: WhatsappNumbers) {
    return o1?.phoneNumberId === o2?.phoneNumberId;
  }

  compareInbox(o1: Inbox, o2: Inbox) {
    return o1?.uuid === o2?.uuid;
  }

  private isValidFileType(file: File) {
    let typeAllow = [];
    switch (this.templateValue.component?.header?.format) {
      case ComponentStructureFormat.IMAGE:
        typeAllow = ['jpg', 'jpeg', 'png'];
        return file.type.startsWith('image/') && typeAllow.includes(file.type.split('/')[1]);
      case ComponentStructureFormat.VIDEO:
        typeAllow = ['mp4'];
        return file.type.startsWith('video/mp4') && typeAllow.includes(file.type.split('/')[1]);
      case ComponentStructureFormat.DOCUMENT:
        typeAllow = ['pdf'];
        return file.type.startsWith('application/pdf') && typeAllow.includes(file.type.split('/')[1]);
    }
    return false;
  }

  onFileChange(event, format: ComponentStructureFormat) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      if (file.size > this.MAX_FILE_SIZE * 1024 * 1024) {
        this.toastService.warning(`Your proposed upload exceeds ${this.MAX_FILE_SIZE}MB`);
        return;
      }
      if (!this.isValidFileType(file)) {
        this.toastService.warning(`File is invalid`);
        return;
      }
      this.messageHeader = URL.createObjectURL(file);
      this.file = file;
      this.fileUI = new UIFileUpload(file);

      if (format === ComponentStructureFormat.IMAGE) {
        const image = new Image();
        image.onload = () => {
          this.fileUI.width = image.width;
          this.fileUI.height = image.height;
        };
        image.src = this.messageHeader;
      }
    }
  }

  private avScanProgress(scanId: number, res: TempUploadRes, timer: TimerCall, txnUuid: string) {
    this.uploading = true;
    this.s3Service.scaningFileStatus(scanId).subscribe({
      next: status => {
        if (status === ScaningStatus.scanning) {
          if (timer.second >= 60 * 15) {
            // timeout 1m
            timer.clearIntervalTime();
            this.toastService.error('High traffic. Please try again later.');
            return;
          }
          setTimeout(() => {
            this.avScanProgress(scanId, res, timer, txnUuid);
          }, 1000);
        } else if (status === ScaningStatus.failed) {
          timer.clearIntervalTime();
          this.toastService.error('High traffic. Please try again later.');
        } else if (status === ScaningStatus.infected) {
          timer.clearIntervalTime();
          this.toastService.error('Warning! The upload file has been infected.');
        } else if (status === ScaningStatus.clean) {
          timer.clearIntervalTime();
          const media: Media = {
            fileKey: res.tempKey,
            fileSize: this.file.size + '',
            mimeType: this.file.type,
            fileName: this.file.name,
            caption: this.file.name,
            width: this.fileUI.width,
            height: this.fileUI.height
          };
          this.uploading = false;
          this.send(txnUuid, media);
          this.linkRelatedToTxn(txnUuid);
        }
      },
      error: error => {
        this.toastService.error(error.message || 'The file could not be uploaded. Please try again in a few minutes');
        this.uploading = false;
      }
    });
  }

  private transformToastData(txnUuid: string) {
    let data = new ToastData(<ToastDataBase>{ guid: txnUuid, duration: 5000 });

    data = new ToastData(<ToastData>{
      ...data,
      // inject component
      template: this.toast,
      templateContext: {
        txn: <ToastTxnCreated>{
          txnUuid: txnUuid,
          displayText: `${this.titleDialog} successfully`
        },
        toastData: data
      }
    });
    return data;
  }

  createWa() {
    this.meService.getAgentsMe().subscribe(res => {
      if (res.whatsappAvailableCapacity > 0) {
        this.fetching = true;
        this.cdr.markForCheck();
        this.whatsappService
          .createTxnWhatsapp(<ReqCreateTxnWhatsapp>{
            wabaPhoneNumberId: (<WhatsappNumbers>this.wabaPhoneNumberId?.value)?.phoneNumberId,
            waId: this.data.txn.whatsapp?.waId,
            selfAssign: true,
            inboxUuid: (<Inbox>this.inboxUuid.value)?.uuid
          })
          .subscribe({
            next: data => {
              if (
                [
                  ComponentStructureFormat.IMAGE,
                  ComponentStructureFormat.VIDEO,
                  ComponentStructureFormat.DOCUMENT
                ].includes(this.templateValue.component?.header?.format)
              ) {
                this.s3Service.tempUploadWithAVScan(this.file).subscribe({
                  next: res => {
                    if (res.status === Status.COMPLETED) {
                      const timer = new TimerCall();
                      timer.countTimeCall();
                      this.avScanProgress(res?.scanId, res, timer, data?.txnUuid);
                    }
                  },
                  error: err => this.toastService.error(err.message)
                });
              } else {
                this.send(data?.txnUuid);
                this.linkRelatedToTxn(data?.txnUuid);
              }
            },
            error: err => {
              this.toastService.error(err.message);
              this.fetching = false;
              this.cdr.markForCheck();
            }
          });
      } else {
        this.toastService.error(
          `You've reached your capacity limit. Triggering a WhatsApp back transaction is currently unavailable.`
        );
      }
    });
  }

  private send(txnUuid: string, media?: Media) {
    const components: TemplateComponent[] = [];
    if (this.templateValue.component?.header?.arrayParams.length > 0) {
      const componentHeader = <TemplateComponent>{
        type: TemplateComponentType.HEADER,
        parameters: this.templateValue.component?.header?.arrayParams.map(
          param =>
            <TemplateComponentParameter>{
              type: 'text',
              text: this.formHeader.value?.[param]
            }
        )
      };
      components.push(componentHeader);
    }

    if (
      [ComponentStructureFormat.IMAGE, ComponentStructureFormat.VIDEO, ComponentStructureFormat.DOCUMENT].includes(
        this.templateValue.component?.header?.format
      )
    ) {
      const componentHeader = <TemplateComponent>{
        type: TemplateComponentType.HEADER,
        parameters: [{ type: 'media', media }]
      };
      components.push(componentHeader);
    }

    if (this.templateValue.component?.body?.arrayParams.length > 0) {
      const componentBody = <TemplateComponent>{
        type: TemplateComponentType.BODY,
        parameters: this.templateValue.component?.body?.arrayParams.map(
          param =>
            <TemplateComponentParameter>{
              type: 'text',
              text: this.formBody.value?.[param]
            }
        )
      };
      components.push(componentBody);
    }

    if (this.buttonsHasParameter) {
      this.templateValue.component.buttons
        .filter(b => b.hasParameters)
        .forEach(b => {
          const componentButton = <TemplateComponent>{
            type: TemplateComponentType.BUTTON,
            index: this.templateValue.component.buttons.indexOf(b),
            parameters: b.arrayParams.map(
              param =>
                <TemplateComponentParameter>{
                  type: 'text',
                  text: this.formButton.value?.[param]
                }
            )
          };
          components.push(componentButton);
        });
    }

    const req = <RequestSendMsgWhatsapp>{
      txnUuid: txnUuid,
      wabaPhoneNumberId: (<WhatsappNumbers>this.wabaPhoneNumberId?.value)?.phoneNumberId,
      waId: this.data.txn.whatsapp?.waId,
      messageType: TypeMsgWhatsapp.template,
      template: {
        name: this.templateValue.name,
        language: this.templateValue.language,
        components: components
      }
    };
    this.whatsappService
      .sendMessage(req)
      .pipe(
        finalize(() => {
          this.fetching = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          const toast = this.transformToastData(txnUuid);
          this.toastService.addToast(toast);
          this.cancel();
        },
        error: err => this.toastService.error(err?.message)
      });
  }

  cancel() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  private linkRelatedToTxn(newTxnUuid: string) {
    if (!newTxnUuid) return;
    this.casesService
      .updateRelatedCases(X.orgUuid, this.data.txn.txnUuid, {
        relatedInfo: [
          <CaseRelatedTo>{
            uuid: newTxnUuid,
            orgUuid: X.orgUuid,
            title: null,
            channel: ChannelType.WHATSAPP
          }
        ],
        action: 'add'
      })
      .subscribe({
        next: data => {
          console.log('data: ', data);
        }
      });
  }

  private initFormParameterTemplate(template: WhatsappTemplate) {
    const { component } = template;
    if (component.header) {
      if (component.header.hasParameters) {
        this.formHeader = this.fb.group({});
        const { mapParamsExample, arrayParams } = component.header;
        arrayParams.forEach(item => {
          const ctrol = new FormControl(mapParamsExample[item].value, [Validators.required]);
          this.formHeader.addControl(`${item}`, ctrol);
        });

        let convertMatchText = component.header.text;
        const matched = convertMatchText.match(WhatsappTemplateRegex);
        const channelMatchs: WidgetMatched[] = [];
        if (matched) {
          matched.forEach(item => {
            const random: string = guid();
            convertMatchText = convertMatchText.replace(item, random);
            channelMatchs.push({ random: random, text: item, index: this.findIndexInTemplate(item) });
          });
        }

        this.formHeader.valueChanges
          .pipe(startWith(this.formHeader.value), takeUntil(this._destroyValueChange$))
          .subscribe(value => {
            let mappingText = convertMatchText;
            channelMatchs.forEach((item, i) => {
              const replaceText = value?.[item.text]
                ? `<span class='edited'><span class="wa-badge">${item.index + 1}</span> ${value?.[item.text]}</span>`
                : `<span class='no-edit'>${item.text}</span>`;
              mappingText = mappingText.replace(item.random, replaceText);
            });
            this.messageHeader = mappingText;
          });
      } else {
        if (component.header.format === ComponentStructureFormat.TEXT) {
          this.messageHeader = component.header?.text;
        } else if (
          [ComponentStructureFormat.IMAGE, ComponentStructureFormat.VIDEO, ComponentStructureFormat.DOCUMENT].includes(
            component.header.format
          )
        ) {
          const linkMedia = component?.header?.example?.[0].replace('storage://', '');
          if (linkMedia) {
            this.fileService.downloadFileV3(linkMedia).subscribe({
              next: res => {
                if (res) {
                  this.messageHeader = res.url;
                  const blobFile = new Blob([new Uint8Array(res.body)], {
                    type: `${res.headers.get('content-type')}`
                  });

                  const ext = this.messageHeader.split('?')[0].split('.').pop();
                  this.file = new File([blobFile], `example.${ext}`, { type: blobFile.type });
                  this.fileUI = new UIFileUpload(this.file);
                  if (component.header.format === ComponentStructureFormat.IMAGE) {
                    const image = new Image();
                    image.onload = () => {
                      this.fileUI.width = image.width;
                      this.fileUI.height = image.height;
                    };
                    image.src = this.messageHeader;
                  }
                  this.cdr.detectChanges();
                }
              },
              error: () => this.toastService.error(`Can't get media from storage`)
            });
          }
        } else {
          this.messageHeader = null;
        }
      }
    }

    if (component.body) {
      if (component.body.hasParameters) {
        this.formBody = this.fb.group({});
        const { mapParamsExample, arrayParams } = component.body;
        arrayParams.forEach(item => {
          const ctrol = new FormControl(mapParamsExample[item].value, [Validators.required]);
          this.formBody.addControl(`${item}`, ctrol);
        });

        let convertMatchText = component.body.text;
        const matched = convertMatchText.match(WhatsappTemplateRegex);
        const channelMatchs: WidgetMatched[] = [];
        if (matched) {
          matched.forEach(item => {
            const random: string = guid();
            convertMatchText = convertMatchText.replace(item, random);
            channelMatchs.push({ random: random, text: item, index: this.findIndexInTemplate(item) });
          });
        }

        this.formBody.valueChanges
          .pipe(startWith(this.formBody.value), takeUntil(this._destroyValueChange$))
          .subscribe(value => {
            let mappingText = convertMatchText;
            channelMatchs.forEach((item, i) => {
              const replaceText = value?.[item.text]
                ? `<span class='edited'><span class="wa-badge">${item.index + 1}</span> ${value?.[item.text]}</span>`
                : `<span class='no-edit'>${item.text}</span>`;
              mappingText = mappingText.replace(item.random, replaceText);
            });
            this.messageBody = mappingText;
          });
      } else {
        this.messageBody = component.body.text;
      }
    }

    this.buttonsHasParameter = false;
    if (component.buttons) {
      this.buttonsHasParameter = component.buttons.some(b => b.hasParameters);
      this.activeBtns ? (this.activeBtns.length = 0) : (this.activeBtns = []);
      component.buttons.forEach(b => {
        this.activeBtns.push(false);
        if (b.hasParameters) {
          const { mapParamsExample, arrayParams } = b;
          arrayParams.forEach(item => {
            const ctrol = new FormControl(mapParamsExample[item].value, Validators.required);
            this.formButton.addControl(`${item}`, ctrol);
          });

          let convertMatchText;
          if (b.type) {
            convertMatchText = b.url ?? '';
          } else {
            convertMatchText = b.text;
          }

          const matched = convertMatchText.match(WhatsappTemplateRegex);
          const channelMatchs: WidgetMatched[] = [];
          if (matched) {
            matched.forEach(item => {
              const random: string = guid();
              convertMatchText = convertMatchText.replace(item, random);
              channelMatchs.push({ random: random, text: item, index: this.findIndexInTemplate(item) });
            });
          }
        }
      });
    }
  }

  private findIndexInTemplate(text: string) {
    try {
      const number = text.replace(`{{`, '').replace(`}}`, '');
      return +number - 1;
    } catch (error) {
      return null;
    }
  }
}

class TxnCustom extends Txn {
  hasPermission: boolean;
  inbox: Inbox;

  constructor(obj?: Partial<TxnCustom>) {
    super();
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
