import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatMessage, PinMessageQuery, PinMessageRequest, PinMessageService, UserType } from '@b3networks/api/chat';
import { ToastService } from '@b3networks/shared/ui/toast';
import { finalize } from 'rxjs';
import { ConfigMessageOption } from '../../chat-message/chat-message.component';

const MAX_PIN_SIZE = 5;

export interface PinMessageData {
  message: ChatMessage;
  isPin: boolean;
}

@Component({
  selector: 'b3n-pin-message',
  templateUrl: './pin-message.component.html',
  styleUrls: ['./pin-message.component.scss']
})
export class PinMessageComponent implements OnInit {
  configMessageOption: ConfigMessageOption = {
    isHideAction: true
  };
  loading: boolean;
  isNotAllowPin: boolean;

  readonly MAX_PIN_SIZE = MAX_PIN_SIZE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PinMessageData,
    public dialogRef: MatDialogRef<PinMessageComponent>,
    private pinMessageService: PinMessageService,
    private toastService: ToastService,
    private pinMessageQuery: PinMessageQuery,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.isNotAllowPin = this.isMaxPin();
  }

  submit() {
    if (this.isMaxPin()) {
      this.toastService.error(`Maximun pinned messsages is ${6}`);
      return;
    }

    this.loading = true;
    const req = <PinMessageRequest>{
      convoId: this.data.message.convo,
      convoType: this.data.message.ct,
      userType: UserType.TeamMember,
      messageId: this.data.message.id
    };
    (this.data.isPin ? this.pinMessageService.pinMessage(req) : this.pinMessageService.unpinMessage(req))
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        () => {
          this.toastService.success(`${this.data.isPin ? 'Pinned' : 'Unpinned'} message successfully!`);
          this.ngZone.run(() => {
            this.dialogRef.close(true);
          });
        },
        err => this.toastService.error(err?.message)
      );
  }

  private isMaxPin() {
    if (!this.data.isPin) {
      return false;
    }
    const count = this.pinMessageQuery.getCountPinByChannel(this.data.message?.convo);
    return count >= MAX_PIN_SIZE;
  }
}
