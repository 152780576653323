import { Extension } from '@b3networks/api/bizphone';
import { TxnChannel, TxnStatus } from '@b3networks/api/inbox';
import { HashMap } from '@datorama/akita';
import { LicenceType } from '../licence/licence';
import { Me } from '../me/me.model';
import { quotaConfig } from '../org-config/org-config.model';

export interface AgentId {
  domain: string;
  identityUuid: string;
  label: string;
  orgUuid: string;
}

export class AgentConfig {
  id: AgentId;
  extKey: string;
  extLabel: string;
  licence: LicenceType;
  popupShowedOn: PopupShowedOn;
  extension?: Extension;
  quotaConfig: quotaConfig;
  focusMode: boolean;
  constructor(obj?: Partial<AgentConfig>) {
    if (obj) {
      if (obj.extension) {
        obj.extension = new Extension(obj.extension);
      }
      Object.assign(this, obj);
    }
  }

  get displayText() {
    return this.extLabel ? `${this.extLabel} (#${this.extKey})` : `Extension ${this.extKey} (#${this.extKey})`;
  }
}

export class AgentConfigReq {
  quotaConfig: quotaConfig;
  popupShowedOn: PopupShowedOn;
  focusMode: boolean;
  constructor(obj?: Partial<AgentConfigReq>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class AssignAgent {
  memberUuid: string;
  displayName: string;
  agentStatus: AgentStatus;
  extKey: string;
  extLabel: string;
  identityUuid: string;
  constructor(obj?: Partial<AssignAgent>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
  get displayText() {
    return this.displayName
      ? `${this.displayName} ${this.extKey ? `(#${this.extKey})` : ''}`
      : `${this.extLabel} ${this.extKey ? `(#${this.extKey})` : ''}` ?? '';
  }
}

export enum SystemStatusCode {
  dialing = 'dialing',
  talking = 'talking',
  acw = 'acw',
  free = 'free',
  blockDial = 'blockDial',
  away = 'away'
}

export interface SystemStatus {
  systemStatusExpiredAt: number;
  lastAssignedAt: number;
  lastUnassignedAt: number;
  lastPickupAt: number;
  lastFreeAt: number;
  status: SystemStatusCode;
}

export enum AgentStatus {
  available = 'available',
  busy = 'busy',
  offline = 'offline',
  dnd = 'dnd',
  enabled = 'enabled',
  disabled = 'disabled'
}

export enum AgentState {
  available = 'Available',
  wrapping = 'Wrapping',
  talking = 'Talking',
  dialing = 'Dialing',
  busy = 'Busy',
  offline = 'Offline',
  away = 'Away',
  inConversation = 'In Conversation',
  ACW = 'ACW'
}

export enum PopupShowedOn {
  none = 'none',
  web = 'web',
  app = 'app',
  webNapp = 'webNapp'
}

export interface AssignedQueue {
  uuid: string;
  label: string;
}

export interface AgentMember {
  identity: {
    uuid: string;
    displayName: string;
  };
  createdDateTime: number;
}

export interface ChatStatusAgent {
  channel: TxnChannel;
  status: TxnStatus;
}

export class Agent {
  identityUuid: string;
  extKey: string;
  extLabel: string;
  licence: LicenceType;
  numberOfQueue: number;

  status: AgentStatus;
  systemStatus: SystemStatusCode;
  statusDuration: number;
  busyReason: string;

  // omni
  chatStatus: HashMap<ChatStatusAgent> = {};

  // assignedQueues: AssignedQueue[] = []; // update when get assigned queues

  // ui
  chatStatusArray: ChatStatusAgent[] = [];

  constructor(obj?: Partial<Agent>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.chatStatus) {
        this.chatStatusArray = Object.values(obj?.chatStatus) || [];
      }
    }
  }

  get displayText() {
    return `${this.extKey} - ${this.extLabel}`;
  }

  get displayStatus() {
    const displayedStatus = `${this.extKey} - ${this.extLabel}`;
    return displayedStatus?.length > 16 ? displayedStatus?.slice(0, 16) + '...' : displayedStatus;
  }

  get tooltip() {
    const displayedStatus = `${this.extKey} - ${this.extLabel}`;
    return displayedStatus?.length > 16 ? displayedStatus : '';
  }

  get hasChatOmniAssigned() {
    return this.chatStatusArray.some(
      x => [TxnChannel.livechat, TxnChannel.whatsapp].includes(x.channel) && x.status === TxnStatus.assigned
    );
  }

  // https://b3networks.atlassian.net/browse/B3-12693
  get state() {
    let state: AgentState;
    if (
      (this.systemStatus === SystemStatusCode.free || this.systemStatus === SystemStatusCode.blockDial) &&
      this.status === AgentStatus.available
    ) {
      state = AgentState.available;
    } else if (this.systemStatus === SystemStatusCode.talking) {
      state = AgentState.inConversation;
    } else if (this.systemStatus === SystemStatusCode.acw) {
      state = AgentState.ACW;
    } else if (this.systemStatus === SystemStatusCode.dialing) {
      state = AgentState.dialing;
    } else if (this.systemStatus === SystemStatusCode.away || this.status === AgentStatus.dnd) {
      state = AgentState.away;
    } else if (this.status === AgentStatus.busy) {
      state = AgentState.busy;
    } else if (this.status === AgentStatus.offline) {
      state = AgentState.offline;
    }

    const hasChatOmniAssigned = this.hasChatOmniAssigned;
    if (hasChatOmniAssigned) {
      if (state !== AgentState.ACW) {
        state = AgentState.inConversation;
      }
    }

    return state;
  }

  get isSupervisor() {
    return this.licence === LicenceType.supervisor;
  }

  get statusPriority() {
    //Avai > Busy > Away > Off
    if (this.status === AgentStatus.available) {
      return 1;
    } else if (this.status === AgentStatus.busy) {
      return 2;
    } else if (this.status === AgentStatus.dnd) {
      return 3;
    } else {
      return 4;
    }
  }

  syncupNewStatus(agent: Me | Agent) {
    this.status = agent.status;
    this.systemStatus = agent.systemStatus;
    this.statusDuration = 0; // reset status duration
  }
}

export interface RequestFinishTxnAcwV2 {
  txnUuid: string;
  noteTemplateId: string;
  noteVersion: string | '2'; // should be 2
  tag: HashMap<string>; // key - value
}
