import { Pipe, PipeTransform } from '@angular/core';
import { ChannelQuery } from '@b3networks/api/chat';
import { of } from 'rxjs';

@Pipe({
  name: 'selectConvo'
})
export class SelectConvoPipe implements PipeTransform {
  constructor(private channelQuery: ChannelQuery) {}

  transform(channelId: string) {
    if (!channelId) {
      return of(null);
    }

    return this.channelQuery.selectEntity(channelId);
  }
}
