export const LOCATES = {
  'ar-EG': 'Arabic (Egypt)',
  'ar-SA': 'Arabic (Saudi Arabia)',
  bg: 'Bulgarian',
  ca: 'Catalan',
  cs: 'Czech',
  da: 'Danish',
  de: 'German (Germany)',
  'de-AT': 'German (Austria)',
  'de-CH': 'German (Switzerland)',
  el: 'Greek',
  en: 'English (US)',
  'en-AU': 'English (Australia)',
  'en-IE': 'English (Ireland)',
  'en-CA': 'English (Canada)',
  'en-IN': 'English (India)',
  'en-SG': 'English (Singapore)',
  'en-UK': 'English (British)',
  es: 'Spanish (Spain)',
  'es-MX': 'Spanish (Mexico)',
  fi: 'Finish',
  fr: 'French (France)',
  'fr-CA': 'French (Canada)',
  'fr-CH': 'French (Switzerland)',
  he: 'Hebrew',
  hi: 'Hindi',
  hr: 'Croatian',
  hu: 'Hungarian',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  ms: 'Malaysian',
  nl: 'Dutch',
  no: 'Norwegian',
  po: 'Polish',
  pt: 'Portuguese (Portugal)',
  'pt-BR': 'Portuguese (Brazil)',
  ro: 'Romanian',
  ru: 'Russian',
  sk: 'Slovak',
  sl: 'Slovenian',
  sv: 'Swedish',
  ta: 'Tamil',
  th: 'Thai',
  tr: 'Turkish',
  vi: 'Vietnamese',
  zh: 'Chinese',
  'zh-HK': 'Chinese (Hongkong)',
  'zh-TW': 'Chinese (Taiwan)'
};
