<div
  *ngIf="message"
  class="container flex column"
  [class.hight-light]="isHighlight || isEditing"
  [class.no-hover]="configMessageOption.noHoverAffect"
  (contextmenu)="onRightClick($event)"
>
  <div class="header-reply" *ngIf="position === MessagePosition.first && msgReply">
    <csh-reply-message
      [msgReply]="msgReply"
      [participants]="participants"
      [parentElr]="parentElr"
      (jumpToMessage)="jumpToMessage.emit($event)"
      (showProfile)="showProfile.emit($event)"
    ></csh-reply-message>
  </div>

  <div [id]="message?.id" class="chat_message flex justify-content-start align-items-start gap-8">
    <div *ngIf="position === MessagePosition.first" class="chat_message__avatar">
      <ng-container *ngIf="user; else asyncUser">
        <b3n-avatar-user
          [member]="user"
          [parentElr]="parentElr"
          (showProfile)="showProfile.emit($event)"
        ></b3n-avatar-user>
      </ng-container>
      <ng-template #asyncUser>
        <ng-container *ngIf="user$ | async as user">
          <b3n-avatar-user
            [member]="user"
            [parentElr]="parentElr"
            (showProfile)="showProfile.emit($event)"
          ></b3n-avatar-user>
        </ng-container>
      </ng-template>
    </div>

    <div class="chat_message__row flex align-items-center">
      <div class="chat_message__time" *ngIf="position === MessagePosition.middle && message.mt !== MsgType.system">
        <!-- // clipboard use time_msg -->
        <div class="secondary-text time_msg">
          {{ messageRender.buildMsg?.displayTime }}
        </div>
      </div>
      <div class="chat_message__content flex column align-items-start">
        <div *ngIf="position === MessagePosition.first" class="content_header flex align-items-baseline gap-8">
          <div class="flex align-items-baseline gap-4">
            <ng-container *ngIf="user; else asyncUser">
              <div class="content_header__title" (click)="onShowProfile($event, user)">
                {{ user.displayName }}
              </div>
            </ng-container>
            <ng-template #asyncUser>
              <ng-container *ngIf="user$ | async as user">
                <div class="content_header__title" (click)="onShowProfile($event, user)">
                  {{ user.displayName }}
                </div>
              </ng-container>
            </ng-template>

            <ng-container *ngIf="configMessageOption?.isBookmarkChannel">
              <ng-container *ngIf="nameConvo; else asyncConvo">
                <span>- </span>
                <div
                  class="content_header__convo"
                  matTooltip="Jump to channel"
                  (click)="canJumpChannel && jumpChannel()"
                >
                  {{ nameConvo }}
                </div>
              </ng-container>
              <ng-template #asyncConvo>
                <ng-container *ngIf="nameConvo$ | async as name">
                  <span>- </span>
                  <div
                    class="content_header__convo"
                    matTooltip="Jump to channel"
                    (click)="canJumpChannel && jumpChannel()"
                  >
                    {{ name }}
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>

          <div
            class="content_header__time time_msg secondary-text"
            [matTooltip]="message.buildMsg.fullTime"
            matTooltipPosition="above"
          >
            {{ message.buildMsg?.displayTime }}
            <ng-container *ngIf="configMessageOption.fullDate">
              {{ message.ts | viewDate }}
            </ng-container>
          </div>
        </div>
        <div class="content_body">
          <ng-container *ngIf="!message.aclActions.isDeletedMessage; else deletedMessage">
            <ng-container [ngSwitch]="messageRender.mt">
              <csh-attachment-message
                *ngSwitchCase="MsgType.attachment"
                [parentElr]="parentElr"
                [message]="messageRender"
                [isHideAction]="configMessageOption.isHideAction"
                [configMessageOption]="configMessageOption"
                (showProfile)="showProfile.emit($event)"
              ></csh-attachment-message>
              <ng-container *ngSwitchCase="MsgType.imess">
                <csh-interactive-message
                  *ngIf="message.state.isInteractiveV2; else webhook"
                  [message]="messageRender"
                  (showProfile)="showProfile.emit($event)"
                ></csh-interactive-message>
                <ng-template #webhook>
                  <csh-webhook-message [message]="messageRender"></csh-webhook-message>
                </ng-template>
              </ng-container>
              <csh-pre-chat-survey-message
                *ngSwitchCase="MsgType.prechatsurvey"
                [message]="messageRender"
              ></csh-pre-chat-survey-message>
              <b3n-wa-template-message
                *ngSwitchCase="MsgType.waTemplate"
                [parentElr]="parentElr"
                [message]="messageRender"
              ></b3n-wa-template-message>
              <csh-summary-message *ngSwitchCase="MsgType.summary" [message]="messageRender"></csh-summary-message>
              <csh-normal-message
                *ngSwitchDefault
                [message]="messageRender"
                [participants]="participants"
                [isEditing]="isEditing"
                [isExpand]="isExpand"
                [parentElr]="parentElr"
                [configMessageOption]="configMessageOption"
                (edited)="onEditedMessage($event)"
                (mentionsChanged)="onMentionsChanged($event)"
                (showProfile)="showProfile.emit($event)"
                (jumpToMessage)="jumpToMessage.emit($event)"
                (expandMsg)="onExpandMsg($event)"
                (seeAllPinned)="seeAllPinned.emit($event)"
              ></csh-normal-message>
            </ng-container>

            <ng-container *ngIf="isShowActions">
              <div
                class="actions-container flex justify-content-end align-items-center"
                [style.top]="positionAction"
                [class.visibility]="isShowingMenu"
                *ngIf="
                  allowRemoveBookmark ||
                  message.aclActions.allowUseAddBookmark ||
                  allowUseReplyAction ||
                  (allowEditDeleteMessage && withinLimitV2) ||
                  allowPinOrUnpinAction
                "
              >
                <div
                  *ngIf="allowUseReplyAction"
                  class="actions-container__item"
                  matTooltip="Reply"
                  matTooltipPosition="above"
                  (click)="replyMessage()"
                >
                  <mat-icon>reply</mat-icon>
                </div>

                <!-- <div
                  *ngIf="allowUseCreateThreadAction"
                  class="actions-container__item"
                  (click)="createDiscussion()"
                  matTooltip="Create discussion"
                  matTooltipPosition="above"
                >
                  <mat-icon [svgIcon]="'thread_outline'"></mat-icon>
                </div> -->

                <div
                  *ngIf="configMessageOption.isBookmarkChannel && canJumpChannel"
                  class="actions-container__item"
                  (click)="jumpChannel()"
                  matTooltip="Jump to channel"
                  matTooltipPosition="above"
                >
                  <mat-icon>move_up</mat-icon>
                </div>

                <div
                  *ngIf="allowRemoveBookmark"
                  class="actions-container__item"
                  (click)="deleteBookmark()"
                  matTooltip="Remove from bookmark"
                  matTooltipPosition="above"
                >
                  <mat-icon>bookmark_remove</mat-icon>
                </div>
                <div
                  *ngIf="message.aclActions.allowUseAddBookmark"
                  class="actions-container__item"
                  matTooltip="Add to bookmark"
                  matTooltipPosition="above"
                  (click)="addBookmark()"
                >
                  <mat-icon>bookmark_add</mat-icon>
                </div>

                <div
                  *ngIf="allowCopyLink"
                  class="actions-container__item"
                  (click)="copyLinkMessage()"
                  matTooltip="Copy link"
                  matTooltipPosition="above"
                >
                  <mat-icon>link</mat-icon>
                </div>

                <div
                  *ngIf="(allowEditDeleteMessage && withinLimitV2) || allowPinOrUnpinAction"
                  [matMenuTriggerFor]="menu"
                  class="actions-container__item"
                  matTooltip="More actions"
                  matTooltipPosition="above"
                  (menuOpened)="menuOpenedActions()"
                  (menuClosed)="isShowingMenu = false"
                >
                  <mat-icon> more_vert </mat-icon>
                </div>

                <mat-menu #menu="matMenu" class="custom">
                  <ng-container *ngIf="allowPinOrUnpinAction">
                    <button *ngIf="!isPinned" mat-menu-item (click)="pinMsg(true)">
                      <mat-icon>keep</mat-icon>
                      <span>Pin message</span>
                    </button>
                    <button *ngIf="isPinned" mat-menu-item (click)="pinMsg(false)">
                      <mat-icon>keep_off</mat-icon>
                      <span>Unpin message</span>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="allowEditDeleteMessage && withinLimitV2">
                    <button mat-menu-item (click)="edit()">
                      <mat-icon>edit</mat-icon>
                      <span>Edit message</span>
                    </button>

                    <button mat-menu-item (click)="delete()" class="delete-message">
                      <mat-icon color="warn" class="inherit-color">delete</mat-icon>
                      <span class="b3n-warn">Delete message</span>
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #deletedMessage>
            <div class="flex justify-content-start align-items-none">
              <span class="deleted-msg" [innerHTML]="messageRender?.body?.text"></span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
