import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelType } from '@b3networks/api/chat';
import { User } from '@b3networks/api/workspace';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { Observable } from 'rxjs';
import { SupportedConvo } from '../../../core/adapter/convo-helper.service';

export interface DirectDetailsInput {
  member: User;
  convo: SupportedConvo; // optional to render action blocks
}

@Component({
  selector: 'b3n-direct-details',
  templateUrl: './direct-details.component.html',
  styleUrls: ['./direct-details.component.scss']
})
export class DirectDetailsComponent extends DestroySubscriberComponent implements OnInit {
  archiving: boolean;
  members$: Observable<User[]>;

  readonly ChannelType = ChannelType;

  constructor(
    public dialogRef: MatDialogRef<DirectDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DirectDetailsInput
  ) {
    super();
  }

  ngOnInit() {}
}
