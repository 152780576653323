import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AgentService,
  AssignedTxn,
  CampaignInfo,
  CampaignService,
  CampaignTxnService,
  Me,
  RequestFinishTxnAcwV2,
  SystemStatusCode
} from '@b3networks/api/callcenter';
import { BACKBONE_SUPPORTED, BackboneFieldsType, FieldType, FormQuery, UserDefinedField } from '@b3networks/api/data';
import { DestroySubscriberComponent, WindownActiveService } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, finalize, takeUntil } from 'rxjs';
import { AppQuery } from '../../../core/state/app.query';
import { RequestFinishedCall } from '../pop-up-processor.component';
import { TemplateFieldComponent } from '../template-field/template-field.component';

@Component({
  selector: 'b3n-outbound-txn',
  templateUrl: './outbound-txn.component.html',
  styleUrls: ['./outbound-txn.component.scss']
})
export class OutboundTxnComponent extends DestroySubscriberComponent implements OnInit, OnChanges {
  @ViewChildren(TemplateFieldComponent) comps: QueryList<TemplateFieldComponent>;

  @Input() me: Me;

  @Output() finishCall = new EventEmitter<boolean>();

  request: RequestFinishedCall = {};
  progressing: boolean;
  loadingCampagin: boolean;
  campaign: CampaignInfo;
  inAcwMode = false;
  countDown: number;

  templateFields: UserDefinedField[] = [];
  formGroup = this.fb.group({});

  remainTime$: Observable<number>;

  readonly endedStatuses = ['agentMarkCallDone', 'hangupBySupervisor', 'ended', 'callback', 'voicemail'];
  readonly SystemStatusCode = SystemStatusCode;
  readonly callEndedStatuses = [
    'answered',
    'agentUnanswered',
    'agentTransferFailed',
    'agentBusy',
    'talking',
    'customerBusy',
    'customerTransferFailed',
    'customerUnanswered'
  ];

  get hasCustomField() {
    return this.me?.assignedTxn?.queue?.customFields?.length > 0;
  }

  get isACW() {
    return (
      this.me.assignedTxn?.status !== 'dialingAgent' &&
      this.me.assignedTxn?.status !== 'dialingCustomer' &&
      this.me.assignedTxn?.status !== 'previewing' &&
      (this.me.assignedTxn?.status !== 'talking' || this.inAcwMode)
    );
  }

  constructor(
    private fb: FormBuilder,
    private campaignSevice: CampaignService,
    private campaignTxnService: CampaignTxnService,
    private toastService: ToastService,
    private formQuery: FormQuery,
    private appQuery: AppQuery,
    private agentService: AgentService,
    private windownActiveService: WindownActiveService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['me']) {
      this.showNotificationIfNeeded(changes['me'].previousValue);
      this.inAcwMode = this.me.inAcwMode;
    }
  }

  ngOnInit() {
    this.remainTime$ = this.appQuery.remainTime$;

    let _preValue: number;
    this.appQuery.systemStatusExpiredAt$.pipe(takeUntil(this.destroySubscriber$)).subscribe(systemStatusExpiredAt => {
      if (!systemStatusExpiredAt && !!_preValue) {
        console.log('windowActiveStatus: ', this.windownActiveService.windowActiveStatus);
        if (this.windownActiveService.windowActiveStatus) {
          this.takeAction('done');
        } else {
          this.finishCall.emit(true);
        }
      }
      _preValue = systemStatusExpiredAt;
    });

    this.fetchCampaignDetails();
  }

  copy() {
    this.toastService.success('Copied to clipboard');
  }

  fetchCampaignDetails() {
    if (this.me.assignedTxn.campaignUuid) {
      this.loadingCampagin = true;
      this.campaignSevice
        .getCampaign(this.me.assignedTxn.campaignUuid)
        .pipe(finalize(() => (this.loadingCampagin = false)))
        .subscribe(
          res => {
            this.campaign = res;

            const templateUuid = this.me?.assignedTxn?.queue?.agentWorkflowConfig?.noteTemplateId;
            const template = this.formQuery.getEntity(templateUuid);
            const formOrder = template?.formOrder || [];
            this.templateFields = [];
            formOrder.forEach(key => {
              const data = template.fields?.find(x => x.key === key);
              if (data) {
                this.templateFields.push(data);
              }
            });

            this.templateFields.forEach(field => {
              this.setValueFormControl(field);
            });
          },
          err => {
            this.toastService.error(err.message);
          }
        );
    }
  }

  takeAction(action: string) {
    if (!this.progressing) {
      this.progressing = true;
      this.request.action = action;
      this.request.txnUuid = this.me.assignedTxn.txnUuid;
      this.request.noteTemplateId = this.me?.assignedTxn?.queue?.agentWorkflowConfig?.noteTemplateId;

      const values = {};
      const templateUuid = this.me?.assignedTxn?.queue?.agentWorkflowConfig?.noteTemplateId;
      const template = this.formQuery.getEntity(templateUuid);
      template.fields?.forEach(field => {
        const prop = field.key;
        const form = this.formGroup.get(prop);
        if (form && form.valid) {
          values[prop] = form.value;
        } else {
          if (BACKBONE_SUPPORTED.includes(prop)) {
            if (prop === BackboneFieldsType.inboxName) {
              values[prop] = this.me?.assignedTxn?.queue?.label;
            } else if (prop === BackboneFieldsType.inboxUuid) {
              values[prop] = this.me?.assignedTxn?.queue?.uuid;
            } else if (prop === BackboneFieldsType.txnUuid) {
              values[prop] = this.me.assignedTxn.txnUuid;
            }
          }
        }
      });

      this.request.tag = values;
      (action === 'done'
        ? this.agentService.finishTxnAcwV2(this.buildRequest())
        : this.campaignTxnService.updateCampaign(this.me.assignedTxn.campaignUuid, this.request)
      )
        .pipe(finalize(() => (this.progressing = false)))
        .subscribe(
          _ => {
            this.toastService.success(this.successMsg(this.request, this.me.assignedTxn));
            this.finishCall.emit(true);
          },
          err => {
            this.toastService.error(err.message.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()));
            this.finishCall.emit(true);
          }
        );
    }
  }

  private successMsg(req: RequestFinishedCall, txn: AssignedTxn) {
    if (req.action === 'skip') {
      return `You have just skipped customer who has number ${txn.customerNumber}`;
    } else if (req.action === 'dial') {
      return `Submited request to dial number ${txn.customerNumber}`;
    } else if (req.action === 'done') {
      return `Submited your note to server`;
    }

    return `${req.action.toUpperCase()} successfully`;
  }

  private showNotificationIfNeeded(preMe: Me) {
    if (!preMe) {
      return;
    }

    if (!this.me || !this.me.assignedTxn || !preMe.assignedTxn) {
      return;
    }

    if (this.me.assignedTxn.txnUuid === preMe.assignedTxn.txnUuid) {
      return;
    }

    this.request.code = '';
    this.request.note = '';
    this.request.tag = {};
    this.toastService.success(`You have been assigned to new auto dialer call to ${preMe.assignedTxn.customerNumber}`);
  }

  private buildRequest() {
    const values = {};
    this.comps.forEach(comp => {
      const prop = comp.field.key;
      const value = comp.ctrl?.value;
      if (value && comp.ctrl.valid) {
        if (BACKBONE_SUPPORTED.includes(prop)) {
          if (prop === BackboneFieldsType.inboxName) {
            values['inboxName'] = this.me?.assignedTxn?.queue?.label;
          } else if (prop === BackboneFieldsType.inboxUuid) {
            values['inboxUuid'] = this.me?.assignedTxn?.queue?.uuid;
          } else if (prop === BackboneFieldsType.txnUuid) {
            values['txnUuid'] = this.me.assignedTxn.txnUuid;
          }
        } else {
          values[prop] = value;
        }
      }
    });
    const noteTemplateId = this.me?.assignedTxn?.queue?.agentWorkflowConfig?.noteTemplateId;
    const request = <RequestFinishTxnAcwV2>{
      noteVersion: '2',
      noteTemplateId,
      tag: values,
      txnUuid: this.me.assignedTxn.txnUuid
    };

    return request;
  }

  private setValueFormControl(field: UserDefinedField) {
    const tag = this.appQuery.getValue().popupState?.tag || {};
    const value = tag?.[field.key] as any;
    const control = this.fb.control('');
    switch (field?.type) {
      case FieldType.text:
        if (!field.isBackbone) {
          control.setValidators(Validators.maxLength(256));
        }
        control.setValue(value);
        break;
      case FieldType.textarea:
        if (!field.isBackbone) {
          control.setValidators(Validators.maxLength(2000));
        }
        control.setValue(value);
        break;
      case FieldType.long:
        if (!field.isBackbone) {
          control.setValidators([
            Validators.max(1000000000000000),
            Validators.min(-1000000000000000),
            Validators.pattern(/^\d+$/)
          ]);
        }
        control.setValue(value);
        break;
      case FieldType.double:
        if (!field.isBackbone) {
          control.setValidators([Validators.max(1000000000000000), Validators.min(-1000000000000000)]);
        }
        control.setValue(value);
        break;
      case FieldType.options:
        control.setValue(value);
        break;
    }
    control.markAsTouched();
    this.formGroup.addControl(field.key, control);
  }
}
