import { Pipe, PipeTransform } from '@angular/core';
import { UserQuery, UserService } from '@b3networks/api/workspace';
import { firstValueFrom } from 'rxjs';

@Pipe({
  name: 'identityUuidName'
})
export class IdentityUuidNamePipe implements PipeTransform {
  constructor(private userQuery: UserQuery, private userService: UserService) {}

  async transform(value: string): Promise<string> {
    const user = this.userQuery.getUserByUuid(value);

    if (user) {
      return user.displayName;
    }

    const users = await firstValueFrom(this.userService.findByUuidAndUserType([value], 'identity'));

    if (!users.length) {
      return '-';
    }

    return users[0].displayName;
  }
}
