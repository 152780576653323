import { Injectable } from '@angular/core';
import { EntityUIQuery, ID, Order, QueryEntity } from '@datorama/akita';
import { TxnChannel } from '../txn/txn.model';
import { InboxUI } from './inboxes-ui.model';
import { Inbox } from './inboxes.model';
import { InboxUIState, InboxesState, InboxesStore } from './inboxes.store';

@Injectable({ providedIn: 'root' })
export class InboxesQuery extends QueryEntity<InboxesState> {
  override ui: EntityUIQuery<InboxUIState>;

  all$ = this.selectAll();
  orgInboxConfig$ = this.select('orgInboxConfig');
  listWhatsappNumber$ = this.select('listWhatsappNumber');
  supplierInboxes$ = this.select('supplierInboxes');
  constructor(protected override store: InboxesStore) {
    super(store);
    this.createUIQuery();
  }

  getInbox(inboxUuid: string | ID) {
    return this.getEntity(inboxUuid);
  }

  selectByChannelAndKeyword(keyword: string, channel: TxnChannel) {
    return this.selectAll({
      filterBy: entity => entity.channel === channel && entity?.name?.toUpperCase().includes(keyword?.toUpperCase())
    });
  }

  selectAllInbox() {
    return this.selectAll({
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }

  selectInboxByChannel(channel: TxnChannel) {
    return this.selectAll({
      filterBy: entity => entity.channel === channel,
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }

  selectMyInbox(identityUuid: string) {
    return this.selectAll({
      filterBy: entity => entity.isMyInbox || entity.agents?.includes(identityUuid),
      sortBy: 'createdAt',
      sortByOrder: Order.DESC
    });
  }

  getInboxByQueue(queueUuid) {
    return this.getAll().find(x => x.queueUuid === queueUuid);
  }

  selectPropertyInbox<K extends keyof Inbox>(id: string, property: K) {
    return this.selectEntity(id, property);
  }

  getUiState(inboxUuid: string | ID) {
    return this.ui.getEntity(inboxUuid);
  }

  selectUIState<K extends keyof InboxUI>(id: string | ID, property: K) {
    return this.ui.selectEntity(id, property);
  }
}
