import { Injectable } from '@angular/core';
import { ChannelStateV3 } from '@b3networks/api/contact';
import { PER_PAGE_TXN } from '@b3networks/shared/common';
import { Store, StoreConfig } from '@datorama/akita';
import { AppState, CollapseSidebar, NameAppStore, SplitMode, TxnFilterState } from './app-state.model';

export function createInitialState(): AppState {
  return <AppState>{
    showMainSidebar: false,
    sidebarTabActive: null,
    emailUWState: {
      isExpandPersonal: true,
      isExpandTeam: false,
      isExpandTeammate: false
    },
    endTxnsUWState: {
      hasMore: false,
      perPage: 100,
      page: 1
    },
    endTxnsUWOrgState: {
      hasMore: false,
      perPage: 100,
      page: 1
    },
    mentionCountTeamChat: 0,
    quillEditor: {
      triggerfocus: false
    },
    popupState: {
      isShowPopup: false,
      isLoadingTemplate: false,
      tag: {}
    },
    isLeftChatSidebar: false,
    isCollapseAssignedToMe: false,
    isCollapseAllInbox: true,
    triggerRefreshTxn: false,
    customerTxnReports: [],
    collapseSidebar: <CollapseSidebar>{
      inboxes: true,
      me: false,
      starred: false,
      watching: true,
      related: true,
      created: true
    },
    submittedTicket: <TxnFilterState>{
      txnFilter: <ChannelStateV3>{
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: []
    },
    splitMode: SplitMode.horizontalSplit,
    isFetchingTxnDetail: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: NameAppStore })
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }
}
