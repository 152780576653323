import { Injectable } from '@angular/core';
import { ChannelStateV3 } from '@b3networks/api/contact';
import { PER_PAGE_TXN } from '@b3networks/shared/common';
import { ActiveState, EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { WhatsappNumbers } from '../whatsapp/wa-numbers/wa-numbers.model';
import { InboxUI } from './inboxes-ui.model';
import { Inbox, OrgInboxConfig } from './inboxes.model';

export interface InboxesState extends EntityState<Inbox>, ActiveState {
  orgInboxConfig: OrgInboxConfig;
  listWhatsappNumber: WhatsappNumbers[];
}

export type InboxUIState = EntityState<InboxUI>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox-inboxes', idKey: 'uuid' })
export class InboxesStore extends EntityStore<InboxesState> {
  override ui: EntityUIStore<InboxUIState>;
  constructor() {
    super();

    this.createUIStore({}, { deepFreezeFn: obj => obj }).setInitialEntityState(
      entity =>
        <InboxUI>{
          isShowAssignOther: true,
          txnFilterV4: <ChannelStateV3>{
            loaded: false,
            page: 1,
            perPage: PER_PAGE_TXN,
            hasMore: false
          },
          txnUuidsFilter: [],
          txnActive: {
            loaded: false,
            page: 1,
            perPage: 10,
            hasMore: false
          },
          txnPending: {
            loaded: false,
            page: 1,
            perPage: 10,
            hasMore: false
          }
        }
    );
  }
}
