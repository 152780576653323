<div *ngIf="member" class="container flex column gap-8">
  <div class="header">
    <ng-container *ngIf="!member.hasPhoto || !backgroundImage; else hasAvatar" (click)="$event.stopPropagation()">
      <span class="no-avatar flex justify-content-center align-items-center">
        {{ member.displayName | firstWord }}
      </span>
    </ng-container>
    <ng-template #hasAvatar>
      <div class="avatar" [style.background-image]="backgroundImage"></div>
    </ng-template>

    <mat-icon *ngIf="isDialog" class="close-icon" (click)="closeDialog.emit(true)">close</mat-icon>
  </div>

  <div class="body flex-auto flex column gap-8">
    <div class="flex column align-items-center gap-8" (click)="$event.stopPropagation()">
      <div class="flex gap-4">
        <h2>{{ member.displayName }}</h2>

        <ng-container *ngIf="isDialog">
          <ng-container *ngIf="{ isStarred: isStarred$ | async } as data">
            <mat-icon
              class="icon star-channel s-24 material-symbols-rounded fill"
              *ngIf="data.isStarred"
              (click)="unstarConvo($event); $event.stopPropagation()"
              matTooltip="Unstar this channel"
            >
              star
            </mat-icon>
            <mat-icon
              class="icon star-channel s-24 material-symbols-rounded"
              *ngIf="!data.isStarred"
              (click)="starConvo($event); $event.stopPropagation()"
              matTooltip="Star this channel"
            >
              star_border
            </mat-icon>
          </ng-container>
        </ng-container>
      </div>

      <div class="flex gap-4">
        <ng-container *ngIf="{ isOnline: member.userUuid | userOnline | async } as data">
          <mat-icon
            class="icon s-20 status material-symbols-rounded fill"
            [class]="data.isOnline ? 'status__online' : 'status__offline'"
          >
            fiber_manual_record
          </mat-icon>
          <span class="status" [class]="data.isOnline ? 'status__online' : 'status__offline'">{{
            data.isOnline ? 'Available' : 'Offline'
          }}</span>
        </ng-container>
      </div>

      <ng-container *ngIf="infoMember$ | async as info">
        <span
          class="secondary-text text-center"
          *ngIf="(info?.about || '').trim()"
          [innerHTML]="info?.about | linkify"
        ></span>
      </ng-container>
    </div>
    <div class="body__info flex column gap-8" (click)="$event.stopPropagation()">
      <ng-container *ngIf="infoMember$ | async as info">
        <div *ngIf="info?.teams?.length" class="flex gap-8 align-items-center">
          <div class="icon-widget">
            <mat-icon class="s-20" svgIcon="b3n_group"></mat-icon>
          </div>
          <span>{{ info?.displayTeams }}</span>
        </div>

        <div *ngIf="selectExtension$ | async as extension" class="flex gap-8 align-items-center">
          <div class="icon-widget">
            <mat-icon class="s-20">tag</mat-icon>
          </div>
          <span>#{{ extension?.extKey }}</span>
        </div>

        <div *ngIf="info?.mobileNumber" class="flex gap-8 align-items-center">
          <div class="icon-widget">
            <mat-icon class="s-20">phone_iphone</mat-icon>
          </div>
          <span>{{ info?.mobileNumber }}</span>
        </div>

        <div *ngIf="info?.email" class="flex gap-8 align-items-center">
          <div class="icon-widget">
            <mat-icon class="s-20">mail</mat-icon>
          </div>
          <span>{{ info?.email }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="footer flex gap-8">
    <ng-container *ngIf="member | getRole: convo | async as role">
      <ng-container *ngIf="me?.uuid !== member.uuid && hasPhoneFeature$ | async">
        <ng-container *ngIf="infoMember$ | async as info">
          <ng-container *ngIf="selectExtension$ | async as extension">
            <div
              class="item flex-auto flex flex-wrap justify-content-center align-item-center gap-8"
              [matMenuTriggerFor]="extension?.extKey && info?.mobileNumber ? menuSelectContact : null"
              [matMenuTriggerData]="{ extKey: extension?.extKey, mobileNum: info?.mobileNumber }"
              (click)="$event.stopPropagation(); handleCall(extension?.extKey, info?.mobileNumber)"
            >
              <mat-icon class="s-24" svgIcon="b3n_call"></mat-icon>
              <span>Call</span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- <div class="item flex-auto flex flex-wrap justify-content-center align-item-center gap-8">
        <mat-icon class="s-20">Email</mat-icon>
        <span>Email</span>
      </div> -->

      <ng-container *ngIf="me?.uuid !== member.uuid && convo.type !== ChannelType.dm">
        <div
          class="item flex-auto flex flex-wrap justify-content-center align-item-center gap-8"
          (click)="createOrGetConvo(member)"
        >
          <mat-icon class="s-24">send</mat-icon>
          <span>Message</span>
        </div>

        <ng-container *ngIf="!isGeneral && !convo.isThread">
          <div
            *ngIf="role !== RoleType.guest; else inviteUser"
            class="item flex-auto flex flex-wrap justify-content-center align-item-center gap-8"
            (click)="remove(member)"
          >
            <mat-icon class="s-24 b3n-warn">do_not_disturb_on</mat-icon>
            <span class="b3n-warn">Remove</span>
          </div>

          <ng-template #inviteUser>
            <div
              class="item flex-auto flex flex-wrap justify-content-center align-item-center gap-8"
              (click)="invite()"
            >
              <mat-icon class="s-24">person_add</mat-icon>
              <span>Invite</span>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <mat-menu #menuSelectContact="matMenu">
    <ng-template matMenuContent let-extKey="extKey" let-mobileNum="mobileNum">
      <button mat-menu-item *ngIf="extKey" (click)="call(extKey)">Call #{{ extKey }}</button>
      <button mat-menu-item *ngIf="mobileNum" (click)="call(mobileNum)">Call {{ mobileNum }}</button>
    </ng-template>
  </mat-menu>
</div>
